// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Input, Form, Space, Avatar, Tag, Row, Col, Button, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// Custom
import nailieLogo from 'assets/images/nailie-logo-review.png';
import { useDispatch } from 'hooks/useCustomDispatch';
// Utils
import { getLanguages } from 'utils/lang';
import { CUSTOMER_RANKING } from 'utils/constants';
import { bookingCancel } from 'providers/BookingProvider/actions';
const { Text } = Typography;
const { TextArea } = Input;

const NailistCancelModal = ({
  isShowNailistCancelModal,
  SetIsShowNailistCancelModal,
  booking,
  reason,
  onCallback,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();

  // States
  const customerRank = booking?.extraInfo?.customerRank;
  const [loading, setLoading] = useState(false);
  // submit values for case nailist cancel
  const handleSubmit = (values) => {
    const extraReason = values.messageToCustomer;
    Modal.confirm({
      width: 900,
      centered: true,
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.nailistCancelConfirmContent,
      cancelText: lang.back,
      okType: 'danger',
      onOk: () => {
        setLoading(true);
        dispatch(bookingCancel({ bookingId: booking?.id, reason, extraReason, extraInfo: {} }))
          .then(() => {
            setLoading(false);
            SetIsShowNailistCancelModal(false);
            onCallback();
          })
          .catch(() => setLoading(false));
      },
    });
  };

  return (
    <Modal
      title={<Text strong >{lang.nailistCanCelTitle }</Text>}
      visible={isShowNailistCancelModal}
      onCancel={() => SetIsShowNailistCancelModal(false) }
      destroyOnClose={true}
      width={900}
      centered
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="nailistCancelForm"
          layout="vertical"
          onFinish={(values) => handleSubmit(values)}
        >
          <div style={{ marginBottom: 24 }}>
            <Text strong >{lang.nailistCanCelDescription}</Text>
          </div>
          <div style={{ marginBottom: 24 }}>
            <Text type='danger' strong>{lang.nailistAttentionNote}</Text>
          </div>

          <div style={{ marginBottom: 16 }}>
            <Text type='danger' strong>{lang.reviewImage}</Text>
          </div>

          <div style={{ marginBottom: 24 }}>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f5f5f5', padding: 16 }}>
              <Avatar src={nailieLogo} size={48} />
              <Space direction='vertical' style={{ marginLeft: 16 }}>
                <Text strong>{lang.automaticPostingTitle}</Text>
                <Text>{lang.automaticPostingContent}</Text>
              </Space>
            </div>
          </div>

          <div style={{ margin: '24px 0px' }}>
            <Text strong>{lang.customerInfo}</Text>
          </div>
          <div style={{ marginBottom: 24, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={booking?.customer?.avatar} size={64} />
              <Space direction='vertical' style={{ marginLeft: 16 }}>
                <Text strong>{booking?.customer?.username}</Text>
                <Text>{booking?.customer?.name}</Text>
                <Text>({booking?.customer?.phonetic})</Text>
              </Space>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {customerRank && <Tag color={CUSTOMER_RANKING[customerRank]?.color}>{lang[CUSTOMER_RANKING[customerRank]?.langKey]}</Tag>}
            </div>
          </div>

          <div style={{ marginBottom: 8 }}>
            <Text>{lang.messageToCustomer} </Text>
            <Text type='danger'>({lang.required})</Text>
          </div>
          <Form.Item
            name="messageToCustomer"
            rules={[{ required: true, message: lang.emptyCancelMessageError }]}
          >
            <TextArea
              showCount rows={4}
              maxLength={250}
              placeholder='例）申し訳ありませんが、事情により施術をすることができません。他の日時で再度ご予約頂けると幸いです。キャンセル料は発生しません。'/>
          </Form.Item>

          <div
            className="ant-modal-footer"
            style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
          >
            <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
              <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                <Button type="clear" onClick={() => SetIsShowNailistCancelModal(false)}>
                  {lang.back}
                </Button>
                <Button type="primary" htmlType="submit">
                  OK
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

NailistCancelModal.propTypes = {
  isShowNailistCancelModal: PropTypes.func,
  SetIsShowNailistCancelModal: PropTypes.func,
  booking: PropTypes.object,
  reason: PropTypes.string,
  onCallback: PropTypes.func,
};
export default NailistCancelModal;
