// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Modal, List, Avatar, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

// Utils
import { getLanguages } from 'utils/lang';

const WaitingDoneBookingListModal = ({
  showModal,
  closeModal,
  data,
  handleDetailBooking,
}) => {
  const localData = _orderBy(data, ['startTime'], ['asc']);
  const locale = useSelector((state) => state.i18n.locale);
  const { salon } = useSelector(state => state.salon);
  const utcOffset = moment.tz.zone(salon.timezone).utcOffset(moment());
  const utcOffsetHours = utcOffset / 60;

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const renderBookingDateTime = (obj) => {
    const startTime = moment(obj.startTime);
    const endTime = startTime.clone().add(obj.totalDuration, 'minutes');
    let bookingDate = 'MM-DD';
    let bookingTime = 'HH:mm - HH:mm';
    if (obj.startTime) {
      if (locale === 'en') {
        bookingDate = startTime.format('MM-DD');
      } else {
        bookingDate = startTime.format('M月 D日');
      }
      bookingTime = `${startTime.format('HH:mm')} ~ ${endTime.format('HH:mm')}`;
    }

    return (
      <div className="content-wrapper">
        <span>{bookingDate}</span>
        <span>{bookingTime}</span>
      </div>
    );
  };

  return (
    <Modal
      visible={showModal}
      title={lang.titleStatusWaitingDone}
      okText={lang.confirm}
      footer={null}
      onCancel={closeModal}
      destroyOnClose={true}
      width={640}
      centered
      maskClosable={false}
    >
      <div style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>{lang.WaitingDoneBookingWarning}</div>
      <div style={{ color: 'red', textAlign: 'center' }}>{lang.WaitingDoneBookingWarningImportant}</div>
      <List
        className="waiting-done-booking-list"
        itemLayout="horizontal"
        dataSource={localData}
        renderItem={item => {
          const current = moment().add(utcOffsetHours, 'hours');
          const endTime = moment(_get(item, 'startTime'))
            .add(utcOffsetHours, 'hours')
            .add(_get(item, 'totalDuration'), 'minutes')
            .add(48, 'hours');
          const duration = moment.duration(endTime.diff(current));
          const hours = (duration.days() * 24) + duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          return (
            <div className="booking-item-wrapper" onClick={() => handleDetailBooking(item)}>
              <List.Item
                actions={[<div key="time" style={{ color: 'red' }} className="deadline-time">
                  <span>{lang.deadline}:</span>
                  <span>{hours < 10 ? ('0' + hours) : hours}:</span>
                  <span>{minutes < 10 ? ('0' + minutes) : minutes}:</span>
                  <span>{seconds < 10 ? ('0' + seconds) : seconds}</span>
                </div>]}
              >
                <List.Item.Meta
                  avatar={
                    <div>
                      {_get(item, 'customer.avatar')
                        ? <Avatar
                          size={70}
                          src={<Image src={_get(item, 'customer.avatar')} />}
                        />
                        : <Avatar
                          size={70}
                          icon={<UserOutlined />}
                        />
                      }
                    </div>
                  }
                  title={<b>{_get(item, 'customer.name')} ({_get(item, 'customer.phonetic')})</b>}
                  description={<div className="description">
                    {renderBookingDateTime(item)}
                    <div className="content-wrapper">
                      <span>{lang.employer}：</span>
                      <span>{_get(item, 'orders[0].staff.connections[0].data.name')}</span>
                    </div>
                  </div>}
                />
              </List.Item>
            </div>
          );
        }}
      />
    </Modal>
  );
};

WaitingDoneBookingListModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  onCallbackChangeBookingDateModal: PropTypes.func,
  data: PropTypes.array,
};
export default WaitingDoneBookingListModal;
