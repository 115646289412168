import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import logoBeautyMerit from 'assets/images/beauty-merit.svg';
// utils
import './styles.less';
import { getLanguages } from 'utils/lang';

const { Text } = Typography;
const BM_CODE = 'BM';
const CS_CODE = 'CS';

const SalonConnectBeautyMerit = ({ connectingParty, handleToggle3rdParty, showWarningDisconnect3rdParty }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const locale = useSelector((state) => state.i18n.locale);

  const onChangeStatus = (value) => {
    if (connectingParty === CS_CODE) {
      showWarningDisconnect3rdParty(CS_CODE);
    } else {
      handleToggle3rdParty({ code: BM_CODE, connectingParty });
    }
  };

  return (
    <div style={{ marginTop: 24, display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: 240, height: 80 }}>
        <img src={logoBeautyMerit} className='logo-beauty-merit' />
      </div>
      <div style={{ marginTop: 32, marginBottom: 16 }}><Text strong>{lang.howToConnectBM}</Text></div>
      <div >
        <Text>{lang.turnOnSwitchButton} </Text><br/>
        <Text style={{ marginTop: 16, fontSize: 14 }}>
          BeautyMerit連携を開始する
        </Text>
      </div>

      <div style={{ marginTop: 4, marginBottom: 40 }}>
        <Switch
          style={{ position: 'absolute' }}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          checked={connectingParty === BM_CODE}
          onChange={(value) => onChangeStatus(value)} />
      </div>
      <div style={{ marginBottom: 24 }}>
        <Text>{lang.followProgressSetting }</Text>
      </div>
      <Text>{lang.bmConnectSteps}:</Text>
      <Text>
        {lang.bmStepLine1} <br />
        {lang.bmStepLine2} <br />
        {lang.bmStepLine3}
      </Text>
      <div style={{ marginTop: 24 }}>
        <Text strong>【{lang.notes}】</Text>
      </div>
      <div>
        <Text>
          {lang.bmNoteLine1} <br />
          {lang.bmNoteLine2} <br />
          {locale === 'en' && <>{lang.bmNoteLine3}< br /> </>}
          {lang.bmNoteLine4} <br />
          {lang.bmNoteLine5}
        </Text>
      </div>
    </div>
  );
};

SalonConnectBeautyMerit.propTypes = {
  connectingParty: PropTypes.string,
  handleToggle3rdParty: PropTypes.func,
  showWarningDisconnect3rdParty: PropTypes.func,
};
export default SalonConnectBeautyMerit;
