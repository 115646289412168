import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react';

import environment from 'environment';
import store, { persistor } from '../src/redux/store';
import './index.less';
import App, { history } from './App';
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || '/mysalon');
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Auth0Provider
        domain={environment.api.auth0Domain}
        clientId={environment.api.auth0ClientId}
        audience={environment.api.auth0Audience}
        redirectUri={environment.api.auth0RedirectUri}
        useRefreshTokens
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
