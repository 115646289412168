// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, InputNumber, Input, Typography, Row, Col, Alert } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

// Utils
import { getLanguages } from 'utils/lang';
import { formatYen } from 'utils/stringFormat';

const { Text } = Typography;

const layout = {
  labelCol: { span: 6, offset: 0 },
  wrapperCol: { span: 18, offset: 0 },
};

const ChangePriceModal = ({
  changePriceModal,
  closeChangePriceModal,
  booking,
  onCallbackChangePriceModal,
}) => {
  const [form] = Form.useForm();

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // State
  const [newPrice, setNewPrice] = useState(booking?.newPrice || '');
  const [newPriceFieldVisited, setNewPriceFieldVisited] = useState(false);
  const [changePriceReason, setChangePriceReason] = useState(booking?.changePriceReason || '');

  // Constants
  const minPrice = 100 + booking.discount;
  const invalidTotalPrice = !newPrice || newPrice === '' || newPrice < minPrice || newPrice >= 100000; // Max booking price ¥99,999
  const changePriceReasonDisabled = invalidTotalPrice || newPrice === booking.totalPrice;
  const invalidMemo = !changePriceReasonDisabled && !changePriceReason;
  const okButtonPropsDisabled = invalidTotalPrice || invalidMemo;

  const handleCloseChangePriceModal = () => {
    closeChangePriceModal();
    form.resetFields();
    setNewPrice(form.getFieldValue('newPrice'));
    setNewPriceFieldVisited(false);
    setChangePriceReason(form.getFieldValue('changePriceReason'));
  };

  useEffect(() => {
    form.validateFields();
  }, []);

  useEffect(() => {
    form.setFields([
      { name: 'newPrice', value: booking?.newPrice },
      { name: 'changePriceReason', value: booking?.changePriceReason },
    ]);

    setNewPrice(form.getFieldValue('newPrice'));
    setChangePriceReason(form.getFieldValue('changePriceReason'));
  }, [booking]);

  return (
    <Modal
      visible={changePriceModal}
      title={lang.changePrice}
      okText={lang.editPrice}
      okButtonProps={{
        disabled: okButtonPropsDisabled,
      }}
      onCancel={handleCloseChangePriceModal}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (invalidTotalPrice) { return; }

            onCallbackChangePriceModal({
              ...values,
              newPrice: newPrice,
              changePriceReason: changePriceReason,
            });
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      destroyOnClose={true}
      width={640}
      centered
      maskClosable={false}
    >
      <Alert style={{ marginBottom: 24 }} type="info" showIcon message={lang.completeBookingInstruction} />
      <Form
        {...layout}
        form={form}
        name="ChangePriceForm"
        validateTrigger={['onBlur', 'onChange']}
        initialValues={{
          newPrice: booking?.newPrice ? formatYen(booking?.newPrice) : undefined,
          changePriceReason: booking?.changePriceReason,
        }}
      >
        <Form.Item
          label={lang.total}
          name="newPrice"
          onKeyPress={(e) => {
            const key = e.which || e.keyCode;
            if (key < 48 || key > 57) {
              e.preventDefault();
            }
          }}
          help={
            <Row>
              <Col span={16} offset={8}>
                {invalidTotalPrice && newPriceFieldVisited && (
                  <Text type="danger">
                    {lang.completeBookingSubtotalErrorHead}{minPrice}{lang.completeBookingSubtotalErrorTail}
                  </Text>
                )}
              </Col>
            </Row>
          }
        >
          <Row align="middle">
            <Col span={6}>
              <Text style={{ fontWeight: 'bold' }}>{formatYen(booking.totalPrice)}</Text>
            </Col>
            <Col span={2}>
              <ArrowRightOutlined/>
            </Col>
            <Col span={16}>
              <InputNumber
                className="ipn-input"
                autoFocus
                style={{ width: '100%' }}
                defaultValue={booking?.newPrice ?? null}
                onBlur={() => setNewPriceFieldVisited(1)}
                onChange={(value) => setNewPrice(value)}
              />
              <span className="ipn-input-suffix">{lang.includedTax}</span>
            </Col>
          </Row>

        </Form.Item>
        <Form.Item
          style={{ marginTop: '16px' }}
          label={lang.memo}
          name="changePriceReason"
          rules={changePriceReasonDisabled ? [] : [{ required: true, message: lang.completeBookingMemoError }]}
          validateStatus={changePriceReasonDisabled || !invalidMemo ? 'success' : 'error'}
          help={changePriceReasonDisabled || !invalidMemo ? lang.completeBookingMemoHelp : lang.completeBookingMemoError}
        >
          <Input.TextArea
            placeholder={!changePriceReasonDisabled ? lang.completeBookingMemoHelp : ''}
            disabled={changePriceReasonDisabled}
            onChange={(e) => setChangePriceReason(e.target.value.trim())}
            rows={4}
            maxLength={8000}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ChangePriceModal.propTypes = {
  changePriceModal: PropTypes.bool,
  closeChangePriceModal: PropTypes.func,
  booking: PropTypes.object,
  onCallbackChangePriceModal: PropTypes.func,
};

export default ChangePriceModal;
