import { getAxiosInstance } from './makeAxiosInstance';

const request = (url, data = {}, method = 'POST', token, options = {}) => {
  try {
    const API = getAxiosInstance(token, options);
    switch (method) {
      case 'GET': return API.get(url, { params: data });
      case 'PUT': return API.put(url, data);
      case 'PATCH': return API.patch(url, data);
      case 'DELETE': return API.delete(url, data);
      default: return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
