/* eslint-disable react/jsx-no-target-blank */
// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Radio, Input, Form, Row, Col, Button, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import moment from 'moment';
// import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// Custom
import { useDispatch } from 'hooks/useCustomDispatch';
// Utils
import { getLanguages } from 'utils/lang';
import { bookingCancel } from 'providers/BookingProvider/actions';
const { Text } = Typography;
const { TextArea } = Input;

const EmergencyCancelModal = ({
  isShowEmergencyCancelModal,
  SetIsShowEmergencyCancelModal,
  booking,
  reason,
  onCallback,
}) => {
  const dispatch = useDispatch();
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();

  // States
  const [loading, setLoading] = useState(false);
  // render emergency content before submit
  const renderEmergencyContent = (values) => {
    return (
      <div>
        <Text>{lang.messageToOurCustomerConfirmed}:</Text>
        <div style={{ border: '1px solid #000', padding: 16, marginTop: 8, marginBottom: 16, borderRadius: 4 }}>
          {values.messageToCustomer}
        </div>
        <Text>{lang.messageToAdminOperatorConfirmed}:</Text>
        <div style={{ border: '1px solid #000', padding: 16, marginTop: 8, borderRadius: 4 }}>
          {values.messageToOperator}
        </div>
      </div>
    );
  };
  // submit values for case emergency cancel
  const handleSubmit = (values) => {
    const extraReason = values.messageToOperator;
    const extraInfo = {
      messageEmergency: values.messageToCustomer,
    };
    Modal.confirm({
      width: 900,
      centered: true,
      title: lang.emergencyCancelConfirmTitle,
      icon: <ExclamationCircleOutlined />,
      content: renderEmergencyContent(values),
      okType: 'danger',
      onOk: () => {
        setLoading(true);
        dispatch(bookingCancel({ bookingId: booking?.id, reason, extraReason, extraInfo }))
          .then(() => {
            setLoading(false);
            SetIsShowEmergencyCancelModal(false);
            onCallback();
          })
          .catch(() => setLoading(false));
      },
    });
  };

  return (
    <Modal
      title={<Text strong>{lang.emergencyCanCelTitle}</Text>}
      visible={isShowEmergencyCancelModal}
      onCancel={() => SetIsShowEmergencyCancelModal(false)}
      destroyOnClose={true}
      width={900}
      centered
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="bookingEmergencyCancelForm"
          layout="vertical"
          onFinish={(values) => handleSubmit(values)}
        >
          <div style={{ marginBottom: 24 }}>
            <Text >{lang.emergencyCanCelDescription}</Text>
          </div>
          <div style={{ marginBottom: 24 }}>
            <Text strong >{lang.emergencyReasonExample}</Text>
          </div>
          <div style={{ marginBottom: 24 }}>
            <Text type='danger'>{lang.emergencyAttentionNote}</Text>
          </div>
          <div style={{ marginLeft: 24, marginBottom: 32 }}>
            <Form.Item
              name="confirmCancel"
              rules={[{ required: true, message: lang.emptyEmergencyPolicy }]}
            >
              <Radio.Group>
                <Radio value={1}>
                  {/* {lang.emergencyConfirmSelect} */}
                  <a href='https://support.nailie.jp/hc/ja/articles/4409755388057' target='_blank'style={{ textDecoration: 'underline', color: 'black', textUnderlineOffset: '1.5px' }}>緊急事態の詳細</a>を確認しました
                  <Text type='danger'> ({lang.required})</Text>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={{ marginBottom: 8 }}>
            <Text>{lang.messageToCustomer} </Text>
            <Text type='danger'>({lang.required})</Text>
          </div>
          <div style={{ marginBottom: 8 }}>
            {lang.messageToCustomerNote}
          </div>
          <Form.Item
            name="messageToCustomer"
            rules={[{ required: true, message: lang.emptyCancelMessageError }]}
          >
            <TextArea
              showCount
              rows={4}
              maxLength={250}
              placeholder='例）申し訳ございません。大型の台風による影響で電車が運休となり施術が困難になりました。' />
          </Form.Item>
          <div style={{ marginBottom: 8 }}>
            <Text>{lang.messageToNaileOperator} </Text>
            <Text type='danger'>({lang.required})</Text>
          </div>
          <div style={{ marginBottom: 8 }}>
            <Text>{lang.messageToOperatorNote}</Text>
          </div>
          <Form.Item
            name="messageToOperator"
            rules={[{ required: true, message: lang.emptyCancelMessageOperatorError }]}
          >
            <TextArea
              showCount
              rows={4}
              maxLength={250}
              placeholder='例）大型の台風による影響で電車が運休となったため施術が困難となりました。'/>
          </Form.Item>

          <div
            className="ant-modal-footer"
            style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
          >
            <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
              <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                <Button type="clear" onClick={() => SetIsShowEmergencyCancelModal(false)}>
                  {lang.back}
                </Button>
                <Button type="primary" htmlType="submit">
                  OK
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

EmergencyCancelModal.propTypes = {
  isShowEmergencyCancelModal: PropTypes.func,
  SetIsShowEmergencyCancelModal: PropTypes.func,
  booking: PropTypes.object,
  reason: PropTypes.string,
  onCallback: PropTypes.func,
};
export default EmergencyCancelModal;
