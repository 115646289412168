import {
  SAMPLE_CONST,
  SAMPLE_CONST_SUCCESS,
} from './constants';

export function setSampleAction (payload = {}) {
  return { type: SAMPLE_CONST, payload };
}

export function setSampleActionSuccess (payload = {}) {
  return { type: SAMPLE_CONST_SUCCESS, payload };
}
