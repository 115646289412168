import axios from 'axios';
import _ from 'lodash';
import environment from 'environment';
import { deleteAllCookies } from 'utils/SharedSalonEnv';

export const getAxiosInstance = (token, options, domain) => {
  let { headers, ...restOptions } = options;
  headers = {
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
    ...headers,
  };

  const axiosInstance = axios.create({
    baseURL: domain || environment.cbApiEndPoint,
    headers,
    ...restOptions,
  });

  axiosInstance.interceptors.response.use((response) => {
    if ([200, 201, 202, 204].includes(response.status)) {
      const result = response.data;
      if (_.isObject(result.isObject)) {
        result.statusCode = response.status;
      }
      return response.data;
    }
    return Promise.reject(response);
  }, (error) => {
    // Handle error code properly
    let { code } = error.response.data || error.response.status;
    code = code || error.response.status;

    if (code) {
      // Authorize error
      if (code === 401) {
        deleteAllCookies(); // clear all cookie
        window.localStorage.clear(); // clear all local storage
        window.logout({});
        window.location = '/login';
      }

      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.response ? error.response.data : error);
  });

  return axiosInstance;
};
