import React from 'react';
import PropTypes from 'prop-types';
import { Row, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import logoComingSoon from 'assets/images/coming-soon.svg';
// utils
import './styles.less';
import { getLanguages } from 'utils/lang';

const { Text } = Typography;
const BM_CODE = 'BM';
const CS_CODE = 'CS';

const SalonConnectComingSoon = ({ connectingParty, handleToggle3rdParty, showWarningDisconnect3rdParty }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const onChangeStatus = (value) => {
    if (connectingParty === BM_CODE) {
      showWarningDisconnect3rdParty(BM_CODE);
    } else {
      handleToggle3rdParty({ code: CS_CODE, connectingParty });
    }
  };

  return (
    <div style={{ marginTop: 24, display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: 240, height: 80 }}>
        <img src={logoComingSoon} className='logo-coming-soon' />
      </div>
      <div style={{ marginTop: 32, marginBottom: 24 }}>
        <Text strong>{lang.procedureLinkCS}</Text>
      </div>
      <Row style={{ marginBottom: 24 }}>
        <Text>{lang.connectCSstep1} </Text>
      </Row>

      <Row style={{ marginBottom: 8, fontWeight: 500 }}>
        <Text>{lang.connectCSstep} </Text>
      </Row>
      <Row>
        <Text>{lang.connectCSstepLine1} </Text>
      </Row>
      <Row style={{ marginBottom: 32 }}>
        <Text>{lang.connectCSstepLine2} </Text>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Text>{lang.connectCSstep2} </Text>
      </Row>
      <Row>
        <Text>{lang.connectCSstepLine3} </Text>
      </Row>

      <div style={{ marginBottom: 40 }}>
        <Switch
          style={{ position: 'absolute' }}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          checked={connectingParty === CS_CODE}
          onChange={(value) => onChangeStatus(value)}
        />
      </div>
      <div>
        <Text>{lang.connectCSstepLine4}</Text>
      </div>
      <Text>{lang.connectCSstepLine5}</Text>

      <div style={{ marginTop: 32 }}>
        <Text strong>【{lang.notes}】</Text>
      </div>
      <div>
        <Text>{lang.connectCSstepNoteContent}</Text>
      </div>
    </div>
  );
};

SalonConnectComingSoon.propTypes = {
  connectingParty: PropTypes.string,
  handleToggle3rdParty: PropTypes.func,
  showWarningDisconnect3rdParty: PropTypes.func,
};
export default SalonConnectComingSoon;
