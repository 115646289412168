import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import moment from 'moment-timezone';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Spin, ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale-provider/ja_JP';
import enUS from 'antd/lib/locale-provider/en_US';
import 'moment/locale/ja';

import { timezone } from 'utils/constants';
import './i18n';

// BEGIN DESKTOP
import ProtectedRoute from 'components/Desktop/ProtectedRoute';

// Public
import HomeLayout from 'components/Desktop/Layout/Home';
import Login from 'pages/Desktop/Login';
import CompleteProfile from 'pages/Desktop/CompleteProfile';
import CompleteSchedule from 'pages/Desktop/CompleteSchedule';

// Private
import MySalonLayout from 'components/Desktop/Layout/MySalon';
import SalonSaleReport from 'pages/Desktop/MySalon/SalonSaleReport/salon-sale-report.container';
import SalonSalesDetail from 'pages/Desktop/MySalon/SalonSaleReport/salon-sales-detail.container';
import SalonAccount from 'pages/Desktop/MySalon/Settings/SalonAccount';

import Services from 'pages/Desktop/Services';
import StaffMember from 'pages/Desktop/Staffs/StaffMember';
import StaffSchedule from 'pages/Desktop/Staffs/StaffSchedule';
import StaffScheduleDaily from 'pages/Desktop/Staffs/StaffScheduleDaily';
import StaffSaleReport from 'pages/Desktop/Staffs/StaffSaleReport/staff-sale-report.container';

import StaffDetail from 'pages/Desktop/Staffs/StaffDetail';

import Bookings from 'pages/Desktop/Bookings';

import SalesManagement from 'pages/Desktop/SalesManagement/sales-management.container';
import ScrollToTop from 'utils/scrollToTop';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from 'providers/I18nProvider/actions';
import environment from 'environment';

export const history = createBrowserHistory();

const App = () => {
  const { isLoading } = useAuth0();
  moment.tz.setDefault(timezone);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const locale = useSelector((state) => state.i18n.locale);
  const [antdLocale, setAntdLocale] = useState(locale === 'en' ? enUS : jaJP);

  const changeLocale = (locale) => {
    i18n.changeLanguage(locale);
    setAntdLocale(locale === 'en' ? enUS : jaJP);
    // Customize JP locale with first day of week on Monday
    moment.locale(locale, {
      week: {
        dow: isNaN(parseInt(process.env.REACT_APP_START_DAY_OF_WEEK)) ? 1 : parseInt(process.env.REACT_APP_START_DAY_OF_WEEK), // Sun -> Sat index 0 -> 6
        doy: isNaN(parseInt(process.env.REACT_APP_START_DAY_OF_YEAR)) ? 7 : parseInt(process.env.REACT_APP_START_DAY_OF_YEAR), // Jan 1 must be in the first week of the year
      },
    });
  };

  useEffect(() => {
    const _locale = locale || process.env.REACT_APP_DEFAULT_LOCALE || 'en';

    if (!locale) {
      dispatch(setLanguage({ locale: _locale }));
    }

    changeLocale(_locale);
  }, []);

  useEffect(() => {
    if (!locale || i18n.language === locale) return;

    changeLocale(locale);
  }, [locale]);

  if (isLoading) {
    return (
      <div className="center-full-screen-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <BrowserRouter history={history}>
      <ConfigProvider locale={antdLocale}>
        {/* Uncomment the Fragment tag to force reload page upon changing locale */}
        {/* <Fragment key={antdLocale ? antdLocale.locale : 'en'}> */}
        <ScrollToTop />
        <HelmetProvider>
          <Helmet>
            <title>{environment.title}</title>
            <link rel="icon" type="image/png" href={environment.favicon}/>
          </Helmet>
          <Routes>
            <Route path="/" element={<HomeLayout />}>
              <Route path="/" element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="complete-profile" element={<CompleteProfile />} />
              <Route path="complete-schedule" element={<CompleteSchedule />} />
              <Route path="*" element={<Login />} />
            </Route>
            <ProtectedRoute path="mysalon" component={MySalonLayout}>
              <Route path="/" element={<StaffScheduleDaily />} />
              <Route path="settings" element={<SalonAccount />} />
              <Route path="bookings" element={<Bookings />} />
              <Route path="menu-settings" element={<Services />} />
              <Route path="staff-member" element={<StaffMember />} />
              <Route path="staff-member/:id" element={<StaffDetail />} />
              <Route path="staff-schedule" element={<StaffScheduleDaily />} />
              <Route path="staff-schedule-week" element={<StaffSchedule />} />
              <Route path="sales-management" element={<SalesManagement />} />
              <Route path="salon-sale-report" element={<SalonSaleReport />} />
              <Route path="transfer-management" element={<SalonSaleReport />} />
              <Route path="salon-sales-detail" element={<SalonSalesDetail />} />
              <Route path="sales-management/staff-sale-report" element={<StaffSaleReport />} />
            </ProtectedRoute>
          </Routes>
        </HelmetProvider>
        {/* </Fragment> */}
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
