import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSearchParams, shouldFetchApi, shouldSetSearchParams } from 'hooks/useCustomSearchParams';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useTranslation } from 'react-i18next';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import _ from 'lodash';

import { Modal, Input, Button, Table, Card, Row, Col, Tag, Form, Typography } from 'antd';

import { PlusOutlined, SearchOutlined, QuestionCircleOutlined, MenuOutlined, UserOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { fetchStaffList, updateStaffOrder } from 'providers/StaffProvider/actions';
import StaffImportForm from 'components/Desktop/Staff/StaffImportForm';
import PageLayout from 'components/Desktop/Layout/PageLayout';
import { getLanguages } from 'utils/lang';
import './styles.less';
import { useSelector } from 'react-redux';
import Avatar from 'components/Desktop/Avatar';
import { SALON_HIDDEN_TYPES } from 'utils/SharedSalonEnv';
const { Text, Title } = Typography;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const StaffMember = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { keyword, sort = 'order asc' } = searchParamsValues;
  const splitSort = sort.split(' ');
  const sorterColumnKey = splitSort[0];
  const sorterOrder = splitSort[1] + 'end';
  const page = parseInt(searchParamsValues.page);
  // </URLSearchParams>

  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const { salon } = useSelector(state => state.salon);
  const isConnectedBM = salon?.thirdParties[0]?.code === 'BM';
  const isConnectedCS = salon?.thirdParties[0]?.code === 'CS';
  const isHiddenFeature = SALON_HIDDEN_TYPES.includes(salon?.salonType);

  const lang = getLanguages(t);
  const dispatch = useDispatch();

  const getStaffList = () => {
    setLoading(true);
    const params = {
      currentPage: page,
      pageSize: 10000,
      sortedInfo: sort,
      searchValue: keyword,
    };

    dispatch(fetchStaffList(params))
      .then(result => {
        let { data } = result.data;
        data = data.map((item, index) => {
          const itemNew = { ...item, index };
          return itemNew;
        });
        setStaffs(data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (shouldFetchApi(searchParamsValues)) {
      getStaffList();
    }

    form.setFieldsValue({
      keyword,
    });
  }, [keyword, sort, page]);

  useEffect(() => {
    const defaultSearch = {
      page: 1,
      sort,
    };

    if (shouldSetSearchParams(searchParamsValues)) {
      handleSetSearchParams(defaultSearch, true);
    }
  }, [searchParamsValues]);

  const getSortOrder = (columnKey) => {
    if (sorterColumnKey === columnKey) {
      return sorterOrder;
    }

    return false;
  };
  const renderModalInformationHelper = () => {
    Modal.info({
      width: '560px',
      icon: '',
      content: (
        <div>
          <Title level={5}>{lang.modalInforTitle}</Title>
          <Text>{lang.modalInforContent}</Text>
        </div>
      ),
      onOk () {},
    });
  };

  const handleClearSearchStaff = (e) => {
    if (e.target.value === '') {
      // input is cleared.
      const defaultSearch = {
        page: 1,
        sort,
      };
      handleSetSearchParams(defaultSearch, true);
      const params = {
        currentPage: page,
        pageSize: 10000,
        sortedInfo: sort,
      };

      dispatch(fetchStaffList(params))
        .then(result => {
          const { data } = result.data;
          setStaffs(data);
          setLoading(false);
        });
    }
  };

  // staffCreate
  const [isStaffCreateModal, setIsStaffCreateModal] = useState(false);
  const columns = [
    {
      dataIndex: 'sort',
      width: '5%',
      className: 'drag-visible',
      render: function renderItem () {
        return (
          <DragHandle />
        );
      },
    },
    {
      title: lang.staffName,
      defaultSortOrder: 'descend',
      key: 'name',
      width: '18%',
      className: 'drag-visible',
      render: function renderItem (record) {
        const staffId = _.get(record, 'id');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(!record?.connections[0]?.data?.avatarLink)
              ? <Avatar src={record.avatar} size='large' style={{ marginRight: 8 }} icon={!record.avatar && <UserOutlined />}/>
              : <Avatar src={record?.connections[0]?.data?.avatarLink} size='large' style={{ marginRight: 8 }} />
            }
            <Link to={`/mysalon/staff-member/${staffId}`}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(!record?.connections[0]?.data?.name) ? <span>{record.name}</span> : <span>{record?.connections[0]?.data?.name}</span>}
                {(!record?.connections[0]?.data?.username) ? <span>{record.username}</span> : <span>{record?.connections[0]?.data?.username}</span>}
              </div>
            </Link>
          </div >
        );
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: getSortOrder('name'),
    },
    {
      title: lang.menuTitle,
      key: 'services',
      width: '15%',
      className: !isHiddenFeature ? '' : 'hidden-column',
      render: function renderItem (record) {
        const staffId = _.get(record, 'id');
        // Check staff status base on length of connections. If length = 0 : inviting, length > 0 : connected
        const staffConnectionsStatus = record?.connections.length;
        if (staffConnectionsStatus === 0) {
          return (<Text>ー</Text>);
        } else {
          return (
            <div >
              {record?.services.filter(i => i.active === true)?.length > 0
                ? <Link to={`/mysalon/staff-member/${staffId}?tab=services`}><Text style={{ color: 'black' }}>{lang.menuStatusAssigned}</Text></Link>
                : <Link to={`/mysalon/staff-member/${staffId}?tab=services`}><Text type='danger' >{lang.menuStatusNotAssigned}</Text></Link>}
            </div >
          );
        }
      },
    },

    {
      title: lang.bookingAvailabilityTitle,
      width: '15%',
      className: !isHiddenFeature ? '' : 'hidden-column',
      render: function renderItem (record) {
        const staffConnectionsStatus = record?.connections.length;
        const staffConnections = record?.connections?.[0]?.data;
        const isPublished = staffConnections?.extraInfo?.isPublished;
        const availableBooking = staffConnections?.extraInfo?.availableForBooking;
        const assignedMenuStatus = record?.services.filter(i => i.active === true)?.length;
        // Check staff status base on length of connections. If length = 0 : inviting, length > 0 : connected
        if (staffConnectionsStatus === 0) {
          return (<Text>ー</Text>);
        } else {
          return (
            <div >
              {
                ((isPublished || availableBooking) && assignedMenuStatus > 0)
                  ? <Text>{lang.bookingAvailabilityOK}</Text>
                  : <>
                    <span style={{ color: 'red', marginRight: 8 }}>{lang.bookingAvailabilityNO}</span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => renderModalInformationHelper()}
                    >
                      <QuestionCircleOutlined />
                    </span>
                  </>
              }
            </div >
          );
        }
      },
    },

    {
      title: lang.status,
      width: '15%',
      key: 'connections',
      render: function renderItem (record) {
        switch (record?.connections.length) {
          case 1:
            return <Tag color='green'>{lang.connected}</Tag>;
          case 0:
            return <Tag color='error'>{lang.inviting}</Tag>;
          default:
            return <Tag color='error'>{lang.inviting}</Tag>;
        }
      },
    },
    {
      title: `${lang.externalParties} (${lang.comingSoon})`,
      width: '20%',
      key: 'comingSoon',
      align: 'center',
      className: isConnectedCS ? 'external-party-column' : 'hidden-column',
      render: function renderItem (record) {
        const staffConnectionsStatus = record?.connections.length;
        if (staffConnectionsStatus === 0) {
          return (<Text>ー</Text>);
        } else if (record?.thirdParties?.length === 0) {
          return <Text>ー</Text>;
        } else {
          return <CheckCircleTwoTone twoToneColor='#62C728' />;
        }
      },
    },
    {
      title: `${lang.externalParties} (${lang.beautyMerit})`,
      width: '20%',
      className: isConnectedBM ? 'external-party-column' : 'hidden-column',
      key: 'beautyMerit',
      align: 'center',
      render: function renderItem (record) {
        const staffConnectionsStatus = record?.connections.length;
        if (staffConnectionsStatus === 0) {
          return (<Text>ー</Text>);
        } else if (record?.thirdParties?.length > 0) {
          return <CheckCircleTwoTone twoToneColor= '#62C728' />;
        }
      },
    },

    {
      title: `${lang.externalParties}`,
      width: '20%',
      align: 'center',
      className: (!isConnectedBM && !isConnectedCS) ? '' : 'hidden-column',
      key: 'external',
      render: function renderItem () {
        return (<Text>ー</Text>);
      },
    },
    {
      title: '',
      key: 'Action',
      width: '15%',
      render: function renderItem (record) {
        const staffId = _.get(record, 'id');
        return (
          <Link to={`/mysalon/staff-member/${staffId}`}>
            {lang.detailBtn}
          </Link>
        );
      },
    },
  ];

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/staff-member',
      breadcrumbName: lang.staffMember,
    },
  ];

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource = staffs;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex,
      ).filter((el) => !!el);
      setStaffs(newData);
      dispatch(updateStaffOrder({ oldIndex, newIndex, dataSource }))
        .then(() => {
          getStaffList();
        },
        );
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DragableBodyRow = ({ className, style, ...restProps }) => {
    const index = staffs.findIndex(
      (x) => x.index === restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <PageLayout ghost={false} title={lang.staffMember} routes={routes}>
      <div className ="staff-member-page">
        <Card style={{ marginBottom: 16 }}>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={18}>
              <Row >
                <Form
                  form={form}
                  layout="inline"
                  onFinish={(values) => {
                    const search = {
                      ...searchParamsValues,
                      page: 1,
                    };
                    const keywordValue = values.keyword;
                    if (keywordValue && keywordValue.trim()) {
                      search.keyword = keywordValue.trim();
                    } else {
                      delete search.keyword;
                    }
                    handleSetSearchParams(search);
                  }}
                >
                  <Form.Item name="keyword">
                    <Input
                      placeholder={lang.searchStaffPlaceholder}
                      allowClear
                      style={{ width: 300 }}
                      onChange={(e) => handleClearSearchStaff(e)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SearchOutlined />}
                    >
                      {lang.search}
                    </Button>
                  </Form.Item>
                </Form>
              </Row>
            </Col>
            <Col className="gutter-row" span={6} style={{ textAlign: 'right' }}>
              <Button type="primary" onClickCapture={() => setIsStaffCreateModal(true)}>
                <PlusOutlined />
                {lang.importFromNailie}
              </Button>
            </Col>
          </Row>
        </Card>
        <Card style={{ marginBottom: 18 }} >
          <Table
            loading={loading}
            columns={columns}
            dataSource={staffs}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DragableBodyRow,
              },
            }}
            pagination={false}
          />
        </Card>
        <Modal
          visible={isStaffCreateModal}
          title={lang.importFromNailie}
          onCancel={() => setIsStaffCreateModal(false)}
          destroyOnClose={true}
          width={700}
          centered
          footer={null}
          maskClosable={false}
        >
          <StaffImportForm
            onCancel={() => setIsStaffCreateModal(false)}
            onCallBack={getStaffList}
          />
        </Modal>
      </div>
    </PageLayout>
  );
};

export default StaffMember;
