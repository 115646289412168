import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import { Row, Col, Card } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'hooks/useCustomDispatch';

import { salonDetail } from 'providers/SalonProvider/actions';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

import SalonAccountUpdateForm from 'components/Desktop/Salon/SalonAccountUpdateForm';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import environment from 'environment';
import './styles.less';

const CompleteProfile = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [salon, setSalon] = useState();
  const { getAccessTokenSilently } = useAuth0();
  // Not  show this popup with new salon account, save this salon id to local storage
  const hidePopupEditStationWithNewSalon = (salon = {}) => {
    let editStationPopupShownSalonIdsList = localStorage.getItem('editStationPopupShownSalonIdsList');
    let newSalonPropertiesPopupShownSalonIdsList = localStorage.getItem('newSalonPropertiesPopupShownSalonIdsList');
    try {
      editStationPopupShownSalonIdsList = JSON.parse(editStationPopupShownSalonIdsList || '') || [];
      newSalonPropertiesPopupShownSalonIdsList = JSON.parse(newSalonPropertiesPopupShownSalonIdsList || '') || [];
    } catch {
      editStationPopupShownSalonIdsList = [];
      newSalonPropertiesPopupShownSalonIdsList = [];
    }
    editStationPopupShownSalonIdsList.push(salon.id);
    newSalonPropertiesPopupShownSalonIdsList.push(salon.id);
    localStorage.setItem('editStationPopupShownSalonIdsList', JSON.stringify(editStationPopupShownSalonIdsList));
    localStorage.setItem('newSalonPropertiesPopupShownSalonIdsList', JSON.stringify(newSalonPropertiesPopupShownSalonIdsList));
  };

  useEffect(() => {
    (async function () {
      const token = await getAccessTokenSilently({ audience: environment.api.auth0Audience });
      const user = jwtDecode(token);
      dispatch(salonDetail({
        salonId: _.get(user, '[https://user_metadata].salon_id'),
      }))
        .then((result) => {
          setSalon(result.data);
          hidePopupEditStationWithNewSalon(result.data);
        });
    })();
  }, []);

  return (
    <div className="complete-profile-page">
      <Card>
        <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="request-page-row">
          <Col xs={{ span: 24 }}>
            <div className="request-logo">
              <img src={environment.logo} alt="logo" style={{ height: 60 }} />
              <h3 className="request-title">{lang.completeBusinessInfo}</h3>
            </div>
            <div>
              {salon &&
              <SalonAccountUpdateForm
                isCompleteProfile={true}
                salon={salon}
                onCallback={() => {
                  navigate('/complete-schedule');
                }}
              />
              }
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CompleteProfile;
