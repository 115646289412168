// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Table, Avatar, Card, DatePicker, Form, Radio, Checkbox } from 'antd';
import { ScheduleOutlined, CaretLeftOutlined, CaretRightOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSearchParams, shouldFetchApi, shouldSetSearchParams } from 'hooks/useCustomSearchParams';

// Components
import WeekFormModal from 'components/Desktop/WorkingTime/week-form.modal';
import WeekViewModal from 'components/Desktop/WorkingTime/WeekViewModal';
import OutsideBookingsModal from 'components/Desktop/Booking/outside-booking.modal';
import BookingDetailModal from 'components/Desktop/Booking/booking-detail.modal';
import PageLayout from 'components/Desktop/Layout/PageLayout';

// Providers
import { staffScheduleList } from 'providers/StaffProvider/actions';
import { bookingList } from 'providers/BookingProvider/actions';

// Utils
import { round } from 'utils/common';
import { getLanguages } from 'utils/lang';
import { getLocaleDayOfWeek } from 'utils/schedule';
import { BOOKING_CANCELED_STATUSES } from 'utils/constants';

import './styles.less';

const LIMIT = 10000;
const tableScrollHeightArea = window.innerHeight - 360; // px

const StaffSchedule = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { showBooking } = searchParamsValues;
  const isShowBooking = showBooking === 'true';
  const currentPage = parseInt(searchParamsValues.page);
  // </URLSearchParams>

  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);

  const [weekViewModal, setWeekViewModal] = useState(null);
  const [weekFormModal, setWeekFormModal] = useState(null);
  const [outsideBookingsModal, setOutsideBookingsModal] = useState(null);
  const [bookingDetailModal, setBookingDetailModal] = useState(null);

  // <Show booking>
  const [bookings, setBookings] = useState({});
  const bookingsInSchedule = _.get(bookings, 'data', [])
    .filter(booking => {
      const bookingStatus = booking?.notes.filter(note => note.type === 'targetSystemStatus')[0]?.value.toUpperCase();
      return !BOOKING_CANCELED_STATUSES.includes(bookingStatus);
    })
    .map(booking => {
      return {
        staffId: _.get(booking, 'orders[0].staff.id', ''),
        dayOfWeek: moment(booking.startTime).days(),
      };
    });
  // </Show booking>

  const currentDate = moment(searchParamsValues.date || moment(), 'YYYY-MM-DD');

  /**
   * Keep track of previous value of `currentDate`
   */
  const currentDateRef = useRef(currentDate);

  const onChangeRangeDate = (date, dateString) => {
    const search = {
      ...searchParamsValues,
      date: date.format('YYYY-MM-DD'),
    };
    handleSetSearchParams(search);
  };

  const handelChangeRangeDate = (numberDay) => {
    const newDate = currentDate.clone().add(numberDay, 'day');
    const search = {
      ...searchParamsValues,
      date: newDate.format('YYYY-MM-DD'),
    };
    handleSetSearchParams(search);
  };

  const from = currentDate.clone().startOf('week').toISOString();
  const to = currentDate.clone().endOf('week').toISOString();

  const renderCell = (record) => {
    return (
      <div className="cell">
        {record.schedules.map((item, index) => {
          const date = moment(from).weekday(getLocaleDayOfWeek(item.dayOfWeek));
          if (
            moment(date).diff(moment(item.startSchedule)) >= 0 &&
            (item.endSchedule === null || moment(date).diff(moment(item.endSchedule)) <= 0)
          ) {
            const duration = moment.duration(moment(item.endAt).diff(moment(item.startAt)));
            const hours = duration.hours();
            const minutes = duration.minutes();
            const totalHours = hours + round(minutes / 60, 1);

            return (
              <div key={index}>
                <div style={totalHours !== 0 ? {} : { visibility: 'hidden' }}>
                  <div>{`${moment(item.startAt).format('HH:mm')} - ${moment(item.endAt).format('HH:mm')}`}</div>
                  <hr style={{ width: 20 }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {totalHours !== 0 && (<div>{totalHours} {lang.hour}</div>)}
                  {/* <Show booking> */}
                  {isShowBooking && record.bookings.length !== 0 && (
                    <div style={{ backgroundColor: '#30c9fc', color: '#fff', padding: '0 4px', marginLeft: 4 }}>
                      {record.bookings.length} {lang.bookings}
                    </div>
                  )}
                  {/* </Show booking> */}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const columns = [
    {
      title: lang.staff,
      dataIndex: 'staff',
      width: 160,
      fixed: 'left',
      render: function renderItem (record) {
        const { staffId, staffName, photo } = record;
        return (
          <div style={{ paddingTop: 4, paddingLeft: 16, backgroundColor: '#FFFFFF' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={photo} size='large' style={{ marginRight: 8 }} />
              {staffName}
            </div >
            <div
              style={{ cursor: 'pointer', marginTop: 4 }}
              onClick={() => {
                setWeekFormModal({
                  staffId,
                  staffName,
                });
              }}
            >
              <ScheduleOutlined style={{ marginRight: 8 }} />
              {lang.editSchedule}
            </div>
          </div>
        );
      },
    },
  ];

  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  daysOfWeek.forEach((dow, index) => {
    const columnTitle = currentDate.clone().weekday(index).format('dddd YYYY-MM-DD'); // 0.Mon -> 6.Next Sun, based on first day of week of current moment setting
    const titleClassName = `day-of-week ${dow.slice(0, 3)}`; // mon...sat..sun
    columns.push({
      key: columnTitle,
      title: (
        <div style={{ textAlign: 'center' }}>
          <div className={titleClassName}>{columnTitle.split(' ')[0]}</div>
          <div>{columnTitle.split(' ')[1]}</div>
        </div>
      ),
      dataIndex: dow,
      width: 100,
      render: (value) => renderCell(value),
    });
  });

  useEffect(() => {
    if (shouldFetchApi(searchParamsValues)) {
      getStaffSchedule();
    }
  }, [currentPage, from, to]);

  const getStaffSchedule = () => {
    setLoading(true);
    dispatch(staffScheduleList({ currentPage, pageSize: LIMIT, sortedInfo: 'createdAt desc', from, to }))
      .then(result => {
        const { data } = result.data;
        setStaffs(data);
        setTimeout(() => setLoading(false), 300);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error);
      });
  };

  const getBookingList = () => {
    setLoading(true);
    dispatch(bookingList({ page: 1, limit: 10000, from, to }))
      .then((result) => {
        setBookings(result.bookings);
        setTimeout(() => setLoading(false), 300);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error);
      });
  };

  useEffect(() => {
    // Get booking list when switching from day view to week view.
    getBookingList();
  }, []);

  useEffect(() => {
    let date = state ? moment().weekYear(state.year).week(state.week).startOf('week') : moment();
    // Re-create Moment object to use the new locale
    if (moment.locale() !== currentDate.locale()) {
      date = moment(currentDate.toISOString());
    }

    if (shouldSetSearchParams(searchParamsValues)) {
      handleSetSearchParams({ page: 1, date: date.format('YYYY-MM-DD'), showBooking: true }, true);
    }
  }, [searchParamsValues]);

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/staff-schedule-week',
      breadcrumbName: lang.manageBookings,
    },
  ];

  // <Show booking>
  // Get all bookings of all staffs of salon from start of week to end of week.
  useEffect(() => {
    // Missing case switch from day view to week view (week and year are always equal).
    if (currentDate.week() !== currentDateRef.current.week() ||
    currentDate.weekYear() !== currentDateRef.current.weekYear()) {
      getStaffSchedule();
      setBookings({}); // Clear previous booking list.
      getBookingList();
    }

    currentDateRef.current = currentDate;
  }, [currentDate]);
  // </Show booking>

  return (
    <PageLayout ghost={false} title={lang.manageBookings} routes={routes}>
      <div className="staff-schedules">
        <Card style={{ marginBottom: 16 }}>
          <Form layout="inline" onSubmit={() => {}} style={{ justifyContent: 'space-between' }}>
            <Form.Item>
              <div style={{ display: 'flex', flexWrap: 1, flexDirection: 'row' }}>
                <Button
                  onClick={() => {
                    handelChangeRangeDate(-7);
                  }}
                >
                  <CaretLeftOutlined />
                </Button>
                <DatePicker
                  style={{ margin: '0 4px' }}
                  allowClear={false}
                  picker="week"
                  value={currentDate}
                  onChange={onChangeRangeDate}
                  format={value => {
                    const format = 'YYYY/MM/DD';
                    const startOfWeek = value.clone().startOf('week').format(format);
                    const endOfWeek = value.clone().endOf('week').format(format);
                    return `${startOfWeek} - ${endOfWeek}`;
                  }}
                />
                <Button
                  onClick={() => {
                    handelChangeRangeDate(7);
                  }}
                >
                  <CaretRightOutlined />
                </Button>
              </div>
            </Form.Item>
            <Form.Item>
              {/* Show booking */}
              <Checkbox
                checked={isShowBooking}
                onChange={e => {
                  const search = {
                    ...searchParamsValues,
                    showBooking: e.target.checked,
                  };
                  handleSetSearchParams(search);
                }}
              >
                {lang.showBooking}
              </Checkbox>
              {/* Reload */}
              <Button
                onClickCapture={() => {
                  getStaffSchedule();
                  getBookingList();
                }}
                icon={<RedoOutlined />}
                style={{ marginRight: 8 }}
              >
                {lang.reload}
              </Button>
              {/* Week/Day view */}
              <Radio.Group
                buttonStyle="solid"
                value={pathname}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value === '/mysalon/staff-schedule') {
                    navigate(value, {
                      state: {
                        week: currentDate.week(),
                        year: currentDate.weekYear(),
                      },
                    });
                  }
                }}
              >
                <Radio.Button value="/mysalon/staff-schedule-week">{lang.week}</Radio.Button>
                <Radio.Button value="/mysalon/staff-schedule">{lang.day}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ marginBottom: 18 }}>
          <Table
            columns={columns}
            dataSource={staffs.map((item) => {
              let staff = _.get(item, 'connections[0].data');
              if (!staff) {
                staff = {
                  avatarLink: 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png',
                  name: item.name,
                };
              }
              const bookingsInScheduleByStaff = bookingsInSchedule.filter(booking => booking.staffId === item.id) || [];
              return {
                key: item.id,
                staff: {
                  photo: staff.avatarLink,
                  staffId: item.id,
                  staffName: staff.name,
                },
                monday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 1),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 1),
                },
                tuesday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 2),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 2),
                },
                wednesday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 3),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 3),
                },
                thursday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 4),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 4),
                },
                friday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 5),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 5),
                },
                saturday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 6),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 6),
                },
                sunday: {
                  schedules: item.schedules.filter(schedule => schedule.dayOfWeek === 0),
                  bookings: bookingsInScheduleByStaff.filter(booking => booking.dayOfWeek === 0),
                },
              };
            })}
            loading={loading}
            bordered
            onChange={(pagination, filters, sorter) => {
              const search = {
                ...searchParamsValues,
                page: pagination.current,
              };
              handleSetSearchParams(search);
            }}
            pagination ={false}
            scroll={{ x: 1300, y: tableScrollHeightArea }}
          />
        </Card>
      </div>

      <WeekFormModal
        weekFormModal={weekFormModal}
        closeWeekFormModal={() => setWeekFormModal(null)}
        onCallbackWeekFormModal={() => {
          getStaffSchedule();
        }}
        setWeekViewModal={setWeekViewModal}
        setOutsideBookingsModal={setOutsideBookingsModal}
      />

      <WeekViewModal
        weekViewModal={weekViewModal}
        setWeekViewModal={setWeekViewModal}
      />

      <OutsideBookingsModal
        outsideBookingsModal={outsideBookingsModal}
        closeOutsideBookingsModal={() => setOutsideBookingsModal(null)}
        setBookingDetailModal={setBookingDetailModal}
      />

      <BookingDetailModal
        bookingId={bookingDetailModal}
        closeBookingDetailModal={() => setBookingDetailModal(null)}
        onCallbackBookingDetailModal={getBookingList}
      />
    </PageLayout>
  );
};

export default StaffSchedule;
