// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';

// Components
import WeekForm from './WeekForm';

// Providers
import { getOutsideBookingsByStaff } from 'providers/BookingProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';

const WeekFormModal = ({
  weekFormModal,
  closeWeekFormModal,
  onCallbackWeekFormModal,
  setWeekViewModal,
  setOutsideBookingsModal,
}) => {
  const dispatch = useDispatch();

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Modal
      visible={!!weekFormModal}
      title={`${weekFormModal?.staffName} ${lang.workingTime}`}
      onCancel={closeWeekFormModal}
      destroyOnClose={true}
      width={980}
      centered
      footer={null}
      maskClosable={false}
    >
      <WeekForm
        staffId={weekFormModal?.staffId}
        staffName={weekFormModal?.staffName}
        onCancel={closeWeekFormModal}
        setWeekViewModal={setWeekViewModal}
        onCallback={() => {
          dispatch(getOutsideBookingsByStaff({ staffId: weekFormModal?.staffId }))
            .then((result) => {
              const { data } = result;
              if (data.length !== 0) {
                setOutsideBookingsModal({ data, staffName: weekFormModal?.staffName });
              }
            });
          onCallbackWeekFormModal();
          closeWeekFormModal();
        }}
      />
    </Modal>
  );
};

WeekFormModal.propTypes = {
  weekFormModal: PropTypes.object,
  closeWeekFormModal: PropTypes.func,
  onCallbackWeekFormModal: PropTypes.func,
  setWeekViewModal: PropTypes.func,
  setOutsideBookingsModal: PropTypes.func,
};

export default WeekFormModal;
