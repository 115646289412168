import { BOOKING_CANCELED_STATUSES } from './constants';

export const getCustomerPayAmount = (bookingData) => {
  const targetSystemStatus = (bookingData.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = targetSystemStatus?.value || '';
  const isCanceledOrDone = [...BOOKING_CANCELED_STATUSES, 'DONE'].includes(bookingStatus);
  const { charge = 0, finalPrice = 0 } = bookingData;

  return isCanceledOrDone ? charge : finalPrice;
};

export const getSalonSalesAmount = (bookingData) => {
  /* If booking is canceled, meaning that customer was already charged,
     * then use `charge` as salon sales because it already calculates cancel fee.
     */
  const targetSystemStatus = (bookingData.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = targetSystemStatus?.value || '';
  const isCanceled = BOOKING_CANCELED_STATUSES.includes(bookingStatus);
  const { additionalPrice = 0, totalPrice = 0, charge = 0 } = bookingData;

  return isCanceled ? charge : totalPrice + additionalPrice;
};

export const calcTotalAmountBookings = (bookings = [], initTotal = 0) => {
  return bookings.reduce((sum, booking) => {
    return Number(sum) + Number(getSalonSalesAmount(booking) || 0);
  }, initTotal);
};
