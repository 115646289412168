import moment from 'moment';
import _ from 'lodash';
import { formatYen } from 'utils/stringFormat';
import { FILE_HELPER } from 'utils/fileHelper';
import { TRANSFER_STATUS, BOOKING_STATUS, BOOKING_CUSTOMER_TYPE, TAX_RATE } from 'utils/constants';
import { getSalonSalesAmount } from './sales';

export const HANDLE_EXPORT_CSV_FILE = {
  handleExportListSalonSaleReportCSV (listSalonSaleReport, CSVStructEN, CSVStructJA, csvFileName, utcOffsetHours, locale, lang) {
    const listSalonSaleReportData = listSalonSaleReport.map(payment => {
      const { totalBookingValue = 0, nailieComission = 0, settlementFee = 0, bankFee = 0, transferAmount = 0, transferDate, status } = payment?.extra || {};
      const format = locale === 'en' ? 'YYYY-MM-DD' : 'YYYY年MM月DD日';
      const startDate = moment(payment?.period?.startDate).add(utcOffsetHours, 'hours');
      const endDate = moment(payment?.period?.endDate).add(utcOffsetHours, 'hours');
      const payoutCyCle = locale === 'en'
        ? `${startDate.format('MMM-DD')} to ${endDate.format('DD')}`
        : `${startDate.format('MM月D日')}~${endDate.format('D日')}`;
      const salesAmount = formatYen(totalBookingValue);
      const bookingFee = formatYen(nailieComission);
      const settlementFeeAmount = formatYen(settlementFee);
      const subtotalSales = formatYen(totalBookingValue - nailieComission - settlementFee);
      const transferFee = formatYen(bankFee);
      const transferAmountSales = formatYen(transferAmount);
      const transferDatePayment = transferDate ? moment(transferDate).format(format) : '';
      const statusPayment = lang[TRANSFER_STATUS[status].langKey];
      const sumBookingSettleTransfer = (nailieComission || 0) + (settlementFee || 0) + (bankFee || 0);
      const totalConsumptionTax = Math.floor(sumBookingSettleTransfer * TAX_RATE / (100 + TAX_RATE));

      return {
        payoutCyCle,
        salesAmount,
        bookingFee,
        settlementFeeAmount,
        subtotalSales,
        transferFee,
        transferAmountSales,
        transferDatePayment,
        statusPayment,
        salonName: payment.salonName,
        sumBookingSettleTransfer: formatYen(sumBookingSettleTransfer),
        totalConsumptionTax: formatYen(totalConsumptionTax),
        nailieCompanyName: payment.nailieCompanyName,
        nailieCompanyTaxID: payment.nailieCompanyTaxID,
      };
    });

    const CSVStruct = locale === 'en' ? CSVStructEN : CSVStructJA;
    const csvData = FILE_HELPER.makeCSV(listSalonSaleReportData, CSVStruct);
    FILE_HELPER.saveFileCSV(csvData, csvFileName);
  },

  handleExportSalonSaleReportDetailCSV (listBookingData, CSVStructEN, CSVStructJA, csvFileName, locale, lang, categoriesWithTrashed) {
    const listSalonSaleReportDetailData = listBookingData.map(payment => {
      let bookingCustomerType = '';
      const { sourceId, startTime, customer, orders = [], notes } = payment?.booking || {};
      const format = locale === 'en' ? 'YYYY-MM-DD HH:mm' : 'YYYY年MM月DD日 HH:mm';
      const treatmentDate = moment(startTime).format(format);
      const customerName = customer?.name;
      const staffName = payment?.staff?.connections[0].data?.name;
      const categoryBooking = (categoriesWithTrashed || []).find(category => {
        return (category.services || []).find(service => {
          if (service.id === orders[0]?.serviceId) {
            return true;
          }
        });
      });
      const menuCategory = categoryBooking?.name || orders[0]?.category?.name;
      const menuName = orders[0]?.serviceName;
      const salesAmount = formatYen(payment?.totalTreatmentSales || 0);
      const bookingStatus = notes.find(note => note.type === 'targetSystemStatus')?.value;
      const status = lang[BOOKING_STATUS[bookingStatus]?.langKey];
      if (['CANCELED', 'NOVISIT'].includes(bookingStatus)) {
        bookingCustomerType = '-';
      } else {
        bookingCustomerType = lang[BOOKING_CUSTOMER_TYPE[payment?.booking?.extraInfo?.customerBookingBadge].langKey];
      }

      return { sourceId, treatmentDate, customerName, staffName, menuCategory, menuName, salesAmount, status, bookingCustomerType };
    });

    const CSVStruct = locale === 'en' ? CSVStructEN : CSVStructJA;
    const csvData = FILE_HELPER.makeCSV(listSalonSaleReportDetailData, CSVStruct);
    FILE_HELPER.saveFileCSV(csvData, csvFileName);
  },
  // export booking list csv on Search Booking screen
  handleExportAllBookingListCSV (bookingData, CSVStructEN, CSVStructJA, csvFileName, locale, lang, categoriesWithTrashed) {
    const bookingListData = bookingData.map(booking => {
      let status = '';
      const id = booking.sourceId;
      const bookingTime = moment(_.get(booking, 'startTime')).format('YYYY-MM-DD HH:mm');
      const customer = _.get(booking, 'customer.name');
      const staff = (booking?.orders || []).map(item => ({
        name: _.get(item, 'staff.connections[0].data.name') || _.get(item, 'staff.name'),
      }));
      const amount = getSalonSalesAmount(booking);
      const price = formatYen(amount);
      const CANCELED_STATUS = ['CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'];
      const NAILIST_CANCELED_STATUS = ['NAILIST_CANCELED', 'DECLINED_RESERVATION'];
      const statusNotesIndex = (booking.notes || []).findIndex(note => note.type === 'targetSystemStatus');
      const bookingStatusValue = _.get(booking, `notes[${statusNotesIndex}].value`);

      status = lang[BOOKING_STATUS[bookingStatusValue]?.langKey];
      if (CANCELED_STATUS.includes(bookingStatusValue)) {
        status = lang[BOOKING_STATUS.CANCELED?.langKey];
      }

      if (NAILIST_CANCELED_STATUS.includes(bookingStatusValue)) {
        status = lang[BOOKING_STATUS.NAILIST_CANCELED?.langKey];
      }
      return { id, bookingTime, customer, staff, price, status, amount };
    });

    const CSVStruct = locale === 'en' ? CSVStructEN : CSVStructJA;
    const csvData = FILE_HELPER.makeCSV(bookingListData, CSVStruct);
    FILE_HELPER.saveFileCSV(csvData, csvFileName);
  },

  // export booking list csv on Staff sale report detail
  handleExportBookingOnStaffSaleReportDetailCSV (bookingData, CSVStructEN, CSVStructJA, csvFileName, locale, lang, categoriesWithTrashed) {
    const bookingListData = bookingData.map(b => {
      let status = '';
      let bookingCustomerType = '';
      const sourceId = b.booking.sourceId;
      const treatmentDate = moment(_.get(b.booking, 'startTime')).format('YYYY-MM-DD HH:mm');
      const customerPhonetic = _.get(b.booking, 'customer.phonetic');
      const customerName = `${_.get(b.booking, 'customer.name')} (${customerPhonetic})`;

      const amount = getSalonSalesAmount(b.booking);
      const salesAmount = formatYen(amount);
      const CANCELED_STATUS = ['CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'];
      const NAILIST_CANCELED_STATUS = ['NAILIST_CANCELED', 'DECLINED_RESERVATION'];
      const statusNotesIndex = (b.booking.notes || []).findIndex(note => note.type === 'targetSystemStatus');
      const bookingStatusValue = _.get(b.booking, `notes[${statusNotesIndex}].value`);
      const categoryBooking = (categoriesWithTrashed || []).find(category => {
        return (category.services || []).find(service => {
          if (service.id === b.booking?.orders[0]?.serviceId) {
            return true;
          }
        });
      });
      const menuCategory = categoryBooking?.name || b.booking?.orders[0]?.category?.name;
      const menuName = b.booking?.orders[0]?.serviceName;
      status = lang[BOOKING_STATUS[bookingStatusValue]?.langKey];
      if (CANCELED_STATUS.includes(bookingStatusValue)) {
        status = lang[BOOKING_STATUS.CANCELED?.langKey];
      }
      if (NAILIST_CANCELED_STATUS.includes(bookingStatusValue)) {
        status = lang[BOOKING_STATUS.NAILIST_CANCELED?.langKey];
      }
      if (['CANCELED', 'NOVISIT'].includes(bookingStatusValue)) {
        bookingCustomerType = '-';
      } else {
        bookingCustomerType = lang[BOOKING_CUSTOMER_TYPE[b.booking?.extraInfo?.customerBookingBadge].langKey];
      }
      return { sourceId, treatmentDate, customerName, salesAmount, status, menuCategory, menuName, bookingCustomerType };
    });

    const CSVStruct = locale === 'en' ? CSVStructEN : CSVStructJA;
    const csvData = FILE_HELPER.makeCSV(bookingListData, CSVStruct);
    FILE_HELPER.saveFileCSV(csvData, csvFileName);
  },
};
