import {
  logoutFail,
  logoutSuccess,
  requestAccountSalonFail,
  requestAccountSalonSuccess,
  uploadImageFail,
  uploadImageSuccess,
  getAddressByPostCodeSuccess,
  getAddressByPostCodeFail,
  updateSchedulesSalonSuccess,
  updateSchedulesSalonFail,
  changePasswordSuccess,
  changePasswordFail,
} from './actions';
import {
  LOGOUT,
  REQUEST_ACCOUNT_SALON,
  COMPLETED_PROFILE_SALON,
  UPLOAD_IMAGE,
  GET_ADDRESS_BY_POST_CODE,
  UPDATE_SCHEDULES_SALON,
  CHANGE_PASSWORD,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

function * logout (action) {
  try {
    localStorage.removeItem('__a_token');
    yield put(logoutSuccess({}, action.meta));
  } catch (error) {
    Alert.error('エーロが発生しました');
    yield put(logoutFail('エーロが発生しました'));
  }
}

function * requestSalonAccount (action) {
  try {
    const { businessName, email, phoneNumber } = action.payload;

    console.log('environment.api.salonRequest: ', environment.api.salonRequest);
    yield call(request, environment.api.salonRequest, { businessName, email, phoneNumber });

    console.log('requestSalonAccount.action.payload: ', action.payload);

    yield put(requestAccountSalonSuccess({}, action.meta));
    Alert.success('アカウントをリクエストしました');
  } catch (error) {
    console.log(JSON.stringify(error, 0, 2));
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(requestAccountSalonFail('アカウントをもう一回チェックして、後にログインしてください'));
  }
}

function * completedProfileSalon (action) {
  try {
    const { businessName, schedules, salonId, postCode, prefecture, cityOrTown, address, logoUrl, timezone, token } = action.payload;
    const { isMon, isTue, isWed, isThu, isFri, isSat, isSun } = action.payload;
    const { isNail, isHair, isMassage, isSpa } = action.payload;
    const { startTime, endTime } = action.payload;

    const businessTypes = [];
    if (isNail === true) {
      businessTypes.push('nail');
    }

    if (isHair === true) {
      businessTypes.push('hair');
    }

    if (isMassage === true) {
      businessTypes.push('massage');
    }

    if (isSpa === true) {
      businessTypes.push('spa');
    }

    const workingDays = [];
    if (isMon === true) {
      workingDays.push('mon');
    }

    if (isTue === true) {
      workingDays.push('tue');
    }

    if (isWed === true) {
      workingDays.push('web');
    }

    if (isThu === true) {
      workingDays.push('thu');
    }

    if (isFri === true) {
      workingDays.push('fri');
    }

    if (isSat === true) {
      workingDays.push('sat');
    }

    if (isSun === true) {
      workingDays.push('sun');
    }

    console.log('environment.api.salonRequest: ', environment.api.completedProfileSalon);

    yield call(request, `${environment.api.completedProfileSalon}/${salonId}`, {
      businessName,
      businessTypes,
      workingDays,
      location: {
        postCode,
        prefecture,
        cityOrTown,
        address,
      },
      logoUrl,
      businessHour: {
        startAt: {
          hour: Math.floor(startTime / 100),
          minute: startTime % 100,
        },
        endAt: {
          hour: Math.floor(endTime / 100),
          minute: endTime % 100,
        },
      },
      timezone,
    }, 'PATCH', token);

    yield call(request, `${environment.api.updateScheduleSalon}/${salonId}/schedule`, {
      timezone,
      schedules,
    }, 'PUT', token);

    yield put(requestAccountSalonSuccess({}, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(requestAccountSalonFail(error.message, action.meta));
  }
}

function * uploadImage (action) {
  try {
    const { file, token } = action.payload;
    const signedKey = yield call(request, environment.api.uploadImage, { fileName: file.name }, 'POST', token);

    const headers = {
      'Content-Type': file.type,
    };
    yield call(request, '', file, 'PUT', undefined, { baseURL: signedKey.data.signed, headers });

    yield put(uploadImageSuccess(signedKey.data.url, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(uploadImageFail(error, action.meta));
  }
}

function * getAddressByPostCode (action) {
  try {
    const { postCode, token, lang } = action.payload;
    const headers = {
      'Accept-Language': lang || 'ja',
    };
    const result = yield call(request, environment.api.getAddressByPostCode, { postCode }, 'POST', token, { headers });

    yield put(getAddressByPostCodeSuccess(result, action.meta));
  } catch (error) {
    yield put(getAddressByPostCodeFail(error, action.meta));
  }
}

function * updateSchedulesSalon (action) {
  try {
    const { schedules, salon, timezone, token } = action.payload;

    // Transfer From Slot to DateTime UTC String
    const result = yield call(request, `${environment.api.completedProfileSalon}/${salon.id}`, {
      timezone,
      schedules,
      businessTypes: salon.businessTypes,
      location: salon.location,
      businessName: salon.businessName,
    }, 'PATCH', token);
    console.log('result: ', result);

    yield put(updateSchedulesSalonSuccess({}, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    console.log(JSON.stringify(error, 0, 2));
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(updateSchedulesSalonFail({}, action.meta));
  }
}

function * changePassword (action) {
  try {
    const { salonId } = action.payload;
    yield call(request, `${environment.api.changePassword}/${salonId}/reset-password`, {}, 'PUT', action.payload?.token);
    yield put(changePasswordSuccess({}, action.meta));
    Alert.success('パスワードを再設定するメールを送りました');
  } catch (error) {
    yield put(changePasswordFail('エーロが発生しました'));
  }
}

export default function * watchNailist () {
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(REQUEST_ACCOUNT_SALON, requestSalonAccount);
  yield takeLeading(COMPLETED_PROFILE_SALON, completedProfileSalon);
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
  yield takeEvery(GET_ADDRESS_BY_POST_CODE, getAddressByPostCode);
  yield takeEvery(UPDATE_SCHEDULES_SALON, updateSchedulesSalon);
  yield takeLeading(CHANGE_PASSWORD, changePassword);
}
