// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography, Card, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import Avatar from 'components/Desktop/Avatar';

import _get from 'lodash/get';
// Components
import BookingDetailInvoice from './booking-detail-invoice.component';
import CustomerInfo from '../Customer/CustomerInfo';
import PaymentSummaryCompleteBookingModal from './payment-summary-complete-booking-modal';
// Utils
import { getLanguages } from 'utils/lang';
import { BOOKING_CANCELED_STATUSES, PAYMENT_METHOD } from 'utils/constants';
import './styles.less';

const { Text } = Typography;

const BookingViewForm = ({
  booking,
  payment,
  enabledPaymentActionsBtn,
  renderCancelBookingButton,
  onCallbackBookingDetailModal,
  openChangeBookingDateModal,
  handleCloseBookingDetailModal,
}) => {
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // State
  const [paymentSummaryCompleteBookingModal, setPaymentSummaryCompleteBookingModal] = useState(false);
  const locale = useSelector((state) => state.i18n.locale);
  // Constants
  const targetSystemStatus = (booking.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = _get(targetSystemStatus, 'value');
  const isCanceled = BOOKING_CANCELED_STATUSES.includes(bookingStatus);
  const isCanceledOrDone = isCanceled || bookingStatus === 'DONE';
  const isConfirmed = bookingStatus === 'CONFIRMED';

  const renderStaffInfo = () => {
    const { orders = [] } = booking;
    const staffInfo = _get(orders, '[0].staff', {});
    const staffConnectedInfo = _get(staffInfo, 'connections[0].data', {});
    const staffAvatar = staffConnectedInfo.avatarLink || staffInfo.avatar;
    const staffName = staffConnectedInfo.name || staffInfo.name;
    const staffUserName = staffConnectedInfo.username || '';
    const connectionStatus = _get(staffInfo, 'connections[0].status');

    return (
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <h3 style={{ fontWeight: 'bold' }}>{lang.staff}</h3>
        <Row align="middle">
          <Col span={6}>
            {staffAvatar
              ? <Avatar size={70} src={staffAvatar} />
              : <Avatar size={70} icon={<UserOutlined />} />
            }
          </Col>
          <Col span={16} offset={2}>
            {
              connectionStatus === 1 // Salon can click to view staff detail with connections status ( approved: 1)
                ? <a target='_blank' rel="noreferrer" href={`/mysalon/staff-member/${staffInfo.id}`}>
                  <Row align="middle">
                    {staffUserName || '---'}
                  </Row>
                  <Row align="middle">
                    {staffName || '---'}
                  </Row>
                </a>
                : <>
                  <Row align="middle">
                    {staffUserName || '---'}
                  </Row>
                  <Row align="middle">
                    {staffName || '---'}
                  </Row>
                </>
            }
          </Col>
        </Row>
      </Col>
    );
  };

  const renderBookingDateTime = () => {
    const startTime = moment(booking.startTime);
    const endTime = startTime.clone().add(booking.totalDuration, 'minutes');
    let bookingDate = 'YYYY-MM-DD';
    let bookingTime = 'HH:mm - HH:mm';
    if (booking.startTime) {
      if (locale === 'en') {
        bookingDate = startTime.format('YYYY-MM-DD');
      } else {
        bookingDate = startTime.format('YYYY年MM月DD日');
      }
      bookingTime = `${startTime.format('HH:mm')} ~ ${endTime.format('HH:mm')}`;
    }

    return (
      <Row gutter={24} type="flex" style={{ marginTop: 20 }} align="middle">
        <Col sm={{ span: 16 }} xs={{ span: 24 }}>
          <Text type="secondary">{lang.bookingDate}: </Text>
          {bookingDate} {bookingTime}
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Button disabled={isCanceledOrDone} onClick={openChangeBookingDateModal}>{lang.changeDateTime}</Button>
        </Col>
      </Row>
    );
  };

  const renderPayment = () => {
    if (!payment) {
      return null;
    }

    return (
      <Row className="booking-detail-row">
        <span>
          <Text type="secondary">{lang.paymentMethod}: </Text>
          {lang[PAYMENT_METHOD[payment?.method?.name.toUpperCase()].langKey] || '-'}
        </span>
      </Row>
    );
  };
  const renderQuickPaymentButton = () => {
    if (enabledPaymentActionsBtn) {
      return (
        <Button
          block
          danger
          onClick={() => setPaymentSummaryCompleteBookingModal(true)}
          disabled={!enabledPaymentActionsBtn}
          style={{ marginTop: 16 }}
        >
          {lang.quickPayment}
        </Button>
      );
    }
  };

  return (
    <div className="booking-detail">
      <Row gutter={24} type="flex">
        <CustomerInfo
          booking={booking}
          isConfirmed={isConfirmed}
          isCanceled={isCanceled}
        />
        {renderStaffInfo()}
      </Row>
      {renderBookingDateTime()}
      {renderQuickPaymentButton()}
      {enabledPaymentActionsBtn && renderCancelBookingButton()}
      <Card style={{ margin: '2rem 0' }}>
        <BookingDetailInvoice
          booking={booking}
        />
        {renderPayment()}
      </Card>
      <PaymentSummaryCompleteBookingModal
        originalBooking={booking}
        paymentSummaryCompleteBookingModal={paymentSummaryCompleteBookingModal}
        closePaymentSummaryCompleteBookingModal={() => {
          setPaymentSummaryCompleteBookingModal(false);
        }}
        handleCloseBookingDetailModal={handleCloseBookingDetailModal}
        onCallbackBookingDetailModal={onCallbackBookingDetailModal}
      />
    </div>
  );
};

BookingViewForm.propTypes = {
  booking: PropTypes.object,
  payment: PropTypes.object,
  openPaymentDetailModal: PropTypes.func,
  openChangeBookingDateModal: PropTypes.func,
  enabledPaymentActionsBtn: PropTypes.bool,
  renderCancelBookingButton: PropTypes.func,
  onCallbackBookingDetailModal: PropTypes.func,
  handleCloseBookingDetailModal: PropTypes.func,
};

export default BookingViewForm;
