import React, { useMemo } from 'react';
import { Avatar as AntAvatar } from 'antd';
import PropTypes from 'prop-types';
import { mappingS3Domain } from 'utils/mappingS3Domain';

const Avatar = ({ ...props }) => {
  const src = useMemo(() => mappingS3Domain(props.src), [props.src]);
  return <AntAvatar {...props} src={src} />;
};

Avatar.propTypes = {
  src: PropTypes.string,
};

export default Avatar;
