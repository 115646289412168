import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getLanguages } from 'utils/lang';
import './styles.less';
import pinMarker from 'assets/images/pin-marker.png';

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const hideFullScreenOptions = function (maps) {
  return {
    fullscreenControl: false,
  };
};

const SalonEditPlaceOfThePin = ({ salon, coordinateInitValue, handleSetCoordination, isCompleteProfile }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [resetMap, setResetMap] = useState(false);
  const latitude = coordinateInitValue?.lat || _.get(salon, 'location.latLng.lat') || 35.652832;
  const longitude = coordinateInitValue?.lng || _.get(salon, 'location.latLng.lng') || 139.839478;
  const [coordinatesValue, setCoordinatesValue] = useState({ lat: latitude, lng: longitude });

  return (
    <>
      <div style={isCompleteProfile === false ? { height: '45vh', width: '100%', position: 'relative' }
        : { height: '70vh', width: '100%', position: 'relative' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapKey }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          center={resetMap && coordinatesValue}
          defaultZoom={16}
          yesIWantToUseGoogleMapApiInternals
          options={hideFullScreenOptions}
          onChange={(e) => {
            setCoordinatesValue({ lat: e.center.lat, lng: e.center.lng });
            setResetMap(false);
          }}
        />
        <img src={pinMarker}
          style={{
            height: 40,
            position: 'absolute',
            bottom: '50%',
            left: '50%',
            transform: 'translate(-50%, 0px)',
          }} />
      </div>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                setResetMap(true);
                setCoordinatesValue({ lat: latitude, lng: longitude });
              }}>
              {lang.reset}
            </Button>
            <Button
              type="primary"
              onClick={() => handleSetCoordination({ coordinates: coordinatesValue })}>
              {lang.selectedOption}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

SalonEditPlaceOfThePin.propTypes = {
  salon: PropTypes.object,
  coordinateInitValue: PropTypes.object,
  handleSetCoordination: PropTypes.func,
  isCompleteProfile: PropTypes.bool,
  onCallback: PropTypes.func,
};

export default SalonEditPlaceOfThePin;
