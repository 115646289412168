import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'hooks/useCustomDispatch';
import moment from 'moment';
import { Button, Modal, Form, Col, Row, Radio, Card, Divider, Select, InputNumber, Input, Checkbox } from 'antd';
import { formatYen } from 'utils/stringFormat';
import { useTranslation } from 'react-i18next';
import { paymentCompletion, paymentRefund, paymentTip } from 'providers/PaymentProvider/actions';
import { getLanguages } from 'utils/lang';
import './styles.less';
const PaymentBillingForm = ({ booking, paymentDetail, isComplete, setIsComplete, onCallback }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const dispatch = useDispatch();
  const id = paymentDetail?.id;
  const [form] = Form.useForm();
  const [values, setValues] = useState(1);
  const [isOpenRefundModal, setisOpenRefundModal] = useState(false);
  const [tipEachStaff, setTipEachStaff] = useState([]);
  const [tipAllStaff, setTipAllStaff] = useState();
  const [finalPrice, setFinalPrice] = useState(booking?.finalPrice);
  const { salon } = useSelector(state => state.salon);
  const statusBilling = booking?.status;
  const [isAddTip, setIsAddTip] = useState(false);

  const setTipValues = () => {
    (() => {
      let tips = _.get(booking, 'orders', []).map((item, id) => ({ tip: '', name: item?.staff?.name, idStaff: item?.staff?.id }));
      tips = _.uniqBy(tips, 'idStaff');
      form.setFieldsValue({
        tips,
      });
    })();
  };
  useEffect(setTipValues, []);

  const calcTotalMoney = (tips = [], initTotal = 0) => {
    return tips.reduce((sum, item) => {
      return Number(sum) + Number(item.tip || 0);
    }, initTotal);
  };

  const onChange = e => {
    setValues(e.target.value);
  };
  // add tips to staff
  const addTipToStaff = async () => {
    if (form.getFieldValue('isCheckAll')) {
      // add tip all staff
      let currentTips = form.getFieldValue('tips') || [];
      const tipAll = form.getFieldValue('allTip') ?? 0;
      currentTips = currentTips.map((item) => ({
        ...item,
        tip: '',
      }));
      form.setFieldsValue({
        tips: currentTips,
      });

      setTipEachStaff([]);
      if (tipAll > 0) {
        const paymentId = id;
        const tips = [{
          amount: tipAll,
          staffId: null,
        }];
        await dispatch(paymentTip({ tips, paymentId }));
      }
    } else {
      // add tip each staff
      const currentTips = form.getFieldValue('tips') || [];
      const tipEachStaff = currentTips.filter(item => item.tip !== '');
      const paymentId = id;
      const tips = tipEachStaff.map((item) => {
        return {
          amount: item.tip,
          staffId: item.idStaff,
        };
      });
      await dispatch(paymentTip({ tips, paymentId }));
    }
  };
  // remove tip
  const removeTip = () => {
    let currentTips = form.getFieldValue('tips') || [];
    currentTips = currentTips.map((item) => ({
      ...item,
      tip: '',
    }));

    form.setFieldsValue({
      allTip: '',
      tips: currentTips,
    });
    setIsAddTip(false);
    setFinalPrice(booking?.finalPrice);
    setTipAllStaff();
    setTipEachStaff([]);
  };

  return (
    <Form
      form={form}
      name="paymentBilling"
      onFinish={
        () => setIsComplete(true)
      }
    >
      <div className="payment-billing-form">
        <Row>
          <Col span={12} >
            <Card bordered className="billing-card-info">
              <Row >
                <div style={{ fontSize: 14, fontWeight: 'bold' }}> {_.get(salon, 'businessName')}</div>
                <Col span={24}>
                  <Row>
                    <Col span={12} style={{ color: '#A8A8A8' }}>
                      <div>{lang.bookingID}</div>
                      <div>{lang.date}</div>
                      <div>{lang.customerID}</div>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <div>{_.get(booking, 'id')}</div>
                      <div>{moment(_.get(booking, 'startTime')).format('HH:mm YYYY-MM-DD')}</div>
                      <div>{_.get(booking, 'customer.id')}</div>
                    </Col>
                  </Row>
                  <div style={{ height: 1, width: '100%', backgroundColor: '#D7D7D7', marginTop: 8, marginBottom: 8 }} />
                  {
                    _.get(booking, 'orders', []).map((item, id) => {
                      if (item.service !== null && item.service !== undefined) {
                        return (
                          <Row id={id}>
                            <Col span={18} >
                              <div > {item?.service?.name} - {item?.service?.duration}{lang.min} </div>
                            </Col>
                            <Col span={6} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                              <div>{formatYen(item?.service?.price)}</div>
                            </Col>
                          </Row>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
                  <div style={{ height: 0.75, width: '100%', backgroundColor: '#D7D7D7', marginTop: 8, marginBottom: 8 }} />
                  <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={14} >
                      <div>
                        <span>{lang.subtotal}</span>
                        <span> - </span>
                        <span>{_.get(booking, 'totalDuration')}{lang.min}</span>
                      </div>
                      <div>{lang.discount}</div>
                      <div>{lang.tax}</div>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '13' }}>
                      <div >{formatYen(booking?.totalPrice)}</div>
                      <div >{booking?.discount > 0 ? `-${formatYen(booking?.discount)}` : formatYen(0)}</div>
                      <div >{formatYen(0)}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ fontSize: 14, fontWeight: 'bold' }}>
                <div style={{ height: 1, width: '100%', backgroundColor: '#D7D7D7', marginTop: 8, marginBottom: 8 }} />
                <Col span={12} >
                  <div >{lang.total}</div>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {
                    <div>{formatYen(booking?.finalPrice)}</div>
                  }
                </Col>
              </Row>
              {
                (booking?.status === 1 && booking?.cancellationFee !== 0) &&
                <>
                  <Row >
                    <Col span={16} style={{ textAlign: 'left' }}>
                      <div >{lang.cancellationFee} ({booking?.cancellationFee === 0.5 ? '50%' : booking?.cancellationFee === 1 ? '100%' : ''})</div>
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                      <div>{(formatYen(booking?.charge))}</div>
                    </Col>
                  </Row>
                </>
              }
              {
                (tipEachStaff !== undefined && tipEachStaff !== null) &&
                tipEachStaff.map((item, index) => {
                  return (
                    <Row key={index}>
                      <Col span={12} >
                        <div>
                          <span>{lang.tipFor} {item?.name}</span>
                        </div>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '13' }}>
                        <div>
                          <span>{formatYen(item?.tip)}</span>
                        </div>
                      </Col>
                    </Row>
                  );
                })
              }
              {
                (tipAllStaff !== undefined && tipAllStaff !== null) &&
                <Row>
                  <Col span={12} >
                    <div>
                      <span>{lang.tipForAllStaff}</span>
                    </div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '13' }}>
                    <div>
                      <span>{formatYen(tipAllStaff)}</span>
                    </div>
                  </Col>
                </Row>
              }
              <Row style={{ fontSize: 14, fontWeight: 'bold' }}>
                <div style={{ height: 1, width: '100%', backgroundColor: '#D7D7D7', marginTop: 8, marginBottom: 8 }} />
                <Col span={12} >
                  <div>{lang.customerPay}</div>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {
                    booking?.status === 1 ? <div>{formatYen(booking?.charge)}</div>
                      : <div>{formatYen(finalPrice)}</div>
                  }
                </Col>
              </Row>
            </Card>

          </Col>
          <Col lg={{ span: 1 }} xs={{ span: 24 }}>
            <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '108%', marginTop: -25 }} />
          </Col>
          <Col span={11}>
            <div style={{ marginLeft: 0 }}>
              <div style={{ fontWeight: 'bold', fontSize: 14 }}>{lang.customerInfo}</div>
              <Row gutter={12}>
                <Col span={8} style={{ textAlign: 'left', color: '#A8A8A8' }}>
                  <div>{lang.customerName}</div>
                  <div>{lang.gender}</div>
                  <div>{lang.phone}</div>
                  <div>{lang.email}</div>
                </Col>
                <Col span={12} style={{ textAlign: 'left' }}>
                  <div>{booking?.customer?.name}</div>
                  <div>{booking?.customer?.gender === 0 ? lang.male : lang.female}</div>
                  <div>{booking?.customer?.phoneNumber}</div>
                  <div>{booking?.customer?.email}</div>
                </Col>
              </Row>
              <Row>
                <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 16 }}>{lang.paymentMethod}</div>
                <Col span={24} style={{ marginLeft: 16 }}>
                  {
                    isComplete === false &&
                    <Radio.Group onChange={onChange} value={values}>
                      <Radio style={{ display: 'block' }} value={1}>
                        {lang.cash}
                      </Radio>
                      <Radio style={{ display: 'block' }} value={2}>
                        {lang.card}
                      </Radio>
                    </Radio.Group>
                  }
                  {
                    isComplete &&
                    <Radio.Group >
                      <Radio style={{ display: 'block' }} defaultChecked={true}>
                        {values === 1 ? lang.cash : lang.cash }
                      </Radio>
                    </Radio.Group>
                  }
                </Col>
              </Row>

              {
                isComplete === false &&
                <>
                  <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 16, marginBottom: 16 }}>{lang.tips}</div>
                  <Form.List name={'tips'}>
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map(field => (
                            <Row gutter={12} style={{ marginTop: -12 }} key={field.name}>
                              <Col span={8} className={`${statusBilling === 1 ? 'disabled' : ''}`}>{form.getFieldValue(['tips', field.name, 'name'])}</Col>
                              <Col span={16}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'tip']}
                                  fieldKey={[field.fieldKey, 'tip']}
                                >
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder={lang.tipsForStaffPlaceholder}
                                    min={0}
                                    formatter={value => `¥${value}`}
                                    disabled={!!(form.getFieldValue('isCheckAll') || statusBilling === 1)}
                                  />
                                </Form.Item>
                              </Col>

                            </Row>
                          ))}
                        </>
                      );
                    }}
                  </Form.List>
                  <Row gutter={12} style={{ marginTop: -12 }}>
                    <Col span={8}>
                      <Form.Item name="isCheckAll">
                        <Checkbox
                          disabled={statusBilling === 1}
                          defaultChecked={false}
                          onChange={(e) => {
                            form.setFieldsValue({
                              isCheckAll: e.target.checked,
                            });
                          }}
                        >
                          {lang.allStaff}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) => (prevValues.isCheckAll !== currentValues.isCheckAll)}
                      >
                        {({ getFieldValue }) => (
                          <Form.Item name="allTip">
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder={lang.tipsForStaffPlaceholder}
                              disabled={!getFieldValue('isCheckAll')}
                              min={0}
                              formatter={value => `¥${value}`}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ textAlign: 'right', marginTop: -32 }}>
                    <Button
                      type="link"
                      disabled={statusBilling === 1}
                      onClick={() => removeTip()}
                    >
                      <span>{lang.removeTips}</span>
                    </Button>
                    <Button
                      type="gost"
                      style={{ color: '#17AEF0', borderColor: '#17AEF0' }}
                      disabled={statusBilling === 1}
                      onClick={() => {
                        setIsAddTip(true);
                        if (form.getFieldValue('isCheckAll')) {
                          // add tip all staff
                          let currentTips = form.getFieldValue('tips') || [];
                          const tipAll = form.getFieldValue('allTip') ?? 0;
                          currentTips = currentTips.map((item) => ({
                            ...item,
                            tip: '',
                          }));
                          form.setFieldsValue({
                            tips: currentTips,
                          });

                          setTipEachStaff([]);
                          if (tipAll > 0) {
                            setTipAllStaff(tipAll);
                            setFinalPrice(booking?.finalPrice + tipAll);
                          }
                        } else {
                          // add tip each staff
                          setTipAllStaff();
                          const currentTips = form.getFieldValue('tips') || [];
                          const tipEachStaff = currentTips.filter(item => (item.tip !== '' && item.tip > 0));
                          tipEachStaff.map((item) => {
                            setTipEachStaff(tipEachStaff);
                            setFinalPrice(calcTotalMoney(tipEachStaff, booking?.finalPrice));
                          });
                        }
                      }}>
                      {lang.addTips}
                    </Button>
                  </div>
                </>
              }

              <div style={{ textAlign: 'right', marginRight: 33 }}>
                {
                  isComplete === false &&
                  <>
                    <Divider style={{ width: '127%', marginLeft: -31 }} />
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={
                        async () => {
                          if (isAddTip) {
                            await addTipToStaff();
                          }
                          dispatch(paymentCompletion({ id }));
                        }}
                    >
                      {lang.complete}
                    </Button>
                  </>
                }
              </div>
              <div style={{ textAlign: 'right', marginRight: 53 }}>
                {
                  isComplete &&
                  <div style={{ marginTop: 200 }}>
                    <Divider style={{ width: '135%', marginLeft: -31 }} />
                    <span style={{ marginRight: 8, color: 'red' }}>
                      <Button
                        type="gost"
                        style={{ color: 'red', borderColor: 'red' }}
                        onClick={() => setisOpenRefundModal(true)}
                      >
                        {lang.refund}
                      </Button>
                    </span>
                    <Button
                      type="primary"
                      htmlType="submit"
                    >
                      Print
                    </Button>
                  </div>
                }

              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        visible={isOpenRefundModal}
        title={lang.refundReason}
        onCancel={() => {
          setisOpenRefundModal(false);
        }}
        destroyOnClose={true}
        width={480}
        centered
        footer={null}
      >
        <Form
          form={form}
          name="refundForm"
          onFinish={values => {
            values.paymentId = id;
            dispatch(paymentRefund({ values }))
              .then(() => {
                onCallback();
                setisOpenRefundModal(false);
              });
          }}
        >
          <div className="refund-billing-form">
            <Form.Item
              label={lang.reason}
              name="notes"
              labelCol={{ span: 8, offset: 0 }}
              rules={[
                {
                  required: true,
                  message: lang.emptyRefundReasonError,
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder={lang.refundReasonPlaceholder}
              >
                <Select.Option value={lang.customerUnsatisfied}> {lang.customerUnsatisfied}</Select.Option>
                <Select.Option value={lang.badDesign}> {lang.badDesign}</Select.Option>
                <Select.Option value={lang.badServices}> {lang.badServices}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={lang.note}
              name="noteCategory"
              labelCol={{ span: 8, offset: 0 }}
            >
              <Input.TextArea maxLength={200}/>
            </Form.Item>
            <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 16 }}>
              <Button type="primary" htmlType="submit">OK</Button>
            </div>
          </div>
        </Form>
      </Modal>
    </Form>
  );
};

PaymentBillingForm.propTypes = {
  booking: PropTypes.object,
  paymentDetail: PropTypes.object,
  isComplete: PropTypes.bool,
  setIsComplete: PropTypes.func,
  onCallback: PropTypes.func,
};

export default PaymentBillingForm;
