import {
  serviceCreateSuccess,
  serviceCreateFail,
  serviceUpdateSuccess,
  serviceUpdateFail,
  serviceOrderSuccess,
  serviceDeleteSuccess,
  serviceDeleteFail,
} from './actions';

import {
  categoryList as categoryListAction,
} from '../CategoryProvider/actions';

import {
  SERVICE_CREATE,
  SERVICE_UPDATE,
  SERVICE_ORDER,
  SERVICE_DELETE,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

function * serviceCreate (action) {
  try {
    let { values, token } = action.payload;
    const repeatTypeValue = values.repeatType;
    values = {
      ...values,
      extraInfo: {
        repeatType: repeatTypeValue,
      },
    };
    delete values.repeatType;

    yield call(request, environment.api.serviceCreate, values, 'POST', token);
    yield put(serviceCreateSuccess({}, action.meta));
    yield put(categoryListAction({ token: token }));
    Alert.success('作成完了');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(serviceCreateFail());
    console.log(error);
  }
}

function * serviceUpdate (action) {
  try {
    let { id, values, token } = action.payload;
    const repeatTypeValue = values?.repeatType || values?.extraInfo?.repeatType;
    values = {
      ...values,
      extraInfo: {
        repeatType: repeatTypeValue,
      },
    };
    delete values.repeatType;

    yield call(request, `${environment.api.serviceUpdate}/${id}`, values, 'PUT', token);
    yield put(serviceUpdateSuccess({}, action.meta));
    yield put(categoryListAction({ token: token }));
    Alert.success('設定を変更しました');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(serviceUpdateFail());
    console.log(error);
  }
}

function * serviceOrder (action) {
  try {
    const { dataSource, oldIndex, newIndex, token } = action.payload;

    /**
     * Drag menu from top to bottom.
     *
     * @var {number} dragDirection use to calculate front menu index.
     * @var {number} startIndex drag menu index + 1
     * @var {number} endIndex destination menu index
     */
    let dragDirection = -1;
    let startIndex = oldIndex + 1;
    let endIndex = newIndex;
    /**
     * Drag menu from bottom to top.
     *
     * @var {number} dragDirection use to calculate back menu index.
     * @var {number} startIndex destination menu index
     * @var {number} endIndex drag menu index - 1
     */
    if (oldIndex > newIndex) {
      dragDirection = 1;
      startIndex = newIndex;
      endIndex = oldIndex - 1;
    }

    // Change order for each menu.
    for (let index = startIndex; index <= endIndex; index++) {
      const menu = dataSource[index];
      const nearbyIndex = index + dragDirection;
      const nearbyMenu = dataSource[nearbyIndex];
      yield call(
        request,
        `${environment.api.serviceUpdate}/${menu.id}`,
        {
          ...menu,
          order: nearbyMenu.order,
        },
        'PUT',
        token,
      );
    }

    // Change order for drag menu.
    const dragMenu = dataSource[oldIndex];
    yield call(
      request,
      `${environment.api.serviceUpdate}/${dragMenu.id}`,
      {
        ...dragMenu,
        order: dataSource[newIndex].order,
      },
      'PUT',
      token,
    );

    yield put(serviceOrderSuccess({}, action.meta));
    yield put(categoryListAction({ token: token }));
  } catch (e) {
    Alert.error('エラーが発生しました');
    yield put(serviceUpdateFail());
    console.log(e);
  }
}

function * serviceDelete (action) {
  try {
    const { id, token } = action.payload;
    yield call(request, `${environment.api.serviceDelete}/${id}`, {}, 'DELETE', token);
    yield put(serviceDeleteSuccess());
    yield put(categoryListAction({ token: token }));
    Alert.success('削除しました');
  } catch (error) {
    Alert.error(error.message);
    yield put(serviceDeleteFail());
  }
}

export default function * watchNailist () {
  yield takeLeading(SERVICE_CREATE, serviceCreate);
  yield takeEvery(SERVICE_UPDATE, serviceUpdate);
  yield takeEvery(SERVICE_ORDER, serviceOrder);
  yield takeLeading(SERVICE_DELETE, serviceDelete);
}
