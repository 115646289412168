import { useEffect, useState } from 'react';
import { getThirdPartySourceStaffList } from 'providers/StaffProvider/actions';
import { useDispatch } from 'hooks/useCustomDispatch';

const useFetchStaffsComingSoon = () => {
  const dispatch = useDispatch();
  const [staffsComingSoon, setStaffsCOmingSoon] = useState([]);
  const [loading, setLoading] = useState(false);
  const getStaffComingSoonList = async () => {
    setLoading(true);
    const result = await dispatch(getThirdPartySourceStaffList());
    const { data } = result;
    setStaffsCOmingSoon(data);
    setLoading(false);
    return data;
  };
  useEffect(() => {
    getStaffComingSoonList();
  }, []);
  return { staffsComingSoon, getStaffComingSoonList, loading };
};

export default useFetchStaffsComingSoon;
