/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import createSagaMiddleware from 'redux-saga';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['auth'],
  // transforms: [createFilter('auth', ['isAuth', 'userInfo'])],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunkMiddleware, sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle */
const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    shouldHotReload: false,
  })
  : compose;
/* eslint-enable */

const store = createStore(persistedReducer, composeEnhancers(...enhancers));
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
