import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  SET_CURRENT_USER,
  REQUEST_ACCOUNT_SALON,
  REQUEST_ACCOUNT_SALON_FAIL,
  REQUEST_ACCOUNT_SALON_SUCCESS,
  COMPLETED_PROFILE_SALON,
  COMPLETED_PROFILE_SALON_SUCCESS,
  COMPLETED_PROFILE_SALON_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_SUCCESS,
  GET_ADDRESS_BY_POST_CODE,
  GET_ADDRESS_BY_POST_CODE_FAIL,
  GET_ADDRESS_BY_POST_CODE_SUCCESS,
  UPDATE_SCHEDULES_SALON,
  UPDATE_SCHEDULES_SALON_SUCCESS,
  UPDATE_SCHEDULES_SALON_FAIL,
} from './types';

const INITIAL_STATE = {
  user: {},
  token: '',
  error: '',
  loading: false,
  areas: {},
  prefectures: {},
  cities: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: false,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case LOGOUT:
      return {
        ...state,
        user: {},
        token: '',
        error: '',
        loading: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };

    case REQUEST_ACCOUNT_SALON:
      return {
        ...state,
        loading: true,
      };

    case REQUEST_ACCOUNT_SALON_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: false,
      };

    case REQUEST_ACCOUNT_SALON_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case COMPLETED_PROFILE_SALON:
    case COMPLETED_PROFILE_SALON_SUCCESS:
    case COMPLETED_PROFILE_SALON_FAIL:
    case GET_ADDRESS_BY_POST_CODE:
    case GET_ADDRESS_BY_POST_CODE_SUCCESS:
    case GET_ADDRESS_BY_POST_CODE_FAIL:
    case UPLOAD_IMAGE:
    case UPLOAD_IMAGE_SUCCESS:
    case UPLOAD_IMAGE_FAIL:
    case UPDATE_SCHEDULES_SALON:
    case UPDATE_SCHEDULES_SALON_SUCCESS:
    case UPDATE_SCHEDULES_SALON_FAIL:
      return {
        ...state,
      };

    default:
      return state;
  }
};
