// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

// Providers
import { ComingSoonContext } from './SalonConnectThirdPartiesSchedule';
// Utils
import { SalonStaffConnectComingSoonStaff } from './SalonStaffConnectComingSoonForm';
import { getLanguages } from 'utils/lang';

const StaffsMappingInitSettingModal = ({
  connectingParty,
  renderButtonDisconnect3rd,
  staffsComingSoon,
  getStaffList,
  staffs,
}) => {
  // const dispatch = useDispatch();
  const CSContext = useContext(ComingSoonContext);

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Modal
      visible={CSContext.isOpenInitSettingMappingPopup}
      destroyOnClose={true}
      title={lang.mappingPopupTitle}
      style={{ height: 'calc(100vh - 160px)' }}
      bodyStyle={{ overflowY: 'scroll' }}
      footer={null}
      width={762}
      centered={true}
      maskClosable={false}
      closable={false}
      keyboard={false}
      onCancel={() => CSContext.setIsOpenInitSettingMappingPopup(false)}
    >
      <SalonStaffConnectComingSoonStaff
        staffsComingSoon={staffsComingSoon}
        staffs={staffs}
        getStaffList={getStaffList}
        connectingParty={connectingParty}
        renderButtonDisconnect3rd={renderButtonDisconnect3rd}
      />
    </Modal>
  );
};

StaffsMappingInitSettingModal.propTypes = {
  connectingParty: PropTypes.string,
  renderButtonDisconnect3rd: PropTypes.func,
  staffsComingSoon: PropTypes.array,
  staffs: PropTypes.array,
  getStaffList: PropTypes.func,
};
export default StaffsMappingInitSettingModal;
