import { combineReducers } from 'redux';
import i18n from 'providers/I18nProvider/reducer';
import auth from 'providers/AuthProvider/reducer';
import customer from 'providers/CustomerProvider/reducer';
import category from 'providers/CategoryProvider/reducer';
import service from 'providers/ServiceProvider/reducer';
import salon from 'providers/SalonProvider/reducer';
import staffs from 'providers/StaffProvider/reducer';
import booking from 'providers/BookingProvider/reducer';
import station from 'providers/StationProvider/reducer';

export default combineReducers({
  i18n,
  auth,
  customer,
  category,
  service,
  salon,
  staffs,
  booking,
  station,
});
