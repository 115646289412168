import {
  SERVICE_DETAIL,
  SERVICE_CREATE,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_FAIL,
  SERVICE_UPDATE,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
  SERVICE_ORDER,
  SERVICE_ORDER_SUCCESS,
  SERVICE_ORDER_FAIL,
  SERVICE_DELETE,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,
} from './types';

// SERVICE_DETAIL
export function serviceDetail (payload = {}) {
  return { type: SERVICE_DETAIL, payload };
}

// SERVICE_CREATE
export function serviceCreate (payload = {}) {
  console.log('payload: ', payload);
  return { type: SERVICE_CREATE, payload, meta: { thunk: true } };
}
export function serviceCreateSuccess (payload = {}, meta) {
  return { type: SERVICE_CREATE_SUCCESS, payload, meta };
}
export function serviceCreateFail (payload = {}, meta) {
  return { type: SERVICE_CREATE_FAIL, payload, meta, error: true };
}

// SERVICE_UPDATE
export function serviceUpdate (payload = {}) {
  return { type: SERVICE_UPDATE, payload, meta: { thunk: true } };
}
export function serviceUpdateSuccess (payload = {}, meta) {
  return { type: SERVICE_UPDATE_SUCCESS, payload, meta };
}
export function serviceUpdateFail (payload = {}, meta) {
  return { type: SERVICE_UPDATE_FAIL, payload, meta, error: true };
}

// SERVICE_ORDER
export function serviceOrder (payload = {}) {
  return { type: SERVICE_ORDER, payload, meta: { thunk: true } };
}
export function serviceOrderSuccess (payload = {}, meta) {
  return { type: SERVICE_ORDER_SUCCESS, payload, meta };
}
export function serviceOrderFail (payload = {}, meta) {
  return { type: SERVICE_ORDER_FAIL, payload, meta, error: true };
}

// SERVICE_DELETE
export function serviceDelete (payload = {}) {
  return { type: SERVICE_DELETE, payload };
}
export function serviceDeleteSuccess (payload = {}) {
  return { type: SERVICE_DELETE_SUCCESS, payload };
}
export function serviceDeleteFail (payload = {}) {
  return { type: SERVICE_DELETE_FAIL, payload };
}
