import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Col, Row, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

const { Option } = Select;

const SalonJapanPublicHolidaysAddForm = ({ workingTimes, onCancel }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [daySelecteds] = useState([
    {
      index: 0,
      from: 800,
      to: 1200,
      is_monday: true,
      is_tueday: true,
      is_wednesday: true,
      is_thursday: true,
      is_friday: true,
      is_saturday: false,
      is_sunday: false,
      is_deleted: false,
    },
    {
      index: 1,
      from: 1400,
      to: 1700,
      is_monday: false,
      is_tueday: false,
      is_wednesday: false,
      is_thursday: false,
      is_friday: false,
      is_saturday: true,
      is_sunday: true,
      is_deleted: false,
    },
  ]);

  const days = [
    {
      value: '2020-02-09',
      name: 'New Year Day',
    },
    {
      value: '2020-02-10',
      name: 'New Year Day 1',
    },
    {
      value: '2020-02-11',
      name: 'New Year Day 2',
    },
    {
      value: '2020-02-12',
      name: 'New Year Day 3',
    },
    {
      value: '2020-02-13',
      name: 'New Year Day 3',
    },
  ];
  const years = [
    {
      value: 2020,
      label: '2020',
    },
    {
      value: 2021,
      label: '2021',
    },
  ];

  return (
    <Form>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <span>{lang.selectYear}:</span>
          <Select
            placeholder=""
            onChange={value => {
              console.log(value);
            }}
            style={{ width: 120, marginLeft: 20 }}
          >
            {years.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 24, marginBottom: 24 }}>
        {days.map((day, index) => {
          if (index % 2 === 0) {
            return (
              <>
                <Col lg={{ span: 6 }} xs={{ span: 24 }} />
                <Col lg={{ span: 8 }} xs={{ span: 24 }} style={{ marginBottom: 10 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Checkbox style={{ fontWeight: 'bold' }}>{day.name}</Checkbox>
                    <span style={{ marginLeft: 24 }}>{day.value}</span>
                  </div>
                </Col>
              </>
            );
          } else {
            return (
              <Col lg={{ span: 10 }} xs={{ span: 24 }} style={{ marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Checkbox style={{ fontWeight: 'bold' }}>{day.name}</Checkbox>
                  <span style={{ marginLeft: 24 }}>{day.value}</span>
                </div>
              </Col>
            );
          }
        })}
      </Row>
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel}>
              {lang.cancel}
            </Button>
            <Button type="primary" onClick={() => console.log('values: ', daySelecteds)}>
              {lang.save}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SalonJapanPublicHolidaysAddForm.propTypes = {
  onCancel: PropTypes.func,
  workingTimes: PropTypes.array,
};

export default SalonJapanPublicHolidaysAddForm;
