import {
  SALON_DETAIL,
  SALON_DETAIL_SUCCESS,
  SALON_DETAIL_FAIL,

  SALON_ACCOUNT_UPDATE,
  SALON_ACCOUNT_UPDATE_FAIL,
  SALON_ACCOUNT_UPDATE_SUCCESS,

  SALON_GET_PROPERTIES,
  SALON_GET_PROPERTIES_FAIL,
  SALON_GET_PROPERTIES_SUCCESS,

  SALON_BANK_UPDATE,
  SALON_BANK_UPDATE_SUCCESS,
  SALON_BANK_UPDATE_FAIL,

  SALON_GET_BANK,
  SALON_GET_BANK_SUCCESS,
  SALON_GET_BANK_FAIL,

  SALON_GET_BANK_BRANCH,
  SALON_GET_BANK_BRANCH_SUCCESS,
  SALON_GET_BANK_BRANCH_FAIL,

  SALON_SCHEDULE_UPDATE,
  SALON_SCHEDULE_UPDATE_SUCCESS,
  SALON_SCHEDULE_UPDATE_FAIL,

  SALON_SETTINGS_GET,
  SALON_SETTINGS_GET_SUCCESS,
  SALON_SETTINGS_GET_FAIL,

  SALON_CANCELLATION_POLICY_UPDATE,
  SALON_CANCELLATION_POLICY_UPDATE_FAIL,
  SALON_CANCELLATION_POLICY_UPDATE_SUCCESS,
  SALON_GET_ALL_SURVEY,
  SALON_GET_ALL_SURVEY_SUCCESS,
  SALON_GET_ALL_SURVEY_FAIL,
  SALON_SURVEY_DETAIL,
  SALON_UPDATE_SURVEY,
  SALON_UPDATE_SURVEY_SUCCESS,
  SALON_UPDATE_SURVEY_FAIL,
  SALON_SURVEY_DETAIL_SUCCESS,
  SALON_SURVEY_DETAIL_FAIL,
  SALON_SURVEY_RESULT,
  SALON_SURVEY_RESULT_SUCCESS,
  SALON_SURVEY_RESULT_FAIL,

  GET_LIST_SALON_SALE_REPORT,
  GET_LIST_SALON_SALE_REPORT_SUCCESS,
  GET_LIST_SALON_SALE_REPORT_FAIL,

  GET_LIST_STAFF_SALE_REPORT_BY_SALON,
  GET_LIST_STAFF_SALE_REPORT_BY_SALON_SUCCESS,
  GET_LIST_STAFF_SALE_REPORT_BY_SALON_FAIL,

  GET_LIST_BOOKING_SALON_SALE_REPORT,
  GET_LIST_BOOKING_SALON_SALE_REPORT_SUCCESS,
  GET_LIST_BOOKING_SALON_SALE_REPORT_FAIL,

  SALON_GET_ALL_AREAS,
  SALON_GET_ALL_AREAS_SUCCESS,
  SALON_GET_ALL_AREAS_FAIL,
  TOGGLE_THIRD_PARTY,
  TOGGLE_THIRD_PARTY_SUCCESS,
  TOGGLE_THIRD_PARTY_FAIL,
  CHANGE_SALON_EMAIL,
  CHANGE_SALON_EMAIL_SUCCESS,
  CHANGE_SALON_EMAIL_FAIL,

} from './types';

export function salonDetail (payload = {}) {
  return { type: SALON_DETAIL, payload, meta: { thunk: true } };
}
export function salonDetailSuccess (payload = {}, meta) {
  return { type: SALON_DETAIL_SUCCESS, payload, meta };
}
export function salonDetailFail (payload = {}, meta) {
  return { type: SALON_DETAIL_FAIL, payload, meta, error: true };
}

export function salonAccountUpdate (payload = {}) {
  return { type: SALON_ACCOUNT_UPDATE, payload, meta: { thunk: true } };
}
export function salonAccountUpdateSuccess (payload = {}, meta) {
  return { type: SALON_ACCOUNT_UPDATE_SUCCESS, payload, meta };
}
export function salonAccountUpdateFail (payload = {}, meta) {
  return { type: SALON_ACCOUNT_UPDATE_FAIL, payload, meta, error: true };
}

export function salonGetProperties (payload = {}) {
  return { type: SALON_GET_PROPERTIES, payload, meta: { thunk: true } };
}
export function salonGetPropertiesSuccess (payload = {}, meta) {
  return { type: SALON_GET_PROPERTIES_SUCCESS, payload, meta };
}
export function salonGetPropertiesFail (payload = {}, meta) {
  return { type: SALON_GET_PROPERTIES_FAIL, payload, meta, error: true };
}

export function salonBankUpdate (payload = {}) {
  return { type: SALON_BANK_UPDATE, payload, meta: { thunk: true } };
}
export function salonBankUpdateSuccess (payload = {}, meta) {
  return { type: SALON_BANK_UPDATE_SUCCESS, payload, meta };
}
export function salonBankUpdateFail (payload = {}, meta) {
  return { type: SALON_BANK_UPDATE_FAIL, payload, meta, error: true };
}

export function salonGetBank (payload = {}) {
  return { type: SALON_GET_BANK, payload, meta: { thunk: true } };
}
export function salonGetBankSuccess (payload = {}, meta) {
  return { type: SALON_GET_BANK_SUCCESS, payload, meta };
}
export function salonGetBankFail (payload = {}, meta) {
  return { type: SALON_GET_BANK_FAIL, payload, meta, error: true };
}

export function salonGetBankBranch (payload = {}) {
  return { type: SALON_GET_BANK_BRANCH, payload, meta: { thunk: true } };
}
export function salonGetBankBranchSuccess (payload = {}, meta) {
  return { type: SALON_GET_BANK_BRANCH_SUCCESS, payload, meta };
}
export function salonGetBankBranchFail (payload = {}, meta) {
  return { type: SALON_GET_BANK_BRANCH_FAIL, payload, meta, error: true };
}

export function salonScheduleUpdate (payload = {}) {
  return { type: SALON_SCHEDULE_UPDATE, payload, meta: { thunk: true } };
}
export function salonScheduleUpdateSuccess (payload = {}, meta) {
  return { type: SALON_SCHEDULE_UPDATE_SUCCESS, payload, meta };
}
export function salonScheduleUpdateFail (payload = {}, meta) {
  return { type: SALON_SCHEDULE_UPDATE_FAIL, payload, meta, error: true };
}

export function salonSettingsGet (payload = {}) {
  return { type: SALON_SETTINGS_GET, payload, meta: { thunk: true } };
}
export function salonSettingsGetSuccess (payload = {}, meta) {
  return { type: SALON_SETTINGS_GET_SUCCESS, payload, meta };
}
export function salonSettingsGetFail (payload = {}, meta) {
  return { type: SALON_SETTINGS_GET_FAIL, payload, meta, error: true };
}

export function salonCancellationPolicyUpdate (payload = {}) {
  return { type: SALON_CANCELLATION_POLICY_UPDATE, payload, meta: { thunk: true } };
}
export function salonCancellationPolicyUpdateSuccess (payload = {}, meta) {
  return { type: SALON_CANCELLATION_POLICY_UPDATE_SUCCESS, payload, meta };
}
export function salonCancellationPolicyUpdateFail (payload = {}, meta) {
  return { type: SALON_CANCELLATION_POLICY_UPDATE_FAIL, payload, meta, error: true };
}

export function salonGetAllSurvey (payload = {}) {
  return { type: SALON_GET_ALL_SURVEY, payload, meta: { thunk: true } };
}
export function salonGetAllSurveySuccess (payload = {}, meta) {
  return { type: SALON_GET_ALL_SURVEY_SUCCESS, payload, meta };
}
export function salonGetAllSurveyFail (payload = {}, meta) {
  return { type: SALON_GET_ALL_SURVEY_FAIL, payload, meta, error: true };
}

export function salonSurveyDetail (payload = {}) {
  return { type: SALON_SURVEY_DETAIL, payload, meta: { thunk: true } };
}
export function salonSurveyDetailSuccess (payload = {}, meta) {
  return { type: SALON_SURVEY_DETAIL_SUCCESS, payload, meta };
}
export function salonSurveyDetailFail (payload = {}, meta) {
  return { type: SALON_SURVEY_DETAIL_FAIL, payload, meta, error: true };
}

export function salonUpdateSurvey (payload = {}) {
  return { type: SALON_UPDATE_SURVEY, payload, meta: { thunk: true } };
}
export function salonUpdateSurveySuccess (payload = {}, meta) {
  return { type: SALON_UPDATE_SURVEY_SUCCESS, payload, meta };
}
export function salonUpdateSurveyFail (payload = {}, meta) {
  return { type: SALON_UPDATE_SURVEY_FAIL, payload, meta, error: true };
}

export function salonSurveyResult (payload = {}) {
  return { type: SALON_SURVEY_RESULT, payload, meta: { thunk: true } };
}
export function salonSurveyResultSuccess (payload = {}, meta) {
  return { type: SALON_SURVEY_RESULT_SUCCESS, payload, meta };
}
export function salonSurveyResultFail (payload = {}, meta) {
  return { type: SALON_SURVEY_RESULT_FAIL, payload, meta, error: true };
}

// GET LIST SALON SALE REPORT
export function getListSalonSaleReport (payload = {}) {
  return { type: GET_LIST_SALON_SALE_REPORT, payload, meta: { thunk: true } };
}
export function getListSalonSaleReportSuccess (payload = {}, meta) {
  return { type: GET_LIST_SALON_SALE_REPORT_SUCCESS, payload, meta };
}
export function getListSalonSaleReportFail (payload = {}, meta) {
  return { type: GET_LIST_SALON_SALE_REPORT_FAIL, payload, meta, error: true };
}

// GET LIST STAFF SALE REPORT BY SALON
export function getListStaffSaleReportBySalon (payload = {}) {
  return { type: GET_LIST_STAFF_SALE_REPORT_BY_SALON, payload, meta: { thunk: true } };
}
export function getListStaffSaleReportBySalonSuccess (payload = {}, meta) {
  return { type: GET_LIST_STAFF_SALE_REPORT_BY_SALON_SUCCESS, payload, meta };
}
export function getListStaffSaleReportBySalonFail (payload = {}, meta) {
  return { type: GET_LIST_STAFF_SALE_REPORT_BY_SALON_FAIL, payload, meta, error: true };
}

// GET LIST BOOKING SALON SALE REPORT
export function getListBookingSalonSaleReport (payload = {}) {
  return { type: GET_LIST_BOOKING_SALON_SALE_REPORT, payload, meta: { thunk: true } };
}
export function getListBookingSalonSaleReportSuccess (payload = {}, meta) {
  return { type: GET_LIST_BOOKING_SALON_SALE_REPORT_SUCCESS, payload, meta };
}
export function getListBookingSalonSaleReportFail (payload = {}, meta) {
  return { type: GET_LIST_BOOKING_SALON_SALE_REPORT_FAIL, payload, meta, error: true };
}

export function salonGetAllAreas (payload = {}) {
  return { type: SALON_GET_ALL_AREAS, payload, meta: { thunk: true } };
}
export function salonGetAllAreasSuccess (payload = {}, meta) {
  return { type: SALON_GET_ALL_AREAS_SUCCESS, payload, meta };
}
export function salonGetAllAreasFail (payload = {}, meta) {
  return { type: SALON_GET_ALL_AREAS_FAIL, payload, meta, error: true };
}

export function toggle3rdParty (payload = {}) {
  return { type: TOGGLE_THIRD_PARTY, payload, meta: { thunk: true } };
}
export function toggle3rdPartySuccess (payload = {}, meta) {
  return { type: TOGGLE_THIRD_PARTY_SUCCESS, payload, meta };
}
export function toggle3rdPartyFail (payload = {}, meta) {
  return { type: TOGGLE_THIRD_PARTY_FAIL, payload, meta, error: true };
}

export function changeSalonEmail (payload = {}) {
  return { type: CHANGE_SALON_EMAIL, payload, meta: { thunk: true } };
}
export function changeSalonEmailSuccess (payload = {}, meta) {
  return { type: CHANGE_SALON_EMAIL_SUCCESS, payload, meta };
}
export function changeSalonEmailFail (payload = {}, meta) {
  return { type: CHANGE_SALON_EMAIL_FAIL, payload, meta, error: true };
}
