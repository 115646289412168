import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import environment from 'environment';
// import jwtDecode from 'jwt-decode'; // For debugging token only

/**
 * Attempt to silently get the `access_token` and return.
 *
 * If there is a valid token in the storage, return it.
 * Otherwise, request to Auth0 to get a new token using the current `refresh_token`.
 *
 * In case the `refresh_token` is invalid, redirect user to Auth0 login screen and
 * redirect back to current location after login successfully.
 *
 * ```js
 * // Usage
 * const token = useToken();
 * doSomethingWithToken(token);
 * ```
 *
 * @returns {string}
 */
const useToken = () => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [token, setToken] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  useEffect(() => {
    if (isLoading && !isAuthenticated) return;

    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently({ audience: environment.api.auth0Audience });
        setToken(token);

        // For debug purpose
        // const user = jwtDecode(token);
        // const exp = new Date(user.exp * 1000);
        // console.log(
        //   `Token: ${token.slice(-7)}\nEXP: ${exp.toLocaleDateString()} ${exp.toLocaleTimeString()}`,
        // );
      } catch (e) {
        /*
         * Handle force login again after revoke token, such as after change password.
         * If use `getAccessTokenWithPopup`, user can close the popup, leading to confusing behavior.
         * Therefore, opt to use `loginWithRedirect` instead.
         */
        if (e.error === 'invalid_grant') {
          alert(lang.sessionExpired);
          await retryWithLogin();
        } else {
          console.error(e);
        }
      }
    };

    const retryWithLogin = async () => {
      const opts = {
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      };
      await loginWithRedirect(opts);
      getToken();
    };

    getToken();
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  return token;
};

export { useToken };
