import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import moment from 'moment';
import _ from 'lodash';
import { Button, Form, Checkbox, Col, Row, Select, Space, Spin, Modal, Divider, Progress, message } from 'antd';
import { LogoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useAuth0 } from '@auth0/auth0-react';
import { salonScheduleUpdate } from 'providers/SalonProvider/actions';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import environment from 'environment';

import './styles.less';
import { slots, timezone } from 'utils/constants';
import { staffScheduleList, staffScheduleUpdate } from 'providers/StaffProvider/actions';
import { calculatePercentProgress } from 'utils/calculatePercentProgress';
import { keepLocalStorageBeforeLogout, _sleep } from 'utils/common';
const { Option } = Select;

const formatToISOString = (hours, minutes) => {
  const tomorrow = moment().add(1, 'days');
  return moment(`${tomorrow.format('YYYY/MM/DD')} ${hours}:${minutes}`, 'YYYY/MM/DD HH:mm').toISOString();
};

const SalonScheduleUpdateForm = ({ salon, onCallback, isCompleteProfile = false, onCancel }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const [user, setUser] = useState();

  const [isMonday, setIsMonday] = useState(true);
  const [isTuesday, setIsTuesday] = useState(true);
  const [isWednesday, setIsWednesday] = useState(true);
  const [isThursday, setIsThursday] = useState(true);
  const [isFriday, setIsFriday] = useState(true);
  const [isSaturday, setIsSaturday] = useState(true);
  const [isSunday, setIsSunday] = useState(true);
  // Get all staff for apply salon schedule
  const from = moment().clone().startOf('day').toISOString();
  const to = moment().clone().endOf('day').toISOString();
  const [appliedNailist, setAppliedNailist] = useState([0, 0]); // [Applied, total]
  const progressPercent = calculatePercentProgress(appliedNailist[0], appliedNailist[1]);
  const isSalonNotConnected3rdParty = salon?.thirdParties?.length === 0;

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      window.onbeforeunload = () => '';
    } else {
      window.onbeforeunload = null;
    }
  }, [loading]);

  const handleLogout = () => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.confirmLogout,
      okText: lang.sure,
      cancelText: lang.no,
      onOk: () => {
        keepLocalStorageBeforeLogout();
        logout({ returnTo: window.location.origin });
      },
    });
  };

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const token = await getAccessTokenSilently({ audience: environment.api.auth0Audience });
        const user = jwtDecode(token);
        setUser(user);
      } catch (e) {
        console.log(e);
      }
    };
    getUserMetadata();

    if (!isCompleteProfile) {
      // Monday
      const monday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 1);
      const mondayStartAt = parseInt(moment(_.get(monday, '[0]')?.startAt).format('HHmm'));
      const mondayEndAt = parseInt(moment(_.get(monday, '[0]')?.endAt).format('HHmm'));

      // Tuesday
      const tuesday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 2);
      const tuesdayStartAt = parseInt(moment(_.get(tuesday, '[0]')?.startAt).format('HHmm'));
      const tuesdayEndAt = parseInt(moment(_.get(tuesday, '[0]')?.endAt).format('HHmm'));

      // Wednesday
      const wednesday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 3);
      const wednesdayStartAt = parseInt(moment(_.get(wednesday, '[0]')?.startAt).format('HHmm'));
      const wednesdayEndAt = parseInt(moment(_.get(wednesday, '[0]')?.endAt).format('HHmm'));

      // Thursday
      const thursday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 4);
      const thursdayStartAt = parseInt(moment(_.get(thursday, '[0]')?.startAt).format('HHmm'));
      const thursdayEndAt = parseInt(moment(_.get(thursday, '[0]')?.endAt).format('HHmm'));

      // Friday
      const friday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 5);
      const fridayStartAt = parseInt(moment(_.get(friday, '[0]')?.startAt).format('HHmm'));
      const fridayEndAt = parseInt(moment(_.get(friday, '[0]')?.endAt).format('HHmm'));

      // Saturday
      const saturday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 6);
      const saturdayStartAt = parseInt(moment(_.get(saturday, '[0]')?.startAt).format('HHmm'));
      const saturdayEndAt = parseInt(moment(_.get(saturday, '[0]')?.endAt).format('HHmm'));

      // Sunday
      const sunday = _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 0);
      const sundayStartAt = parseInt(moment(_.get(sunday, '[0]')?.startAt).format('HHmm'));
      const sundayEndAt = parseInt(moment(_.get(sunday, '[0]')?.endAt).format('HHmm'));

      if (monday.length !== 0 && mondayStartAt !== mondayEndAt) {
        setIsMonday(true);
      } else {
        setIsMonday(false);
      }

      if (tuesday.length !== 0 && tuesdayStartAt !== tuesdayEndAt) {
        setIsTuesday(true);
      } else {
        setIsTuesday(false);
      }

      if (wednesday.length !== 0 && wednesdayStartAt !== wednesdayEndAt) {
        setIsWednesday(true);
      } else {
        setIsWednesday(false);
      }

      if (thursday.length !== 0 && thursdayStartAt !== thursdayEndAt) {
        setIsThursday(true);
      } else {
        setIsThursday(false);
      }

      if (friday.length !== 0 && fridayStartAt !== fridayEndAt) {
        setIsFriday(true);
      } else {
        setIsFriday(false);
      }

      if (saturday.length !== 0 && saturdayStartAt !== saturdayEndAt) {
        setIsSaturday(true);
      } else {
        setIsSaturday(false);
      }

      if (sunday.length !== 0 && sundayStartAt !== sundayEndAt) {
        setIsSunday(true);
      } else {
        setIsSunday(false);
      }
    }

    setLoading(false);
  }, []);

  const initialValues = {
    mondayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 1)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 1)[0].startAt).format('HHmm')),
    mondayEndAt: _.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 1)[0]?.endAt === undefined ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 1)[0].endAt).format('HHmm')),

    tuesdayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 2)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 2)[0].startAt).format('HHmm')),
    tuesdayEndAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 2)[0]?.endAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 2)[0].endAt).format('HHmm')),

    wednesdayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 3)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 3)[0].startAt).format('HHmm')),
    wednesdayEndAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 3)[0]?.endAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 3)[0].endAt).format('HHmm')),

    thursdayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 4)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 4)[0].startAt).format('HHmm')),
    thursdayEndAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 4)[0]?.endAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 4)[0].endAt).format('HHmm')),

    fridayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 5)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 5)[0].startAt).format('HHmm')),
    fridayEndAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 5)[0]?.endAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 5)[0].endAt).format('HHmm')),

    saturdayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 6)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 6)[0].startAt).format('HHmm')),
    saturdayEndAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 6)[0]?.endAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 6)[0].endAt).format('HHmm')),

    sundayStartAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 0)[0]?.startAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 0)[0].startAt).format('HHmm')),
    sundayEndAt: !_.get(salon, 'schedules', []).filter(item => item.dayOfWeek === 0)[0]?.endAt ? 0 : parseInt(moment(salon.schedules.filter(item => item.dayOfWeek === 0)[0].endAt).format('HHmm')),
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const schedules = [];
    const schedulesApplyForStaff = [];
    const currentDate = moment();

    const { mondayStartAt, mondayEndAt, tuesdayStartAt, tuesdayEndAt, wednesdayStartAt, wednesdayEndAt, thursdayStartAt, thursdayEndAt, fridayStartAt, fridayEndAt, saturdayStartAt, saturdayEndAt, sundayStartAt, sundayEndAt } = values;

    // Monday
    if (isMonday === true) {
      const item = {
        dayOfWeek: 1,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(mondayStartAt / 100)}:${mondayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(mondayEndAt / 100)}:${mondayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 1,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }

    // Tuesday
    if (isTuesday === true) {
      const item = {
        dayOfWeek: 2,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(tuesdayStartAt / 100)}:${tuesdayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(tuesdayEndAt / 100)}:${tuesdayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 2,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }

    // Wednesday
    if (isWednesday === true) {
      const item = {
        dayOfWeek: 3,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(wednesdayStartAt / 100)}:${wednesdayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(wednesdayEndAt / 100)}:${wednesdayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 3,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }

    // Thursday
    if (isThursday === true) {
      const item = {
        dayOfWeek: 4,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(thursdayStartAt / 100)}:${thursdayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(thursdayEndAt / 100)}:${thursdayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 4,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }

    // Friday
    if (isFriday === true) {
      const item = {
        dayOfWeek: 5,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(fridayStartAt / 100)}:${fridayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(fridayEndAt / 100)}:${fridayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 5,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }

    // Saturday
    if (isSaturday === true) {
      const item = {
        dayOfWeek: 6,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(saturdayStartAt / 100)}:${saturdayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(saturdayEndAt / 100)}:${saturdayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 6,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }

    // Sunday
    if (isSunday === true) {
      const item = {
        dayOfWeek: 0,
        startAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(sundayStartAt / 100)}:${sundayStartAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
        endAt: moment(`${currentDate.format('YYYY/MM/DD')} ${Math.floor(sundayEndAt / 100)}:${sundayEndAt % 100}`, 'YYYY/MM/DD HH:mm').toISOString(),
      };
      schedules.push(item);
      schedulesApplyForStaff.push({ ...item, isOpen: true });
    } else {
      schedulesApplyForStaff.push({
        dayOfWeek: 0,
        isOpen: false,
        startAt: formatToISOString(0, 0),
        endAt: formatToISOString(0, 0),
      });
    }
    // Auto apply salon schedule for all staff from the next day
    const applySalonScheduleForAllStaff = async (schedules) => {
      setLoading(true);

      // get all staff
      let staffs = await dispatch(staffScheduleList({ currentPage: 1, pageSize: 1000, sortedInfo: 'createdAt desc', from, to }));
      staffs = staffs.data.data;
      setAppliedNailist([0, staffs.length]);

      // Call the group update api staff schedule
      const groupOfStaffs = _.chunk(staffs, 4);
      let handledStaffs = 0;
      try {
        for (const group of groupOfStaffs) {
          await Promise.all(group.map(staff => {
            const staffId = staff.id;
            return dispatch(staffScheduleUpdate({
              timezone,
              schedules,
              staffId,
            }));
          }));
          await _sleep(1200);

          handledStaffs = handledStaffs + group.length;
          setAppliedNailist([handledStaffs, staffs.length]);
        }
      } catch (error) {
        // Show error message when salon can not update schedule
        message.error(error.message);
        setLoading(false);
      }
    };
    // Don't update staff Business Hour (BH - Schedule Setting) for each staff if the salon connecting to a 3rd party. Update 9/6/2022
    if (isSalonNotConnected3rdParty) {
      await applySalonScheduleForAllStaff(schedulesApplyForStaff);
    }

    await dispatch(salonScheduleUpdate({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      schedules,
      // eslint-disable-next-line camelcase
      salonId: user['https://user_metadata']?.salon_id,
    })).then((result) => {
      onCallback();
    }).catch((error) => {
      console.log('Oops!', error);
    });

    setLoading(false);
  };

  const OneDay = ({
    dayString = 'monday',
    setIsDay = setIsMonday,
    isDay = isMonday,
    language = lang.monday,
  }) => {
    if (form.getFieldsValue()[`${dayString}StartAt`] !== undefined && form.getFieldsValue()[`${dayString}EndAt`] !== undefined && form.getFieldsValue()[`${dayString}StartAt`] === form.getFieldsValue()[`${dayString}EndAt`]) {
      form.setFields([
        {
          name: `${dayString}StartAt`,
          value: 1000,
        },
        {
          name: `${dayString}EndAt`,
          value: 2000,
        },
      ]);
    }

    return (
      <>
        <Row
          gutter={24}
          style={{
            backgroundColor: 'rgba(128, 128, 128, 0.08)',
            paddingTop: 10,
            justifyContent: 'center',
            maxWidth: 580,
            margin: 'auto',
          }}
        >
          <Col span={7}>
            <Checkbox
              checked={isDay}
              onChange={(e) => {
                if (e.target.checked === true) {
                  setIsDay(true);
                } else {
                  setIsDay(false);
                }
              }}
              className={`day-of-week ${(dayString === 'sunday' && 'sun') || (dayString === 'saturday' && 'sat')}`}
            >
              {language}
            </Checkbox>
          </Col>
          <Col span={1} style={{ marginRight: -24 }}>
            <div style={{ width: 2, backgroundColor: 'white', height: '124%', marginTop: -10 }}></div>
          </Col>
          <Col span={14}>
            <Space style={{ display: 'flex', marginBottom: -15 }} align="start">
              <Form.Item
                name={`${dayString}StartAt`}
                rules={isDay === true ? [
                  {
                    required: true,
                    message: lang.emptyStartTimeError,
                  },
                ] : []}
              >
                <Select
                  disabled={isDay === false}
                  style={{ width: 120, marginLeft: 24 }}
                  onChange={
                    (value) => {
                      if (isDay === true && value >= form.getFieldsValue()[`${dayString}EndAt`]) {
                        form.setFields([
                          {
                            name: `${dayString}EndAt`,
                            value: value % 100 === 0 ? value + 30 : value + 70,
                          },
                        ]);
                      }
                    }
                  }
                >
                  {slots.filter(x => x.value !== 2330).map((item) => {
                    return (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={`${dayString}EndAt`}
                rules={isDay === true ? [
                  {
                    required: true,
                    message: lang.emptyEndTimeError,
                  },
                ] : []}
              >
                <Select
                  disabled={isDay === false }
                  style={{ width: 120, marginLeft: 24 }}
                  onChange={
                    (value) => {
                      if (isDay === true && value <= form.getFieldsValue()[`${dayString}StartAt`]) {
                        form.setFields([
                          {
                            name: `${dayString}StartAt`,
                            value: value % 100 === 0 ? value - 70 : value - 30,
                          },
                        ]);
                      }
                    }
                  }
                >
                  {slots.filter(x => x.value !== 0).map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>{item.label}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
          </Col>
        </Row>

        <Row gutter={24} style={{ backgroundColor: 'rgba(128, 128, 128, 0.08)' }}>
          <Col span={24}>
            <div style={{ width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24 }}></div>
          </Col>
        </Row>

      </>
    );
  };

  return (
    <Form
      className="salon-form"
      style={{ marginLeft: 15, marginRight: 15 }}
      form={form}
      name="salonScheduleUpdateForm"
      onFinish={values => onSubmit(values)}
      initialValues={initialValues}
    >
      <Spin spinning={loading}>
        <OneDay
          dayString="monday"
          setIsDay={setIsMonday}
          isDay={isMonday}
          language={lang.monday}
        />
        <OneDay
          dayString="tuesday"
          setIsDay={setIsTuesday}
          isDay={isTuesday}
          language={lang.tuesday}
        />
        <OneDay
          dayString="wednesday"
          setIsDay={setIsWednesday}
          isDay={isWednesday}
          language={lang.wednesday}
        />
        <OneDay
          dayString="thursday"
          setIsDay={setIsThursday}
          isDay={isThursday}
          language={lang.thursday}
        />
        <OneDay
          dayString="friday"
          setIsDay={setIsFriday}
          isDay={isFriday}
          language={lang.friday}
        />
        <OneDay
          dayString="saturday"
          setIsDay={setIsSaturday}
          isDay={isSaturday}
          language={lang.saturday}
        />
        <OneDay
          dayString="sunday"
          setIsDay={setIsSunday}
          isDay={isSunday}
          language={lang.sunday}
        />
      </Spin>
      {isCompleteProfile === false ? (
        <>
          {
            // Show progress bar for apply salon schedule for all nailist
            (loading && isSalonNotConnected3rdParty) &&
            <div style={{ marginTop: 16, textAlign: 'center' }}>
              <Progress percent={progressPercent} style={{ width: 580 }}/>
              <div> {lang.appliedNailistScheduleHead} {appliedNailist[0]}/{appliedNailist[1]} {lang.appliedNailistScheduleTail}</div>
            </div>
          }
          <Divider />
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div style={{ marginRight: 16 }}>{lang.staffWorkingTimeFooter}</div>
                <Space>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {lang.save}
                  </Button>
                  <Button onClick={onCancel} disabled={loading}>
                    {lang.cancel}
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              danger
              icon={<LogoutOutlined />}
              onClick={handleLogout}>
              {lang.logout}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {lang.continue}
            </Button>
          </div>
        </>)
      }

    </Form>
  );
};

SalonScheduleUpdateForm.propTypes = {
  salon: PropTypes.object,
  onCallback: PropTypes.func,
  isCompleteProfile: PropTypes.bool,
};

export default SalonScheduleUpdateForm;
