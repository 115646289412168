import environment from 'environment';

// ============================[ data process ]=================================
// common shared salon helper and config
export const SALON_TYPES = {
  REG_A: { value: 'REG_A', text: '通常-A型', shortenedText: '通常A' },
  REG_B: { value: 'REG_B', text: '通常-B型', shortenedText: '通常B' },
  SHARED: { value: 'SHARED', text: 'シェア型', shortenedText: 'シェア' },
  FIVE_STAR: { value: 'FIVE_STAR', text: 'Five Star Studio', shortenedText: 'FSS' },
};
export const SALON_MANAGER_TYPES = ['REG_A', 'REG_B', 'SHARED'];
export const FIVE_STAR_TYPES = ['FIVE_STAR', 'FIVE_STAR_STUDIO'];
export const SALON_HIDDEN_TYPES = ['SHARED', 'FIVE_STAR'];

const getMatchBuildEnvBySalonType = (salonSharedType) => {
  if (FIVE_STAR_TYPES.includes(salonSharedType)) {
    return SHARED_TYPE_BUILD_ENV.FSS;
  }

  if (SALON_MANAGER_TYPES.includes(salonSharedType)) {
    return SHARED_TYPE_BUILD_ENV.SM;
  }

  return SHARED_TYPE_BUILD_ENV.SM;
};

// ===================================[ ENV ]===================================
// Build + Deployment environment config
export const SHARED_TYPE_BUILD_ENV = {
  FSS: 'FSS',
  SM: 'SM',
};

// export const ENV_SM_TYPES = [undefined, null, '', 'develop', 'beta', 'production', 'local_dev'];
// export const ENV_FSS_TYPES = ['beta_fss', 'production_fss'];

// BUILD_ENV is [SM or FSS, default is SM]
const getBuildEnv = () => {
  return environment.build_env;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const checkAndRedirectSalonType = async (salonSharedType) => { // [salonSharedType: undefined == REG_A, REG_B, SHARED, FIVE_STAR]
  const thisBuildEnv = getBuildEnv(); // FSS or SM
  const salonTypeMatchEnv = getMatchBuildEnvBySalonType(salonSharedType); // FSS or SM

  const isLoginWrongLocation = thisBuildEnv !== salonTypeMatchEnv;

  if (isLoginWrongLocation) {
    if (salonTypeMatchEnv === SHARED_TYPE_BUILD_ENV.FSS) {
      deleteAllCookies();
      window.logout({});
      window.alert('This is not FSS board, your account is FSS redirect to FSS ');
      window.location.href = environment.redirect_fss_location; // FSS
    }

    if (salonTypeMatchEnv === SHARED_TYPE_BUILD_ENV.SM) {
      deleteAllCookies();
      window.logout({});
      window.alert('This is not SM board, your account is SM redirect to SM');
      window.location.href = environment.redirect_sm_location; // SM
    }
  }
};

const SharedSalonEnv = {
  isFSS: () => getBuildEnv() === SHARED_TYPE_BUILD_ENV.FSS,
  isSM: () => getBuildEnv() === SHARED_TYPE_BUILD_ENV.SM,
  getBuildEnv: getBuildEnv,
  checkAndRedirectSalonType,
};

export default SharedSalonEnv;
