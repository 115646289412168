import {
  CATEGORY_DETAIL,
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_CREATE,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_ORDER,
  CATEGORY_ORDER_SUCCESS,
  CATEGORY_ORDER_FAIL,
  CATEGORY_DELETE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
} from './types';

const INITIAL_STATE = {
  categories: [],
  categoriesWithTrashed: [],
  category: {},
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATEGORY_LIST:
      return {
        ...state,
        loading: true,
      };

    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoriesWithTrashed: action.payload.categoriesWithTrashed,
        categories: action.payload.categories,
        loading: false,
      };

    case CATEGORY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CATEGORY_DETAIL:
      return {
        ...state,
        category: action.payload,
      };

    case CATEGORY_CREATE:
      return {
        ...state,
      };

    case CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
      };

    case CATEGORY_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CATEGORY_UPDATE:
      return {
        ...state,
      };

    case CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
      };

    case CATEGORY_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CATEGORY_ORDER:
      return {
        ...state,
      };

    case CATEGORY_ORDER_SUCCESS:
      return {
        ...state,
      };

    case CATEGORY_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CATEGORY_DELETE:
      return {
        ...state,
        loading: true,
      };

    case CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CATEGORY_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
