import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Typography } from 'antd';
import { SALES_CARD_BORDER_COLOR } from 'utils/constants';
import { formatYen } from 'utils/stringFormat';
import { getLanguages } from 'utils/lang';

const { Title, Text } = Typography;

const SalesCard = ({ langKey, amounts }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <Card
      style={{
        borderRadius: 8,
        borderTop: `8px solid ${SALES_CARD_BORDER_COLOR[langKey]}`,
      }}
    >
      <Text strong style={{ margin: 0 }}>
        {lang[langKey]}
      </Text>
      <Title level={4} style={{ margin: 0 }}>
        {formatYen(amounts)}
      </Title>
    </Card>
  );
};

SalesCard.propTypes = {
  langKey: PropTypes.string,
  amounts: PropTypes.number,
};

export default SalesCard;
