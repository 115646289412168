// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import moment from 'moment';

// Utils
import { getLanguages } from 'utils/lang';

const OutsideBookingsModal = ({
  outsideBookingsModal,
  closeOutsideBookingsModal,
  setBookingDetailModal,
}) => {
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Modal
      visible={!!outsideBookingsModal}
      title={`${lang.outsideBookingsModalTitleHead}${outsideBookingsModal?.staffName || lang.salon}${lang.outsideBookingsModalTitleTail}`}
      onOk={closeOutsideBookingsModal}
      onCancel={closeOutsideBookingsModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose={true}
      width={600}
      centered
      maskClosable={false}
    >
      <div style={{ whiteSpace: 'pre' }}>
        {lang.outsideBookingsModalContent}
      </div>
      <div className="outside-booking-list">
        {outsideBookingsModal &&
          outsideBookingsModal.data.map((item) => (
            <div key={item.id} className="outside-booking-item">
              <div
                className="outside-booking-item-id"
                onClick={() => setBookingDetailModal(item.id)}
              >
                {item.id}
              </div>
              <div>{moment(item.startTime).format('HH:mm YYYY-MM-DD')}</div>
            </div>
          ))}
      </div>
    </Modal>
  );
};

OutsideBookingsModal.propTypes = {
  outsideBookingsModal: PropTypes.object,
  closeOutsideBookingsModal: PropTypes.func,
  setBookingDetailModal: PropTypes.func,
};

export default OutsideBookingsModal;
