import {
  FETCH_STAFF_LIST,
  FETCH_STAFF_LIST_SUCCESS,
  FETCH_STAFF_LIST_FAIL,
  UPDATE_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE_SUCCESS,
  UPDATE_STAFF_PROFILE_FAIL,
  ADD_STAFF,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  GET_INVITATION_SUGGESTION_LIST,
  GET_INVITATION_SUGGESTION_LIST_SUCCESS,
  GET_INVITATION_SUGGESTION_LIST_FAIL,
  CHECK_STAFF_INVITATION,
  CHECK_STAFF_INVITATION_FAIL,
  CHECK_STAFF_INVITATION_SUCCESS,
  IMPORT_STAFF,
  IMPORT_STAFF_FAIL,
  IMPORT_STAFF_SUCCESS,
  STAFF_DETAIL_CATEGORIES_SERVICES,
  STAFF_DETAIL_CATEGORIES_SERVICES_FAIL,
  STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS,
  STAFF_DETAIL_ADD_SERVICES,
  STAFF_DETAIL_ADD_SERVICES_FAIL,
  STAFF_DETAIL_ADD_SERVICES_SUCCESS,
  STAFF_SEND_INVITE,
  STAFF_SEND_INVITE_FAIL,
  STAFF_SEND_INVITE_SUCCESS,
  STAFF_CANCEL_INVITE,
  STAFF_CANCEL_INVITE_FAIL,
  STAFF_CANCEL_INVITE_SUCCESS,
  STAFF_DETAIL,
  STAFF_DETAIL_SUCCESS,
  STAFF_DETAIL_FAIL,
  STAFF_SCHEDULE_LIST,
  STAFF_SCHEDULE_LIST_SUCCESS,
  STAFF_SCHEDULE_LIST_FAIL,
  STAFF_SCHEDULE_UPDATE,
  STAFF_SCHEDULE_UPDATE_SUCCESS,
  STAFF_SCHEDULE_UPDATE_FAIL,
  STAFF_SCHEDULE_DAILY_UPDATE,
  STAFF_SCHEDULE_DAILY_UPDATE_SUCCESS,
  STAFF_SCHEDULE_DAILY_UPDATE_FAIL,
  GET_CURRENT_SCHEDULE_BY_STAFF,
  GET_CURRENT_SCHEDULE_BY_STAFF_SUCCESS,
  GET_CURRENT_SCHEDULE_BY_STAFF_FAIL,
  GET_SCHEDULE_BY_STAFF_ON_A_DATE,
  GET_SCHEDULE_BY_STAFF_ON_A_DATE_SUCCESS,
  GET_SCHEDULE_BY_STAFF_ON_A_DATE_FAIL,
  GET_LIST_STAFF_SALE_REPORT,
  GET_LIST_STAFF_SALE_REPORT_SUCCESS,
  GET_LIST_STAFF_SALE_REPORT_FAIL,
  GET_LIST_BOOKING_STAFF_SALE_REPORT,
  GET_LIST_BOOKING_STAFF_SALE_REPORT_SUCCESS,
  GET_LIST_BOOKING_STAFF_SALE_REPORT_FAIL,
  REMOVE_STAFF,
  REMOVE_STAFF_SUCCESS,
  REMOVE_STAFF_FAIL,
  GET_STAFF_SCHEDULE_DAILY,
  GET_STAFF_SCHEDULE_DAILY_SUCCESS,
  GET_STAFF_SCHEDULE_DAILY_FAIL,
  UPDATE_STAFF_ORDER,
  UPDATE_STAFF_ORDER_SUCCESS,
  UPDATE_STAFF_ORDER_FAIL,
  GET_THIRD_PARTY_SOURCE_STAFF_LIST,
  GET_THIRD_PARTY_SOURCE_STAFF_LIST_SUCCESS,
  GET_THIRD_PARTY_SOURCE_STAFF_LIST_FAIL,
  GET_THIRD_PARTY_SOURCE_ID,
  GET_THIRD_PARTY_SOURCE_ID_SUCCESS,
  GET_THIRD_PARTY_SOURCE_ID_FAIL,
  UPDATE_THIRD_PARTY_SOURCE_STAFF,
  UPDATE_THIRD_PARTY_SOURCE_STAFF_SUCCESS,
  UPDATE_THIRD_PARTY_SOURCE_STAFF_FAIL,
  TOGGLE_THIRD_PARTY_STAFF_CONNECTION,
  TOGGLE_THIRD_PARTY_STAFF_CONNECTION_SUCCESS,
  TOGGLE_THIRD_PARTY_STAFF_CONNECTION_FAIL,
} from './types';

// FETCH STAFF LIST
export function fetchStaffList (payload = {}) {
  return { type: FETCH_STAFF_LIST, payload, meta: { thunk: true } };
}

export function fetchStaffListSuccess (payload = {}, meta) {
  return { type: FETCH_STAFF_LIST_SUCCESS, payload, meta };
}

export function fetchStaffListFail (payload = {}, meta) {
  return { type: FETCH_STAFF_LIST_FAIL, payload, meta, error: true };
}

// ADD STAFF
export function addStaff (payload = {}) {
  return { type: ADD_STAFF, payload, meta: { thunk: true } };
}

export function addStaffSuccess (payload = {}, meta) {
  return { type: ADD_STAFF_SUCCESS, payload, meta };
}

export function addStaffFail (payload = {}, meta) {
  return { type: ADD_STAFF_FAIL, payload, meta, error: true };
}

// GET_INVITATION_SUGGESTION
export function getInvitationSuggestionList (payload = {}) {
  return { type: GET_INVITATION_SUGGESTION_LIST, payload, meta: { thunk: true } };
}

export function getInvitationSuggestionListSuccess (payload = {}, meta) {
  return { type: GET_INVITATION_SUGGESTION_LIST_SUCCESS, payload, meta };
}

export function getInvitationSuggestionListFail (payload = {}, meta) {
  return { type: GET_INVITATION_SUGGESTION_LIST_FAIL, payload, meta, error: true };
}

// CHECK STAFF INVITATION
export function checkStaffInvitation (payload = {}) {
  return { type: CHECK_STAFF_INVITATION, payload, meta: { thunk: true } };
}

export function checkStaffInvitationSuccess (payload = {}, meta) {
  return { type: CHECK_STAFF_INVITATION_SUCCESS, payload, meta };
}

export function checkStaffInvitationFail (payload = {}, meta) {
  return { type: CHECK_STAFF_INVITATION_FAIL, payload, meta, error: true };
}

// IMPORT STAFF
export function importStaff (payload = {}) {
  return { type: IMPORT_STAFF, payload, meta: { thunk: true } };
}

export function importStaffSuccess (payload = {}, meta) {
  return { type: IMPORT_STAFF_SUCCESS, payload, meta };
}

export function importStaffFail (payload = {}, meta) {
  return { type: IMPORT_STAFF_FAIL, payload, meta, error: true };
}

// UPDATE STAFF_PROFILE
export function updateStaffProfile (payload = {}) {
  return { type: UPDATE_STAFF_PROFILE, payload, meta: { thunk: true } };
}

export function updateStaffProfileSuccess (payload = {}, meta) {
  return { type: UPDATE_STAFF_PROFILE_SUCCESS, payload, meta };
}

export function updateStaffProfileFail (payload = {}, meta) {
  return { type: UPDATE_STAFF_PROFILE_FAIL, payload, meta, error: true };
}

// STAFF_DETAIL
export function staffDetailCategoriesServices (payload = {}) {
  return { type: STAFF_DETAIL_CATEGORIES_SERVICES, payload, meta: { thunk: true } };
}
export function staffDetailCategoriesServicesSuccess (payload = {}, meta) {
  return { type: STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS, payload, meta };
}
export function staffDetailCategoriesServicesFail (payload = {}, meta) {
  return { type: STAFF_DETAIL_CATEGORIES_SERVICES_FAIL, payload, meta, error: true };
}

export function staffDetailAddServices (payload = {}) {
  return { type: STAFF_DETAIL_ADD_SERVICES, payload, meta: { thunk: true } };
}
export function staffDetailAddServicesSuccess (payload = {}, meta) {
  return { type: STAFF_DETAIL_ADD_SERVICES_SUCCESS, payload, meta };
}
export function staffDetailAddServicesFail (payload = {}, meta) {
  return { type: STAFF_DETAIL_ADD_SERVICES_FAIL, payload, meta, error: true };
}

export function staffSendInvite (payload = {}) {
  return { type: STAFF_SEND_INVITE, payload, meta: { thunk: true } };
}
export function staffSendInviteSuccess (payload = {}, meta) {
  return { type: STAFF_SEND_INVITE_SUCCESS, payload, meta };
}
export function staffSendInviteFail (payload = {}, meta) {
  return { type: STAFF_SEND_INVITE_FAIL, payload, meta, error: true };
}

export function staffCancelInvite (payload = {}) {
  return { type: STAFF_CANCEL_INVITE, payload, meta: { thunk: true } };
}
export function staffCancelInviteSuccess (payload = {}, meta) {
  return { type: STAFF_CANCEL_INVITE_SUCCESS, payload, meta };
}
export function staffCancelInviteFail (payload = {}, meta) {
  return { type: STAFF_CANCEL_INVITE_FAIL, payload, meta, error: true };
}

export function staffDetail (payload = {}) {
  return { type: STAFF_DETAIL, payload, meta: { thunk: true } };
}
export function staffDetailSuccess (payload = {}, meta) {
  return { type: STAFF_DETAIL_SUCCESS, payload, meta };
}
export function staffDetailFail (payload = {}, meta) {
  return { type: STAFF_DETAIL_FAIL, payload, meta, error: true };
}

export function staffScheduleList (payload = {}) {
  return { type: STAFF_SCHEDULE_LIST, payload, meta: { thunk: true } };
}
export function staffScheduleListSuccess (payload = {}, meta) {
  return { type: STAFF_SCHEDULE_LIST_SUCCESS, payload, meta };
}
export function staffScheduleListFail (payload = {}, meta) {
  return { type: STAFF_SCHEDULE_LIST_FAIL, payload, meta, error: true };
}

export function staffScheduleUpdate (payload = {}) {
  return { type: STAFF_SCHEDULE_UPDATE, payload, meta: { thunk: true } };
}
export function staffScheduleUpdateSuccess (payload = {}, meta) {
  return { type: STAFF_SCHEDULE_UPDATE_SUCCESS, payload, meta };
}
export function staffScheduleUpdateFail (payload = {}, meta) {
  return { type: STAFF_SCHEDULE_UPDATE_FAIL, payload, meta, error: true };
}

export function staffScheduleDailyUpdate (payload = {}) {
  return { type: STAFF_SCHEDULE_DAILY_UPDATE, payload, meta: { thunk: true } };
}
export function staffScheduleDailyUpdateSuccess (payload = {}, meta) {
  return { type: STAFF_SCHEDULE_DAILY_UPDATE_SUCCESS, payload, meta };
}
export function staffScheduleDailyUpdateFail (payload = {}, meta) {
  return { type: STAFF_SCHEDULE_DAILY_UPDATE_FAIL, payload, meta, error: true };
}

// GET STAFF SCHEDULE DAILY
export function getStaffScheduleDaily (payload = {}) {
  return { type: GET_STAFF_SCHEDULE_DAILY, payload, meta: { thunk: true } };
}
export function getStaffScheduleDailySuccess (payload = {}, meta) {
  return { type: GET_STAFF_SCHEDULE_DAILY_SUCCESS, payload, meta };
}
export function getStaffScheduleDailyFail (payload = {}, meta) {
  return { type: GET_STAFF_SCHEDULE_DAILY_FAIL, payload, meta, error: true };
}

// GET CURRENT SCHEDULE BY STAFF
export function getCurrentScheduleByStaff (payload = {}) {
  return { type: GET_CURRENT_SCHEDULE_BY_STAFF, payload, meta: { thunk: true } };
}
export function getCurrentScheduleByStaffSuccess (payload = {}, meta) {
  return { type: GET_CURRENT_SCHEDULE_BY_STAFF_SUCCESS, payload, meta };
}
export function getCurrentScheduleByStaffFail (payload = {}, meta) {
  return { type: GET_CURRENT_SCHEDULE_BY_STAFF_FAIL, payload, meta, error: true };
}

// GET SCHEDULE BY STAFF ON A DATE
export function getScheduleByStaffOnADate (payload = {}) {
  return { type: GET_SCHEDULE_BY_STAFF_ON_A_DATE, payload, meta: { thunk: true } };
}
export function getScheduleByStaffOnADateSuccess (payload = {}, meta) {
  return { type: GET_SCHEDULE_BY_STAFF_ON_A_DATE_SUCCESS, payload, meta };
}
export function getScheduleByStaffOnADateFail (payload = {}, meta) {
  return { type: GET_SCHEDULE_BY_STAFF_ON_A_DATE_FAIL, payload, meta, error: true };
}

// GET LIST STAFF SALE REPORT
export function getListStaffSaleReport (payload = {}) {
  return { type: GET_LIST_STAFF_SALE_REPORT, payload, meta: { thunk: true } };
}
export function getListStaffSaleReportSuccess (payload = {}, meta) {
  return { type: GET_LIST_STAFF_SALE_REPORT_SUCCESS, payload, meta };
}
export function getListStaffSaleReportFail (payload = {}, meta) {
  return { type: GET_LIST_STAFF_SALE_REPORT_FAIL, payload, meta, error: true };
}

// GET LIST BOOKING STAFF SALE REPORT
export function getListBookingStaffSaleReport (payload = {}) {
  return { type: GET_LIST_BOOKING_STAFF_SALE_REPORT, payload, meta: { thunk: true } };
}
export function getListBookingStaffSaleReportSuccess (payload = {}, meta) {
  return { type: GET_LIST_BOOKING_STAFF_SALE_REPORT_SUCCESS, payload, meta };
}
export function getListBookingStaffSaleReportFail (payload = {}, meta) {
  return { type: GET_LIST_BOOKING_STAFF_SALE_REPORT_FAIL, payload, meta, error: true };
}

// REMOVE STAFF
export function removeStaff (payload = {}) {
  return { type: REMOVE_STAFF, payload, meta: { thunk: true } };
}
export function removeStaffSuccess (payload = {}, meta) {
  return { type: REMOVE_STAFF_SUCCESS, payload, meta };
}
export function removeStaffFail (payload = {}, meta) {
  return { type: REMOVE_STAFF_FAIL, payload, meta, error: true };
}
// UPDATE STAFF ORDER
export function updateStaffOrder (payload = {}) {
  return { type: UPDATE_STAFF_ORDER, payload, meta: { thunk: true } };
}
export function updateStaffOrderSuccess (payload = {}, meta) {
  return { type: UPDATE_STAFF_ORDER_SUCCESS, payload, meta };
}
export function updateStaffOrderFail (payload = {}, meta) {
  return { type: UPDATE_STAFF_ORDER_FAIL, payload, meta, error: true };
}

// CONNECT THIRD PARTY STAFF
export function getThirdPartySourceStaffList (payload = {}) {
  return { type: GET_THIRD_PARTY_SOURCE_STAFF_LIST, payload, meta: { thunk: true } };
}
export function getThirdPartySourceStaffListSuccess (payload = {}, meta) {
  return { type: GET_THIRD_PARTY_SOURCE_STAFF_LIST_SUCCESS, payload, meta };
}
export function getThirdPartySourceStaffListFail (payload = {}, meta) {
  return { type: GET_THIRD_PARTY_SOURCE_STAFF_LIST_FAIL, payload, meta, error: true };
}

export function getThirdPartySourceId (payload = {}) {
  return { type: GET_THIRD_PARTY_SOURCE_ID, payload, meta: { thunk: true } };
}
export function getThirdPartySourceIdSuccess (payload = {}, meta) {
  return { type: GET_THIRD_PARTY_SOURCE_ID_SUCCESS, payload, meta };
}
export function getThirdPartySourceIdFail (payload = {}, meta) {
  return { type: GET_THIRD_PARTY_SOURCE_ID_FAIL, payload, meta, error: true };
}

export function updateThirdPartySourceStaff (payload = {}) {
  return { type: UPDATE_THIRD_PARTY_SOURCE_STAFF, payload, meta: { thunk: true } };
}
export function updateThirdPartySourceStaffSuccess (payload = {}, meta) {
  return { type: UPDATE_THIRD_PARTY_SOURCE_STAFF_SUCCESS, payload, meta };
}
export function updateThirdPartySourceStaffFail (payload = {}, meta) {
  return { type: UPDATE_THIRD_PARTY_SOURCE_STAFF_FAIL, payload, meta, error: true };
}
export function toggleThirdPartyStaffConnection (payload = {}) {
  return { type: TOGGLE_THIRD_PARTY_STAFF_CONNECTION, payload, meta: { thunk: true } };
}
export function toggleThirdPartyStaffConnectionSuccess (payload = {}, meta) {
  return { type: TOGGLE_THIRD_PARTY_STAFF_CONNECTION_SUCCESS, payload, meta };
}
export function toggleThirdPartyStaffConnectionFail (payload = {}, meta) {
  return { type: TOGGLE_THIRD_PARTY_STAFF_CONNECTION_FAIL, payload, meta, error: true };
}
