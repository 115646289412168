import {
  FETCH_STAFF_LIST,
  UPDATE_STAFF_PROFILE,
  ADD_STAFF,
  CHECK_STAFF_INVITATION,
  IMPORT_STAFF,
  STAFF_DETAIL_CATEGORIES_SERVICES,
  STAFF_DETAIL_ADD_SERVICES,
  STAFF_SEND_INVITE,
  STAFF_CANCEL_INVITE,
  STAFF_DETAIL,
  STAFF_SCHEDULE_LIST,
  STAFF_SCHEDULE_UPDATE,
  STAFF_SCHEDULE_DAILY_UPDATE,
  GET_CURRENT_SCHEDULE_BY_STAFF,
  GET_SCHEDULE_BY_STAFF_ON_A_DATE,
  GET_LIST_STAFF_SALE_REPORT,
  GET_LIST_BOOKING_STAFF_SALE_REPORT,
  REMOVE_STAFF,
  GET_STAFF_SCHEDULE_DAILY,
  GET_INVITATION_SUGGESTION_LIST,
  UPDATE_STAFF_ORDER,
  GET_THIRD_PARTY_SOURCE_STAFF_LIST,
  TOGGLE_THIRD_PARTY_STAFF_CONNECTION,
} from './types';
import request from 'utils/request';
import { put, takeLeading, call, takeLatest, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import {
  fetchStaffListSuccess,
  updateStaffProfileSuccess,
  addStaffFail,
  addStaffSuccess,
  getInvitationSuggestionListSuccess,
  getInvitationSuggestionListFail,
  checkStaffInvitationFail,
  checkStaffInvitationSuccess,
  importStaffFail,
  importStaffSuccess,
  fetchStaffList as fetchStaffListAction,
  staffDetailCategoriesServicesSuccess,
  staffDetailCategoriesServicesFail,
  staffDetailAddServicesSuccess,
  staffDetailAddServicesFail,
  staffSendInviteFail,
  staffSendInviteSuccess,
  staffCancelInviteFail,
  staffCancelInviteSuccess,
  staffDetailSuccess,
  staffDetailFail,
  staffScheduleListFail,
  staffScheduleListSuccess,
  staffScheduleUpdateFail,
  staffScheduleUpdateSuccess,
  staffScheduleDailyUpdateFail,
  staffScheduleDailyUpdateSuccess,
  getCurrentScheduleByStaffFail,
  getCurrentScheduleByStaffSuccess,
  getScheduleByStaffOnADateFail,
  getScheduleByStaffOnADateSuccess,
  getListStaffSaleReportFail,
  getListStaffSaleReportSuccess,
  getListBookingStaffSaleReportFail,
  getListBookingStaffSaleReportSuccess,
  removeStaffSuccess,
  removeStaffFail,
  getStaffScheduleDailySuccess,
  getStaffScheduleDailyFail,
  updateStaffOrderSuccess,
  updateStaffOrderFail,
  getThirdPartySourceStaffListSuccess,
  getThirdPartySourceStaffListFail,
  toggleThirdPartyStaffConnectionSuccess,
  toggleThirdPartyStaffConnectionFail,
} from './actions';
import environment from 'environment';
import { message as Alert } from 'antd';
import BookingStatusHelper from 'utils/bookingStatusHelper';
import { PRE_RANDOM_NUM } from 'utils/constants';
import { generatePreRandomInfor } from 'utils/common';

function * fetchStaffList (action) {
  const { sortedInfo = 'order asc' } = action.payload;
  const search = _.get(action.payload, 'searchValue');
  const token = action.payload.token;
  const { connectionStatus = '' } = action.payload;
  try {
    const { data } = yield call(request, environment.api.staffList,
      {
        page: action.payload.currentPage,
        limit: action.payload.pageSize,
        active: true,
        sort: sortedInfo,
        keyword: search,
        connectionStatus,
      }, 'GET', token);
    yield put(fetchStaffListSuccess({ data: data }, action.meta));
  } catch (error) {
    console.log('errors: ', error);
  }
}

function * staffCreate (action) {
  try {
    const { values } = action.payload;
    const { data } = yield call(request, environment.api.staffCreate,
      {
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        gender: values.gender,
        birthday: !values.birthday ? undefined : moment(values.birthday).format('YYYY-MM-DD'),
        location: {
          postCode: values.postCode,
          prefecture: values.prefecture,
          cityOrTown: values.cityOrTown,
          address: values.address,
        },
      },
    );

    yield put(addStaffSuccess({ data: data }, action.meta));
    Alert.success('作成完了');
  } catch (e) {
    const errorData = _.get(e, 'data');
    const messageError = errorData.find(error => error.message);
    const errorDetail = _.get(messageError, 'message');
    Alert.error(`${errorDetail}!`);
    yield put(addStaffFail());
    console.log(e);
  }
}

function * updateStaffProfile (action) {
  try {
    const { values, token } = action.payload;
    const updateStaffProfile = yield call(request, `${environment.api.staffUpdate}/${action.payload.id}/connections`,
      {
        avatarLink: values.avatar,
        username: values.username,
        name: values.name,
        phonetic: values.phonetic.trim(),
        gender: values.gender,
        birthday: !values.birthday ? null : moment(values.birthday).format('YYYY-MM-DD'),
      }, 'PUT', token);
    yield put(updateStaffProfileSuccess({
      updateStaffProfile: updateStaffProfile,
    }, action.meta));
    yield put(fetchStaffListAction({ token }));
    Alert.success('設定を変更しました');
  } catch (e) {
    const errorData = _.get(e, 'data');
    const messageError = errorData.find(error => error.message);
    const errorDetail = _.get(messageError, 'message');
    Alert.error(`${errorDetail}!`);
  }
}

function * staffDetailCategoriesServices (action) {
  try {
    const { token } = action.payload;
    const result = yield call(request, environment.api.categoryList, {}, 'GET', token);
    const categories = [];

    for (let i = 0; i < result.data.length; i++) {
      const services = [];
      for (let j = 0; j < result.data[i].services.length; j++) {
        const itemService = { ...result.data[i].services[j], checked: true };
        services.push(itemService);
      }

      const itemNew = { ...result.data[i], checked: true, services };
      categories.push(itemNew);
    }

    yield put(staffDetailCategoriesServicesSuccess(categories, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(staffDetailCategoriesServicesFail('エーロが発生しました'));
  }
}

function * staffDetailAddServices (action) {
  try {
    const { recommend, staffId, services, token } = action.payload;
    yield call(request, `${environment.api.staffAddServices}/${staffId}/services`, { services, recommend: recommend || undefined }, 'POST', token);

    yield put(staffDetailAddServicesSuccess({}, action.meta));
    yield put(fetchStaffListAction({ token }));

    Alert.success('設定を変更しました');
  } catch (error) {
    console.log('error: ', error);
    yield put(staffDetailAddServicesFail(error.message, action.meta));
  }
}

function * staffSendInvite (action) {
  try {
    const { staffId, identity, token } = action.payload;
    yield call(request, `${environment.api.staffSendInvite}/${staffId}/invitation`, { identity }, 'POST', token);

    Alert.success('インビテーションが送りました');
    yield put(staffSendInviteSuccess({}, action.meta));
  } catch (error) {
    Alert.error(error.message);
    yield put(staffSendInviteFail(error.message, action.meta));
  }
}

function * staffCancelInvite (action) {
  try {
    const { staffId, token } = action.payload;
    yield call(request, `${environment.api.staffCancelInvite}/${staffId}/cancellation`, {}, 'POST', token);

    Alert.success('インビテーションをキャンセルしました');
    yield put(staffCancelInviteSuccess({}, action.meta));
  } catch (error) {
    Alert.error(error.message);
    yield put(staffCancelInviteFail(error.message, action.meta));
  }
}

function * staffDetail (action) {
  const { staffId, token } = action.payload;
  try {
    const staff = yield call(request, `${environment.api.staffDetail}/${staffId}`, {}, 'GET', token);
    yield put(staffDetailSuccess(staff, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(staffDetailFail(error, action.meta));
  }
}

function * staffScheduleList (action) {
  const { from, to, token } = action.payload;
  try {
    const { data } = yield call(request, environment.api.staffScheduleList,
      {
        page: action.payload.currentPage,
        limit: action.payload.pageSize,
        sort: action.payload.sortedInfo,
        from,
        to,
        connectionStatus: '1',
      }, 'GET', token);
    yield put(staffScheduleListSuccess({ data: data }, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(staffScheduleListFail(error, action.meta));
  }
}

function * staffScheduleUpdate (action) {
  try {
    const { timezone, schedules, staffId, token } = action.payload;
    const salon = yield call(request, `${environment.api.staffScheduleUpdate}/${staffId}/schedules`,
      {
        timezone,
        schedules,
      }, 'PUT', token);
    yield put(staffScheduleUpdateSuccess(salon, action.meta));
  } catch (error) {
    yield put(staffScheduleUpdateFail(error, action.meta));
  }
}

function * staffScheduleDailyUpdate (action) {
  try {
    const { timezone, schedules, staffId, token } = action.payload;
    const staff = yield call(request, `${environment.api.staffScheduleUpdate}/${staffId}/schedules/daily`,
      {
        timezone,
        schedules,
      }, 'PUT', token);
    yield put(staffScheduleDailyUpdateSuccess(staff, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    yield put(staffScheduleDailyUpdateFail(error, action.meta));
    Alert.error(error.message);
  }
}

function * getStaffScheduleDaily (action) {
  const { from, to, token } = action.payload;
  try {
    const { data } = yield call(request, environment.api.staffScheduleList,
      {
        from,
        to,
        connectionStatus: '1',
        ids: action.payload.staffId,
      }, 'GET', token);
    yield put(getStaffScheduleDailySuccess({ data: data }, action.meta));
  } catch (error) {
    yield put(getStaffScheduleDailyFail(error, action.meta));
    Alert.error(error.message);
  }
}

function * staffImport (action) {
  let staffId = null;
  const { values, token, staffDefaultValues } = action.payload;
  const PRE_RANDOM_INFOR = generatePreRandomInfor(PRE_RANDOM_NUM);
  try {
    const { data } = yield call(request, environment.api.staffCreate,
      {
        name: staffDefaultValues.fullname,
        username: staffDefaultValues.username,
        avatar: staffDefaultValues.avatar,
        email: `${PRE_RANDOM_INFOR}${staffDefaultValues.email}` || `${values.identity}${PRE_RANDOM_INFOR}@yopmail.com`,
        phoneNumber: `${PRE_RANDOM_INFOR}${staffDefaultValues.phoneNumber}`,
        location: {
          address: '-',
        },
        isGenerated: true,
      },
      'POST', token);
    staffId = data.id;
    yield call(request, `${environment.api.staffSendInvite}/${data.id}/invitation`, { identity: values.identity }, 'POST', token);
    yield put(importStaffSuccess(data, action.meta));
    Alert.success('インポートしました');
  } catch (e) {
    if (e.statusCode === 404) {
      yield call(request, `${environment.api.staffDetail}/${staffId}`, {}, 'DELETE', token);
    }
    yield put(importStaffFail(e, action.meta));
    Alert.error(`${e.message}!`);
    console.log(e);
  }
}

function * getInvitationSuggestionList (action) {
  const { identity, token } = action.payload;
  try {
    const { data } = yield call(request, `${environment.api.getStaffInfoByIndentity}/${identity.trim()}/suggestions?systemId=085094953333804`, {}, 'GET', token);
    yield put(getInvitationSuggestionListSuccess(data, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getInvitationSuggestionListFail(error, action.meta));
  }
}

function * checkStaffInvitation (action) {
  const { values, token } = action.payload;
  try {
    const staff = yield call(request, `${environment.api.getStaffInfoByIndentity}/${values.identity}?systemId=085094953333804`, {}, 'GET', token);
    yield put(checkStaffInvitationSuccess(staff, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(checkStaffInvitationFail(error, action.meta));
  }
}

function * getCurrentScheduleByStaff (action) {
  const { staffId, token } = action.payload;
  try {
    const { data } = yield call(request, `${environment.api.staffScheduleUpdate}/${staffId}/latestSchedules`, {}, 'GET', token);
    yield put(getCurrentScheduleByStaffSuccess(data, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getCurrentScheduleByStaffFail(error, action.meta));
  }
}

function * getScheduleByStaffOnADate (action) {
  const { staffId, token } = action.payload;
  const date = moment().startOf('day').toISOString();
  try {
    const { data } = yield call(request, `${environment.api.staffScheduleUpdate}/${staffId}/schedules?date=${date}`, {}, 'GET', token);
    yield put(getScheduleByStaffOnADateSuccess(data, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getScheduleByStaffOnADateFail(error, action.meta));
  }
}

function * getListStaffSaleReport (action) {
  try {
    const { staffId, from, to, token } = action.payload;
    const { data } = yield call(
      request,
      `${environment.api.saleReportsStaff}/${staffId}`,
      { from, to },
      'GET',
      token,
    );
    yield put(getListStaffSaleReportSuccess(data, action.meta));
  } catch (error) {
    yield put(getListStaffSaleReportFail(error, action.meta));
  }
}

function * getListBookingStaffSaleReport (action) {
  try {
    const { staffId, page, limit, from, to, token } = action.payload;
    const { data } = yield call(
      request,
      `${environment.api.saleReportsStaff}/${staffId}/bookings`,
      { page, limit, from, to, targetSystemStatus: 'CANCELED_RESERVATION,DONE,NOVISIT' },
      'GET',
      token,
    );
    BookingStatusHelper.FormatSaleReportBookingStatus(data);
    yield put(getListBookingStaffSaleReportSuccess(data, action.meta));
  } catch (error) {
    yield put(getListBookingStaffSaleReportFail(error, action.meta));
  }
}

function * removeStaff (action) {
  try {
    const { staffId, token } = action.payload;
    const { data } = yield call(
      request,
      `${environment.api.removeStaff}/${staffId}`,
      {},
      'DELETE',
      token,
    );
    Alert.success('スタッフを削除しました');
    yield put(removeStaffSuccess(data, action.meta));
  } catch (error) {
    Alert.error('該当のネイリストにはまだ完了していない予約があります');
    yield put(removeStaffFail(error, action.meta));
  }
}

function * updateStaffOrder (action) {
  try {
    const { dataSource, oldIndex, newIndex, token } = action.payload;

    const staffId = dataSource[oldIndex]?.id;
    if (oldIndex < newIndex) { // Drag staff from top to bottom.
      const prevStaffId = dataSource[newIndex]?.id;
      yield call(
        request,
        `${environment.api.staffUpdateOrder}/${staffId}/order`,
        {
          prevStaffId,
        },
        'PUT',
        token,
      );
    } else { // Drag staff from bottom to top.
      const prevStaffId = newIndex === 0 ? null : dataSource[newIndex - 1]?.id;
      yield call(
        request,
        `${environment.api.staffUpdateOrder}/${staffId}/order`,
        {
          prevStaffId,
        },
        'PUT',
        token,
      );
    }
    yield put(updateStaffOrderSuccess({}, action.meta));
  } catch (error) {
    yield put(updateStaffOrderFail(error, action.meta));
  }
}

function * getThirdPartySourceStaffList (action) {
  const THIRD_PARTIES_CODE = 'CS';
  try {
    const { token } = action.payload;
    const { data } = yield call(request,
      `${environment.api.staffThirdParties}/${THIRD_PARTIES_CODE}/staff`,
      {}, 'GET', token);
    yield put(getThirdPartySourceStaffListSuccess({ data: data }, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(getThirdPartySourceStaffListFail(error, action.meta));
  }
}

function * toggleThirdPartyStaffConnection (action) {
  const THIRD_PARTIES_CODE = 'CS';
  const staffId = action.payload.staffId;
  try {
    const { token } = action.payload;
    const { data } = yield call(request,
      `${environment.api.toggleThirdPartyStaffConnection}/${THIRD_PARTIES_CODE}/staff/${staffId}/toggle-connection`,
      {
        sourceId: action.payload.sourceId || undefined,
      }, 'POST', token);

    yield put(toggleThirdPartyStaffConnectionSuccess({ data: data }, action.meta));
    // Alert.success('設定を変更しました');
  } catch (error) {
    console.log('errors: ', error);
    yield put(toggleThirdPartyStaffConnectionFail(error, action.meta));
  }
}

export default function * watchStaffList () {
  yield takeLeading(FETCH_STAFF_LIST, fetchStaffList);
  yield takeLeading(UPDATE_STAFF_PROFILE, updateStaffProfile);
  yield takeLeading(ADD_STAFF, staffCreate);
  yield takeLatest(GET_INVITATION_SUGGESTION_LIST, getInvitationSuggestionList);
  yield takeLeading(CHECK_STAFF_INVITATION, checkStaffInvitation);
  yield takeLeading(IMPORT_STAFF, staffImport);
  yield takeLeading(STAFF_DETAIL_CATEGORIES_SERVICES, staffDetailCategoriesServices);
  yield takeLeading(STAFF_DETAIL_ADD_SERVICES, staffDetailAddServices);
  yield takeLeading(STAFF_SEND_INVITE, staffSendInvite);
  yield takeLeading(STAFF_CANCEL_INVITE, staffCancelInvite);
  yield takeLeading(STAFF_DETAIL, staffDetail);
  yield takeLatest(STAFF_SCHEDULE_LIST, staffScheduleList);
  yield takeEvery(STAFF_SCHEDULE_UPDATE, staffScheduleUpdate);
  yield takeEvery(STAFF_SCHEDULE_DAILY_UPDATE, staffScheduleDailyUpdate);
  yield takeLeading(GET_STAFF_SCHEDULE_DAILY, getStaffScheduleDaily);
  yield takeLeading(GET_CURRENT_SCHEDULE_BY_STAFF, getCurrentScheduleByStaff);
  yield takeLeading(GET_SCHEDULE_BY_STAFF_ON_A_DATE, getScheduleByStaffOnADate);
  yield takeLeading(GET_LIST_STAFF_SALE_REPORT, getListStaffSaleReport);
  yield takeLeading(GET_LIST_BOOKING_STAFF_SALE_REPORT, getListBookingStaffSaleReport);
  yield takeLeading(REMOVE_STAFF, removeStaff);
  yield takeEvery(UPDATE_STAFF_ORDER, updateStaffOrder);
  yield takeEvery(GET_THIRD_PARTY_SOURCE_STAFF_LIST, getThirdPartySourceStaffList);
  yield takeEvery(TOGGLE_THIRD_PARTY_STAFF_CONNECTION, toggleThirdPartyStaffConnection);
}
