// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Table, Popconfirm, Typography, Button, Row, Col } from 'antd';
import _ from 'lodash';
// Providers
import { salonAccountUpdate } from 'providers/SalonProvider/actions';
// Utils
import { getLanguages } from 'utils/lang';
import { useDispatch } from 'hooks/useCustomDispatch';
import './styles.less';
const { Text } = Typography;

const SalonEmailSetting = ({ salon, onCallback }) => {
  const [formAddEmail] = Form.useForm();
  const [formEmailList] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const _listEmail = salon?.listEmail;
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const isEditing = record => record.email === editingKey;
  // Rules validate emails
  const makeEmailRules = (ignoreDuplicateValue) => [
    {
      required: true,
      message: lang.emptyEmailError,
    },
    {
      type: 'email',
      message: lang.invalidEmailError,
    },
    () => ({
      validator (rule, value) {
        if (value) {
          if (_listEmail.some((item, index) => (new RegExp(`^${item.email}$`, 'i').test(value) && (ignoreDuplicateValue ? ignoreDuplicateValue !== value : true)))
          ) {
            return Promise.reject(new Error(lang.duplicatedEmailError));
          } else {
          }
        }
        return Promise.resolve();
      },
    }),
  ];
  const EditTableEmails = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    emailList,
    ...restProps
  }) => {
    const emailRules = makeEmailRules(editingKey);

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={ dataIndex === 'email' ? emailRules : []}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  // Initializes the value when selecting the row to be edited
  const RecordEditing = record => {
    formEmailList.setFieldsValue({
      email: '',
      label: '',
      ...record,
    });
    setEditingKey(record.email);
  };

  const handleUpdateSalon = (salon, listEmail) => {
    setLoading(true);
    dispatch(salonAccountUpdate({
      salonId: _.get(salon, 'id'),
      address: salon.location.address,
      building: salon.location.building,
      cityOrTown: salon.location.cityOrTown,
      businessName: salon.businessName,
      listEmail: listEmail,
      location: salon.location,
      latLng: salon.location.latLng,
      postCode: salon.location.postCode,
      prefecture: salon.location.prefecture,
      area: salon.location.area,
      isPrivate: salon.location.isPrivate,
      logoUrl: salon.logoUrl || '',
      photos: salon.photos,
      propertyIds: salon.properties.map(item => item.id),
      description: salon.description || '',
    })).then((result) => {
      setLoading(false);
      onCallback(result);
    },
    ).catch(error => {
      setLoading(false);
      console.error(error.message);
    });
  };
  // Handle add - update - delete email
  const addEmail = ({ label, email }) => {
    const listEmail = [..._listEmail];
    listEmail.push(
      {
        label: label,
        email: email,
      });
    handleUpdateSalon(salon, listEmail);
    // Clear email, label input
    formAddEmail.setFieldsValue(
      {
        labelInput: '',
        emailInput: '',
      });
  };

  const saveEmail = async _email => {
    try {
      const row = await formEmailList.validateFields();
      if (row) {
        const listEmail = _listEmail.map((item, index) => {
          if (item.email === _email) {
            return {
              label: row?.label,
              email: row?.email,
            };
          }
          return item;
        });
        handleUpdateSalon(salon, listEmail);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const deleteEmail = (_email) => () => {
    const listEmail = _listEmail.filter((item) => item.email !== _email);
    handleUpdateSalon(salon, listEmail);
    setEditingKey('');
  };

  const columns = [
    {
      dataIndex: 'label',
      width: '40%',
      editable: true,
      render: function renderItem (_, record, rowIndex) {
        if (rowIndex === 0) {
          return (
            <div style={{ color: 'gray', fontSize: 15 }}>
              {lang.defaultLabel}
            </div>
          );
        } else {
          return (
            <div>
              {record.label}
            </div>
          );
        }
      },
    },

    {
      dataIndex: 'email',
      width: '40%',
      editable: true,
      render: function renderItem (_, record, rowIndex) {
        return (
          <div style={rowIndex === 0 ? { color: 'gray', fontSize: 15 } : {}}>
            {record.email}
          </div>
        );
      },
    },
    {
      dataIndex: 'Action',
      render: function renderItem (_, record, rowIndex) {
        const editable = isEditing(record);
        if (editable) {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button
                type='link'
                onClick={() => { setEditingKey(''); }}
              >{lang.cancel}
              </Button>
              <Button
                type='primary'
                onClick={() => saveEmail(record.email)}
              >
                {lang.save}
              </Button>
            </div>);
        } else if (rowIndex > 0) {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button
                type='link'
                disabled={editingKey !== ''}
                onClick={() => RecordEditing(record)}
              >
                {lang.edit}
              </Button>
              <Button
                type='link'
                disabled={editingKey !== ''}>
                <Popconfirm title={lang.confirmDeleteEmail} onConfirm={deleteEmail(record.email)}>
                  <Text type='danger'>{lang.delete}</Text>
                </Popconfirm>
              </Button>
            </div>
          );
        }
      },
    },
  ];
  const EmailColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
        _listEmail: _listEmail,
      }),
    };
  });

  const onFinish = values => {
    const isCanAddEmail = formAddEmail.validateFields();
    if (isCanAddEmail) {
      addEmail(
        {
          label: values.labelInput,
          email: values.emailInput,
        });
    }
  };
  return (
    <div className="emails-table">
      <Text>{lang.emailSettingDescription}</Text>
      <Form
        autoComplete='off'
        form={formAddEmail}
        onFinish={onFinish}
      >
        <Row gutter={24} type="flex" style={{ marginTop: 20 }}>
          <Col sm={{ span: 10 }} xs={{ span: 24 }}>
            <Form.Item
              name="labelInput"
            >
              <Input
                placeholder={lang.labelName}
                disabled={_listEmail?.length > 9}
                autoComplete='off' maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col sm={{ span: 10 }} xs={{ span: 24 }}>
            <Form.Item
              name="emailInput"
              rules={ makeEmailRules()}
            >
              <Input
                placeholder={lang.emailAddress}
                autoComplete='off'
                disabled={_listEmail?.length > 9}
              />
            </Form.Item>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Button
              type='primary'
              htmlType="submit"
              disabled={_listEmail?.length > 9}
            >
              {lang.add}
            </Button>
          </Col>
        </Row>
      </Form>

      <Form form={formEmailList}>
        <Table
          components={{
            body: {
              cell: EditTableEmails,
            },
          }}
          loading={loading}
          dataSource={_listEmail}
          columns={EmailColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </div>
  );
};

SalonEmailSetting.propTypes = {
  salon: PropTypes.object,
};

export default SalonEmailSetting;
