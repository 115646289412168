const MAP_REPLACE_S3 = {
  d18mauf5k4osvd: 'd1qyhbwogwcazp',
  'nailie-prod': 'production-nailie-api-default',
};

export const mappingS3Domain = (src) => {
  if (typeof src === 'string') {
    for (const oldS3 in MAP_REPLACE_S3) {
      if (src.includes(oldS3)) {
        const newS3 = MAP_REPLACE_S3[oldS3];
        return src.replace(oldS3, newS3);
      }
    }
  }
  return src;
};
