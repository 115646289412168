import {
  CUSTOMER_DETAIL,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_DETAIL_FAIL,
  CUSTOMER_LIST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
} from './types';

export function customerDetail (payload = {}) {
  return { type: CUSTOMER_DETAIL, payload, meta: { thunk: true } };
}
export function customerDetailSuccess (payload = {}, meta) {
  return { type: CUSTOMER_DETAIL_SUCCESS, payload, meta };
}
export function customerDetailFail (payload = {}, meta) {
  return { type: CUSTOMER_DETAIL_FAIL, payload, meta, error: true };
}

export function customerList (payload = {}) {
  return { type: CUSTOMER_LIST, payload, meta: { thunk: true } };
}
export function customerListSuccess (payload = {}, meta) {
  return { type: CUSTOMER_LIST_SUCCESS, payload, meta };
}
export function customerListFail (payload = {}, meta) {
  return { type: CUSTOMER_LIST_FAIL, payload, meta, error: true };
}

export function customerCreate (payload = {}) {
  return { type: CUSTOMER_CREATE, payload, meta: { thunk: true } };
}
export function customerCreateSuccess (payload = {}, meta) {
  return { type: CUSTOMER_CREATE_SUCCESS, payload, meta };
}
export function customerCreateFail (payload = {}, meta) {
  return {
    type: CUSTOMER_CREATE_FAIL, payload, meta, error: true,
  };
}

export function customerUpdate (payload = {}) {
  return { type: CUSTOMER_UPDATE, payload, meta: { thunk: true } };
}
export function customerUpdateSuccess (payload = {}, meta) {
  return { type: CUSTOMER_UPDATE_SUCCESS, payload, meta };
}
export function customerUpdateFail (payload = {}, meta) {
  return {
    type: CUSTOMER_UPDATE_FAIL, payload, meta, error: true,
  };
}

export function customerDelete (payload = {}) {
  return { type: CUSTOMER_DELETE, payload };
}
export function customerDeleteSuccess (payload = {}) {
  return { type: CUSTOMER_DELETE_SUCCESS, payload };
}
export function customerDeleteFail (payload = {}) {
  return { type: CUSTOMER_DELETE_FAIL, payload };
}
