import {
  customerListSuccess,
  customerListFail,
  customerCreateSuccess,
  customerCreateFail,
  customerUpdateSuccess,
  customerUpdateFail,
  customerList as customerListAction,
  customerDetailSuccess,
  customerDetailFail,
} from './actions';
import {
  CUSTOMER_LIST,
  CUSTOMER_CREATE,
  CUSTOMER_UPDATE,
  CUSTOMER_DETAIL,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';
import moment from 'moment';
import _ from 'lodash';

function * customerList (action) {
  const { token } = action.payload;
  const { order = 'desc', columnKey = 'createdAt' } = action.payload.sortedInfo || {};
  try {
    const customers = yield call(request, environment.api.customerList,
      {
        page: action.payload.currentPage,
        limit: action.payload.pageSize,
        sort: `${columnKey === 'createdAt' ? `createdAt ${order.replace('end', '')} ` : `name ${order.replace('end', '')}`}`,
        keyword: action.payload.searchValue,
      }, 'GET', token);
    yield put(customerListSuccess({ customers }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(customerListFail('エーロが発生しました'));
  }
}

function * customerCreate (action) {
  try {
    const { values, token } = action.payload;
    const customer = yield call(request, environment.api.customerCreate,
      {
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        gender: values.gender,
        birthday: !values.birthday ? undefined : moment(values.birthday).format('YYYY-MM-DD'),
        location: {
          postCode: values.postCode,
          prefecture: values.prefecture,
          cityOrTown: values.cityOrTown,
          address: values.address,
        },
      },
      'POST',
      token,
    );

    yield put(customerCreateSuccess({ customer }, action.meta));
    Alert.success('作成完了');
  } catch (e) {
    const errorData = _.get(e, 'data');
    const messageError = errorData.find(error => error.message);
    const errorDetail = _.get(messageError, 'message');
    Alert.error(`${errorDetail}!`);
    yield put(customerCreateFail());
  }
}

function * customerUpdate (action) {
  try {
    const { values, id, token } = action.payload;
    yield call(request, `${environment.api.customerUpdate}/${id}`,
      {
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        gender: values.gender === '' ? undefined : values.gender,
        birthday: !values.birthday ? null : moment(values.birthday).format('YYYY-MM-DD'),
        active: values.active,
        location: {
          postCode: values.postCode,
          prefecture: values.prefecture,
          cityOrTown: values.cityOrTown,
          address: values.address,
        },
      }, 'PUT', token,
    );
    yield put(customerUpdateSuccess({}, action.meta));
    yield put(customerListAction());
    Alert.success('編集を成功しました');
  } catch (e) {
    const errorData = _.get(e, 'data');
    const messageError = errorData.find(error => error.message);
    const errorDetail = _.get(messageError, 'message');
    Alert.error(`${errorDetail}!`);

    yield put(customerUpdateFail(errorDetail, action.meta));
  }
}

function * customerDetail (action) {
  const { id, token } = action.payload;

  try {
    const customer = yield call(request, `${environment.api.customerDetail}/${id}`, {}, 'GET', token);
    yield put(customerDetailSuccess(customer, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(customerDetailFail(error, action.meta));
  }
}

export default function * watchCustomer () {
  yield takeEvery(CUSTOMER_LIST, customerList);
  yield takeLeading(CUSTOMER_CREATE, customerCreate);
  yield takeLeading(CUSTOMER_UPDATE, customerUpdate);
  yield takeLeading(CUSTOMER_DETAIL, customerDetail);
}
