import { useEffect, useState } from 'react';
import { fetchStaffList } from 'providers/StaffProvider/actions';
import { useDispatch } from 'hooks/useCustomDispatch';

const useFetchStaffs = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);

  const getStaffList = async () => {
    setLoading(true);
    const params = {
      currentPage: 1,
      pageSize: 10000,
      sortedInfo: 'order asc',
    };

    const result = await dispatch(fetchStaffList(params));
    const { data } = result.data;
    const nailieStaff = data.filter(s => s.connections?.length !== 0);
    setStaffs(nailieStaff);
    setLoading(false);
    return nailieStaff;
  };
  useEffect(() => {
    getStaffList();
  }, []);
  return { staffs, loading, getStaffList };
};

export default useFetchStaffs;
