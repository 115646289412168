import {
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAIL,
  CATEGORY_CREATE,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_ORDER,
  CATEGORY_ORDER_SUCCESS,
  CATEGORY_ORDER_FAIL,
  CATEGORY_DELETE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
} from './types';

// CATEGORY_LIST
export function categoryList (payload = {}) {
  return { type: CATEGORY_LIST, payload };
}
export function categoryListSuccess (payload = {}) {
  return { type: CATEGORY_LIST_SUCCESS, payload };
}
export function categoryListFail (payload = {}) {
  return { type: CATEGORY_LIST_FAIL, payload };
}

export function categoryDetail (payload = {}) {
  return { type: CATEGORY_DETAIL, payload };
}

// CATEGORY_CREATE
export function categoryCreate (payload = {}) {
  return { type: CATEGORY_CREATE, payload, meta: { thunk: true } };
}
export function categoryCreateSuccess (payload = {}, meta) {
  return { type: CATEGORY_CREATE_SUCCESS, payload, meta };
}
export function categoryCreateFail (payload = {}, meta) {
  return { type: CATEGORY_CREATE_FAIL, payload, meta, error: true };
}

// CATEGORY_UPDATE
export function categoryUpdate (payload = {}) {
  return { type: CATEGORY_UPDATE, payload, meta: { thunk: true } };
}
export function categoryUpdateSuccess (payload = {}, meta) {
  return { type: CATEGORY_UPDATE_SUCCESS, payload, meta };
}
export function categoryUpdateFail (payload = {}, meta) {
  return { type: CATEGORY_UPDATE_FAIL, payload, meta, error: true };
}

// CATEGORY_ORDER
export function categoryOrder (payload = {}) {
  return { type: CATEGORY_ORDER, payload, meta: { thunk: true } };
}
export function categoryOrderSuccess (payload = {}, meta) {
  return { type: CATEGORY_ORDER_SUCCESS, payload, meta };
}
export function categoryOrderFail (payload = {}, meta) {
  return { type: CATEGORY_ORDER_FAIL, payload, meta, error: true };
}

// CATEGORY_DELETE
export function categoryDelete (payload = {}) {
  return { type: CATEGORY_DELETE, payload };
}
export function categoryDeleteSuccess (payload = {}) {
  return { type: CATEGORY_DELETE_SUCCESS, payload };
}
export function categoryDeleteFail (payload = {}) {
  return { type: CATEGORY_DELETE_FAIL, payload };
}
