// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Modal, List, Avatar, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import { useDispatch } from 'hooks/useCustomDispatch';
import './styles.less';
import InfiniteScroll from 'react-infinite-scroller';
import BookingNotiHelpers from 'utils/bookingNotiHelpers';

// Utils
import { getLanguages } from 'utils/lang';
import { fetchBookingNotificationList, readBookingNotification } from 'providers/BookingProvider/actions';
const LIMIT = 100;

const BookingNotificationListModal = ({
  showNotificationModal,
  closeNotificationModal,
  handleDetailBooking,
}) => {
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  // State
  const locale = useSelector((state) => state.i18n.locale);
  const { notification } = useSelector(state => state.booking);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(2);

  const renderNotificationItem = (id) => {
    const item = notification?.data[id];
    const staffAvatar = _get(item, 'booking.orders[0].staff.connections[0].data.avatarLink');
    const staffFullName = _get(item, 'booking.orders[0].staff.connections[0].data.name');
    const notificationActivity = _get(item, 'activityEnum');
    const isReadNotification = item.isRead;
    const timeline = item.createdAt;

    const renderTreatmentTime = () => {
      return (
        <div className="treatment-time">
          <span>{moment(_get(item, 'booking.startTime')).format(locale === 'en' ? 'MM-DD' : 'M月D日')}</span>
          <span>{moment(_get(item, 'booking.startTime')).format('HH:mm ~')}</span>
        </div>
      );
    };

    return (
      <div className={`${isReadNotification ? 'booking-notification-wrapper' : 'booking-notification-read'}`}>
        <List.Item
          onClick={() => {
            handleDetailBooking(item.booking);
            dispatch(readBookingNotification({ id: item.id }));
          }}
          actions={[<div key={item.id} className="booking-notification-status">
            <div>
              <Avatar shape="circle" size={45} src={BookingNotiHelpers.STYLE[notificationActivity].icon}></Avatar>
            </div>
            <div style={{ marginTop: 4 }}>
              {lang[BookingNotiHelpers.STYLE[notificationActivity]?.langKey]}
            </div>
          </div>]}
        >
          <List.Item.Meta
            title={renderTreatmentTime()}
            avatar={
              <div style={{ marginLeft: 24 }}>
                {staffAvatar ? <Avatar size={70} src={staffAvatar} /> : <Avatar size={70} icon={<UserOutlined />} />}
              </div>
            }
            description={<div className="description">
              <div>
                {lang[BookingNotiHelpers.STYLE[notificationActivity]?.content]}
              </div>
              <div>
                <span>{lang.employer}: </span>
                <span>{staffFullName}</span>
              </div>
              <div className="timeline">{moment(timeline).fromNow()}</div>
            </div>}
          />
        </List.Item>
      </div>
    );
  };

  const handleInfiniteOnLoad = () => {
    setLoading(true);
    dispatch(fetchBookingNotificationList({ page: currentPage, limit: LIMIT }))
      .then((result) => {
        if (currentPage >= result.pagination.lastPage) {
          setHasMore(false);
        }
        setLoading(false);
        setCurrentPage(currentPage + 1);
      });
  };

  return (
    <Modal
      visible={showNotificationModal}
      title={lang.reservationAlertTitle}
      footer={null}
      onCancel={closeNotificationModal}
      destroyOnClose={true}
      width={640}
      centered
      maskClosable={false}
    >
      <div id="booking-notification-list">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            className="notification-list"
            itemLayout="horizontal"
            dataSource={notification.list}
            renderItem={id => renderNotificationItem(id)}
          >
            {loading && hasMore && (
              <div className="notification-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    </Modal>
  );
};

BookingNotificationListModal.propTypes = {
  showNotificationModal: PropTypes.bool,
  closeNotificationModal: PropTypes.func,
  data: PropTypes.array,
};
export default BookingNotificationListModal;
