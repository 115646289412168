import {
  CUSTOMER_DETAIL,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_DETAIL_FAIL,
  CUSTOMER_LIST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_CREATE_FAIL,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
} from './types';

const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_DETAIL: case CUSTOMER_DETAIL_SUCCESS: case CUSTOMER_DETAIL_FAIL:
    case CUSTOMER_LIST: case CUSTOMER_LIST_SUCCESS: case CUSTOMER_LIST_FAIL:
    case CUSTOMER_CREATE: case CUSTOMER_CREATE_SUCCESS: case CUSTOMER_CREATE_FAIL:
    case CUSTOMER_UPDATE: case CUSTOMER_UPDATE_SUCCESS: case CUSTOMER_UPDATE_FAIL:
    case CUSTOMER_DELETE: case CUSTOMER_DELETE_SUCCESS: case CUSTOMER_DELETE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
