import cancelIcon from 'assets/icon-notification/cancel_icon.png';
import confirmIcon from 'assets/icon-notification/confirm_icon.png';
import expiredIcon from 'assets/icon-notification/expired_icon.png';
import undoneIcon from 'assets/icon-notification/undone_icon.png';

const ENUMS = {
  confirm_booking: 'confirm_booking',
  cancel_booking: 'cancel_booking',
  expire_booking: 'expire_booking',
  novisit_booking: 'novisit_booking',
};

export const STYLE = {
  confirm_booking: { langKey: 'statusConfirmed', icon: confirmIcon, content: 'notificationMessageConfirm' },
  cancel_booking: { langKey: 'statusCanceled', icon: cancelIcon, content: 'notificationMessageCanceled' },
  expire_booking: { langKey: 'statusNailieCanceled', icon: expiredIcon, content: 'notificationMessageExpired' },
  novisit_booking: { langKey: 'statusNoVisit', icon: undoneIcon, content: 'notificationMessageNovisit' },
};

const mapNotiToEnum = (activity, bookingStatus, isImportBooking) => {
  if (activity === 'confirm_booking' && isImportBooking && ['DONE', 'WAITING_DONE', 'NAILIST_CANCELED', 'UNDONE', 'DECLINED_RESERVATION'].includes(bookingStatus)) {
    // https://c2cdoc.atlassian.net/browse/NL-5677 hide imported confirm https://docs.google.com/spreadsheets/d/135pS1qtGu_CMnl83kbKGt8XruTFL_oP7ojsUdZdotJI/edit#gid=0
    return 'NEED_FILTERED';
  } else if (activity === 'confirm_booking' && bookingStatus !== 'CHANGE_DATE_CANCEL') {
    // booking status: 'CONFIRMED' and 'CHANGE_DATE_CANCEL is status of old booking confirmed
    return ENUMS.confirm_booking;
  }

  if (activity === 'cancel_booking' && bookingStatus === 'NOVISIT') {
    return ENUMS.novisit_booking;
  }
  if (activity === 'cancel_booking' && bookingStatus === 'NAILIE_CANCELED') {
    return ENUMS.expire_booking;
  }
  if (activity === 'cancel_booking' && bookingStatus === 'CANCELED') {
    return ENUMS.cancel_booking;
  }

  return 'NEED_FILTERED';
};

const BookingNotiHelpers = {
  ENUMS,
  STYLE,
  mapNotiToEnum,
};

export default BookingNotiHelpers;
