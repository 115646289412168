// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Col, Row, Radio, Typography, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _get from 'lodash/get';
// Custom
import { useDispatch } from 'hooks/useCustomDispatch';
// Provider
import { bookingCancel } from 'providers/BookingProvider/actions';
// Utils
import { getLanguages } from 'utils/lang';
import { BOOKING_CANCEL } from 'utils/constants';
import { formatYen } from 'utils/stringFormat';
import './styles.less';
import EmergencyCancelModal from './EmergencyCancelModal';
import NailistCancelModal from './NailistCancelModal';

const { Text, Title } = Typography;
const BookingCancelForm = ({ booking, onCallback }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [reasonType, setReasonType] = useState();
  const [loading, setLoading] = useState(false);
  const [isShowEmergencyCancelModal, SetIsShowEmergencyCancelModal] = useState(false);
  const [isShowNailistCancelModal, SetIsShowNailistCancelModal] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const bookingStatus = (booking?.notes || []).filter(status => status.type === 'targetSystemStatus')[0]?.value.toUpperCase();
  const canPayment = (booking?.notes || []).filter(status => status.type === 'canPayment')[0]?.value;
  // TODO: Move this business logic out of view component
  // Constants
  const isAllowedSetNoVisit = bookingStatus === 'WAITING_DONE' || (bookingStatus === 'CONFIRMED' && canPayment);
  const canSelectNoVisit = isAllowedSetNoVisit && bookingStatus === 'WAITING_DONE'; // NL-4006: NoVisit can select when endTime <= current time or booking status = WaitingDone
  const getFieldValueReason = form.getFieldValue('reason');
  const noVisitReason = !getFieldValueReason || getFieldValueReason === 'NO_VISIT';
  // Validate extra text when change reason cancel
  useEffect(() => {
    form.validateFields();
  }, [getFieldValueReason]);

  const renderPopupContentWithNoVisit = () => {
    let customerPay = 0;
    let cancellationFeePercent = 0;
    const { cancellationPolicy = {}, totalPrice = 0 } = booking;
    // Booking with status waitingDone => charge fee: totalPrice*onDay(cancelPolicyOfBookingDetail)
    if (moment().isSame(moment(booking?.startTime), 'days') || bookingStatus === 'WAITING_DONE') {
      const onDay = _get(cancellationPolicy, 'onDay', '0');
      cancellationFeePercent = onDay * 100;
      customerPay = Math.floor(totalPrice * onDay);
    } else {
      const before1Day = _get(cancellationPolicy, 'before1Day', '0');
      cancellationFeePercent = before1Day * 100;
      customerPay = Math.floor(totalPrice * before1Day);
    }
    return (
      <div style={{ marginRight: '32px' }}>
        <div style={{ textAlign: 'center' }}>{lang.confirmCancelBooking}</div>
        {
          noVisitReason &&
          <div style={{ marginTop: 16, borderColor: 'black' }}>
            <div style={{ textAlign: 'center' }}>
              <Title level={5}>{lang.cancellationChargeTitle}</Title>
              {
                cancellationFeePercent === 0
                  ? <Text>{lang.cancellationChargeContentNofee}</Text>
                  : <>
                    <div><Text type="danger" >{lang.cancellationChargeContentHead}</Text></div>
                    <div><Text type="danger" >{lang.cancellationChargeContentMiddle}{ }{cancellationFeePercent}%{lang.cancellationChargeContentTail}</Text></div>
                  </>
              }
            </div>
            <div style={{ marginTop: 8 }} >
              <div style={{ backgroundColor: 'black', borderRadius: 4, marginBottom: 4, padding: 8 }}>
                <span style={{ color: 'white' }}>{ lang.cancellationCharge}</span>
              </div>
              <div style={{ backgroundColor: '#EEEEEE', marginTop: 4, borderRadius: 4, padding: 8 }}>
                <Row gutter={24}>
                  <Col span={18}>
                    <Text type="strong" >{lang.cancellationFee} {cancellationFeePercent}% （{lang.nailistSalesPrice}）</Text>
                  </Col>
                  <Col span={6}style={{ textAlign: 'right' }}>
                    <Text >{ formatYen(customerPay)}</Text>
                  </Col>
                </Row>
                <div style={{ backgroundColor: 'black', height: 1, width: '100%', marginTop: 4, marginBottom: 4 }}/>
                <Row gutter={24}>
                  <Col span={18} style={{ textAlign: 'right' }}>
                    <Text type="danger" >{lang.customerPay}</Text>
                  </Col>
                  <Col span={6} style={{ textAlign: 'right' }}>
                    <Text type="danger">{ formatYen(customerPay)}</Text>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        }
      </div>
    );
  };
  const radioStyle = {
    whiteSpace: 'normal',
    pointerEvents: 'none',
  };
  const onChangeReaSonType = e => {
    setReasonType(e.target.value);
    if (e.target.value === 'NO_VISIT') {
      form.setFieldsValue({ extraReason: undefined });
    }
  };

  return (
    <div className="booking-cancel-form">
      <Spin spinning ={loading}>
        <Form
          form={form}
          name="bookingCancelForm"
          layout="vertical"
          onFinish={(values) => {
            const reason = values.reason;
            if (reason === BOOKING_CANCEL.NO_VISIT) {
              Modal.confirm({
                width: 500,
                centered: true,
                title: lang.confirm,
                icon: <ExclamationCircleOutlined />,
                content: renderPopupContentWithNoVisit(),
                okType: 'danger',
                onOk: () => {
                  setLoading(true);
                  dispatch(bookingCancel({ bookingId: booking?.id, reason, extraReason: undefined, extraInfo: {} }))
                    .then(() => {
                      setLoading(false);
                      onCallback();
                    })
                    .catch(() => setLoading(false));
                },
              });
            } else if (reason === BOOKING_CANCEL.NAILIST_CANCEL) {
              SetIsShowNailistCancelModal(true);
            } else if (reason === BOOKING_CANCEL.EMERGENCY_CANCEL) {
              SetIsShowEmergencyCancelModal(true);
            }
          }}
        >
          <Form.Item
            name="reason"
            label={lang.cancellationDescription}
            rules={[{ required: true, message: lang.emptyCancelReasonError }]}
            style={{ marginBottom: '16px' }}
          >
            <Radio.Group
              onChange={onChangeReaSonType}
              value={reasonType}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div className={`${isAllowedSetNoVisit ? '' : 'no-visit-reason-hidden'}`}>
                <Radio
                  style={radioStyle}
                  value={BOOKING_CANCEL.NO_VISIT}
                  disabled={!canSelectNoVisit}
                >
                  <strong>{lang.customerCancel}</strong>
                  <ul style={{ marginBottom: 0, listStyle: 'none' }}>
                    <li>{lang.customerCancelContent}</li>
                  </ul>
                </Radio>
              </div>
              <Radio style={radioStyle} value={BOOKING_CANCEL.NAILIST_CANCEL}>
                <strong>{lang.nailistCancel}</strong>
                <ul style={{ marginBottom: 0, listStyle: 'none' }}>
                  <li>{lang.nailistCancelContent}</li>
                </ul>
              </Radio>
              <Radio
                style={{ ...radioStyle, marginBottom: '-16px' }}
                value={BOOKING_CANCEL.EMERGENCY_CANCEL}
              >
                <strong>{lang.emergencyCanCel}</strong>
                <ul style={{ marginBottom: 0, listStyle: 'none' }}>
                  <li>{lang.emergencyCancelContent}</li>
                </ul>
              </Radio>
            </Radio.Group>
          </Form.Item>

          <div
            className="ant-modal-footer"
            style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
          >
            <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
              <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  OK
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
      <EmergencyCancelModal
        isShowEmergencyCancelModal={isShowEmergencyCancelModal}
        SetIsShowEmergencyCancelModal={SetIsShowEmergencyCancelModal}
        booking={booking}
        reason={BOOKING_CANCEL.EMERGENCY_CANCEL}
        onCallback={onCallback}
      />
      <NailistCancelModal
        isShowNailistCancelModal={isShowNailistCancelModal}
        SetIsShowNailistCancelModal={SetIsShowNailistCancelModal}
        booking={booking}
        reason={BOOKING_CANCEL.NAILIST_CANCEL}
        onCallback={onCallback}
      />
    </div>
  );
};

BookingCancelForm.propTypes = {
  booking: PropTypes.object,
  onCallback: PropTypes.func,
};

export default BookingCancelForm;
