// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Form, Row, Col, Button, Spin, Input, Space } from 'antd';
import {
  LinkOutlined,
  MailOutlined,
  FormOutlined,
} from '@ant-design/icons';
// import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// Custom
import { useDispatch } from 'hooks/useCustomDispatch';
// Utils
import { getLanguages } from 'utils/lang';
import { changeSalonEmail } from 'providers/SalonProvider/actions';
const { Text } = Typography;

const ChangeEmailModal = ({
  salon,
  isChangeEmailModal,
  setIsChangeEmailModal,
}) => {
  const dispatch = useDispatch();
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();

  // States
  const [loading, setLoading] = useState(false);
  const renderAlertChangedEmailRequest = () => {
    Modal.info({
      width: '700px',
      icon: '',
      centered: true,
      content: (
        <div style={{ fontSize: 24, textAlign: 'center' }}>
          <div><Text>{lang.changedEmailAlertContent1}</Text></div>
          <div> <Text>{lang.changedEmailAlertContent2}</Text></div>
        </div>
      ),
      onOk () { },
    });
  };

  const onChangeEmail = async (values) => {
    try {
      setLoading(true);
      await dispatch(
        changeSalonEmail({ salonId: salon?.id, newEmail: values?.newEmail }),
      ).then(() => {
        setLoading(false);
        handleCloseChangeEmailModal();
        renderAlertChangedEmailRequest();
      });
    } catch (e) {
      console.error(e);
      setTimeout(() => setLoading(false), 1400);
    }
  };

  const handleCloseChangeEmailModal = () => {
    setIsChangeEmailModal(false);
    form.resetFields();
  };

  return (
    <Modal
      title={<Text strong>{lang.changeSalonEmailModalTitle}</Text>}
      visible={isChangeEmailModal}
      onCancel={() => handleCloseChangeEmailModal()}
      destroyOnClose={true}
      width={900}
      centered
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="change-email-modal-form"
          layout='horizontal'
          onFinish={(values) => onChangeEmail(values)}
        >
          <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={9}>
              <div>
                <MailOutlined /> 1.{lang.changeEmailStep1}
              </div>
            </Col>
            <Col span={7}>
              <div>
                <LinkOutlined /> 2.{lang.changeEmailStep2}
              </div>
            </Col>
            <Col span={6}>
              <div>
                <FormOutlined /> 3.{lang.changeEmailStep3}
              </div>
            </Col>
          </Row>
          <div style={{ marginTop: 24, display: 'flex' }}>
            <Form.Item
              name="newEmail"
              style={{ width: 600, marginRight: 16 }}
              validateTrigger={'onBlur'}
              label={lang.changeEmailInput}
              rules={[
                {
                  required: true,
                  message: lang.emptyEmailError,
                },
                {
                  pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                  message: lang.invalidEmailError,
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input style={{ marginRight: 8 }} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="clear" onClick={() => handleCloseChangeEmailModal()}>
                  {lang.cancel}
                </Button>
                <Button type="primary" htmlType="submit">
                  {lang.confirm}
                </Button>
              </Space>
            </Form.Item>
          </div>
          <div>
            <Text style={{ color: '#F02317' }}>{lang.changeEmailWarning}</Text>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

ChangeEmailModal.propTypes = {
  salon: PropTypes.object,
  isChangeEmailModal: PropTypes.func,
  setIsChangeEmailModal: PropTypes.func,
};

export default ChangeEmailModal;
