import {
  BOOKING_GET_STAFFS_BY_SERVICE,
  BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS,
  BOOKING_GET_STAFFS_BY_SERVICE_FAIL,
  BOOKING_DETAIL,
  BOOKING_DETAIL_SUCCESS,
  BOOKING_DETAIL_FAIL,
  BOOKING_LIST,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_FAIL,
  BOOKING_CREATE,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAIL,
  BOOKING_UPDATE,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_ORDER,
  BOOKING_ORDER_SUCCESS,
  BOOKING_ORDER_FAIL,
  BOOKING_DELETE,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_CANCEL,
  BOOKING_CANCEL_SUCCESS,
  BOOKING_CANCEL_FAIL,
  BOOKING_FINISH,
  BOOKING_FINISH_SUCCESS,
  BOOKING_FINISH_FAIL,
  QUICK_PAYMENT,
  QUICK_PAYMENT_SUCCESS,
  QUICK_PAYMENT_FAIL,
  GET_APPS,
  GET_APPS_SUCCESS,
  GET_APPS_FAIL,
  BOOKING_TIPS,
  BOOKING_TIPS_SUCCESS,
  BOOKING_TIPS_FAIL,
  BOOKING_DELETE_TIPS,
  BOOKING_DELETE_TIPS_SUCCESS,
  BOOKING_DELETE_TIPS_FAIL,
  GET_PAYMENT_BYBOOKINGID,
  GET_PAYMENT_BYBOOKINGID_SUCCESS,
  GET_PAYMENT_BYBOOKINGID_FAIL,
  BOOKING_ACCEPTANCE_TIME,
  BOOKING_ACCEPTANCE_TIME_SUCCESS,
  BOOKING_ACCEPTANCE_TIME_FAIL,
  RESET_BOOKING_PROVIDER,
  FETCH_BOOKING_NOTIFICATION_LIST,
  FETCH_BOOKING_NOTIFICATION_LIST_SUCCESS,
  FETCH_BOOKING_NOTIFICATION_LIST_FAIL,
  READ_BOOKING_NOTIFICATION_SUCCESS,
  READ_ALL_BOOKING_NOTIFICATION_SUCCESS,
  BOOKING_LIST_WAITING_DONE_SUCCESS,
} from './types';
import _ from 'lodash';
import BookingNotiHelpers from 'utils/bookingNotiHelpers';
import moment from 'moment-timezone';

const INITIAL_STATE = {
  bookings: [],
  waitingDoneBookings: [],
  doneBookings: [],
  canceledBookings: [],
  notification: {
    oldNumUnread: '0', // Must be string instead of number for init state.
    numUnread: '0',
    list: [],
    data: {},
    pagination: {},
  },
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOOKING_GET_STAFFS_BY_SERVICE:
      return {
        ...state,
      };

    case BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_GET_STAFFS_BY_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_LIST:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_LIST_SUCCESS:
      return {
        ...state,
        bookings: action.payload.bookings,
        doneBookings: _.filter(action.payload.bookings.data, (booking) => (_.find(booking.notes, ['value', 'DONE']))),
        canceledBookings: _.filter(action.payload.bookings.data, (booking) => (_.find(booking.notes, note => ['CANCELED', 'NOVISIT'].includes(note.value)))),
        loading: false,
      };
    case BOOKING_LIST_WAITING_DONE_SUCCESS:
      return {
        ...state,
        waitingDoneBookings: action.payload.bookings.data.filter(bk => moment(bk.endTime).add(48, 'hours').isAfter(moment())),
      };

    case BOOKING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case BOOKING_DETAIL:
      return {
        ...state,
      };

    case BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BOOKING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_CREATE:
      return {
        ...state,
      };

    case BOOKING_CREATE_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_UPDATE:
      return {
        ...state,
      };

    case BOOKING_UPDATE_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_ORDER:
      return {
        ...state,
      };

    case BOOKING_ORDER_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_DELETE:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BOOKING_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case BOOKING_CANCEL:
      return {
        ...state,
      };

    case BOOKING_CANCEL_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_CANCEL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_FINISH: case BOOKING_FINISH_SUCCESS: case BOOKING_FINISH_FAIL:
      return {
        ...state,
      };

    case QUICK_PAYMENT: case QUICK_PAYMENT_SUCCESS: case QUICK_PAYMENT_FAIL:
      return {
        ...state,
      };

    case GET_APPS: case GET_APPS_SUCCESS: case GET_APPS_FAIL:
      return {
        ...state,
      };
    case BOOKING_TIPS: case BOOKING_TIPS_SUCCESS: case BOOKING_TIPS_FAIL:
      return {
        ...state,
      };
    case BOOKING_DELETE_TIPS: case BOOKING_DELETE_TIPS_SUCCESS: case BOOKING_DELETE_TIPS_FAIL:
      return {
        ...state,
      };
    case GET_PAYMENT_BYBOOKINGID: case GET_PAYMENT_BYBOOKINGID_SUCCESS: case GET_PAYMENT_BYBOOKINGID_FAIL:
      return {
        ...state,
      };
    case BOOKING_ACCEPTANCE_TIME: case BOOKING_ACCEPTANCE_TIME_SUCCESS: case BOOKING_ACCEPTANCE_TIME_FAIL:
      return {
        ...state,
      };
    case RESET_BOOKING_PROVIDER:
      return {
        ...INITIAL_STATE,
        notification: state.notification,
      };

    case FETCH_BOOKING_NOTIFICATION_LIST:
      return {
        ...state,
      };
    case FETCH_BOOKING_NOTIFICATION_LIST_SUCCESS: {
      let numUnread = 0;
      const allData = {
        ...state.notification.data,
        ...(_.keyBy(action.payload.data.map(o => {
          const bookingStatus = (o?.booking?.notes || []).filter(note => note.type === 'targetSystemStatus')[0]?.value.toUpperCase();
          const { isImportBooking } = o?.booking?.extraInfo;
          o.activityEnum = BookingNotiHelpers.mapNotiToEnum(o.activity, bookingStatus, isImportBooking);

          return o;
        }), 'id')),
      };

      let list = [];
      if (action.payload.addHead === true) {
        list = action.payload.data.map(r => r.id).concat(state.notification.list);
      } else {
        list = state.notification.list.concat(action.payload.data.map(r => r.id));
      }

      list = list.filter(id => allData[id]?.activityEnum !== 'NEED_FILTERED');
      list = _.uniq(list);

      // count unread notification by list filtered status required
      _.forEach(list, id => {
        if (allData[id].isRead === false) {
          numUnread++;
        }
      });

      return {
        ...state,
        notification: {
          oldNumUnread: state.notification.numUnread,
          numUnread: numUnread,
          list: list,
          data: allData,
          pagination: action.payload.addHead ? state.notification.pagination : action.payload.pagination,
        },
      };
    }
    case FETCH_BOOKING_NOTIFICATION_LIST_FAIL:
      return {
        ...state,
      };
    case READ_BOOKING_NOTIFICATION_SUCCESS:
      // Update number of read/unread booking notification
      if (state.notification.data[action.payload.id].isRead === false) {
        state.notification.numUnread = state.notification.numUnread - 1;
        state.notification.oldNumUnread = state.notification.numUnread;
        state.notification.data[action.payload.id].isRead = true;
      }
      return {
        ...state,
      };
    case READ_ALL_BOOKING_NOTIFICATION_SUCCESS: {
      const allData = state.notification.data;
      _.forEach(allData, d => { d.isRead = true; });
      state.notification.data = allData;
      state.notification.numUnread = 0;
      return {
        ...state,
      };
    }

    case 'persist/REHYDRATE':
      return {
        ...state,
        notification: INITIAL_STATE.notification,
      };
    default:
      return state;
  }
};
