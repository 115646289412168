import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button, Select, Divider, Spin } from 'antd';
import { getLanguages } from 'utils/lang';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSelector } from 'react-redux';

import { bookingAcceptanceTime } from 'providers/BookingProvider/actions';
import _get from 'lodash/get';
import { salonSettingsGet } from 'providers/SalonProvider/actions';

const SalonAcceptanceTimeForm = ({ salon, onCallback }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };
  const [acceptanceTimeValue, setAcceptanceTimeValue] = useState();
  const locale = useSelector(state => state.i18n.locale);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    dispatch(salonSettingsGet())
      .then(result => {
        setAcceptanceTimeValue(_get(result, 'data.acceptBookingTime', []));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Form
      {...layout}
      style={{ width: '100%' }}
      form={form}
      name="acceptanceTimeForm"
      initialValues={{
        acceptanceDate: salon?.acceptBookingTime?.maximum,
        acceptanceTime: salon?.acceptBookingTime?.minimum,
      }}
      onFinish={(values) => {
        dispatch(bookingAcceptanceTime({ values, salonId: salon.id }))
          .then((result) => {
            onCallback(result);
          })
          .catch((error) => {
            console.log('Oops!', error);
          });
      }}
    >
      <Row gutter={24} >
        <Col span={24}>
          <div style={{ marginTop: 16 }}><h2>{lang.acceptanceTimeTitle}</h2></div>
          <div style={{ marginBottom: 8 }}>{lang.acceptanceTimeContent}</div>
          <Form.Item
            name="acceptanceDate"
            label={lang.acceptanceDateLabel}
            colon={false}
          >
            <Select
              placeholder={lang.acceptanceDateLabel}
              style={{ width: '50%' }}
            >
              {acceptanceTimeValue?.maximumValues.map(item => <Option key={item.order} value={item.value}>{ locale === 'en' ? item.titles.en : item.titles.ja}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            name="acceptanceTime"
            label={lang.acceptanceTimeLabel}
            colon={false}
          >
            <Select
              placeholder={lang.acceptanceTimeLabel}
              style={{ width: '50%' }}
            >
              {acceptanceTimeValue?.minimumValues.map(item => <Option key={item.order} value={item.value}>{ locale === 'en' ? item.titles.en : item.titles.ja}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
        <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            {lang.saveAndPublishSettings}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
SalonAcceptanceTimeForm.propTypes = {
  salon: PropTypes.object,
};
export default SalonAcceptanceTimeForm;
