import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row, Select, Input, Tag, Typography } from 'antd';
import { formatYen } from 'utils/stringFormat';
import PaymentBillingForm from './PaymentBillingForm';
import moment from 'moment';
import { ExclamationCircleOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import './styles.less';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useTranslation } from 'react-i18next';
import { paymentRefund } from 'providers/PaymentProvider/actions';
import { getLanguages } from 'utils/lang';
import { BOOKING_CANCELED_STATUSES, PAYMENT_STATUS, PAYMENT_METHOD } from 'utils/constants';
import { getSalonSalesAmount, getCustomerPayAmount } from 'utils/sales';

const { Text } = Typography;

const PaymentDetailForm = ({ booking, paymentDetail, onCallback, token }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isOpenBillingModal, SetIsOpenBillingModal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isOpenRefundModal, setisOpenRefundModal] = useState(false);
  const [statusPayment, setStatusPayment] = useState(paymentDetail?.status?.id);
  const [notes, setNotes] = useState();
  const [noteCategory, setNoteCategory] = useState();
  const [statusRefund, setStatusRefund] = useState(paymentDetail?.status?.id);

  const phonetic = _.get(paymentDetail, 'customer.phonetic');
  const name = _.get(paymentDetail, 'customer.name');
  const customerPhonetic = phonetic ? `（${phonetic}）` : '';
  const customerName = name ? name + customerPhonetic : '---';
  const targetSystemStatus = (booking.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = targetSystemStatus?.value || '';
  const isCanceled = BOOKING_CANCELED_STATUSES.includes(bookingStatus);

  const renderSubtotal = useCallback(() => {
    const { totalPrice = 0, totalDuration = 0 } = booking;

    return (
      <Row>
        <Col sm={{ span: 18 }} xs={{ span: 18 }}>
          {lang.subtotal}
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 6 }} style={{ textAlign: 'right' }}>
          <strong>{totalDuration} {lang.min}/{formatYen(totalPrice)}</strong>
        </Col>
      </Row>
    );
  }, [booking]);

  const renderCancellationFee = useCallback(() => {
    if (!isCanceled) {
      return null;
    }

    const { cancellationFee = 0 } = booking;
    return (
      <Row className="payment-detail-row">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Text type="danger">{lang.cancellationFee}</Text>
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Text type="danger">{cancellationFee * 100}%</Text>
        </Col>
      </Row>
    );
  }, [booking, isCanceled]);

  const renderDiscount = useCallback(() => {
    const { discount = 0 } = booking;
    /* If booking is canceled, do not show discount/additional price */
    if (isCanceled || discount === 0) {
      return null;
    }

    return (
      <Row className="payment-detail-row">
        <Col xs={{ span: 18 }}>
          {lang.discount}
        </Col>
        <Col xs={{ span: 6 }} style={{ textAlign: 'right' }}>
          <span style={{ fontWeight: 'bold' }}>{formatYen(discount * (-1))}</span>
        </Col>
      </Row>
    );
  }, [booking, isCanceled]);

  const renderAdditionalPrice = useCallback(() => {
    const { additionalPrice = 0 } = booking;
    /* If booking is canceled, do not show discount/additional price */
    if (isCanceled || additionalPrice === 0) {
      return null;
    }

    return (
      <Row className="payment-detail-row">
        <Col xs={{ span: 18 }}>
          {lang.additionalPrice}
        </Col>
        <Col xs={{ span: 6 }} style={{ textAlign: 'right' }}>
          <span style={{ fontWeight: 'bold' }}>{formatYen(additionalPrice)}</span>
        </Col>
      </Row>
    );
  }, [booking, isCanceled]);

  const renderSalonSales = useCallback(() => {
    const total = getSalonSalesAmount(booking);

    return (
      <Row className="payment-detail-row">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>{lang.total}</Text>
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>{formatYen(total || 0)}</Text>
        </Col>
      </Row>
    );
  }, [booking]);

  const renderCustomerPay = useCallback(() => {
    const amount = getCustomerPayAmount(booking);

    return (
      <Row className="payment-detail-row">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>{lang.customerPay}</Text>
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>{formatYen(amount)}</Text>
        </Col>
      </Row>
    );
  }, [booking]);

  return (
    <Form
      form={form}
      name="paymentDetail"
      onFinish={() => {
        // onCallback()
      }}
    >
      <div className="payment-detail-form">
        <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: 16, marginTop: -16 }} >
          <Col span={11}>
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>{lang.paymentInfo}</div>
            <Row gutter={12}>
              <Col span={10} style={{ textAlign: 'left', color: '#A8A8A8' }}>
                <div>{lang.paymentID}</div>
                <div>{lang.bookingID}</div>
                <div>{lang.statusUpdate}</div>
                <div>{lang.status}</div>
              </Col>
              <Col span={14} style={{ textAlign: 'right' }}>
                <div>{_.get(paymentDetail, 'id')}</div>
                <div>{_.get(booking, 'sourceId')}</div>
                <div>{`${moment(_.get(paymentDetail, 'updatedAt')).format('HH:mm YYYY-MM-DD')}`}</div>
                <span>
                  <Tag color={PAYMENT_STATUS[statusPayment].color}>{lang[PAYMENT_STATUS[statusPayment].langKey]}</Tag>
                </span>

              </Col>
            </Row>
          </Col>

          <Col lg={{ span: 1 }} xs={{ span: 24 }}>
            <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '122%', marginTop: -8 }} />
          </Col>
          <Col span={11} >
            <div style={{ fontSize: 14, fontWeight: 'bold' }}>{lang.customerInfo}</div>
            <Row gutter={12}>
              <Col span={10} style={{ textAlign: 'left', color: '#A8A8A8' }}>
                <div>{lang.customerName}</div>
              </Col>
              <Col span={14} style={{ textAlign: 'left' }}>
                <div>{customerName}</div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={10} style={{ textAlign: 'left', color: '#A8A8A8' }}>
                <div>{lang.gender}</div>
              </Col>
              <Col span={14} style={{ textAlign: 'left' }}>
                <div>{_.get(paymentDetail, 'customer.gender') === 0 ? lang.male : lang.female}</div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={10} style={{ textAlign: 'left', color: '#A8A8A8' }}>
                <div>{lang.phone}</div>
              </Col>
              <Col span={14} style={{ textAlign: 'left' }}>
                <div>{_.get(paymentDetail, 'customer.phoneNumber')}</div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={10} style={{ textAlign: 'left', color: '#A8A8A8' }}>
                <div>{lang.email}</div>
              </Col>
              <Col span={14} style={{ textAlign: 'left' }}>
                <div>{_.get(paymentDetail, 'customer.email')}</div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div style={{ height: 1, width: '100%', backgroundColor: '#D7D7D7', margin: '2px auto' }}></div>
        <div style={{ fontWeight: 'bold', fontSize: 13, marginTop: 8 }}><MoneyCollectOutlined /> {lang[PAYMENT_METHOD[paymentDetail?.method?.name.toUpperCase()].langKey]}</div>
        {renderSubtotal()}
        {renderAdditionalPrice()}
        {renderCancellationFee()}
        <div style={{ height: 1, width: '100%', backgroundColor: '#D7D7D7', margin: '2px auto' }}></div>
        {renderSalonSales()}
        {renderDiscount()}
        <div style={{ height: 1, width: '100%', backgroundColor: '#D7D7D7', margin: '2px auto' }}></div>
        {renderCustomerPay()}

        {
          _.get(paymentDetail, 'notes') &&
          <Row style={{ marginTop: 8 }}>
            <Col span={12}>
              <span style={{ color: 'red', textAlign: 'left', marginRight: 4 }}>
                {lang.refundReason}:
              </span>
              <span style={{ color: 'red' }}>{_.get(paymentDetail, 'notes')}</span>
              <div> {_.get(paymentDetail, 'noteCategory')}</div>
            </Col>
          </Row>
        }
        {
          (notes || noteCategory) &&
          <Row style={{ marginTop: 8 }}>

            <Col span={12}>
              <div style={{ color: 'red', textAlign: 'right', marginRight: 4 }}>
                {lang.refundReason}:
              </div>
              <div style={{ color: 'red' }}>{notes}</div>
              <div>{noteCategory}</div>
            </Col>
          </Row>
        }
        {
          (_.get(paymentDetail, 'status.id') === 1) &&
          <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -16, marginBottom: -24, marginTop: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() =>
                SetIsOpenBillingModal(true)}
            >
              {lang.processPayment}
            </Button>
          </div>
        }
        { // not show refund button with booking success of target system
          (statusRefund === 2 && !paymentDetail?.systemId && paymentDetail?.method?.code === 'CASH') &&
          <>
            <div style={{ height: 1, width: '107%', backgroundColor: '#D7D7D7', marginBottom: 8, marginLeft: -24, marginTop: 8 }}></div>
            <div style={{ marginBottom: -4 }} >
              <Button
                type="gost"
                style={{ color: 'red', borderColor: 'red' }}
                onClick={() => setisOpenRefundModal(true)}
              >
                {lang.refund}
              </Button>
            </div>
          </>
        }

      </div>
      <Modal
        visible={isOpenBillingModal}
        title={lang.billing}
        onCancel={() => {
          if (!isComplete) {
            Modal.confirm({
              title: lang.confirm,
              icon: <ExclamationCircleOutlined />,
              content: lang.confirmCancelBilling,
              okText: lang.sure,
              cancelText: lang.no,
              onOk: () => {
                // onCallback();
                SetIsOpenBillingModal(false);
              },
            });
          } else {
            onCallback();
            SetIsOpenBillingModal(false);
          }
        }}
        destroyOnClose={true}
        width={830}
        centered
        footer={null}
      >
        <PaymentBillingForm
          isComplete={isComplete}
          setIsComplete={setIsComplete}
          booking={booking}
          paymentDetail={paymentDetail}
          onClose={() => SetIsOpenBillingModal(false)}
          onCallback={() => {
            onCallback();
            SetIsOpenBillingModal(false);
          }}
        />
      </Modal>
      <Modal
        visible={isOpenRefundModal}
        title={lang.refundReason}
        onCancel={() => {
          setisOpenRefundModal(false);
        }}
        destroyOnClose={true}
        width={480}
        centered
        footer={null}

      >
        <Form
          form={form}
          name="refundForm"
          onFinish={values => {
            values.paymentId = paymentDetail?.id;
            dispatch(paymentRefund({ values }))
              .then(result => {
                const { status } = result?.data?.status;
                setStatusPayment(status);
                setNotes(result?.data?.notes);
                setNoteCategory(result?.data?.noteCategory);
                setStatusRefund();
                onCallback();
                setisOpenRefundModal(false);
              });
          }}

        >
          <div className="refund-billing-form">
            <Form.Item
              label={lang.reason}
              name="notes"
              labelCol={{ span: 8, offset: 0 }}
              rules={[
                {
                  required: true,
                  message: lang.emptyRefundReasonError,
                },
              ]}

            >
              <Select
                style={{ width: '100%' }}
                placeholder={lang.refundReasonPlaceholder}
              >
                <Select.Option value={lang.customerUnsatisfied}> {lang.customerUnsatisfied}</Select.Option>
                <Select.Option value={lang.badDesign}> {lang.badDesign}</Select.Option>
                <Select.Option value={lang.badServices}> {lang.badServices}</Select.Option>
              </Select>

            </Form.Item>
            <Form.Item
              label={lang.note}
              name="noteCategory"
              labelCol={{ span: 8, offset: 0 }}
            >
              <Input.TextArea maxLength={200}/>
            </Form.Item>
            <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
              >
                OK
              </Button>
            </div>

          </div>
        </Form>
      </Modal>
    </Form >
  );
};

PaymentDetailForm.propTypes = {
  booking: PropTypes.string,
  paymentDetail: PropTypes.object,
  onCallback: PropTypes.func,
  token: PropTypes.any,
};

export default PaymentDetailForm;
