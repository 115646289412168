import {
  PAYMENT_CREATE,
  PAYMENT_CREATE_FAIL,
  PAYMENT_CREATE_SUCCESS,
  PAYMENT_LIST,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_SUMMARY,
  PAYMENT_SUMMARY_FAIL,
  PAYMENT_SUMMARY_SUCCESS,
  PAYMENT_COMPLETION,
  PAYMENT_COMPLETION_FAIL,
  PAYMENT_COMPLETION_SUCCESS,
  PAYMENT_DETAIL,
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAIL,
  PAYMENT_REFUND,
  PAYMENT_REFUND_SUCCESS,
  PAYMENT_REFUND_FAIL,
  PAYMENT_TIPS,
  PAYMENT_TIPS_SUCCESS,
  PAYMENT_TIPS_FAIL,
} from './types';

export function paymentList (payload = {}) {
  return { type: PAYMENT_LIST, payload, meta: { thunk: true } };
}
export function paymentListSuccess (payload = {}, meta) {
  return { type: PAYMENT_LIST_SUCCESS, payload, meta };
}
export function paymentListFail (payload = {}, meta) {
  return { type: PAYMENT_LIST_FAIL, payload, meta, error: true };
}

export function paymentCreate (payload = {}) {
  return { type: PAYMENT_CREATE, payload, meta: { thunk: true } };
}
export function paymentCreateSuccess (payload = {}, meta) {
  return { type: PAYMENT_CREATE_SUCCESS, payload, meta };
}
export function paymentCreateFail (payload = {}, meta) {
  return { type: PAYMENT_CREATE_FAIL, payload, meta, error: true };
}

export function paymentSummary (payload = {}) {
  return { type: PAYMENT_SUMMARY, payload, meta: { thunk: true } };
}
export function paymentSummarySuccess (payload = {}, meta) {
  return { type: PAYMENT_SUMMARY_SUCCESS, payload, meta };
}
export function paymentSummaryFail (payload = {}, meta) {
  return { type: PAYMENT_SUMMARY_FAIL, payload, meta, error: true };
}

export function paymentCompletion (payload = {}) {
  return { type: PAYMENT_COMPLETION, payload, meta: { thunk: true } };
}
export function paymentCompletionSuccess (payload = {}, meta) {
  return { type: PAYMENT_COMPLETION_SUCCESS, payload, meta };
}
export function paymentCompletionFail (payload = {}, meta) {
  return { type: PAYMENT_COMPLETION_FAIL, payload, meta, error: true };
}

export function paymentDetail (payload = {}) {
  return { type: PAYMENT_DETAIL, payload, meta: { thunk: true } };
}
export function paymentDetailSuccess (payload = {}, meta) {
  return { type: PAYMENT_DETAIL_SUCCESS, payload, meta };
}
export function paymentDetailFail (payload = {}, meta) {
  return { type: PAYMENT_DETAIL_FAIL, payload, meta, error: true };
}

export function paymentRefund (payload = {}) {
  return { type: PAYMENT_REFUND, payload, meta: { thunk: true } };
}
export function paymentRefundSuccess (payload = {}, meta) {
  return { type: PAYMENT_REFUND_SUCCESS, payload, meta };
}
export function paymentRefundFail (payload = {}, meta) {
  return { type: PAYMENT_REFUND_FAIL, payload, meta, error: true };
}

export function paymentTip (payload = {}) {
  return { type: PAYMENT_TIPS, payload, meta: { thunk: true } };
}
export function paymentTipSuccess (payload = {}, meta) {
  return { type: PAYMENT_TIPS_SUCCESS, payload, meta };
}
export function paymentTipFail (payload = {}, meta) {
  return { type: PAYMENT_TIPS_FAIL, payload, meta, error: true };
}
