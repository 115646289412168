import _ from 'lodash';
import moment from 'moment';

const FormatSaleReportBookingStatus = (data) => {
  data.data = data.data.map(result => {
    const CANCELED_STATUS = ['CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'];
    const NAILIST_CANCELED_STATUS = ['NAILIST_CANCELED', 'DECLINED_RESERVATION'];
    const statusNotesIndex = (result.booking.notes || []).findIndex(note => note.type === 'targetSystemStatus');
    // Canceled includes customer's CANCELED_RESERVATION and CARD_ERROR_CANCEL
    if (CANCELED_STATUS.includes(_.get(result, `booking.notes[${statusNotesIndex}].value`))) {
      _.set(result, `booking.notes[${statusNotesIndex}].value`, 'CANCELED');
    }
    // Nailist canceled includes NAILIST_CANCELED and DECLINED_RESERVATION
    if (NAILIST_CANCELED_STATUS.includes(_.get(result, `booking.notes[${statusNotesIndex}].value`))) {
      _.set(result, `booking.notes[${statusNotesIndex}].value`, 'NAILIST_CANCELED');
    }

    // Format data for show booking confirmed imported after join salon
    const extraServices = result?.booking?.extraInfo?.services || [];
    const orders = result?.booking?.orders || [];
    // The menu list in the booking detail is reverse order with Nailie App
    result.booking.orders = result.booking.orders.reverse();
    if (extraServices) {
      const newOrders = orders.map((order, index) => ({
        ...order,
        ...extraServices[index],
      }));
      result.booking.orders = newOrders;
    }

    return result;
  });
};

const formatImportedBookingIntoNormalOrder = (booking) => {
  const extraServices = booking.extraInfo.services;
  const orders = booking.orders;

  // The menu list in the booking detail is reverse order with Nailie App
  booking.orders = booking.orders.reverse();

  // Format data for show booking confirmed imported after join salon
  if (extraServices) {
    const newOrders = orders.map((order, index) => ({
      ...order,
      ...extraServices[index],
    }));
    booking.orders = newOrders;
  }
  const duration = (booking.orders || []).reduce((total, order) => total + order.duration, 0);
  booking.duration = duration;

  booking.startTime = moment(booking.orders[0].startTime);
  booking.endTime = moment(booking.startTime).add(booking.duration, 'm');

  return booking;
};

const BookingStatusHelper = {
  FormatSaleReportBookingStatus: FormatSaleReportBookingStatus,
  formatImportedBookingIntoNormalOrder: formatImportedBookingIntoNormalOrder,
};
export default BookingStatusHelper;
