// Libraries
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, Form, Card, Row, Col, Button, Spin, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import _get from 'lodash/get';
// Components
import ChangePriceModal from './change-price-modal.component';
import BookingDetailInvoice from './booking-detail-invoice.component';
// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
// Providers
import { bookingFinish } from 'providers/BookingProvider/actions';
// Utils
import { getLanguages } from 'utils/lang';
import { BOOKING_CANCELED_STATUSES } from 'utils/constants';
import CustomerInfo from '../Customer/CustomerInfo';

const { Text } = Typography;
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

const PaymentSummaryCompleteBookingModal = ({
  originalBooking,
  paymentSummaryCompleteBookingModal,
  closePaymentSummaryCompleteBookingModal,
  handleCloseBookingDetailModal,
  onCallbackBookingDetailModal,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // State
  const [changePriceModal, setChangePriceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState(originalBooking || {});
  const locale = useSelector((state) => state.i18n.locale);

  useEffect(() => {
    setBooking(originalBooking);
  }, [originalBooking]);

  // Constants
  const canPayment = (booking.notes || []).find(note => note.type === 'canPayment');
  const targetSystemStatus = (booking.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = _get(targetSystemStatus, 'value');
  const enabledChangePrice = bookingStatus === 'WAITING_DONE' || (bookingStatus === 'CONFIRMED' && _get(canPayment, 'value'));
  const isCanceled = BOOKING_CANCELED_STATUSES.includes(bookingStatus);
  const isCanceledOrDone = isCanceled || bookingStatus === 'DONE';
  const isConfirmed = bookingStatus === 'CONFIRMED';
  const [isCustomerOnTime, setIsCustomerOnTime] = useState([true, false]); // default: yes

  const renderBookingDateTime = () => {
    const startTime = moment(booking.startTime);
    const endTime = startTime.clone().add(booking.totalDuration, 'minutes');
    let bookingDate = 'YYYY-MM-DD';
    let bookingTime = 'HH:mm - HH:mm';
    if (booking.startTime) {
      if (locale === 'en') {
        bookingDate = startTime.format('YYYY-MM-DD');
      } else {
        bookingDate = startTime.format('YYYY年MM月DD日');
      }
      bookingTime = `${startTime.format('HH:mm')} ~ ${endTime.format('HH:mm')}`;
    }

    return (
      <div>
        <Text type="secondary">{lang.bookingDate}: </Text> {bookingDate} {bookingTime}
      </div>
    );
  };

  const renderChangePriceButton = useCallback(() => {
    return (
      <Button
        danger
        onClick={() => setChangePriceModal(true)}
        disabled={!enabledChangePrice}
      >
        {lang.changePrice}
      </Button>
    );
  });
  const renderConfirmCustomerOnTime = () => {
    return (
      <div>
        <span style={{ marginRight: 36 }}>{lang.confirmArriveOnTime}</span>
        <span id='button-confirm-on-time'>
          <Button
            size='small'
            className={`${isCustomerOnTime[0] ? 'active' : ''} `}
            onClick={() => setIsCustomerOnTime([true, false])}
          >
            {lang.yes}
          </Button>
          <Button
            size='small'
            className={`${isCustomerOnTime[1] ? 'active' : ''} `}
            onClick={() => setIsCustomerOnTime([false, true])}
          >
            {lang.no}
          </Button>
        </span>
      </div>
    );
  };

  const renderCompleteBookingButton = () => {
    return (
      <Button
        type="primary"
        onClick={() => {
          Modal.confirm({
            title: lang.confirm,
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: lang.ConfirmCompleteBooking,
            okText: lang.sure,
            cancelText: lang.no,
            onOk: () => {
              setLoading(true);
              const body = !booking?.newPrice ? {} : {
                totalPrice: booking?.newPrice,
                changePriceReason: booking?.changePriceReason,
              };
              body.extraInfo = {
                isLated: isCustomerOnTime[0] ? undefined : true,
              };

              dispatch(bookingFinish({
                bookingId: booking.id,
                body,
              }))
                .then(() => {
                  closePaymentSummaryCompleteBookingModal();
                  handleCloseBookingDetailModal();
                  onCallbackBookingDetailModal();
                  setLoading(false);
                })
                .catch(() => setLoading(false));
            },
          });
        }}
      >
        {lang.completeBooking}
      </Button>
    );
  };

  return (
    <Modal
      visible={paymentSummaryCompleteBookingModal}
      title={lang.paymentSummary}
      onCancel={() => {
        setBooking(originalBooking);
        closePaymentSummaryCompleteBookingModal();
      }}
      destroyOnClose={true}
      width={640}
      centered
      footer={(
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={8} style={{ textAlign: 'left' }}>
          </Col>
          <Col className="gutter-row" span={16} style={{ textAlign: 'right' }}>
            {renderChangePriceButton()}
            {renderCompleteBookingButton()}
          </Col>
        </Row>
      )}
      maskClosable={false}
    >
      <Spin spinning ={loading}>
        <Form
          {...layout}
          form={form}
          name="PaymentSummaryCompleteBooking"
        >
          <div className="booking-detail">
            <Row gutter={24}>
              <CustomerInfo booking={booking} isConfirmed={isConfirmed} isCanceled={isCanceled}/>
            </Row>
            <Row className="row-divider">{renderBookingDateTime()}</Row>
            <Card >
              <BookingDetailInvoice booking ={booking}/>
            </Card>
            <Row className="row-divider">{renderConfirmCustomerOnTime()}</Row>
          </div>

        </Form>
      </Spin>
      <ChangePriceModal
        booking={booking}
        changePriceModal={changePriceModal}
        closeChangePriceModal={() => setChangePriceModal(false)}
        onCallbackChangePriceModal={({ newPrice, changePriceReason }) => {
          setChangePriceModal(false);
          if (isCanceledOrDone) { return; }
          setBooking({
            ...booking,
            additionalPrice: newPrice - booking.totalPrice,
            finalPrice: newPrice - booking.discount,
            newPrice: newPrice !== booking?.totalPrice ? newPrice : undefined,
            changePriceReason: newPrice !== booking?.totalPrice ? changePriceReason : undefined,
          });
        }}
      />
    </Modal>
  );
};

PaymentSummaryCompleteBookingModal.propTypes = {
  PaymentSummaryCompleteBookingModal: PropTypes.func,
  paymentSummaryCompleteBookingModal: PropTypes.bool,
  closePaymentSummaryCompleteBookingModal: PropTypes.func,
  booking: PropTypes.object,
  handleCloseBookingDetailModal: PropTypes.func,
  onCallbackBookingDetailModal: PropTypes.func,
  closeChangePriceModal: PropTypes.func,
  onCallbackChangePriceModal: PropTypes.func,
};
export default PaymentSummaryCompleteBookingModal;
