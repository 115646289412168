import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useToken } from 'hooks/useToken';
import { useDispatch } from 'hooks/useCustomDispatch';
import { Button, Form, Input, Col, Row, Select, InputNumber, Radio } from 'antd';
import ImageField from 'components/Desktop/Commons/ImageField';
import { serviceCreate, serviceUpdate } from 'providers/ServiceProvider/actions';
import './styles.less';
import { MENU_REPEAT_TYPE } from 'utils/constants';
const { Option } = Select;

const durations = [];
for (let i = 1; i <= 24; i++) {
  durations.push(`${i * 10}`);
}

const ServiceManageForm = ({ onCancel, service }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const token = useToken();
  const dispatch = useDispatch();
  const { categories = [] } = useSelector((state) => state.category);
  if (!service.photo) {
    service.photo = '';
  }
  const [category, setCategory] = useState(service.categoryId ? categories.find(c => c.id === service.categoryId) : {});
  const repeatTypeValue = service?.extraInfo?.repeatType || MENU_REPEAT_TYPE.ALL.value;
  const initialValues = { ...service, repeatType: repeatTypeValue };
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  let priceExtraPromptMessage = '';
  if (category.minPrice) {
    priceExtraPromptMessage += lang.minPriceExtraPromptMessageHead;
    priceExtraPromptMessage += category.minPrice;
    priceExtraPromptMessage += lang.minPriceExtraPromptMessageTail;
  }
  if (category.maxPrice) {
    priceExtraPromptMessage += ' ';
    priceExtraPromptMessage += lang.maxPriceExtraPromptMessageHead;
    priceExtraPromptMessage += category.maxPrice;
    priceExtraPromptMessage += lang.maxPriceExtraPromptMessageTail;
  }

  return (
    <Form
      {...layout}
      form={form}
      validateTrigger={['onBlur', 'onChange']}
      name="serviceManageForm"
      onFinish={values => {
        const { id } = service;
        setLoading(true);
        if (id) {
          dispatch(serviceUpdate({ id, values, token })).then(() => onCancel()).catch(() => setLoading(false));
        } else {
          dispatch(serviceCreate({ values, token })).then(() => onCancel()).catch(() => setLoading(false));
        }
      }}
      initialValues={initialValues}
    >
      {/* Service category */}
      <Form.Item
        name='categoryId'
        rules={[{ required: true, message: lang.emptyServiceCategoryError }]}
        label={lang.menuCategory}
      >
        <Select
          showSearch
          placeholder={lang.menuCategory}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={value => setCategory(categories.find(category => category.id === value))}
        >
          {categories.map((item) => {
            return (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            );
          })}
        </Select>
      </Form.Item>
      {/* Menu Repeat Type */}
      <Form.Item
        name='repeatType'
        rules={[{ required: true, message: lang.emptyServiceNameError }]}
        wrapperCol={{ span: 12, offset: 6 }}
        style={{ marginBottom: 8, marginTop: -16 }}
      >
        <Radio.Group >
          <Radio value={MENU_REPEAT_TYPE.NEW.value}>{lang[MENU_REPEAT_TYPE.NEW?.langEditText]}</Radio>
          <Radio value={MENU_REPEAT_TYPE.REPEATER.value}>{lang[MENU_REPEAT_TYPE.REPEATER?.langEditText]}</Radio>
          <Radio value={MENU_REPEAT_TYPE.ALL.value}>{lang[MENU_REPEAT_TYPE.ALL?.langEditText]}</Radio>
        </Radio.Group>
      </Form.Item>
      {/* Service name */}
      <Form.Item
        name='name'
        rules={[{ required: true, message: lang.emptyServiceNameError }]}
        label={lang.menuName}
      >
        <Input placeholder={lang.menuName} maxLength={40} />
      </Form.Item>

      {/* Duration */}
      <Form.Item
        name='duration'
        rules={[{ required: true, message: lang.emptyDurationError }]}
        label={`${lang.duration} (${lang.min})`}
      >
        <Select
          showSearch
          placeholder={lang.duration}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {durations.map(duration => (
            <Option key={duration} value={duration}>{duration}</Option>
          ))}
        </Select>
      </Form.Item>

      {/* Price */}
      <Form.Item
        name='price'
        rules={[
          { required: true, message: '' },
          () => ({
            validator (rule, value) {
              if (!_.isNil(value)) {
                if (!_.isNil(category.minPrice) && value < category.minPrice) {
                  return Promise.reject(new Error(`${lang.minPriceExtraPromptMessageHead}${category.minPrice}${lang.minPriceExtraPromptMessageTail}`));
                }
                if (!_.isNil(category.maxPrice) && value > category.maxPrice) {
                  return Promise.reject(new Error(`${lang.maxPriceExtraPromptMessageHead}${category.maxPrice}${lang.maxPriceExtraPromptMessageTail}`));
                }
              } else {
                return Promise.reject(new Error(lang.emptyPriceError));
              }
              return Promise.resolve();
            },
          }),
        ]}
        label={lang.priceIncludedTax}
        extra={priceExtraPromptMessage}
      >
        <InputNumber placeholder={lang.priceIncludedTax} style={{ width: '100%' }} />
      </Form.Item>

      {/* Description */}
      <Form.Item
        name='description'
        label={`${lang.menuDetail} (${lang.optional})`}
      >
        <Input.TextArea placeholder={lang.menuDetailPlaceholder} maxLength={100} />
      </Form.Item>

      {/* Photo */}
      <Form.Item
        name='photo'
        label={lang.menuImage}
        extra={lang.photoExtraPromptMessage}
      >
        <ImageField maxLength={1} aspect={1} token={token} />
      </Form.Item>

      {/* Submit */}
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {service.id ? lang.updateMenu : lang.addMenu}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ServiceManageForm.propTypes = {
  categoryId: PropTypes.string,
  onCancel: PropTypes.func,
  service: PropTypes.object,
};

export default ServiceManageForm;
