import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Radio, DatePicker, Col, Row, Button } from 'antd';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import _ from 'lodash';
import { updateStaffProfile } from 'providers/StaffProvider/actions';
import { getLanguages } from 'utils/lang';
import ImageField from '../Commons/ImageField';

const isUnderage = (date) => moment().diff(date, 'year') < 18;

const StaffUpdateForm = ({ onCancel, staffDetail, onCallback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const id = _.get(staffDetail, 'id');
  const staffDetailData = staffDetail?.connections[0]?.data || {};
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const lang = getLanguages(t);

  return (

    <Fragment>
      {
        !staffDetail && <Navigate to="/mysalon/staff-member" replace={true} />
      }
      <Form
        form={form}
        {...layout}
        name="staffEditForm"
        onFinish={values => {
          dispatch(updateStaffProfile({ values, id }))
            .then(() => {
              onCallback();
            }).catch((error) => {
              console.log('Oops!', error);
            });
        }}

        initialValues={{
          username: _.get(staffDetailData, 'username') || '',
          avatar: _.get(staffDetailData, 'avatarLink') || '',
          name: _.get(staffDetailData, 'name') || '',
          phonetic: _.get(staffDetailData, 'phonetic') || '',
          email: _.get(staffDetailData, 'email') || '',
          phone: _.get(staffDetailData, 'phoneNumber') || '',
          gender: _.get(staffDetailData, 'gender'),
          birthday: _.get(staffDetailData, 'birthday') === null ? '' : moment(_.get(staffDetailData, 'birthday')),
        }}
      >
        <Form.Item
          name="avatar"
          label={lang.avatar}
          extra={<div >{lang.salonLogoExtraUpload}</div>}
          rules={[{ required: true, message: lang.emptyStaffProfilePicture }]}
        >
          <ImageField aspect={1 / 1}
          />
        </Form.Item>

        <Form.Item
          name="username"
          label={lang.userName}
        >
          <Input placeholder={lang.userName} disabled={true} />
        </Form.Item>

        <Form.Item
          name="name"
          label={lang.fullName}
          rules={[{ required: true, message: lang.emptyStaffNameError }]}
        >
          <Input placeholder={lang.fullName} />
        </Form.Item>

        <Form.Item
          name="phonetic"
          label={lang.phonetic}
          rules={[
            {
              required: true,
              message: lang.emptyPhoneticError,
            },
            {
              pattern: /^[ぁ-ゞ" "\u3000]*$/g,
              message: lang.emptyPhoneticError,
            },
          ]}
        >
          <Input placeholder={lang.phonetic} />
        </Form.Item>

        <Form.Item
          name="phone"
          label={lang.personalPhoneNumber}

        >
          <Input
            placeholder={lang.personalPhoneNumber}
            style={{ width: '100%' }}
            disabled ={true}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label={lang.email}
        >
          <Input placeholder={lang.email} disabled = {true} />
        </Form.Item>

        <Form.Item
          name="gender"
          label={lang.gender}
        >
          <Radio.Group >
            <Radio value={0}>{lang.male}</Radio>
            <Radio value={1}>{lang.female}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="birthday"
          label={lang.birthday}
          rules={[
            () => ({
              validator (rule, value) {
                if (value && isUnderage(value)) {
                  return Promise.reject(new Error(
                    lang.minBirthdayError,
                  ));
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            showToday={false}
            style={{ width: '100%' }}
            placeholder="YYYY-MM-DD"
            defaultPickerValue={moment().subtract(18, 'year')}
            disabledDate={isUnderage}
          />
        </Form.Item>
        <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel}>
                {lang.cancel}
              </Button>
              <Button type="primary" htmlType="submit">
                {lang.save}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  );
};

StaffUpdateForm.propTypes = {
  onCancel: PropTypes.func,
  staffDetail: PropTypes.object,
  onCallback: PropTypes.func,
};

export default StaffUpdateForm;
