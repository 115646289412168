import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Col, Row, Modal, Select, Spin, Avatar, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useTranslation } from 'react-i18next';
import { importStaff, checkStaffInvitation, getInvitationSuggestionList } from 'providers/StaffProvider/actions';
import { getLanguages } from 'utils/lang';
import _debounce from 'lodash/debounce';

const StaffImportForm = ({ onCancel, onCallBack }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [loading, setLoading] = useState(false);
  const [fetchingSuggestions, setFetchingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const options = suggestions.map(d => <Select.Option
    key={d.id}
    value={d.id}
    label={`${d?.fullname} - ${d?.username}`}
  >
    <Space size='middle'>
      <Avatar src={d?.avatar} size='large' />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{d?.fullname}</span>
        <span>{d?.username}</span>
      </div>
    </Space>
  </Select.Option>);

  const openNailistUsernameNotFoundModal = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: lang.nailistUsernameNotFound,
      content: lang.checkStaffInvitationErrorContent,
      cancelButtonProps: { style: { display: 'none' } },
    });
  };

  const handleSearch = React.useMemo(() => {
    const loadOptions = async (keyword) => {
      !fetchingSuggestions && setFetchingSuggestions(true);
      try {
        if (!keyword) throw new Error();
        const { suggestions } = await dispatch(getInvitationSuggestionList({ identity: keyword }));
        setSuggestions(suggestions);
      } catch {
        setSuggestions([]);
      }
      setFetchingSuggestions(false);
    };

    return _debounce(loadOptions, 400);
  }, []);

  return (
    <Form
      form={form}
      {...layout}
      name="staffImportForm"
      onFinish={values => {
        setLoading(true);
        const staffDefaultValues = suggestions.find(s => s.id === values.identity);
        dispatch(checkStaffInvitation({ values }))
          .then((response) => {
            if (response.data.staffId === '') {
              dispatch(importStaff({ values, staffDefaultValues }))
                .then(() => {
                  onCallBack();
                  onCancel();
                })
                .catch((e) => {
                  if (e.statusCode === 404) {
                    openNailistUsernameNotFoundModal();
                  }
                });
            } else {
              Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: lang.checkStaffInvitationErrorTitle,
                content: lang.checkStaffInvitationErrorContent,
                cancelButtonProps: { style: { display: 'none' } },
              });
            }
          })
          .catch((e) => {
            if (e.statusCode === 404) {
              openNailistUsernameNotFoundModal();
            }
          });
        setLoading(false);
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="identity"
            label={lang.staffInfo}
            rules={[{ required: true, message: lang.emptyNailieUsernameError }]}
          >
            <Select
              showSearch
              notFoundContent={fetchingSuggestions ? <Spin size="small" /> : null}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              placeholder={lang.staffInforSearchPlaceholder}
              optionLabelProp="label"
              allowClear
            >
              {options}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            {loading === false
              ? <Button type="primary" htmlType="submit">{lang.import}</Button>
              : <Button type="primary" disabled loading >{lang.import}</Button>
            }
          </Col>
        </Row>
      </div>
    </Form >
  );
};

StaffImportForm.propTypes = {
  onCancel: PropTypes.func,
  onCallBack: PropTypes.func,
};

export default StaffImportForm;
