export const CUSTOMER_DETAIL = 'CUSTOMER_DETAIL';
export const CUSTOMER_DETAIL_SUCCESS = 'CUSTOMER_DETAIL_SUCCESS';
export const CUSTOMER_DETAIL_FAIL = 'CUSTOMER_DETAIL_FAIL';

export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS';
export const CUSTOMER_LIST_FAIL = 'CUSTOMER_LIST_FAIL';

export const CUSTOMER_CREATE = 'CUSTOMER_CREATE';
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS';
export const CUSTOMER_CREATE_FAIL = 'CUSTOMER_CREATE_FAIL';

export const CUSTOMER_UPDATE = 'CATEGOCUSTOMER_UPDATERY_EDIT';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAIL = 'CUSTOMER_UPDATE_FAIL';

export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_FAIL = 'CUSTOMER_DELETE_FAIL';
