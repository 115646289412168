import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Col, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

const { Option } = Select;

const WeekFormItem = props => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dayName = {
    0: lang.sunday,
    1: lang.monday,
    2: lang.tuesday,
    3: lang.wednesday,
    4: lang.thursday,
    5: lang.friday,
    6: lang.saturday,
  };
  const { slots, value, name, onChange } = props;

  return (
    <Row
      gutter={24}
      style={{
        backgroundColor: 'rgba(128, 128, 128, 0.08)',
        paddingTop: 10,
        justifyContent: 'center',
        maxWidth: 480,
        margin: 'auto',
      }}
    >
      <Col span={7}>
        {/* isOpen */}
        <Form.Item name={[name, 'isOpen']}>
          <Checkbox
            checked={value.isOpen}
            onChange={(e) => onChange({ ...value, isOpen: e.target.checked })}
            className={`day-of-week ${(name === 0 && 'sun') || (name === 6 && 'sat')}`}
          >
            {dayName[name]}
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={1} style={{ marginRight: -24 }}>
        <div style={{ width: 2, backgroundColor: 'white', height: '114%', marginTop: -10 }}></div>
      </Col>
      <Col span={16}>
        <div>
          <Space style={{ display: 'flex', marginBottom: -15 }} align="start">
            {/* startAt */}
            <Form.Item
              name={[name, 'startAt']}
              rules={value.isOpen ? [
                {
                  required: true,
                  message: lang.emptyStartTimeError,
                },
                ({ getFieldValue, setFields }) => ({
                  validator (rule, _value) {
                    if (_value >= getFieldValue([name, 'endAt'])) {
                      setFields([
                        {
                          name: [name, 'endAt'],
                          value: _value % 100 === 0 ? _value + 30 : _value + 70,
                        },
                      ]);
                    }
                    return Promise.resolve();
                  },
                }),
              ] : []}
            >
              <Select
                disabled={!value.isOpen}
                style={{ width: 120, marginLeft: 24 }}
              >
                {slots.filter(slot => slot.value !== 2330).map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>{item.label}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* endAt */}
            <Form.Item
              name={[name, 'endAt']}
              rules={value.isOpen ? [
                {
                  required: true,
                  message: lang.emptyEndTimeError,
                },
                ({ getFieldValue, setFields }) => ({
                  validator (rule, _value) {
                    if (_value <= getFieldValue([name, 'startAt'])) {
                      setFields([
                        {
                          name: [name, 'startAt'],
                          value: _value % 100 === 0 ? _value - 70 : _value - 30,
                        },
                      ]);
                    }
                    return Promise.resolve();
                  },
                }),
              ] : []}
            >
              <Select
                disabled={!value.isOpen}
                style={{ width: 120, marginLeft: 24 }}
              >
                {slots.filter(slot => slot.value !== 0).map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>{item.label}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Space>
        </div>
      </Col>
    </Row>
  );
};

WeekFormItem.propTypes = {
  slots: PropTypes.array,
  value: PropTypes.object,
  name: PropTypes.number,
  onChange: PropTypes.func,
};

export default WeekFormItem;
