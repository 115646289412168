
import {
  SALON_DETAIL,
  SALON_DETAIL_SUCCESS,
  SALON_DETAIL_FAIL,
  SALON_ACCOUNT_UPDATE,
  SALON_ACCOUNT_UPDATE_FAIL,
  SALON_ACCOUNT_UPDATE_SUCCESS,
  SALON_GET_PROPERTIES,
  SALON_GET_PROPERTIES_FAIL,
  SALON_GET_PROPERTIES_SUCCESS,
  SALON_BANK_UPDATE,
  SALON_BANK_UPDATE_SUCCESS,
  SALON_BANK_UPDATE_FAIL,
  SALON_GET_BANK,
  SALON_GET_BANK_SUCCESS,
  SALON_GET_BANK_FAIL,
  SALON_GET_BANK_BRANCH,
  SALON_GET_BANK_BRANCH_SUCCESS,
  SALON_GET_BANK_BRANCH_FAIL,
  SALON_SCHEDULE_UPDATE,
  SALON_SCHEDULE_UPDATE_FAIL,
  SALON_SCHEDULE_UPDATE_SUCCESS,
  SALON_SETTINGS_GET,
  SALON_SETTINGS_GET_SUCCESS,
  SALON_SETTINGS_GET_FAIL,
  SALON_CANCELLATION_POLICY_UPDATE,
  SALON_CANCELLATION_POLICY_UPDATE_FAIL,
  SALON_CANCELLATION_POLICY_UPDATE_SUCCESS,
  SALON_GET_ALL_SURVEY,
  SALON_GET_ALL_SURVEY_SUCCESS,
  SALON_GET_ALL_SURVEY_FAIL,
  SALON_SURVEY_DETAIL,
  SALON_SURVEY_DETAIL_SUCCESS,
  SALON_SURVEY_DETAIL_FAIL,
  SALON_UPDATE_SURVEY,
  SALON_UPDATE_SURVEY_SUCCESS,
  SALON_UPDATE_SURVEY_FAIL,
  SALON_SURVEY_RESULT_SUCCESS,
  SALON_SURVEY_RESULT_FAIL,
  SALON_GET_ALL_AREAS,
  SALON_GET_ALL_AREAS_SUCCESS,
  SALON_GET_ALL_AREAS_FAIL,
} from './types';

const INITIAL_STATE = {
  salon: {},
  needUpdateSalonSurvey: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SALON_DETAIL_SUCCESS:
      return {
        ...state,
        salon: action.payload.data,
      };
    case SALON_DETAIL:
    case SALON_DETAIL_FAIL:
    case SALON_BANK_UPDATE:
    case SALON_BANK_UPDATE_SUCCESS:
    case SALON_BANK_UPDATE_FAIL:
    case SALON_GET_BANK:
    case SALON_GET_BANK_SUCCESS:
    case SALON_GET_BANK_FAIL:
    case SALON_GET_BANK_BRANCH:
    case SALON_GET_BANK_BRANCH_SUCCESS:
    case SALON_GET_BANK_BRANCH_FAIL:
    case SALON_ACCOUNT_UPDATE:
    case SALON_ACCOUNT_UPDATE_FAIL:
    case SALON_ACCOUNT_UPDATE_SUCCESS:
    case SALON_GET_PROPERTIES:
    case SALON_GET_PROPERTIES_FAIL:
    case SALON_GET_PROPERTIES_SUCCESS:
    case SALON_SCHEDULE_UPDATE:
    case SALON_SCHEDULE_UPDATE_FAIL:
    case SALON_SCHEDULE_UPDATE_SUCCESS:
    case SALON_SETTINGS_GET:
    case SALON_SETTINGS_GET_FAIL:
    case SALON_SETTINGS_GET_SUCCESS:
    case SALON_CANCELLATION_POLICY_UPDATE:
    case SALON_CANCELLATION_POLICY_UPDATE_FAIL:
    case SALON_CANCELLATION_POLICY_UPDATE_SUCCESS:
    case SALON_GET_ALL_SURVEY:
    case SALON_GET_ALL_SURVEY_SUCCESS:
    case SALON_GET_ALL_SURVEY_FAIL:
    case SALON_SURVEY_DETAIL:
    case SALON_SURVEY_DETAIL_SUCCESS:
    case SALON_SURVEY_DETAIL_FAIL:
    case SALON_UPDATE_SURVEY:
    case SALON_UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
      };
    case SALON_UPDATE_SURVEY_FAIL:
    case SALON_SURVEY_RESULT_SUCCESS: {
      // list question answered 1, not answered = 0
      let needUpdatedSurveyValue;
      if ((action?.payload?.data?.answers || []).some(item => item.answer === 1)) {
        needUpdatedSurveyValue = false;
      } else {
        needUpdatedSurveyValue = true;
      }
      return {
        ...state,
        needUpdateSalonSurvey: needUpdatedSurveyValue,
      };
    }
    case SALON_SURVEY_RESULT_FAIL:
    case SALON_GET_ALL_AREAS:
    case SALON_GET_ALL_AREAS_SUCCESS:
    case SALON_GET_ALL_AREAS_FAIL:
      return {
        ...state,
      };

    default:
      return state;
  }
};
