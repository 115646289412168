import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import useSound from 'use-sound';
import { useDispatch } from 'hooks/useCustomDispatch';
import { fetchBookingNotificationList } from 'providers/BookingProvider/actions';
// import nailieNotificationSound from 'assets/sounds/booking-notification.mp3';

const TIME_RELOAD = 60000; // ms
const LIMIT_PAGE = 100;

const useBookingNotificationNumBadge = () => {
  const dispatch = useDispatch();
  const { numUnread } = useSelector(state => state.booking.notification);

  // View number of unread notification and play sound when get new notification
  // useEffect(() => {
  //   if (numUnread > oldNumUnread && oldNumUnread !== '0') {
  //     // play();
  //   }
  // }, [numUnread]);

  useEffect(() => {
    dispatch(fetchBookingNotificationList({ page: 1, limit: LIMIT_PAGE }));
    const interval = setInterval(() => {
      dispatch(fetchBookingNotificationList({ page: 1, limit: LIMIT_PAGE }));
    }, TIME_RELOAD);
    return () => clearInterval(interval);
  }, []);

  return numUnread;
};

export { useBookingNotificationNumBadge };
