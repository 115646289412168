import React from 'react';
import { componentWillAppendToBody } from 'react-append-to-body';
import { Spin } from 'antd';

let BigSpin = () => (

  <div
    id='sm-big-sprint'
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      zIndex: 10000,
    }}
  >
    <Spin size='large' />
  </div>
);

BigSpin = componentWillAppendToBody(BigSpin);

export default BigSpin;
