import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Select, Spin, Divider } from 'antd';

import { useDispatch } from 'hooks/useCustomDispatch';
import { salonBankUpdate, salonGetBank, salonGetBankBranch } from 'providers/SalonProvider/actions';

import ImageField from 'components/Desktop/Commons/ImageField';
import bankProofSampleImg from 'assets/images/bank-proof-sample.png';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getLanguages } from 'utils/lang';

import './styles.less';

const SalonBankUpdateForm = ({ salon, onCallback }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [banks, setBanks] = useState();
  const [bankBranch, setBankBranch] = useState();
  const [bankId, setBankId] = useState();

  const lang = getLanguages(t);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  useEffect(() => {
    setLoading(true);

    dispatch(salonGetBank())
      .then(result => {
        setBanks(result.data);

        if (salon.bankInfo.bankId) {
          dispatch(salonGetBankBranch({
            bankId: salon.bankInfo.bankId,
          }))
            .then(result => {
              setBankBranch(result.data);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (bankId) {
      dispatch(salonGetBankBranch({
        bankId,
      }))
        .then(result => {
          setBankBranch(result.data);
        });
    }
  }, [bankId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Form
      {...layout}
      form={form}
      name="bankForm"
      onFinish={values => {
        setLoadingSubmit(true);
        dispatch(salonBankUpdate({
          ...values,
          photo: values.photo || '',
          salonId: _.get(salon, 'id'),
        }))
          .then((result) => {
            onCallback(result);
            setLoadingSubmit(false);
          }).catch((error) => {
            setLoadingSubmit(false);
            console.log('Oops!', error);
          });
      }}
      initialValues={{
        bankId: _.get(salon, 'bankInfo.bankId'),
        bankNameLoading: '',
        branchId: _.get(salon, 'bankInfo.branchId'),
        branchNameLoading: '',
        accountType: _.get(salon, 'bankInfo.accountType'),
        accountNumber: _.get(salon, 'bankInfo.accountNumber'),
        accountName: _.get(salon, 'bankInfo.accountName'),
        photo: _.get(salon, 'bankInfo.photo', ''),
      }}
    >
      { !loading
        ? <Form.Item
          name="bankId"
          label={lang.bankName}
          rules={[
            {
              required: true,
              message: lang.emptyBankNameError,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder={lang.bankName}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                option.keywords.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            onChange={
              (value) => {
                setBankId(value);
                setBankBranch();
                form.setFields([
                  {
                    name: 'branchId',
                    value: '',
                  },
                ]);
              }
            }
          >
            {banks && banks.map((item, index) => <Select.Option key={index} value={item.id} keywords={ item.bankCode || item.bankName }>{item.bankName}</Select.Option>)}
          </Select>
        </Form.Item>
        : <Form.Item
          name="bankNameLoading"
          label={lang.bankName}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            loading
          >
            <Select.Option value={''}></Select.Option>
          </Select>
        </Form.Item>
      }

      { !loading
        ? <Form.Item
          name="branchId"
          label={lang.branchName}
          rules={[
            {
              required: true,
              message: lang.emptyBranchNameError,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder={lang.branchName}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                option.keywords.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {bankBranch && bankBranch.map(item => <Select.Option key={item.id} value={item.id} keywords={ item.branchCode || item.branchName}>{`${item.branchCode} - ${item.branchName}`}</Select.Option>)}
          </Select>
        </Form.Item>
        : <Form.Item
          label={lang.branchName}
          name={'branchNameLoading'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            loading
            placeholder={lang.branchName}
          >
            <Select.Option value={''}></Select.Option>
          </Select>
        </Form.Item>
      }
      <Form.Item
        name="accountType"
        label={lang.accountType}
        rules={[
          {
            required: true,
            message: lang.emptyAccountTypeError,
          },
        ]}
      >
        <Select
          style={{ width: '100%' }}
          placeholder={lang.accountType}
        >
          <Select.Option value="normal">{lang.normal}</Select.Option>
          <Select.Option value="saving">{lang.saving}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="accountNumber"
        label={lang.accountNumber}
        rules={[
          {
            required: true,
            message: lang.emptyAccountNumberError,
          },
          {
            pattern: /^[0-9]{7}$/, // Only allow exact 7 digits
            message: lang.invalidAccountNumber,
          },
        ]}
        validateTrigger={'onBlur'}
      >
        <Input
          placeholder={lang.accountNumber}
          onKeyPress={(e) => {
            const key = e.which || e.key;
            if (key < 48 || key > 57 || e.target.value.length >= 7) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item
        name="accountName"
        label={lang.accountName}
        rules={[
          {
            required: true,
            message: lang.emptyAccountNameError,
          },
          {
            pattern: /^[ｧ-ﾝﾞﾟァ-・ヽヾ゛゜ー()-.（-）]*$/, // Only allow katakana and some characters
            message: lang.invalidAccountName,
          },
        ]}
        validateTrigger={'onBlur'}
      >
        <Input
          placeholder={lang.accountName}
        />
      </Form.Item>
      <Row>
        <Col span={9}>
          <Form.Item
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            name="photo"
            label={lang.imageForBankProof}
            extra={lang.salonLogoExtraUpload}
            rules={[
              {
                required: true,
                message: lang.emptyImageForBankProofError,
              },
            ]}
          >
            <ImageField
              aspect={3 / 2}
            />
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: 'left', color: '#00000073' }}>
          <p style={{ fontWeight: 'bold' }}>{lang.bankbookTitle}</p>
          <img src={bankProofSampleImg} style={{ width: '160px', marginTop: '32px', marginBottom: '32px' }} alt="Bank Proof Sample" />
          <p>
            {lang.bankbookDescription1}
            <br />
            {lang.bankbookDescription2}
          </p>
        </Col>
      </Row>

      <Divider />
      <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
        <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loadingSubmit}>
            {lang.updateAndPublish}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

SalonBankUpdateForm.propTypes = {
  onCallback: PropTypes.func,
  salon: PropTypes.object,
};

export default SalonBankUpdateForm;
