import {
  categoryListSuccess,
  categoryListFail,
  categoryCreateSuccess,
  categoryCreateFail,
  categoryUpdateSuccess,
  categoryUpdateFail,
  categoryOrderSuccess,
  categoryOrderFail,
  categoryDeleteSuccess,
  categoryDeleteFail,
  categoryList as categoryListAction,
} from './actions';
import {
  CATEGORY_LIST,
  CATEGORY_CREATE,
  CATEGORY_UPDATE,
  CATEGORY_ORDER,
  CATEGORY_DELETE,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

function * categoryList (action) {
  // use param withTrashed  to query with deleted records
  const { withTrashed } = action.payload;
  try {
    const { data } = yield call(request, environment.api.categoryList, {
      withTrashed,
    }, 'GET', action.payload.token);
    data.forEach(category => {
      category.services.sort((a, b) => a.order - b.order);
    });
    data.sort((a, b) => a.order - b.order);
    if (withTrashed === 1) {
      yield put(categoryListSuccess({
        categoriesWithTrashed: data,
      }));
    } else {
      yield put(categoryListSuccess({
        categories: data,
      }));
    }
  } catch (error) {
    console.log('error: ', error);
    yield put(categoryListFail('エーロが発生しました'));
  }
}

function * categoryCreate (action) {
  try {
    const { values, token } = action.payload;
    yield call(request, environment.api.categoryCreate, values, 'POST', token);
    yield put(categoryCreateSuccess({}, action.meta));
    yield put(categoryListAction({ token: token }));
    Alert.success('作成完了');
  } catch (e) {
    Alert.error('エラーが発生しました');
    yield put(categoryCreateFail());
    console.log(e);
  }
}

function * categoryUpdate (action) {
  try {
    const { values, categoryId, token } = action.payload;
    console.log('categoryUpdate.values: ', values);
    console.log('categoryUpdate.categoryId: ', categoryId);
    yield call(request, `${environment.api.categoryUpdate}/${categoryId}`, values, 'PUT', token);
    yield put(categoryUpdateSuccess({}, action.meta));
    yield put(categoryListAction({ token: token }));
    Alert.success('設定を変更しました');
  } catch (e) {
    Alert.error('エラーが発生しました');
    yield put(categoryUpdateFail());
    console.log(e);
  }
}

function * categoryOrder (action) {
  try {
    const { oldItem, newItem, token } = action.payload;

    yield call(request, `${environment.api.categoryUpdate}/${oldItem.id}`, {
      description: oldItem.description,
      name: oldItem.name,
      order: newItem.order,
    }, 'PUT', token);

    yield call(request, `${environment.api.categoryUpdate}/${newItem.id}`, {
      description: newItem.description,
      name: newItem.name,
      order: oldItem.order,
    }, 'PUT', token);

    yield put(categoryOrderSuccess({}, action.meta));
    yield put(categoryListAction({ token: token }));
  } catch (error) {
    Alert.error(error.message);
    yield put(categoryOrderFail());
  }
}

function * categoryDelete (action) {
  try {
    const { id, token } = action.payload;
    yield call(request, `${environment.api.categoryDelete}/${id}`, {}, 'DELETE', token);
    yield put(categoryDeleteSuccess());
    yield put(categoryListAction({ token: token }));
    Alert.success('削除しました');
  } catch (error) {
    console.log(error);
    Alert.error(error.message);
    yield put(categoryDeleteFail());
  }
}

export default function * watchNailist () {
  yield takeEvery(CATEGORY_LIST, categoryList);
  yield takeLeading(CATEGORY_CREATE, categoryCreate);
  yield takeEvery(CATEGORY_UPDATE, categoryUpdate);
  yield takeEvery(CATEGORY_ORDER, categoryOrder);
  yield takeLeading(CATEGORY_DELETE, categoryDelete);
}
