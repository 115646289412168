// Libraries
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Divider, Typography, Tag } from 'antd';
import _get from 'lodash/get';
// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
// Providers
import { categoryList } from 'providers/CategoryProvider/actions';
// Utils
import { getLanguages } from 'utils/lang';
import { formatYen } from 'utils/stringFormat';
import { BOOKING_CANCELED_STATUSES, MENU_REPEAT_TYPE } from 'utils/constants';
import { getCustomerPayAmount, getSalonSalesAmount } from 'utils/sales';
import './styles.less';

const { Text, Title } = Typography;

const BookingDetailInvoice = ({ booking }) => {
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(categoryList({ withTrashed: 1 }));
  }, []);
  // State
  const { categoriesWithTrashed = [] } = useSelector((state) => state.category);
  // Constants
  const targetSystemStatus = (booking.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = _get(targetSystemStatus, 'value');
  const isCanceled = BOOKING_CANCELED_STATUSES.includes(bookingStatus);
  const hasPayment = bookingStatus === 'DONE' || isCanceled;

  const renderMenuList = () => {
    return (
      <>
        {
          (booking.orders || []).map(order => {
            let menuBookingDetail = {};
            const categoryBookingDetail = (categoriesWithTrashed || []).find(category => {
              return (category.services || []).find(service => {
                if (service.id === order?.serviceId) {
                  menuBookingDetail = service;
                  return true;
                }
              });
            });
            const menuRepeatType = menuBookingDetail?.extraInfo?.repeatType || _get(order, 'extraInfo.repeatType', 'ALL');
            return (
              <Row key={order.serviceName} className="booking-detail-row">
                <Col sm={{ span: 20 }} xs={{ span: 18 }}>
                  <Row>
                    <Col sm={{ span: 4 }} xs={{ span: 24 }}>
                      <Tag color={'cyan'}>{lang[MENU_REPEAT_TYPE[menuRepeatType]?.langText]}</Tag>
                    </Col>
                    <Col sm={{ span: 16 }} xs={{ span: 24 }}>
                      <div style={{ marginRight: 8 }}>
                        [{categoryBookingDetail?.name || order?.category?.name}] {!order?.serviceName ? order?.name : order?.serviceName}
                      </div>
                    </Col>
                    <Col sm={{ span: 4 }} xs={{ span: 24 }}>
                      {order.duration} {lang.min}
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ span: 4 }} xs={{ span: 6 }} style={{ textAlign: 'right' }}>
                  {formatYen(order.price)}
                </Col>
              </Row>
            );
          })
        }
      </>
    );
  };

  const renderSubtotal = () => {
    const { totalPrice = 0, totalDuration = 0 } = booking;

    return (
      <Row className="booking-detail-row">
        <Col sm={{ span: 20 }} xs={{ span: 18 }}>
          <Row>
            <Col lg={{ span: 20 }} xs={{ span: 24 }}>
              {lang.subtotal}
            </Col>
            <Col lg={{ span: 4 }} xs={{ span: 24 }}>
              <strong>{totalDuration} {lang.min}</strong>
            </Col>
          </Row>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 6 }} style={{ textAlign: 'right' }}>
          <strong>{formatYen(totalPrice)}</strong>
        </Col>
      </Row>
    );
  };

  const renderCancellationFee = () => {
    if (!isCanceled) {
      return null;
    }

    const { cancellationFee = 0 } = booking;
    return (
      <Row className="booking-detail-row">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Text type="danger">{lang.cancellationFee}</Text>
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Text type="danger">{cancellationFee * 100}%</Text>
        </Col>
      </Row>
    );
  };

  const renderDiscount = () => {
    const { discount = 0 } = booking;
    const discountType = booking?.extraInfo?.discountType;
    /* If booking is canceled, do not show discount/additional price */
    if (isCanceled || discount === 0) {
      return null;
    }
    return (
      <Row className="booking-detail-row">
        <Col xs={{ span: 18 }}>
          {discountType === 'POINT' ? lang.usePoint : lang.discount}
        </Col>
        <Col xs={{ span: 6 }} style={{ textAlign: 'right' }}>
          <span style={{ fontWeight: 'bold' }}>{ discountType === 'POINT' ? `${discount * (-1)}P` : formatYen(discount * (-1)) }</span>
        </Col>
      </Row>
    );
  };

  const renderAdditionalPrice = () => {
    const { additionalPrice = 0 } = booking;
    /* If booking is canceled, do not show discount/additional price */
    if (isCanceled || additionalPrice === 0) {
      return null;
    }

    return (
      <Row className="booking-detail-row">
        <Col xs={{ span: 18 }}>
          {lang.additionalPrice}
        </Col>
        <Col xs={{ span: 6 }} style={{ textAlign: 'right' }}>
          <span style={{ fontWeight: 'bold', color: '#36cfc9' }}>{formatYen(additionalPrice)}</span>
        </Col>
      </Row>
    );
  };

  const renderSalonSales = () => {
    const total = getSalonSalesAmount(booking);

    return (
      <Row className="booking-detail-row">
        <Col sm={{ span: 16 }} xs={{ span: 24 }}>
          <Title level={4}>{lang.total} { !hasPayment && <Text type='secondary'>({lang.estimate})</Text>}</Title>
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Title level={3}>{formatYen(total || 0)}</Title>
        </Col>
      </Row>
    );
  };

  const renderCustomerPay = () => {
    const amount = getCustomerPayAmount(booking);

    return (
      <Row className="booking-detail-row">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <Title level={4}>{lang.customerPay} { !hasPayment && <Text type='secondary'>({lang.estimate})</Text>}</Title>
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Title level={4}>{formatYen(amount)}</Title>
        </Col>
      </Row>
    );
  };

  return (
    <div className="booking-detail">
      {renderMenuList()}
      <Divider className="row-divider" />
      {renderSubtotal()}
      {renderCancellationFee()}
      {renderAdditionalPrice()}
      <Divider className="row-divider" />
      {renderSalonSales()}
      {renderDiscount()}
      <Divider className="row-divider" />
      {renderCustomerPay()}
    </div>
  );
};

BookingDetailInvoice.propTypes = {
  booking: PropTypes.object,
};

export default BookingDetailInvoice;
