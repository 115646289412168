import moment from 'moment-timezone';
import { SALES_REPORT_PERIOD } from 'utils/constants';

export const getPeriodDate = (inputDateTime, inputPeriod, utcOffsetHours) => {
  let startDateTime;
  let endDateTime;
  if (inputPeriod === SALES_REPORT_PERIOD.firstPeriod) { // first period: YYYY-MM-01->YYYY-MM-15
    startDateTime = moment(inputDateTime).startOf('month').startOf('day').subtract(utcOffsetHours, 'hours').toISOString();
    endDateTime = moment(startDateTime).clone().add(14, 'days').endOf('day').subtract(utcOffsetHours, 'hours').toISOString();
  } else if (inputPeriod === SALES_REPORT_PERIOD.secondPeriod) { // second period: YYYY-MM-16->YYYY-MM-LAST_DAY_OF_MONTH
    startDateTime = moment(inputDateTime).startOf('month').add(15, 'days').startOf('day').subtract(utcOffsetHours, 'hours').toISOString();
    endDateTime = moment(startDateTime).endOf('month').endOf('day').subtract(utcOffsetHours, 'hours').toISOString();
  } else if (inputPeriod === SALES_REPORT_PERIOD.fullMonth) { // full month: YYY-MM-01-YYYY-MM-LAST_DAY_OF_MONTH
    startDateTime = moment(inputDateTime).startOf('month').startOf('day').subtract(utcOffsetHours, 'hours').toISOString();
    endDateTime = moment(startDateTime).endOf('month').endOf('day').subtract(utcOffsetHours, 'hours').toISOString();
  }

  return [startDateTime, endDateTime];
};

export const smartTrim = (str, length, delim, appendix) => {
  if (str.length <= length) return str;

  var trimmedStr = str.substr(0, length + delim.length);

  var lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

  if (trimmedStr) trimmedStr += appendix;
  return trimmedStr;
};

export const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const _sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const generatePreRandomInfor = (num) => {
  return Math.random().toString(36).slice(-num);
};

export const getValueFromRandomInfor = (value, num) => {
  return value?.substr(num);
};

export const formatDataHas0Value = (number, nanIndicator = '--') => {
  if (!number && number !== 0) {
    return nanIndicator; // --
  }

  return String(number);
};

export const keepLocalStorageBeforeLogout = () => {
  const keepSalonsShownStationPopup = localStorage.getItem('editStationPopupShownSalonIdsList');
  const keepSalonsShownPropertiesPopup = localStorage.getItem('newSalonPropertiesPopupShownSalonIdsList');
  localStorage.clear();
  localStorage.setItem('editStationPopupShownSalonIdsList', keepSalonsShownStationPopup);
  localStorage.setItem('newSalonPropertiesPopupShownSalonIdsList', keepSalonsShownPropertiesPopup);
};
