import {
  GET_LINE_LIST_SUCCESS,
  GET_PREFECTURE_ISO_TABLE_SUCCESS,
  GET_STATION_LIST_SUCCESS,
  RESET_STATION_PROVIDER,
} from './type';

const INITIAL_STATE = {
  prefectureISOTable: [],
  lineList: [],
  stationList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PREFECTURE_ISO_TABLE_SUCCESS:
      return {
        ...state,
        prefectureISOTable: action.payload,
      };
    case GET_LINE_LIST_SUCCESS:
      return {
        ...state,
        lineList: action.payload.map(line => {
          return {
            value: `${line._id}|${line.name}`,
            label: line?.name,
            isLeaf: false,
          };
        }),
      };
    case GET_STATION_LIST_SUCCESS: {
      const { data, lineValue } = action.payload;
      const newLine = state?.lineList.find(l => l.value === lineValue);
      newLine.children = data.map(s => ({ value: `${s._id}|${s.name}`, label: `${s?.name}駅` }));
      newLine.loading = false;

      return {
        ...state,
        lineList: [...state.lineList],
      };
    }
    case RESET_STATION_PROVIDER:
      return {
        ...INITIAL_STATE,
        prefectureISOTable: state.prefectureISOTable,
      };

    default:
      return state;
  }
};
