export const SERVICE_DETAIL = 'SERVICE_DETAIL';

export const SERVICE_CREATE = 'SERVICE_CREATE';
export const SERVICE_CREATE_SUCCESS = 'SERVICE_CREATE_SUCCESS';
export const SERVICE_CREATE_FAIL = 'SERVICE_CREATE_FAIL';

export const SERVICE_UPDATE = 'SERVICE_UPDATE';
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_FAIL = 'SERVICE_UPDATE_FAIL';

export const SERVICE_ORDER = 'SERVICE_ORDER';
export const SERVICE_ORDER_SUCCESS = 'SERVICE_ORDER_SUCCESS';
export const SERVICE_ORDER_FAIL = 'SERVICE_ORDER_FAIL';

export const SERVICE_DELETE = 'SERVICE_DELETE';
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS';
export const SERVICE_DELETE_FAIL = 'SERVICE_DELETE_FAIL';
