import {
  BOOKING_GET_STAFFS_BY_SERVICE,
  BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS,
  BOOKING_GET_STAFFS_BY_SERVICE_FAIL,
  BOOKING_LIST,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_FAIL,
  BOOKING_DETAIL,
  BOOKING_DETAIL_SUCCESS,
  BOOKING_DETAIL_FAIL,
  BOOKING_CREATE,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAIL,
  BOOKING_UPDATE,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_DELETE,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_CANCEL,
  BOOKING_CANCEL_FAIL,
  BOOKING_CANCEL_SUCCESS,
  BOOKING_FINISH,
  BOOKING_FINISH_SUCCESS,
  BOOKING_FINISH_FAIL,
  QUICK_PAYMENT,
  QUICK_PAYMENT_SUCCESS,
  QUICK_PAYMENT_FAIL,
  GET_APPS,
  GET_APPS_FAIL,
  GET_APPS_SUCCESS,
  BOOKING_TIPS,
  BOOKING_TIPS_SUCCESS,
  BOOKING_TIPS_FAIL,
  BOOKING_DELETE_TIPS,
  BOOKING_DELETE_TIPS_SUCCESS,
  BOOKING_DELETE_TIPS_FAIL,
  GET_PAYMENT_BYBOOKINGID,
  GET_PAYMENT_BYBOOKINGID_SUCCESS,
  GET_PAYMENT_BYBOOKINGID_FAIL,
  GET_OUTSIDE_BOOKINGS_BY_STAFF,
  GET_OUTSIDE_BOOKINGS_BY_STAFF_SUCCESS,
  GET_OUTSIDE_BOOKINGS_BY_STAFF_FAIL,
  BOOKING_ACCEPTANCE_TIME,
  BOOKING_ACCEPTANCE_TIME_SUCCESS,
  BOOKING_ACCEPTANCE_TIME_FAIL,
  BOOKING_UPDATE_SERVICES,
  BOOKING_UPDATE_SERVICES_SUCCESS,
  BOOKING_UPDATE_SERVICES_FAIL,
  FETCH_BOOKINGS_FOR_CSV,
  FETCH_BOOKINGS_FOR_CSV_SUCCESS,
  FETCH_BOOKINGS_FOR_CSV_FAIL,
  RESET_BOOKING_PROVIDER,
  FETCH_BOOKING_NOTIFICATION_LIST,
  FETCH_BOOKING_NOTIFICATION_LIST_SUCCESS,
  FETCH_BOOKING_NOTIFICATION_LIST_FAIL,
  READ_ALL_BOOKING_NOTIFICATION,
  READ_ALL_BOOKING_NOTIFICATION_SUCCESS,
  READ_ALL_BOOKING_NOTIFICATION_FAIL,
  READ_BOOKING_NOTIFICATION,
  READ_BOOKING_NOTIFICATION_SUCCESS,
  READ_BOOKING_NOTIFICATION_FAIL,
  BOOKING_LIST_ON_SCHEDULE,
  BOOKING_LIST_ON_CHANGE_DATE,
  BOOKING_LIST_ONCHANGE_DATE_SUCCESS,
  BOOKING_LIST_WAITING_DONE,
  BOOKING_LIST_WAITING_DONE_SUCCESS,
  GET_OUTSIDE_BOOKINGS_BY_SALON,
  GET_OUTSIDE_BOOKINGS_BY_SALON_SUCCESS,
  GET_OUTSIDE_BOOKINGS_BY_SALON_FAIL,
} from './types';

// BOOKING_GET_STAFFS_BY_SERVICE
export function bookingGetStaffsByService (payload = {}) {
  return { type: BOOKING_GET_STAFFS_BY_SERVICE, payload, meta: { thunk: true } };
}
export function bookingGetStaffsByServiceSuccess (payload = {}, meta) {
  return { type: BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS, payload, meta };
}
export function bookingGetStaffsByServiceFail (payload = {}, meta) {
  return { type: BOOKING_GET_STAFFS_BY_SERVICE_FAIL, payload, meta, error: true };
}

// BOOKING_LIST
export function bookingList (payload = {}) {
  return { type: BOOKING_LIST, payload, meta: { thunk: true } };
}
// BOOKING_LIST_ON_SCHEDULE
export function bookingListOnSchedule (payload = {}) {
  return { type: BOOKING_LIST_ON_SCHEDULE, payload, meta: { thunk: true } };
}
// BOOKING_LIST_WAITING_DONE
export function bookingListWaitingDone (payload = {}) {
  return { type: BOOKING_LIST_WAITING_DONE, payload, meta: { thunk: true } };
}
// BOOKING_LIST_WAITING_DONE_SUCCESS
export function bookingListWaitingDoneSuccess (payload = {}) {
  return { type: BOOKING_LIST_WAITING_DONE_SUCCESS, payload, meta: { thunk: true } };
}

// BOOKING_LIST_ON_CHANGE_DATE
export function bookingListOnChangeDate (payload = {}) {
  return { type: BOOKING_LIST_ON_CHANGE_DATE, payload, meta: { thunk: true } };
}

export function bookingListSuccess (payload = {}, meta) {
  return { type: BOOKING_LIST_SUCCESS, payload, meta };
}
export function bookingListOnChangeDateSuccess (payload = {}, meta) {
  return { type: BOOKING_LIST_ONCHANGE_DATE_SUCCESS, payload, meta };
}
export function bookingListFail (payload = {}, meta) {
  return { type: BOOKING_LIST_FAIL, payload, meta, error: true };
}

export function bookingDetail (payload = {}) {
  return { type: BOOKING_DETAIL, payload, meta: { thunk: true } };
}
export function bookingDetailSuccess (payload = {}, meta) {
  return { type: BOOKING_DETAIL_SUCCESS, payload, meta };
}
export function bookingDetailFail (payload = {}, meta) {
  return { type: BOOKING_DETAIL_FAIL, payload, meta, error: true };
}

// BOOKING_CREATE
export function bookingCreate (payload = {}) {
  return { type: BOOKING_CREATE, payload, meta: { thunk: true } };
}
export function bookingCreateSuccess (payload = {}, meta) {
  return { type: BOOKING_CREATE_SUCCESS, payload, meta };
}
export function bookingCreateFail (payload = {}, meta) {
  return { type: BOOKING_CREATE_FAIL, payload, meta, error: true };
}

// BOOKING_UPDATE
export function bookingUpdate (payload = {}) {
  return { type: BOOKING_UPDATE, payload, meta: { thunk: true } };
}
export function bookingUpdateSuccess (payload = {}, meta) {
  return { type: BOOKING_UPDATE_SUCCESS, payload, meta };
}
export function bookingUpdateFail (payload = {}, meta) {
  return { type: BOOKING_UPDATE_FAIL, payload, meta, error: true };
}

// BOOKING_DELETE
export function bookingDelete (payload = {}) {
  return { type: BOOKING_DELETE, payload };
}
export function bookingDeleteSuccess (payload = {}) {
  return { type: BOOKING_DELETE_SUCCESS, payload };
}
export function bookingDeleteFail (payload = {}) {
  return { type: BOOKING_DELETE_FAIL, payload };
}

// BOOKING_CANCEL
export function bookingCancel (payload = {}) {
  return { type: BOOKING_CANCEL, payload, meta: { thunk: true } };
}
export function bookingCancelSuccess (payload = {}, meta) {
  return { type: BOOKING_CANCEL_SUCCESS, payload, meta };
}
export function bookingCancelFail (payload = {}, meta) {
  return { type: BOOKING_CANCEL_FAIL, payload, meta, error: true };
}

// BOOKING_FINISH
export function bookingFinish (payload = {}) {
  return { type: BOOKING_FINISH, payload, meta: { thunk: true } };
}
export function bookingFinishSuccess (payload = {}, meta) {
  return { type: BOOKING_FINISH_SUCCESS, payload, meta };
}
export function bookingFinishFail (payload = {}, meta) {
  return { type: BOOKING_FINISH_FAIL, payload, meta, error: true };
}

// QUICK_PAYMENT
export function quickPayment (payload = {}) {
  return { type: QUICK_PAYMENT, payload, meta: { thunk: true } };
}
export function quickPaymentSuccess (payload = {}, meta) {
  return { type: QUICK_PAYMENT_SUCCESS, payload, meta };
}
export function quickPaymentFail (payload = {}, meta) {
  return { type: QUICK_PAYMENT_FAIL, payload, meta, error: true };
}

// GET_APPS
export function getApps (payload = {}) {
  return { type: GET_APPS, payload, meta: { thunk: true } };
}
export function getAppsSuccess (payload = {}, meta) {
  return { type: GET_APPS_SUCCESS, payload, meta };
}
export function getAppsFail (payload = {}, meta) {
  return { type: GET_APPS_FAIL, payload, meta, error: true };
}
// ADD TIPS
export function bookingTips (payload = {}) {
  return { type: BOOKING_TIPS, payload, meta: { thunk: true } };
}
export function bookingTipsSuccess (payload = {}, meta) {
  return { type: BOOKING_TIPS_SUCCESS, payload, meta };
}
export function bookingTipsFail (payload = {}, meta) {
  return { type: BOOKING_TIPS_FAIL, payload, meta, error: true };
}

// REMOVE TIPS
export function bookingDeleteTips (payload = {}) {
  return { type: BOOKING_DELETE_TIPS, payload, meta: { thunk: true } };
}
export function bookingDeleteTipsSuccess (payload = {}, meta) {
  return { type: BOOKING_DELETE_TIPS_SUCCESS, payload, meta };
}
export function bookingDeleteTipsFail (payload = {}, meta) {
  return { type: BOOKING_DELETE_TIPS_FAIL, payload, meta, error: true };
}

// GET PAYMENT BY BOOKINGID
export function getPaymentByBookingId (payload = {}) {
  return { type: GET_PAYMENT_BYBOOKINGID, payload, meta: { thunk: true } };
}
export function getPaymentByBookingIdSuccess (payload = {}, meta) {
  return { type: GET_PAYMENT_BYBOOKINGID_SUCCESS, payload, meta };
}
export function getPaymentByBookingIdFail (payload = {}, meta) {
  return { type: GET_PAYMENT_BYBOOKINGID_FAIL, payload, meta, error: true };
}

// GET OUTSIDE BOOKINGS BY STAFF
export function getOutsideBookingsByStaff (payload = {}) {
  return { type: GET_OUTSIDE_BOOKINGS_BY_STAFF, payload, meta: { thunk: true } };
}
export function getOutsideBookingsByStaffSuccess (payload = {}, meta) {
  return { type: GET_OUTSIDE_BOOKINGS_BY_STAFF_SUCCESS, payload, meta };
}
export function getOutsideBookingsByStaffFail (payload = {}, meta) {
  return { type: GET_OUTSIDE_BOOKINGS_BY_STAFF_FAIL, payload, meta, error: true };
}

// GET OUTSIDE BOOKINGS BY SALON
export function getOutsideBookingsBySalon (payload = {}) {
  return { type: GET_OUTSIDE_BOOKINGS_BY_SALON, payload, meta: { thunk: true } };
}
export function getOutsideBookingsBySalonSuccess (payload = {}, meta) {
  return { type: GET_OUTSIDE_BOOKINGS_BY_SALON_SUCCESS, payload, meta };
}
export function getOutsideBookingsBySalonFail (payload = {}, meta) {
  return { type: GET_OUTSIDE_BOOKINGS_BY_SALON_FAIL, payload, meta, error: true };
}

// BOOKING ACCEPTANCE TIME
export function bookingAcceptanceTime (payload = {}) {
  return { type: BOOKING_ACCEPTANCE_TIME, payload, meta: { thunk: true } };
}
export function bookingAcceptanceTimeSuccess (payload = {}, meta) {
  return { type: BOOKING_ACCEPTANCE_TIME_SUCCESS, payload, meta };
}
export function bookingAcceptanceTimeFail (payload = {}, meta) {
  return { type: BOOKING_ACCEPTANCE_TIME_FAIL, payload, meta, error: true };
}

// BOOKING_UPDATE_SERVICE
export function bookingUpdateServices (payload = {}) {
  return { type: BOOKING_UPDATE_SERVICES, payload, meta: { thunk: true } };
}
export function bookingUpdateServicesSuccess (payload = {}, meta) {
  return { type: BOOKING_UPDATE_SERVICES_SUCCESS, payload, meta };
}
export function bookingUpdateServicesFail (payload = {}, meta) {
  return { type: BOOKING_UPDATE_SERVICES_FAIL, payload, meta, error: true };
}

export function fetchBookingsForCSV (payload = {}) {
  return { type: FETCH_BOOKINGS_FOR_CSV, payload, meta: { thunk: true } };
}
export function fetchBookingsForCSVSuccess (payload = {}, meta) {
  return { type: FETCH_BOOKINGS_FOR_CSV_SUCCESS, payload, meta };
}
export function fetchBookingsForCSVFail (payload = {}, meta) {
  return { type: FETCH_BOOKINGS_FOR_CSV_FAIL, payload, meta, error: true };
}

export function resetBookingProvider (payload = {}) {
  return { type: RESET_BOOKING_PROVIDER, payload };
}

export function fetchBookingNotificationList (payload = {}) {
  return { type: FETCH_BOOKING_NOTIFICATION_LIST, payload, meta: { thunk: true } };
}
export function fetchBookingNotificationListSuccess (payload = {}, meta) {
  return { type: FETCH_BOOKING_NOTIFICATION_LIST_SUCCESS, payload, meta };
}
export function fetchBookingNotificationListFail (payload = {}, meta) {
  return { type: FETCH_BOOKING_NOTIFICATION_LIST_FAIL, payload, meta, error: true };
}

export function readAllBookingNotification (payload = {}) {
  return { type: READ_ALL_BOOKING_NOTIFICATION, payload, meta: { thunk: true } };
}
export function readAllBookingNotificationSuccess (payload = {}, meta) {
  return { type: READ_ALL_BOOKING_NOTIFICATION_SUCCESS, payload, meta };
}
export function readAllBookingNotificationFail (payload = {}, meta) {
  return { type: READ_ALL_BOOKING_NOTIFICATION_FAIL, payload, meta, error: true };
}

export function readBookingNotification (payload = {}) {
  return { type: READ_BOOKING_NOTIFICATION, payload, meta: { thunk: true } };
}
export function readBookingNotificationSuccess (payload = {}, meta) {
  return { type: READ_BOOKING_NOTIFICATION_SUCCESS, payload, meta };
}
export function readBookingNotificationFail (payload = {}, meta) {
  return { type: READ_BOOKING_NOTIFICATION_FAIL, payload, meta, error: true };
}
