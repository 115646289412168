import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import { Row, Col, Card } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'hooks/useCustomDispatch';

import { salonDetail } from 'providers/SalonProvider/actions';

import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

import ScheduleUpdateForm from 'components/Desktop/Salon/SalonScheduleUpdateForm';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import environment from 'environment';
import './styles.less';

const CompleteSchedule = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [salon, setSalon] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async function () {
      const token = await getAccessTokenSilently({ audience: environment.api.auth0Audience });
      const user = jwtDecode(token);

      dispatch(salonDetail({
        salonId: _.get(user, '[https://user_metadata].salon_id'),
      })).then((result) => {
        setSalon(result.data);
      });
    })();
  }, []);

  return (
    <div className="complete-schedule-page">
      <Card>
        <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="request-page-row">
          <Col xs={{ span: 24 }}>
            <div className="request-logo">
              <img src={environment.logo} alt="logo" style={{ height: 60 }} />
              <h3 className="request-title">{lang.settingBusinessHours}</h3>
            </div>
            <div>
              {salon &&
            <ScheduleUpdateForm
              isCompleteProfile={true}
              salon={salon}
              onCallback={() => {
                navigate('/mysalon/settings');
              }}
            />
              }
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CompleteSchedule;
