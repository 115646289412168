// Libraries
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Table, Tag, Typography, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import _ from 'lodash';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSearchParams } from 'hooks/useCustomSearchParams';

// Components
import PageLayout from 'components/Desktop/Layout/PageLayout';
import BookingDetailModal from 'components/Desktop/Booking/booking-detail.modal';

// Providers
import { getListBookingSalonSaleReport } from 'providers/SalonProvider/actions';
import { categoryList } from 'providers/CategoryProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import { BOOKING_CUSTOMER_TYPE, BOOKING_STATUS } from 'utils/constants';
import { formatYen } from 'utils/stringFormat';
import { HANDLE_EXPORT_CSV_FILE } from 'utils/exportCSVHelper';

const { Link } = Typography;
const CSVStructJA = {
  予約番号: 'sourceId',
  施術日: 'treatmentDate',
  カスタマー名: 'customerName',
  スタッフ: 'staffName',
  メニューカテゴリー: 'menuCategory',
  メニュー名: 'menuName',
  売上金額: 'salesAmount',
  '新規/再来': 'bookingCustomerType',
  ステータス: 'status',
};
const CSVStructEN = {
  'Booking ID': 'sourceId',
  'Treatment date': 'treatmentDate',
  'Customer name': 'customerName',
  Staff: 'staffName',
  'Menu category': 'menuCategory',
  'Menu name': 'menuName',
  'Sales amount': 'salesAmount',
  'New/Repeater': 'bookingCustomerType',
  Status: 'status',
};

const SalonSalesDetail = () => {
  const dispatch = useDispatch();
  const { categoriesWithTrashed = [] } = useSelector((state) => state.category);
  const { locale } = useSelector((state) => state.i18n);
  const { salon } = useSelector(state => state.salon);
  const salonId = salon.id;
  const utcOffset = moment.tz.zone(salon.timezone).utcOffset(moment());
  const utcOffsetHours = utcOffset / 60;

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { startDate, endDate } = searchParamsValues;
  const page = parseInt(searchParamsValues.page);
  // </URLSearchParams>

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // Constant
  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/transfer-management',
      breadcrumbName: lang.transferManagement,
    },
    {
      path: '',
      breadcrumbName: lang.salesDetail,
    },
  ];

  // Columns
  const bookingColumns = [
    {
      key: 'bookingId',
      title: lang.bookingID,
      dataIndex: ['booking', 'sourceId'],
      fixed: 'left',
      align: 'center',
      render: function renderItem (value, record) {
        return (
          <div onClick={() => setBookingDetailModal(record.bookingId)}>
            <Link>{value}</Link>
          </div>);
      },
    },
    {
      key: 'startTime',
      title: lang.treatmentDate,
      dataIndex: ['booking', 'startTime'],
      align: 'center',
      render: function renderItem (record) {
        const startTime = moment(record);
        return (
          <div>
            <div>{startTime.format(locale === 'en' ? 'YYYY-MM-DD' : 'YYYY年M月D日')}</div>
            <div>{startTime.format('HH:mm')}</div>
          </div>
        );
      },
    },
    {
      key: 'customer',
      title: lang.customerName,
      dataIndex: ['booking', 'customer'],
      align: 'center',
      render: function renderItem (record) {
        return (
          <div>
            <div>{record.name}</div>
            <div>{record.phonetic}</div>
          </div >
        );
      },
    },
    {
      key: 'staff',
      title: lang.staff,
      dataIndex: ['staff'],
      align: 'center',
      render: function renderItem (record) {
        const staffConnectedInfo = _.get(record, 'connections[0].data', {});
        const staffName = staffConnectedInfo.name || record.name;

        return (
          <div>
            <div>{staffName}</div>
            <div>{staffConnectedInfo.username}</div>
          </div >
        );
      },
    },
    {
      key: 'menu',
      title: lang.menu,
      dataIndex: 'booking',
      align: 'center',
      render: function renderItem (booking) {
        const firstMenu = (booking?.orders || [])[0] || (booking?.extraInfo?.services || [])[0];
        const categoryBooking = (categoriesWithTrashed || []).find(category => {
          return (category.services || []).find(service => {
            if (service.id === firstMenu?.serviceId) {
              return true;
            }
          });
        });

        return (
          <div>
            <div>{categoryBooking?.name || firstMenu?.category?.name}</div>
            <div>{firstMenu?.serviceName}</div>
          </div>
        );
      },
    },
    {
      key: 'totalTreatmentSales',
      title: lang.salesAmount,
      dataIndex: 'totalTreatmentSales',
      align: 'center',
      render: function renderItem (record) {
        return <div style={{ textAlign: 'right' }}>{formatYen(record || 0)}</div>;
      },
    },
    {
      key: 'bookingCustomerType',
      title: lang.bookingCustomerTypeTitle,
      dataIndex: 'booking',
      align: 'center',
      render: function renderItem (record) {
        const bookingStatus = (record?.notes || []).find(note => note.type === 'targetSystemStatus')?.value;
        if (['CANCELED', 'NOVISIT'].includes(bookingStatus)) {
          return <div>-</div>;
        } else {
          return <div >{lang[BOOKING_CUSTOMER_TYPE[record?.extraInfo?.customerBookingBadge].langKey]}</div>;
        }
      },
    },
    {
      key: 'bookingStatus',
      title: lang.status,
      dataIndex: ['booking', 'notes'],
      align: 'center',
      render: function renderItem (record) {
        const bookingStatus = record.find(note => note.type === 'targetSystemStatus')?.value;

        return (
          <Tag color={BOOKING_STATUS[bookingStatus]?.color}>
            {lang[BOOKING_STATUS[bookingStatus]?.langKey]}
          </Tag>
        );
      },
    },
  ];

  // State
  const [listBooking, setListBooking] = useState({});
  const { pagination = {} } = listBooking;
  const listBookingData = listBooking.data || [];
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingDetailModal, setBookingDetailModal] = useState(null);
  const csvFileName = locale === 'en' ? `salesdetail-list-${startDate}_${endDate}_${salon.businessName}.csv` : `${salon.businessName}_${startDate}_${endDate}-売上金額の詳細.csv`;

  const getListBooking = () => {
    const params = {
      salonId,
      page,
      limit: 50,
      from: moment(startDate, 'YYYY-MM-DD').startOf('day').subtract(utcOffsetHours, 'hours').toISOString(),
      to: moment(endDate, 'YYYY-MM-DD').endOf('day').subtract(utcOffsetHours, 'hours').toISOString(),
    };

    setBookingLoading(true);
    dispatch(getListBookingSalonSaleReport(params))
      .then((result) => {
        setListBooking(result);
        setBookingLoading(false);
      });
  };

  useEffect(() => {
    dispatch(categoryList({ withTrashed: 1 }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(searchParamsValues)) {
      getListBooking();
    }
  }, [page, startDate, endDate]);

  const exportSalonSalesDetailCSVFile = () => {
    const params = {
      salonId,
      page: 1,
      limit: 50000,
      from: moment(startDate, 'YYYY-MM-DD').startOf('day').subtract(utcOffsetHours, 'hours').toISOString(),
      to: moment(endDate, 'YYYY-MM-DD').endOf('day').subtract(utcOffsetHours, 'hours').toISOString(),
    };

    setBookingLoading(true);
    dispatch(getListBookingSalonSaleReport(params))
      .then((result) => {
        HANDLE_EXPORT_CSV_FILE.handleExportSalonSaleReportDetailCSV(result?.data, CSVStructEN, CSVStructJA, csvFileName, locale, lang, categoriesWithTrashed);
        setBookingLoading(false);
      }).catch(() => {
        setBookingLoading(false);
      });
  };

  return (
    <PageLayout
      ghost={false}
      title={lang.salesDetail}
      routes={routes}
      onBack={() => window.history.back()}
    >
      <Card>
        <div style={{ textAlign: 'right', marginBottom: 16 }}>
          <Button
            type="primary"
            onClick={() => exportSalonSalesDetailCSVFile(listBookingData)}
            icon={<ExportOutlined />}
            disabled={listBookingData.length === 0}
          >
            {lang.exportCSV}
          </Button>
        </div>
        <Table
          columns={bookingColumns}
          dataSource={listBookingData}
          loading={bookingLoading}
          bordered
          onChange={(pagination, filters, sorter) => {
            const search = {
              ...searchParamsValues,
              page: pagination.current,
            };
            handleSetSearchParams(search);
          }}
          pagination={{
            total: pagination.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSize: pagination.perPage,
            current: pagination.page,
            showSizeChanger: false,
            onChange: (page) => window.scrollTo(0, 0),
          }}
          scroll={{ x: 920 }}
        />
      </Card>

      <BookingDetailModal
        bookingId={bookingDetailModal}
        closeBookingDetailModal={() => setBookingDetailModal(null)}
        onCallbackBookingDetailModal={getListBooking}
      />
    </PageLayout>
  );
};

export default SalonSalesDetail;
