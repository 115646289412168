import _ from 'lodash';

export const FILE_HELPER = {
  makeCSV (dataList, struct) {
    const header = Object.keys(struct);
    const result = dataList.map(data => {
      let line = '';
      for (const keyPath in struct) {
        line += (`"${_.get(data, struct[keyPath], '')}",`);
      }
      return line;
    });
    result.unshift(`"${header.join('","')}",`);
    return result.join('\n');
  },

  saveFileCSV (data, name) {
    const a = document.createElement('a');
    a.href = 'data:text/csv;UTF-8,%EF%BB%BF' + encodeURIComponent(data);
    a.download = name || 'booking_list.csv';
    a.hidden = true;
    document.body.appendChild(a);
    a.innerHTML = '';
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    }, 500);
  },
};
