// Libraries
import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Typography, Modal, Input, Tabs, Button, Form } from 'antd';
import { ExclamationCircleOutlined, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
// Providers
import { toggle3rdParty } from 'providers/SalonProvider/actions';
// Utils
import { getLanguages } from 'utils/lang';
import { useDispatch } from 'hooks/useCustomDispatch';
import SalonConnectBeautyMerit from './SalonConnectBeautyMerit';
import logoComingSoon from 'assets/images/coming-soon.svg';
import './styles.less';
import BigSpin from '../Commons/BigSpin';
import { THIRD_PARTIES_CODE } from 'utils/constants';
import StaffsMappingContainer from './SalonStaffConnectComingSoonForm';
import { useSelector } from 'react-redux';
import SalonConnectComingSoon from './SalonConnectComingSoon';
const { Text } = Typography;
const { TabPane } = Tabs;

const BM_CODE = 'BM';
const CS_CODE = 'CS';
export const ComingSoonContext = createContext();

let currentModalControl = null;
let modalConnectControl = null;
let modalWarningControl = null;

const TokenInput = ({ lang }) => {
  const [form] = Form.useForm();
  return (
    <Form form ={form} validateTrigger={['onBlur', 'onChange']} >
      <div style={{ marginBottom: 16 }}><Text>{lang.connectCSInputTokenDescription}</Text></div>
      <Form.Item
        label={lang.tokenLabel}
        name='token_input_3rd_party'
        rules={[{ required: true, message: lang.inputTokenCSError }]}
      >
        <Input
          id='token_input_3rd_party'
          placeholder={lang.inputTokenCSPlaceholder}
        />
      </Form.Item>
    </Form>
  );
};

const SalonConnectThirdParties = ({ salon, setSalon, onCallback }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isOpenInitSettingMappingPopup, setIsOpenInitSettingMappingPopup] = useState(false);
  const connectingParty = salon?.thirdParties[0]?.code; // CS_CODE || BM_CODE
  const locale = useSelector((state) => state.i18n.locale);
  const acceptanceDate = salon?.acceptBookingTime?.maximum;

  // Close all modal when component unmount (button back of browser)
  useEffect(() => {
    return () => {
      if (currentModalControl) {
        currentModalControl.destroy();
      }
      if (modalConnectControl) {
        modalConnectControl.destroy();
      }
      if (modalWarningControl) {
        modalWarningControl.destroy();
      }
    };
  }, []);

  const dispatchNewAndRefresh3rdParty = ({ code, tokenText }) => {
    setLoading(true);
    return dispatch(toggle3rdParty({ code, tokenText }))
      .then(() => {
        _.set(salon, 'thirdParties[0].code', code);
        setSalon(salon);
        setTimeout(() => setLoading(false), 300);
        onCallback();
      })
      .catch(() => setLoading(false));
  };

  const handleToggle3rdPartyWithToken = ({ code }) => {
    return new Promise((resolve) => {
      currentModalControl = Modal.confirm({
        title: lang.connectWithCSTitle,
        width: '600px',
        icon: <ExclamationCircleOutlined />,
        content: <TokenInput lang={lang}/>,
        okText: lang.connect,
        centered: true,
        cancelText: lang.cancel,
        onOk: async () => {
          const tokenText = document.getElementById('token_input_3rd_party');
          const tokenValue = tokenText.value === '' ? undefined : tokenText.value;
          // trigger validate field
          tokenText.focus();
          tokenText.blur();
          if (tokenValue) {
            await dispatchNewAndRefresh3rdParty({ code, tokenText: tokenValue });
            setIsOpenInitSettingMappingPopup(true);
            currentModalControl.destroy();
            resolve(true);
          }
          // prevent close modal
          throw new Error('form error');
        },
      });
    });
  };

  const showWarningDisconnect3rdParty = (CODE) => {
    modalWarningControl = Modal.warning({
      title: lang.warningDisconnectOther3rdPartyTitle,
      width: '500px',
      icon: <ExclamationCircleOutlined />,
      content: <>
        <Text>{lang.warningDisconnectOther3rdPartyHead}</Text><br />
        <Text>{lang.warningDisconnectOther3rdPartyTail}</Text>
      </>,
      centered: true,
      onOk: () => modalWarningControl.destroy(),
    });
  };
  const handleToggle3rdParty = ({ code, connectingParty }) => {
    return new Promise(resolve => {
      const isConnectAction = code !== connectingParty;
      const isSwitchConnectionAction = isConnectAction && connectingParty !== undefined;
      const isDisconnectAction = !isConnectAction;

      let content = '';
      if (isConnectAction || isSwitchConnectionAction) {
        content = lang[THIRD_PARTIES_CODE[code]?.langKeyConnect];
      } else if (isDisconnectAction) {
        content = lang[THIRD_PARTIES_CODE[connectingParty].langKeyDisConnect1] + '\n' + lang[THIRD_PARTIES_CODE[connectingParty].langKeyDisConnect2];
        if (connectingParty === BM_CODE && locale === 'ja') {
          content = `ビューティーメリットとの予約連携を解除します。よろしいですか？
          ※連携を解除すると、受付開始日（${acceptanceDate}日後）以降のスケジュールは営業時間設定に基づき「○」となります`;
        }
        if (connectingParty === CS_CODE && locale === 'ja') {
          content = `スタッフ全員のカミングスーンとの予約連携を解除します。よろしいですか？
          ※連携を解除すると、受付開始日（${acceptanceDate}日後）以降のスケジュールは営業時間設定に基づき「○」となります`;
        }
      }

      const toastModal = () => {
        modalConnectControl = Modal.confirm({
          className: 'connect-control-modal',
          title: lang.confirm,
          width: '790px',
          icon: <ExclamationCircleOutlined />,
          content: content,
          okText: lang.sure,
          centered: true,
          cancelText: lang.no,
          onOk: async () => {
            if ([CS_CODE].includes(code) && isConnectAction) {
              handleToggle3rdPartyWithToken({ code });
              modalConnectControl.destroy();
            } else {
              await dispatchNewAndRefresh3rdParty({ code });
              modalConnectControl.destroy();
              window.scrollTo(0, 0);
            }
            resolve(true);
          },
        });
      };

      if (isSwitchConnectionAction) {
        handleToggle3rdParty({ code: connectingParty, connectingParty: connectingParty }) // toggle off current party
          .then(toastModal);
      } else {
        toastModal();
      }
    });
  };

  const renderButtonDisconnect3rd = (connectingParty) => {
    if (connectingParty === BM_CODE) {
      return (
        <Button
          style={{ marginTop: 24 }}
          type='primary'
          danger
          ghost
          onClick={() => handleToggle3rdParty({ code: BM_CODE, connectingParty })}
        >
          {lang.disconnectWithBMBtn}
        </Button>
      );
    } else if (connectingParty === CS_CODE) {
      return (
        <Button
          style={{ marginRight: 16 }}
          type='primary'
          danger
          ghost
          onClick={() => handleToggle3rdParty({ code: CS_CODE, connectingParty })}
        >
          {lang.disconnectWithCSBtn}
        </Button>
      );
    }
  };

  return (
    <ComingSoonContext.Provider value={{ isOpenInitSettingMappingPopup, setIsOpenInitSettingMappingPopup }}>
      <div>
        <InfoCircleOutlined /> <Text>{!(process.env.REACT_APP_CONF_SHOW_CS === 'true') ? 'ビューティーメリットに連携すると、スケジュールの◯×がサロンマネージャーから編集できなくなります。よろしいですか。' : lang.salonConnect3rdPartiesScheduleDescription}</Text>
      </div>
      <div id="connection-container">
        {loading && <BigSpin />}
        <div style={{ marginTop: 16 }}>
          <Tabs
            defaultActiveKey={connectingParty}
            tabPosition="top"
            style={{ marginTop: -20 }}
            destroyInactiveTabPane
          >
            <TabPane tab={lang.beautyMerit} key="BM">
              <SalonConnectBeautyMerit
                connectingParty={connectingParty}
                handleToggle3rdParty={handleToggle3rdParty}
                showWarningDisconnect3rdParty={showWarningDisconnect3rdParty}
              />
            </TabPane>
            {process.env.REACT_APP_CONF_SHOW_CS === 'true' &&
              <TabPane tab={lang.comingSoon} key="CS">
                {connectingParty === CS_CODE && <Text style={{ marginBottom: 16 }}>{lang.connectedWithCS}</Text>}
                {connectingParty !== CS_CODE &&
                  <SalonConnectComingSoon
                    connectingParty={connectingParty}
                    handleToggle3rdParty={handleToggle3rdParty}
                    showWarningDisconnect3rdParty={showWarningDisconnect3rdParty}
                  />
                }
                {
                  connectingParty === CS_CODE &&
                  <div
                    className="coming-soon-connection connect-3rd-party-banner"
                    style={connectingParty === CS_CODE ? { backgroundColor: '#F6FFED' } : {}}
                  >
                    <img src={logoComingSoon} className='connect-3rd-logo' />
                    {connectingParty === CS_CODE &&
                      <div className='connected-parties'>
                        <span className="connected-text">{lang.connected}</span>
                        <CheckCircleFilled />
                      </div>}
                  </div>
                }

                {connectingParty === CS_CODE && <StaffsMappingContainer
                  connectingParty={connectingParty}
                  renderButtonDisconnect3rd={renderButtonDisconnect3rd}

                />}
              </TabPane>
            }
          </Tabs>
        </div>
      </div>
    </ComingSoonContext.Provider>
  );
};

SalonConnectThirdParties.propTypes = {
  salon: PropTypes.object,
  setSalon: PropTypes.func,
  onCallback: PropTypes.func,
};

export default SalonConnectThirdParties;
