import {

  paymentCompletionFail,
  paymentCompletionSuccess,
  paymentDetailFail,
  paymentDetailSuccess,
  paymentListFail, paymentListSuccess, paymentRefundFail, paymentRefundSuccess, paymentSummaryFail, paymentSummarySuccess, paymentTipFail, paymentTipSuccess,
} from './actions';
import {
  PAYMENT_COMPLETION,
  PAYMENT_DETAIL,
  PAYMENT_LIST, PAYMENT_REFUND, PAYMENT_SUMMARY, PAYMENT_TIPS,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

function * paymentList (action) {
  try {
    let systemId = '';
    if (action.payload.systemId) {
      action.payload.systemId.forEach((item) => {
        systemId = `${item},${systemId}`;
      });
    }

    const { data } = yield call(request, environment.api.payments,
      {
        page: action.payload.page || 1,
        limit: action.payload.limit || 100000,
        sort: action.payload.sortedInfo,
        systemId: systemId.substr(0, systemId.length - 1) || undefined,
        from: action.payload?.from,
        to: action.payload?.to,
        status: action.payload.status,
        keyword: action.payload.searchValue,
        method: action.payload.method,
      }, 'GET', action.payload?.token);
    yield put(paymentListSuccess({ data }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(paymentListFail('エーロが発生しました'));
  }
}

function * paymentSummary (action) {
  try {
    const { data } = yield call(request, environment.api.paymentSummary,
      {
        from: action.payload?.from,
        to: action.payload?.to,
      }, 'GET', action.payload?.token);
    yield put(paymentSummarySuccess({ data }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(paymentSummaryFail('エーロが発生しました'));
  }
}

function * paymentCompletion (action) {
  try {
    const { id, token } = action.payload;
    yield call(request, `${environment.api.paymentCompletion}/${id}/completion`, {}, 'POST', token);
    yield put(paymentCompletionSuccess({}, action.meta));
    Alert.success('売上確定済み!');
  } catch (error) {
    yield put(paymentCompletionFail('エーロが発生しました'));
  }
}
function * paymentDetail (action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(request, `${environment.api.paymentDetail}/${id}`, {}, 'GET', action.payload?.token);
    yield put(paymentDetailSuccess({ data }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(paymentDetailFail('エーロが発生しました'));
  }
}

function * paymentRefund (action) {
  console.log('🚀 ~ file: saga.js ~ line 91 ~ *paymentRefund ~ action', action);
  try {
    const { values } = action.payload;
    const { data } = yield call(request, `${environment.api.paymentRefund}/${values.paymentId}/refund`, {
      notes: values.notes,
      noteCategory: values.noteCategory,
    }, 'POST', action.payload.token);
    yield put(paymentRefundSuccess({ data }, action.meta));
    Alert.success('更新元取引しました!');
  } catch (error) {
    yield put(paymentRefundFail('エーロが発生しました'));
    Alert.error('更新元取引しました!');
  }
}

function * paymentTips (action) {
  try {
    const { paymentId } = action.payload;
    const { data } = yield call(request, `${environment.api.paymentTips}/${paymentId}/tips`,
      {
        tips: action.payload.tips,
      }, 'PUT', action.payload?.token);
    yield put(paymentTipSuccess({ data }, action.meta));
  } catch (error) {
    // Alert.error("新しいチップを追加する前に、入力したチップを削除してください。");
    yield put(paymentTipFail({}, action.meta));
  }
}

export default function * watchPayments () {
  yield takeEvery(PAYMENT_LIST, paymentList);
  yield takeEvery(PAYMENT_SUMMARY, paymentSummary);
  yield takeLeading(PAYMENT_COMPLETION, paymentCompletion);
  yield takeLeading(PAYMENT_DETAIL, paymentDetail);
  yield takeLeading(PAYMENT_REFUND, paymentRefund);
  yield takeLeading(PAYMENT_TIPS, paymentTips);
}
