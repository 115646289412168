import {
  bookingGetStaffsByServiceFail,
  bookingGetStaffsByServiceSuccess,
  bookingListSuccess,
  bookingDetailFail,
  bookingDetailSuccess,
  bookingListFail,
  bookingCreateSuccess,
  bookingCreateFail,
  bookingUpdateSuccess,
  bookingUpdateFail,
  bookingCancelSuccess,
  bookingCancelFail,
  bookingFinishSuccess,
  bookingFinishFail,
  quickPaymentSuccess,
  quickPaymentFail,
  getAppsSuccess,
  getAppsFail,
  bookingTipsSuccess,
  bookingTipsFail,
  bookingDeleteTipsSuccess,
  bookingDeleteTipsFail,
  getPaymentByBookingIdSuccess,
  getPaymentByBookingIdFail,
  getOutsideBookingsByStaffSuccess,
  getOutsideBookingsByStaffFail,
  bookingAcceptanceTimeSuccess,
  bookingAcceptanceTimeFail,
  fetchBookingsForCSVSuccess,
  fetchBookingsForCSVFail,
  fetchBookingNotificationListSuccess,
  fetchBookingNotificationListFail,
  readAllBookingNotificationSuccess,
  readAllBookingNotificationFail,
  readBookingNotificationSuccess,
  readBookingNotificationFail,
  bookingListOnChangeDateSuccess,
  bookingListWaitingDoneSuccess,
  getOutsideBookingsBySalonSuccess,
  getOutsideBookingsBySalonFail,
} from './actions';
import {
  BOOKING_GET_STAFFS_BY_SERVICE,
  BOOKING_LIST,
  BOOKING_CREATE,
  BOOKING_UPDATE,
  BOOKING_DETAIL,
  BOOKING_CANCEL,
  BOOKING_FINISH,
  QUICK_PAYMENT,
  GET_APPS,
  BOOKING_TIPS,
  BOOKING_DELETE_TIPS,
  GET_PAYMENT_BYBOOKINGID,
  GET_OUTSIDE_BOOKINGS_BY_SALON,
  BOOKING_ACCEPTANCE_TIME,
  BOOKING_UPDATE_SERVICES,
  FETCH_BOOKINGS_FOR_CSV,
  FETCH_BOOKING_NOTIFICATION_LIST,
  READ_ALL_BOOKING_NOTIFICATION,
  READ_BOOKING_NOTIFICATION,
  BOOKING_LIST_ON_SCHEDULE,
  BOOKING_LIST_ON_CHANGE_DATE,
  BOOKING_LIST_WAITING_DONE,
  GET_OUTSIDE_BOOKINGS_BY_STAFF,
} from './types';
import _ from 'lodash';
import { put, takeLeading, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';
import moment from 'moment';
import { BOOKING_SEARCH_STATUS, systemId } from 'utils/constants';
import BookingStatusHelper from '../../utils/bookingStatusHelper';

function * bookingDetail (action) {
  try {
    const { bookingId, token } = action.payload;
    const booking = yield call(request, `${environment.api.getBooking}/${bookingId}`, {}, 'GET', token);
    const CANCELED_STATUS = ['CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'];
    const NAILIST_CANCELED_STATUS = ['NAILIST_CANCELED', 'DECLINED_RESERVATION'];
    const statusNotesIndex = (booking?.data?.notes || []).findIndex(note => note.type === 'targetSystemStatus');
    // Canceled includes customer's CANCELED_RESERVATION and CARD_ERROR_CANCEL
    if (CANCELED_STATUS.includes(_.get(booking?.data, `notes[${statusNotesIndex}].value`))) {
      _.set(booking.data, `notes[${statusNotesIndex}].value`, 'CANCELED');
    }
    // Nailist canceled includes NAILIST_CANCELED and DECLINED_RESERVATION
    if (NAILIST_CANCELED_STATUS.includes(_.get(booking?.data, `notes[${statusNotesIndex}].value`))) {
      _.set(booking.data, `notes[${statusNotesIndex}].value`, 'NAILIST_CANCELED');
    }
    // Format data for show booking confirmed imported after join salon
    booking.data = BookingStatusHelper.formatImportedBookingIntoNormalOrder(booking.data);
    yield put(bookingDetailSuccess(booking, action.meta));
  } catch (error) {
    yield put(bookingDetailFail(error.message, action.meta));
  }
}

function * bookingGetStaffsByService (action) {
  try {
    const { serviceId, startTime, token } = action.payload;
    const staffs = yield call(request, environment.api.getBookingStaff, { serviceId, startTime }, 'GET', token);

    yield put(bookingGetStaffsByServiceSuccess(staffs.data, action.meta));
  } catch (error) {
    yield put(bookingGetStaffsByServiceFail('アカウントをもう一回チェックして、後にログインしてください', action.meta));
  }
}

function * bookingList (action) {
  try {
    const params = {
      ..._.omit(action.payload, ['token']),
      systemId,
    };
    if (!params.targetSystemStatus) {
      params.targetSystemStatus = BOOKING_SEARCH_STATUS.join(',');
    }
    let filteredStatusArr = params.targetSystemStatus.split(',');
    // Canceled includes customer's CANCELED_RESERVATION and CARD_ERROR_CANCEL
    if (filteredStatusArr.includes('CANCELED')) {
      filteredStatusArr = [...filteredStatusArr, 'CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'].filter(item => item !== 'CANCELED');
    }
    // Nailist canceled includes NAILIST_CANCELED and DECLINED_RESERVATION
    if (filteredStatusArr.includes('NAILIST_CANCELED')) {
      filteredStatusArr = [...filteredStatusArr, 'DECLINED_RESERVATION'];
    }
    params.targetSystemStatus = filteredStatusArr.join(',');

    const { data } = yield call(request, environment.api.getBookings, params, 'GET', action.payload?.token);
    data.data = data.data.map(result => {
      const CANCELED_STATUS = ['CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'];
      const NAILIST_CANCELED_STATUS = ['NAILIST_CANCELED', 'DECLINED_RESERVATION'];
      const statusNotesIndex = (result.notes || []).findIndex(note => note.type === 'targetSystemStatus');

      if (CANCELED_STATUS.includes(_.get(result, `notes[${statusNotesIndex}].value`))) {
        _.set(result, `notes[${statusNotesIndex}].value`, 'CANCELED');
      }

      if (NAILIST_CANCELED_STATUS.includes(_.get(result, `notes[${statusNotesIndex}].value`))) {
        _.set(result, `notes[${statusNotesIndex}].value`, 'NAILIST_CANCELED');
      }
      result = BookingStatusHelper.formatImportedBookingIntoNormalOrder(result);

      return result;
    });
    // Fetch list booking for change date
    if (action.type === 'BOOKING_LIST_ON_CHANGE_DATE') {
      yield put(bookingListOnChangeDateSuccess({ bookings: data }, action.meta));
      return;
    }
    // Fetch list booking for status waitingDone
    if (action.type === 'BOOKING_LIST_WAITING_DONE') {
      yield put(bookingListWaitingDoneSuccess({
        bookings: data,
      }, action.meta));
      return;
    }

    yield put(bookingListSuccess({
      bookings: data,
    }, action.meta));
  } catch (error) {
    yield put(bookingListFail({}, action.meta));
  }
}

function * bookingCreate (action) {
  try {
    const { values, discount, removeKey } = action.payload;
    const services = values.services.map((item, index) => {
      if (removeKey.includes(index) === false) {
        return ({
          ...item,
          startTime: moment(`${values.bookingDate.format('YYYY/MM/DD')} ${item.startTime.format('HH:mm')}`, 'YYYY/MM/DD HH:mm').utc().format(),
        });
      } else {
        return null;
      }
    },
    );

    yield call(request, environment.api.bookingCreate,
      {
        ...values,
        services: services.filter(item => item !== null),
        discount,
      }, 'POST', action.payload.token);

    yield put(bookingCreateSuccess({}, action.meta));
    Alert.success('作成完了');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(bookingCreateFail());
  }
}

function * bookingUpdate (action) {
  try {
    const { booking, status, discount, totalPrice, token } = action.payload;
    yield call(request, `${environment.api.bookingUpdate}/${booking.bookingId}`,
      {
        ...booking,
        services: [],
        status,
        discount: discount === undefined ? 0 : discount,
        totalPrice,
      }, 'PUT', token);
    yield put(bookingUpdateSuccess({}, action.meta));
    Alert.success('設定を変更しました');

    // if (booking.systemId === undefined || booking.systemId === null) {
    //   console.log('MySalon')
    //   yield call(request, `${environment.api.bookingUpdate}/${booking.bookingId}`,
    //   {
    //       ...booking,
    //       services: [],
    //       status,
    //       discount: discount === undefined ? 0 : discount,
    //       totalPrice,
    //   }, 'PUT');
    //   yield put(bookingUpdateSuccess({}, action.meta));
    //   Alert.success('編集を成功しました');
    // } else {
    //   console.log('Nailie')
    //   yield call(request, `${environment.api.bookingSystemUpdate}/${booking.bookingId}`,
    //   {
    //       ...booking,
    //       services: [],
    //       status,
    //       discount: discount === undefined ? 0 : discount,
    //       totalPrice,
    //   }, 'PUT');
    //   yield put(bookingUpdateSuccess({}, action.meta));
    //   Alert.warning('Update booking for Nailie is building. Pls waiting for later!');
    // }
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(bookingUpdateFail());
  }
}

function * bookingCancel (action) {
  try {
    const { bookingId, reason, extraReason, extraInfo, token } = action.payload;
    yield call(request, `${environment.api.bookingUpdate}/${bookingId}/cancellation`,
      { reason, extraReason, extraInfo }, 'POST', token);

    yield put(bookingCancelSuccess({}, action.meta));
    Alert.success('予約キャンセルが完了しました');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(bookingCancelFail());
  }
}

function * bookingFinish (action) {
  try {
    const { bookingId, body, token } = action.payload;
    yield call(
      request,
      `${environment.api.bookingFinish}/${bookingId}/finishing`,
      body,
      'POST',
      token,
    );

    yield put(bookingFinishSuccess({}, action.meta));
    Alert.success('お会計を完了しました');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(bookingFinishFail({}, action.meta));
  }
}

function * quickPayment (action) {
  try {
    const { booking, status, token } = action.payload;
    yield call(request, `${environment.api.bookingUpdate}/${booking.bookingId}`,
      {
        ...booking,
        services: [],
        status,
      }, 'PUT', token);

    yield put(quickPaymentSuccess({}, action.meta));
    // Alert.success('Payment successfully');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(quickPaymentFail({}, action.meta));
  }
}

function * getApps (action) {
  try {
    const result = yield call(request, `${environment.api.getApps}`, {}, 'GET', action.payload.token);
    yield put(getAppsSuccess({ data: result.data }, action.meta));
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `;
      });
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(getAppsFail({}, action.meta));
  }
}

function * bookingTips (action) {
  try {
    const { bookingId } = action.payload;
    const { data } = yield call(request, `${environment.api.bookingTips}/${bookingId}/tips`,
      {
        amount: action.payload.tip,
        staffId: action.payload.idStaff,
      }, 'POST');
    yield put(bookingTipsSuccess({ data }, action.meta));
  } catch (error) {
    Alert.error('新しいチップを追加する前に、入力したチップを削除してください。');
    yield put(bookingTipsFail({}, action.meta));
  }
}

function * bookingDeleteTips (action) {
  try {
    const { bookingId } = action.payload;
    const { id } = action.payload;
    const { data } = yield call(request, `${environment.api.bookingDeleteTips}/${bookingId}/tips/${id}`, {
      bookingId: action.payload.bookingId,
      tipId: action.payload.id,
    }, 'DELETE');
    yield put(bookingDeleteTipsSuccess({ data }, action.meta));
  } catch (error) {
    yield put(bookingDeleteTipsFail({}, action.meta));
  }
}

function * getPaymentByBookingId (action) {
  try {
    const { bookingId, token } = action.payload;
    const { data } = yield call(request, `${environment.api.getPaymentByBookingId}/${bookingId}/payments`, {}, 'GET', token);
    yield put(getPaymentByBookingIdSuccess({ data }, action.meta));
  } catch (error) {
    yield put(getPaymentByBookingIdFail({}, action.meta));
  }
}

function * getOutsideBookingsByStaff (action) {
  try {
    const { staffId, token } = action.payload;
    const { data } = yield call(request, `${environment.api.getOutsideBookingsByStaff}/${staffId}`, {}, 'GET', token);
    yield put(getOutsideBookingsByStaffSuccess(data, action.meta));
  } catch (error) {
    yield put(getOutsideBookingsByStaffFail({}, action.meta));
  }
}
function * getOutsideBookingsBySalon (action) {
  try {
    const { token } = action.payload;
    const { data } = yield call(request, environment.api.getOutsideBookingsBySalon, {}, 'GET', token);
    yield put(getOutsideBookingsBySalonSuccess(data, action.meta));
  } catch (error) {
    yield put(getOutsideBookingsBySalonFail({}, action.meta));
  }
}

function * bookingAcceptanceTime (action) {
  try {
    const { salonId, token, values } = action.payload;
    const { data } = yield call(request, `${environment.api.bookingAcceptanceTime}/${salonId}/acceptBookingTime`,
      {
        minimum: values.acceptanceTime,
        maximum: values.acceptanceDate,
      }, 'PUT', token);
    yield put(bookingAcceptanceTimeSuccess(data, action.meta));
    Alert.success('設定を変更しました');
  } catch (error) {
    Alert.error(`${error.message}`);
    yield put(bookingAcceptanceTimeFail({}, action.meta));
  }
}

function * bookingUpdateServices (action) {
  try {
    const { bookingId, body, token } = action.payload;
    yield call(
      request,
      `${environment.api.bookingUpdate}/${bookingId}/services`,
      body,
      'PUT',
      token,
    );
    yield put(bookingUpdateSuccess({}, action.meta));
  } catch (error) {
    Alert.error('こちらの日時は無効です。別の日時を選択してください');
    yield put(bookingUpdateFail({}, action.meta));
  }
}
// Fetch all booking list for export csv file
function * fetchBookingsForCSV (action) {
  try {
    const params = {
      ..._.omit(action.payload, ['token']),
      systemId,
    };
    if (!params.targetSystemStatus) {
      params.targetSystemStatus = BOOKING_SEARCH_STATUS.join(',');
    }
    let filteredStatusArr = params.targetSystemStatus.split(',');
    // Canceled includes customer's CANCELED_RESERVATION and CARD_ERROR_CANCEL
    if (filteredStatusArr.includes('CANCELED')) {
      filteredStatusArr = [...filteredStatusArr, 'CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'].filter(item => item !== 'CANCELED');
    }
    // Nailist canceled includes NAILIST_CANCELED and DECLINED_RESERVATION
    if (filteredStatusArr.includes('NAILIST_CANCELED')) {
      filteredStatusArr = [...filteredStatusArr, 'DECLINED_RESERVATION'];
    }
    params.targetSystemStatus = filteredStatusArr.join(',');

    const { data } = yield call(request, environment.api.getBookings, params, 'GET', action.payload?.token);
    yield put(fetchBookingsForCSVSuccess({
      bookings: data,
    }, action.meta));
  } catch (error) {
    yield put(fetchBookingsForCSVFail({}, action.meta));
  }
}

function * fetchBookingNotificationList (action) {
  const addHead = action.payload.page === 1;
  const { data, list } = yield select(state => state.booking.notification);
  const idsNoti = list.map(id => { return Number(id); });
  const lastestId = Math.max(...idsNoti);
  const lastestNotiCreated = data[lastestId]?.createdAt;

  try {
    const params = {
      ..._.omit(action.payload, ['token']),
      systemId,
      fromCreatedAt: addHead ? lastestNotiCreated : undefined,
    };
    const { data } = yield call(request, environment.api.getBookingNotification, params, 'GET', action.payload?.token);
    data.data = data.data.map(result => {
      const CANCELED_STATUS = ['CANCELED_RESERVATION', 'CARD_ERROR_CANCEL'];
      const statusNotesIndex = (result.booking.notes || []).findIndex(note => note.type === 'targetSystemStatus');
      if (CANCELED_STATUS.includes(_.get(result, `booking.notes[${statusNotesIndex}].value`))) {
        _.set(result, `booking.notes[${statusNotesIndex}].value`, 'CANCELED');
      }
      return result;
    });
    yield put(fetchBookingNotificationListSuccess({
      data: data.data.filter(bk => {
        const DELETED_STATUS = ['PERMANENT_DELETED'];
        const targetSystemStatus = (bk.booking.notes || []).find(note => note.type === 'targetSystemStatus');
        const bookingStatus = _.get(targetSystemStatus, 'value');
        return !DELETED_STATUS.includes(bookingStatus);
      }),
      pagination: data.pagination,
      addHead,
    }, action.meta));
  } catch (error) {
    yield put(fetchBookingNotificationListFail({}, action.meta));
  }
}

function * readAllBookingNotification (action) {
  try {
    yield call(request, environment.api.readAllBookingNotification, {}, 'POST', action.payload?.token);
    yield put(readAllBookingNotificationSuccess({}, action.meta));
  } catch (error) {
    yield put(readAllBookingNotificationFail({}, action.meta));
  }
}

function * readBookingNotification (action) {
  const { id, token } = action.payload;
  try {
    yield call(request, `${environment.api.readBookingNotification}/${id}/read`, {}, 'POST', token);
    yield put(readBookingNotificationSuccess({ id }, action.meta));
  } catch (error) {
    yield put(readBookingNotificationFail({}, action.meta));
  }
}

export default function * watchNailist () {
  yield takeEvery(BOOKING_LIST, bookingList);
  yield takeLatest(BOOKING_LIST_ON_SCHEDULE, bookingList);
  yield takeEvery(BOOKING_LIST_ON_CHANGE_DATE, bookingList);
  yield takeEvery(BOOKING_LIST_WAITING_DONE, bookingList);
  yield takeEvery(BOOKING_GET_STAFFS_BY_SERVICE, bookingGetStaffsByService);
  yield takeLeading(BOOKING_CREATE, bookingCreate);
  yield takeLeading(BOOKING_UPDATE, bookingUpdate);
  yield takeLeading(QUICK_PAYMENT, quickPayment);
  yield takeEvery(BOOKING_DETAIL, bookingDetail);
  yield takeLeading(BOOKING_CANCEL, bookingCancel);
  yield takeLeading(BOOKING_FINISH, bookingFinish);
  yield takeLeading(GET_APPS, getApps);
  yield takeEvery(BOOKING_TIPS, bookingTips);
  yield takeEvery(BOOKING_DELETE_TIPS, bookingDeleteTips);
  yield takeLeading(GET_PAYMENT_BYBOOKINGID, getPaymentByBookingId);
  yield takeLeading(GET_OUTSIDE_BOOKINGS_BY_STAFF, getOutsideBookingsByStaff);
  yield takeLeading(GET_OUTSIDE_BOOKINGS_BY_SALON, getOutsideBookingsBySalon);
  yield takeLeading(BOOKING_ACCEPTANCE_TIME, bookingAcceptanceTime);
  yield takeLeading(BOOKING_UPDATE_SERVICES, bookingUpdateServices);
  yield takeLeading(FETCH_BOOKINGS_FOR_CSV, fetchBookingsForCSV);
  yield takeEvery(FETCH_BOOKING_NOTIFICATION_LIST, fetchBookingNotificationList);
  yield takeEvery(READ_ALL_BOOKING_NOTIFICATION, readAllBookingNotification);
  yield takeEvery(READ_BOOKING_NOTIFICATION, readBookingNotification);
}
