// Libraries
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Table, Avatar, DatePicker, Select, Button, Tag, Form, Typography, AutoComplete, Space } from 'antd';
import { SearchOutlined, UserOutlined, ExportOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import _ from 'lodash';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSearchParams, shouldFetchApi, shouldSetSearchParams } from 'hooks/useCustomSearchParams';

// Components
import PageLayout from 'components/Desktop/Layout/PageLayout';
import BookingDetailModal from 'components/Desktop/Booking/booking-detail.modal';

// Providers
import { getListStaffSaleReport, getListBookingStaffSaleReport, fetchStaffList } from 'providers/StaffProvider/actions';
import { categoryList } from 'providers/CategoryProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import { formatYen } from 'utils/stringFormat';
import { BOOKING_CUSTOMER_TYPE, BOOKING_STATUS, SALES_REPORT_PERIOD } from 'utils/constants';
import { formatDataHas0Value } from 'utils/common';
import SalesReportHelper from 'utils/salesReportHelper';
import { HANDLE_EXPORT_CSV_FILE } from 'utils/exportCSVHelper';

const { Option } = Select;
const { Text, Link } = Typography;
const CSVStructJA = {
  予約番号: 'sourceId',
  施術日: 'treatmentDate',
  カスタマー名: 'customerName',
  メニューカテゴリー: 'menuCategory',
  メニュー名: 'menuName',
  売上金額: 'salesAmount',
  '新規/再来': 'bookingCustomerType',
  ステータス: 'status',
};
const CSVStructEN = {
  'Booking ID': 'sourceId',
  'Treatment date': 'treatmentDate',
  'Customer name': 'customerName',
  'Menu category': 'menuCategory',
  'Menu name': 'menuName',
  'Sales Amount': 'salesAmount',
  'New/Repeater': 'bookingCustomerType',
  Status: 'status',
};

// return { id, bookingTime, customer, staff, price, status, amount, bookingCustomerType };

const StaffSaleReport = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const { categoriesWithTrashed = [] } = useSelector((state) => state.category);
  const { salon } = useSelector(state => state.salon);
  const utcOffset = moment.tz.zone(salon.timezone).utcOffset(moment());
  const utcOffsetHours = utcOffset / 60;
  const locale = useSelector((state) => state.i18n.locale);

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const {
    staffId,
    currentDate,
    period,
  } = searchParamsValues;
  const page = parseInt(searchParamsValues.page);
  const isFullySearchParams = searchParamsValues.page && searchParamsValues.currentDate && searchParamsValues.period;
  // </URLSearchParams>

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // Constant
  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/sales-management',
      breadcrumbName: lang.salesManagement,
    },
    {
      path: '/staff-sale-report',
      breadcrumbName: lang.staffSaleReport,
    },
  ];

  // Columns
  const staffColumns = [
    {
      key: 'staff',
      title: lang.staffName,
      dataIndex: 'staff',
      fixed: 'left',
      render: function renderItem (record) {
        const staffConnectedInfo = _.get(record, 'connections[0].data', {});
        const staffAvatar = staffConnectedInfo.avatarLink || record.avatar;
        const staffName = staffConnectedInfo.name || record.name;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {staffAvatar
              ? <Avatar size='large' src={staffAvatar} />
              : <Avatar size='large' icon={<UserOutlined />} />
            }
            <div style={{ marginLeft: 8 }}>
              <div>{staffName}</div>
              <div>{staffConnectedInfo.username}</div>
            </div>
          </div >
        );
      },
    },
    {
      key: 'period',
      title: lang.period,
      dataIndex: 'period',
      render: function renderItem (record) {
        const enFormat = 'YYYY-MM-DD';
        const jaFormat = 'YYYY年M月D日';
        // moment ISOString subtract utcOffset so we add utcOffset before
        const startDate = moment(record.startDate).add(utcOffsetHours, 'hours');
        const endDate = moment(record.endDate).add(utcOffsetHours, 'hours');
        return (<div>
          {locale === 'en'
            ? `${startDate.format(enFormat)} to ${endDate.format(enFormat)}`
            : `${startDate.format(jaFormat)}~${endDate.format(jaFormat)}`
          }
        </div>);
      },
    },
    {
      key: 'bookingDone',
      title: lang.bookingDone,
      align: 'center',
      render: function renderItem (record) {
        const countDone = formatDataHas0Value(record?.extra?.countDetail?.countDONE, '--');
        const countNew = formatDataHas0Value(record?.extra?.countDetail?.countDONE_NEW, '--');
        const countRepeater = formatDataHas0Value(record?.extra?.countDetail?.countDONE_REPEATER, '--');
        const isShowDetailRow = !!(record?.extra?.countDetail?.countDONE_NEW || record?.extra?.countDetail?.countDONE_REPEATER);
        return (<Space>
          <Text>{countDone}</Text>
          {isShowDetailRow && <Text>{`(${lang.newType} ${countNew} / ${lang.repeatType} ${countRepeater})`}</Text>}
        </Space>);
      },
    },
    {
      key: 'customerCanceled',
      title: lang.customerCanceled,
      dataIndex: ['extra', 'countDetail', 'countCANCELED'],
      render: function renderItem (record) {
        return (<div>{record}</div>);
      },
    },
    {
      key: 'totalTreatmentFee',
      title: lang.totalTreatmentFee,
      dataIndex: ['extra', 'totalBookingValue'],
      render: function renderItem (record) {
        return (<Text type="warning">{formatYen(record || 0)}</Text>);
      },
    },
    {
      key: 'bookingFee',
      title: lang.bookingFee,
      dataIndex: ['extra', 'bookingFeePercent'],
      render: function renderItem (record) {
        return (<Text >{formatYen(record?.bookingFeeAmount + record?.firstBookingFeeAmount || 0)}</Text>);
      },
    },
    {
      key: 'settlementFee',
      title: lang.settlementFee,
      dataIndex: ['extra', 'settlementFee'],
      render: function renderItem (record) {
        return (<Text >{formatYen(record)}</Text>);
      },
    },
    {
      key: 'average',
      title: lang.average,
      dataIndex: 'extra',
      render: function renderItem (record) {
        const doneAmounts = _.get(record, 'bookingValueDetail.DONEValue', 0) + _.get(record, 'bookingValueDetail.firstBK_DONEValue', 0);
        const countDone = _.get(record, 'countDetail.countDONE', 1);
        const average = doneAmounts === 0 ? doneAmounts : doneAmounts / countDone;
        return formatYen(average);
      },
    },
  ];

  const bookingColumns = [
    {
      key: 'bookingId',
      title: lang.bookingID,
      dataIndex: ['booking', 'sourceId'],
      fixed: 'left',
      align: 'center',
      render: function renderItem (value, record) {
        return (
          <div onClick={() => setBookingDetailModal(record.bookingId)}>
            <Link>{value}</Link>
          </div>);
      },
    },
    {
      key: 'startTime',
      title: lang.treatmentDate,
      dataIndex: ['booking', 'startTime'],
      align: 'center',
      render: function renderItem (record) {
        const startTime = moment(record);
        return (
          <div>
            <div>{startTime.format(locale === 'en' ? 'YYYY-MM-DD' : 'YYYY年M月D日')}</div>
            <div>{startTime.format('HH:mm')}</div>
          </div>
        );
      },
    },
    {
      key: 'customer',
      title: lang.customerName,
      dataIndex: ['booking', 'customer'],
      align: 'center',
      render: function renderItem (record) {
        return (
          <div>
            <div>{record.name}</div>
            <div>{record.phonetic}</div>
          </div >
        );
      },
    },
    {
      key: 'menu',
      title: lang.menu,
      dataIndex: 'booking',
      align: 'center',
      render: function renderItem (booking) {
        const firstMenu = (booking?.orders || [])[0] || (booking?.extraInfo?.services || [])[0];
        const categoryBooking = (categoriesWithTrashed || []).find(category => {
          return (category.services || []).find(service => {
            if (service.id === firstMenu?.serviceId) {
              return true;
            }
          });
        });

        return (
          <div>
            <div>{categoryBooking?.name || firstMenu?.category?.name}</div>
            <div>{firstMenu?.serviceName}</div>
          </div>
        );
      },
    },
    {
      key: 'totalTreatmentSales',
      title: lang.salesAmount,
      dataIndex: 'totalTreatmentSales',
      align: 'center',
      render: function renderItem (record) {
        return <div style={{ textAlign: 'right' }}>{formatYen(record || 0)}</div>;
      },
    },
    {
      key: 'bookingCustomerType',
      title: lang.bookingCustomerTypeTitle,
      dataIndex: 'booking',
      align: 'center',
      render: function renderItem (record) {
        const bookingStatus = (record?.notes || []).find(note => note.type === 'targetSystemStatus')?.value;
        if (['CANCELED', 'NOVISIT'].includes(bookingStatus)) {
          return <div>-</div>;
        } else {
          return <div >{lang[BOOKING_CUSTOMER_TYPE[record?.extraInfo?.customerBookingBadge].langKey]}</div>;
        }
      },
    },
    {
      key: 'bookingStatus',
      title: lang.status,
      dataIndex: ['booking', 'notes'],
      align: 'center',
      render: function renderItem (record) {
        const bookingStatus = record.find(note => note.type === 'targetSystemStatus')?.value;

        return (
          <Tag color={BOOKING_STATUS[bookingStatus]?.color}>
            {lang[BOOKING_STATUS[bookingStatus]?.langKey]}
          </Tag>
        );
      },
    },
  ];

  // State
  const [searchValue, setSearchValue] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [listStaffSaleReport, setListStaffSaleReport] = useState([]);
  const [listBookingStaffSaleReport, setListBookingStaffSaleReport] = useState({});
  const { pagination = {} } = listBookingStaffSaleReport;
  const listBookingStaffSaleReportData = listBookingStaffSaleReport.data || [];
  const [staffLoading, setStaffLoading] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingDetailModal, setBookingDetailModal] = useState(null);

  const dateFrom = moment(currentDate, 'YYYY-MM').startOf('month');
  let dateTo = moment(currentDate, 'YYYY-MM').endOf('month');
  if (period === 'first-period') {
    dateTo = dateFrom.clone().add(14, 'days').endOf('day');
  }
  if (period === 'second-period') {
    dateFrom.add(15, 'days').startOf('day');
  }
  const csvPrefixNameDefault = `${dateFrom.format('YYYY-MM-DD')}_${dateTo.format('YYYY-MM-DD')}`;
  /**
   * Server Nailie: Not use timezone
   *
   * Example: {
   *  from: "2021-01-01T00:00:00.000Z",
   *  to:   "2021-01-15T23:59:59.999Z",
   * }
   */
  // Server NLSB: Not handle params are passed by web
  // toISOString add utcOffset so we subtract utcOffset before
  dateFrom.subtract(utcOffsetHours, 'hours');
  dateTo.subtract(utcOffsetHours, 'hours');

  // <Auto Complete>
  let autoCompleteDataSource = staffList;
  if (searchValue !== '') {
    autoCompleteDataSource = autoCompleteDataSource.filter(staff => {
      const staffConnected = staff.connections[0].data;
      const staffConnectedName = (staffConnected.name || '').toUpperCase();
      const staffConnectedUsername = (staffConnected.username || '').toUpperCase();
      const searchValueUpperCase = searchValue.trim().toUpperCase();
      return staffConnectedName.includes(searchValueUpperCase) || staffConnectedUsername.includes(searchValueUpperCase);
    });
  }
  // </Auto Complete>

  const searchStaff = autoCompleteDataSource.find(staff => {
    const staffConnected = staff.connections[0].data;
    const staffConnectedName = (staffConnected.name || '').toUpperCase();
    const staffConnectedUsername = (staffConnected.username || '').toUpperCase();
    const searchValueUpperCase = searchValue.trim().toUpperCase();
    return staffConnectedName === searchValueUpperCase || staffConnectedUsername === searchValueUpperCase;
  });
  const searchStaffId = _.get(searchStaff, 'id');
  const deletedStaff = listStaffSaleReport.find(s => s?.staffId === staffId);
  const csvFileName = `${searchStaff?.username || deletedStaff?.staff?.username}_booking_sales_${csvPrefixNameDefault}.csv`;

  const getListStaff = () => {
    if (!staffId) {
      if (listStaffSaleReport.length !== 0) {
        setListStaffSaleReport([]);
      }

      return;
    }

    setStaffLoading(true);
    dispatch(getListStaffSaleReport({
      staffId,
      from: dateFrom.toISOString(),
      to: dateTo.toISOString(),
    }))
      .then((result) => {
        const fullMontData = SalesReportHelper.FormatListStaffSaleReportFullMonth(result, period, dateFrom.toISOString(), dateTo.toISOString());
        setListStaffSaleReport(fullMontData);
        setStaffLoading(false);
      });
  };

  const getListBooking = () => {
    if (!staffId) {
      if (listBookingStaffSaleReportData.length !== 0) {
        setListBookingStaffSaleReport({});
      }

      return;
    }

    setBookingLoading(true);
    dispatch(getListBookingStaffSaleReport({
      staffId,
      page,
      limit: 50,
      from: dateFrom.toISOString(),
      to: dateTo.toISOString(),
    }))
      .then((result) => {
        setListBookingStaffSaleReport(result);
        setBookingLoading(false);
      });
  };

  // 1st load
  useEffect(() => {
    dispatch(categoryList({ withTrashed: 1 }));
    dispatch(fetchStaffList({ currentPage: 1, pageSize: 10000, connectionStatus: '1' }))
      .then(result => {
        const list = result.data.data;
        setStaffList(list);
        const _staffId = state ? state.staffId : staffId;
        if (_staffId) {
          const staff = list.find(item => item.id === _staffId) || {};
          const staffConnectedInfo = _.get(staff, 'connections[0].data', {});
          const staffName = staffConnectedInfo.name || staff.name || '';
          setSearchValue(staffName);
        }
      });
  }, []);

  useEffect(() => {
    const now = moment();
    const defaultSearch = {
      page: 1,
      currentDate: now.format('YYYY-MM'),
      period: SALES_REPORT_PERIOD.firstPeriod,
    };
    if (now.date() > 15) {
      defaultSearch.period = SALES_REPORT_PERIOD.secondPeriod;
    }
    if (state) {
      defaultSearch.staffId = state.staffId;
      defaultSearch.currentDate = state.currentDate;
      defaultSearch.period = state.period;
    }
    if (!_.isEmpty(searchParamsValues)) {
      // When user opens this screen with a staff ID as parameter but not specify payout cycle, show default current payout cycle.
      if (searchParamsValues.staffId) {
        defaultSearch.staffId = searchParamsValues.staffId;
      }
    }
    if (shouldSetSearchParams(searchParamsValues) || !isFullySearchParams) {
      handleSetSearchParams(defaultSearch, true);

      // Clear previous staff
      if (!searchParamsValues.staffId && searchValue !== '') {
        setSearchValue('');
      }
    }
  }, [searchParamsValues]);

  useEffect(() => {
    if (shouldFetchApi(searchParamsValues) && isFullySearchParams) {
      getListStaff();
      getListBooking();
    }

    form.setFieldsValue({
      staffName: searchValue,
      currentDate: moment(currentDate, 'YYYY-MM'),
      period,
    });
  }, [staffId, currentDate, period]);

  useEffect(() => {
    if (shouldFetchApi(searchParamsValues) && isFullySearchParams) {
      getListBooking();
    }
  }, [page]);

  useEffect(() => {
    form.setFieldsValue({
      staffName: searchValue,
    });
  }, [searchValue]);

  // <Auto Complete>
  const renderOption = (item) => {
    const staff = item.connections[0].data;
    return (
      <Option key={item.id} value={staff.name}>
        <Avatar src={staff.avatarLink} size='small' style={{ marginRight: 8 }} />
        {staff.name}
      </Option>
    );
  };
  // </Auto Complete>

  // <Clear search staff
  const handleClearSearchStaff = (keyword) => {
    if (!keyword) { // input is cleared.
      const search = {
        ...searchParamsValues,
        page: 1,
      };
      delete search.staffId;
      handleSetSearchParams(search);
    }
  };
  // Export CSV
  const exportBookingListOnStaffSaleCSVFile = () => {
    setBookingLoading(true);
    dispatch(getListBookingStaffSaleReport({
      staffId,
      page: 1,
      limit: 50000,
      from: dateFrom.toISOString(),
      to: dateTo.toISOString(),
    })).then((result) => {
      setListBookingStaffSaleReport(result);
      HANDLE_EXPORT_CSV_FILE.handleExportBookingOnStaffSaleReportDetailCSV(result?.data, CSVStructEN, CSVStructJA, csvFileName, locale, lang, categoriesWithTrashed);
      setBookingLoading(false);
    }).catch(() => {
      setBookingLoading(false);
    });
  };

  return (
    <PageLayout ghost={false} title={lang.staffSaleReport} routes={routes} onBack={() => window.history.back()}>
      <Card>
        <Card style={{ marginBottom: 16, backgroundColor: '#F8F8F8', display: 'none' }}>
          <Form
            layout="inline"
            form={form}
            name="staffSaleReportSearchForm"
            onFinish={(values) => {
              const search = {
                ...searchParamsValues,
                staffId: searchStaffId,
                page: 1,
                currentDate: values.currentDate.format('YYYY-MM'),
                period: values.period,
              };
              handleSetSearchParams(search);
            }}
          >
            <Form.Item name="staffName">
              <AutoComplete
                dataSource={autoCompleteDataSource.map(renderOption)}
                style={{ width: 200 }}
                onSelect={(value) => setSearchValue(value)}
                onSearch={(searchText) => setSearchValue(searchText)}
                placeholder={lang.searchStaffSaleReportPlaceholder}
                allowClear
                onChange={(e) => handleClearSearchStaff(e)}
              />
            </Form.Item>
            <Form.Item name="currentDate">
              <DatePicker
                allowClear={false}
                picker="month"
              />
            </Form.Item>
            <Form.Item name="period">
              <Select
                style={{ width: 160 }}
              >
                <Option value={SALES_REPORT_PERIOD.firstPeriod}>{lang.firstPeriod}</Option>
                <Option value={SALES_REPORT_PERIOD.secondPeriod}>{lang.secondPeriod}</Option>
              </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{lang.search}</Button>
          </Form>
        </Card>

        <Table
          columns={staffColumns}
          dataSource={(listStaffSaleReport || []).filter(s => s.totalTreatmentSales > 0)}
          loading={staffLoading}
          bordered
          pagination={false}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingBottom: 16 }}>
          <h3 style={{ marginTop: 24 }}>{lang.bookingData}</h3>
          <Button
            type="primary"
            onClick={() => exportBookingListOnStaffSaleCSVFile(listBookingStaffSaleReportData)}
            icon={<ExportOutlined />}
            disabled={listBookingStaffSaleReportData.length === 0}
          >
            {lang.exportCSV}
          </Button>
        </div>
        <Table
          columns={bookingColumns}
          dataSource={listBookingStaffSaleReportData}
          loading={bookingLoading}
          bordered
          onChange={(pagination, filters, sorter) => {
            const search = {
              ...searchParamsValues,
              page: pagination.current,
            };
            handleSetSearchParams(search);
          }}
          pagination={{
            total: pagination.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSize: pagination.total,
            current: pagination.page,
            showSizeChanger: false,
            onChange: (page) => window.scrollTo(0, 0),
          }}
        />
      </Card>

      <BookingDetailModal
        bookingId={bookingDetailModal}
        closeBookingDetailModal={() => setBookingDetailModal(null)}
        onCallbackBookingDetailModal={getListBooking}
      />
    </PageLayout>
  );
};

export default StaffSaleReport;
