import { put, takeEvery, call } from 'redux-saga/effects';
import { getLineListFail, getLineListSuccess, getPrefectureISOTableFail, getPrefectureISOTableSuccess, getStationListFail, getStationListSuccess } from './action';
import { GET_LINE_LIST, GET_PREFECTURE_ISO_TABLE, GET_STATION_LIST } from './type';
import requestStationService from 'utils/request/requestStationService';
import _ from 'lodash';

function * getPrefectureList (action) {
  try {
    const data = yield call(requestStationService, 'prefectures', {}, 'GET');
    yield put(getPrefectureISOTableSuccess(_.keyBy(data, 'prefecture_kanji'), action.meta));
  } catch (error) {
    console.log('Opps!', error);
    yield put(getPrefectureISOTableFail());
  }
}

function * getLineList (action) {
  const { prefectureCodes } = action.payload;
  try {
    const data = yield call(requestStationService, 'lines', { all: true, prefectureCodes: prefectureCodes }, 'GET');
    yield put(getLineListSuccess(data, action.meta));
  } catch (error) {
    console.log('Opps!', error);
    yield put(getLineListFail());
  }
}

function * getStationList (action) {
  const { prefectureCodes, lineValue } = action.payload;
  const [lineCodes] = lineValue.split('|');
  try {
    const data = yield call(requestStationService, 'stations',
      {
        all: true,
        prefectureCodes: prefectureCodes,
        lineCodes,
      },
      'GET');
    const payload = { data, lineCodes, lineValue };
    yield put(getStationListSuccess(payload, action.meta));
  } catch (error) {
    console.log('Opps!', error);
    yield put(getStationListFail());
  }
}
export default function * watchStatio () {
  yield takeEvery(GET_PREFECTURE_ISO_TABLE, getPrefectureList);
  yield takeEvery(GET_LINE_LIST, getLineList);
  yield takeEvery(GET_STATION_LIST, getStationList);
}
