export default function () {
  return {
    cbApiEndPoint: process.env.REACT_APP_CB_API_ENDPOINT,
    stationServiceAPI: process.env.REACT_APP_STATION_SERVICE_API,
    api: {
      // Auth0
      auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
      auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      auth0RedirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,

      // Authentication
      salonLogin: 'api/v1/users/login',
      salonRequest: 'api/v1/salons',
      completedProfileSalon: 'api/v1/salons',
      updateAccountSalon: 'api/v1/salons',
      updateScheduleSalon: 'api/v1/salons',
      uploadImage: 'api/v1/media/images/signature',
      getAddressByPostCode: 'api/v1/media/address',
      changePassword: 'api/v1/salons',

      // Apps
      getApps: 'api/v1/apps',

      // Service
      categoryList: 'api/v1/services/categories',
      categoryCreate: 'api/v1/services/categories',
      categoryUpdate: 'api/v1/services/categories',
      categoryDelete: 'api/v1/services/categories',

      // Category
      serviceCreate: 'api/v1/services',
      serviceList: 'api/v1/services',
      serviceUpdate: 'api/v1/services',
      serviceDelete: 'api/v1/services',

      // Staff
      staffList: 'api/v1/staff',
      staffDetail: 'api/v1/staff',
      staffCreate: 'api/v1/staff',
      staffUpdate: 'api/v1/apps/085094953333804/staff',
      areasList: 'api/v1/areas',
      prefecturesList: 'api/v1/prefectures',
      citiesList: 'api/v1/cities',
      salonGetCurrentUser: 'api/v1/users/profile',
      staffAddServices: 'api/v1/staff',
      staffSendInvite: 'api/v1/apps/085094953333804/staff',
      staffCancelInvite: 'api/v1/apps/085094953333804/staff',
      staffScheduleList: 'api/v1/staff/schedules',
      staffScheduleUpdate: 'api/v1/staff',
      getStaffInfoByIndentity: 'api/v1/staff/identity',
      removeStaff: 'api/v1/staff',
      staffUpdateOrder: 'api/v1/staff',
      staffThirdParties: 'api/v1/staff/third-parties',

      // Booking
      getBookings: 'api/v1/bookings',
      getBookingStaff: 'api/v1/bookings/staff',
      bookingCreate: 'api/v1/bookings',
      bookingUpdate: 'api/v1/bookings',
      bookingSystemUpdate: 'api/v1/apps/085094953333804/bookings',
      getBooking: 'api/v1/bookings',
      getPaymentByBookingId: 'api/v1/bookings',
      getOutsideBookingsByStaff: 'api/v1/bookings/outside/staff',
      getOutsideBookingsBySalon: '/api/v1/bookings/outside/salon',
      bookingFinish: 'api/v1/bookings',
      bookingAcceptanceTime: 'api/v1/salons',
      getBookingNotification: 'api/v1/bookings/notifications',
      readAllBookingNotification: 'api/v1/bookings/notifications/read',
      readBookingNotification: 'api/v1/bookings/notifications',

      // Customer
      customerList: 'api/v1/customers',
      customerCreate: 'api/v1/customers',
      customerUpdate: 'api/v1/customers',
      customerDetail: 'api/v1/customers',

      // Salon
      salonDetail: 'api/v1/salons',
      salonAccountUpdate: '/api/v1/salons',
      salonGetProperties: '/api/v1/salons/properties',
      salonBankUpdate: 'api/v1/salons',
      salonGetBank: 'api/v1/banks',
      salonGetBankBranch: 'api/v1/banks',
      salonSettingsGet: '/api/v1/salons/settings',
      salonCancellationPolicyUpdate: '/api/v1/salons',
      salonGetAllSurvey: '/api/v1/salons/surveys',
      salonSurveyDetail: '/api/v1/salons/surveys',
      salonUpdateSurvey: '/api/v1/salons',
      salonGetAllAreas: '/api/v1/areas',
      toggleThirdPartyConnection: '/api/v1/salons/current/third-parties/toggle-connection',
      toggleThirdPartyStaffConnection: '/api/v1/salons/current/third-parties',
      requestChangeEmail: '/api/v1/salons',

      // Payment
      payments: 'api/v1/payments',
      paymentSummary: 'api/v1/payments/summary',
      paymentCompletion: 'api/v1/payments',
      paymentDetail: 'api/v1/payments',
      paymentRefund: 'api/v1/payments',
      paymentTips: 'api/v1/payments',

      // Sale reports
      saleReportsSalons: 'api/v1/sale-reports/salons',
      saleReportsStaff: 'api/v1/sale-reports/staff',
    },
    isProduction: false,
    isDevelopment: true,
  };
}
