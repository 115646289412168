// GET PREFECTURE
export const GET_PREFECTURE_ISO_TABLE = 'GET_PREFECTURE_ISO_TABLE';
export const GET_PREFECTURE_ISO_TABLE_SUCCESS = 'GET_PREFECTURE_ISO_TABLE_SUCCESS';
export const GET_PREFECTURE_ISO_TABLE_FAIL = 'GET_PREFECTURE_ISO_TABLE_FAIL';

// GET LINE LIST
export const GET_LINE_LIST = 'GET_LINE_LIST';
export const GET_LINE_LIST_SUCCESS = 'GET_GET_LINE_LIST_SUCCESS';
export const GET_LINE_LIST_FAIL = 'GET_LINE_LIST_FAIL';

// GET STATION LIST
export const GET_STATION_LIST = 'GET_STATION_LIST';
export const GET_STATION_LIST_SUCCESS = 'GET_STATION_LIST_SUCCESS';
export const GET_STATION_LIST_FAIL = 'GET_STATION_LIST_FAIL';

// RESET STATION PROVIDER
export const RESET_STATION_PROVIDER = 'RESET_STATION_PROVIDER';
