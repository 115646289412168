import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Checkbox, Spin, Button, Divider } from 'antd';
import {
  salonGetAllSurvey,
  salonSurveyDetail,
  salonSurveyResult,
  salonUpdateSurvey,
} from 'providers/SalonProvider/actions';
import { getLanguages } from 'utils/lang';
import { useDispatch } from 'hooks/useCustomDispatch';

const SalonSurveyForm = ({ salon }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [surveyDetail, setSurveyDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(salonGetAllSurvey())
      .then((result) => {
        const surveyId = result?.data[0].id;
        dispatch(salonSurveyDetail({ surveyId })).then((surveyQuestion) => {
          setSurveyDetail(surveyQuestion.data);
          dispatch(
            salonSurveyResult({
              salonId: salon.id,
              surveyId,
            }),
          ).then((surveyResult) => {
            form.setFieldsValue({
              questions: surveyResult.data.answers
                .filter((answer) => answer.answer === 1)
                .map((answer) => answer.questionId),
            });

            setLoading(false);
          });
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="salonSurveyForm"
        onFinish={(values) => {
          const questions = surveyDetail.questions.map((question) => ({
            id: question.id,
            answer: values.questions.includes(question.id) ? 1 : 0,
          }));

          dispatch(
            salonUpdateSurvey({
              questions,
              salonId: salon.id,
              surveyId: surveyDetail.id,
            })).then(() => dispatch(
            salonSurveyResult({
              salonId: salon.id,
              surveyId: surveyDetail.id,
            })))
            .catch((error) => {
              console.log('Oops!', error);
            });
        }}
      >
        <div>{surveyDetail?.title}</div>
        <Row gutter={24} style={{ paddingRight: 10, paddingLeft: 20, marginTop: 10 }}>
          <Col span={24}>
            <Form.Item name="questions">
              <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }}>
                {surveyDetail &&
                  (surveyDetail.questions || []).map((question) => (
                    <Checkbox
                      value={question.id}
                      key={question.id}
                      style={{ marginLeft: 8, marginBottom: 8 }}
                    >
                      <div>{question.text}</div>
                    </Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Divider/>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              {lang.saveAndPublishSettings}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

SalonSurveyForm.propTypes = {
  salon: PropTypes.object,
};

export default SalonSurveyForm;
