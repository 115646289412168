// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import _ from 'lodash';

/**
 *
 * @param {number} index
 * @return {number}
 */
export const getDataIndexFromIndex = (index) => {
  const halfIndex = Math.floor(index / 2);
  if (index % 2 === 0) {
    return halfIndex * 100;
  } else {
    return halfIndex * 100 + 30;
  }
};

/**
 *
 * @param {object} time
 * @return {number}
 */
export const getIndexFromMoment = (time) => {
  return (time.hours() + time.minutes() / 60) * 2;
};

/**
 *
 * @param {number} time
 * @return {string}
 */
export const padStartTime = (time) => time.toString().padStart(2, '0');

/**
 * Calculate datetime from slot index.
 *
 * @param {number} slotIndex Integer indicate the slot number from 0 - 47
 * @param {typeof moment} currentDate Moment object represent the reference date to convert time
 * @param {number} slotDuration Duration of a slot in minutes. Default 30 minutes.
 *
 * @returns {typeof moment}
 */
export const getDateTimeFromSlotIndex = (slotIndex, currentDate, slotDuration = 30) => {
  const hours = Math.floor(slotIndex / 2);
  const minutes = (slotIndex % 2) * slotDuration;
  const time = currentDate.clone().startOf('day').add(hours, 'hour').add(minutes, 'minute');
  return time;
};

/**
 * Convert a standard day of week index to index in current locale.
 *
 * @remarks
 * * Standard day of week index: 0-Sunday, 1-Monday, ..., 6-Saturday
 * * Locale day of week index depends on current setting of `moment` (`week.dow`).
 * If setting `dow = 1` meaning week starts on Monday, then locale day of week index of Sunday will become 6 instead of 0.
 *
 * @param {number} standardDayOfWeek Index of day of week to convert in standard moment (0: Sunday ... 6: Saturday)
 */
export const getLocaleDayOfWeek = (standardDayOfWeek) => {
  const currentDoW = moment.localeData()._week.dow;
  return (standardDayOfWeek + 7 - currentDoW) % 7; // Convert to locale weekday
};

export const getDefaultTimeTableScrollPosition = (referenceDate, salonOpenHours, minAcceptBookingHour, slotColumnWidth) => {
  // If selected today
  let selectedHour = salonOpenHours;
  if (referenceDate.clone().startOf('day').toString() === moment().clone().startOf('day').toString()) {
    const pastHour = getPastHour(minAcceptBookingHour);
    selectedHour = _.max([salonOpenHours, pastHour]);
  }
  return selectedHour * 2 * slotColumnWidth;
};

export const getPastHour = (minAcceptBookingHour) => {
  return minAcceptBookingHour ? moment().get('hour') + 0.5 + minAcceptBookingHour + (moment().get('minute') > 30 ? 0.5 : 0) : 24;
};
