import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button, Divider, Row, Col, Radio, Spin } from 'antd';

import { useDispatch } from 'hooks/useCustomDispatch';
import { salonSettingsGet, salonCancellationPolicyUpdate } from 'providers/SalonProvider/actions';

import _get from 'lodash/get';
import { getLanguages } from 'utils/lang';

const SalonCancellationPolicyUpdateForm = ({ salon, onCallback }) => {
  const { t, i18n } = useTranslation();
  const lang = getLanguages(t);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    dispatch(salonSettingsGet())
      .then(result => {
        setSettings(_get(result, 'data.cancellationPolicy', []));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Form
      form={form}
      name="cancellationPolicyForm"
      onFinish={values => {
        dispatch(salonCancellationPolicyUpdate({
          ...values,
          salonId: salon.id,
        }))
          .then((result) => {
            onCallback(result);
          })
          .catch((error) => {
            console.log('Oops!', error);
          });
      }}
      initialValues={{
        code: _get(salon, 'cancellationPolicy'),
      }}
    >
      <Row gutter={24} style={{ paddingRight: 10, paddingLeft: 20, marginTop: 10 }}>
        <Col span={23}>
          <Form.Item name="code">
            <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
              {settings && settings.map((item) => {
                return (
                  <Radio key={item.name} value={item.name}>
                    <div style={{ marginBottom: 10 }}>
                      <strong>{item.titles[i18n.language]}</strong><br />
                      <ul>
                        {_get(item, `descriptions.${i18n.language}`, []).map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Divider/>
      <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
        <Col className="gutter-row" span={24}>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'right' }}>
            <div style={{ marginTop: 5 }}>
              <Button type="primary" htmlType="submit">
                {lang.updateAndPublish}
              </Button>
            </div>
            <div style={{ color: '#a7a7a7', marginRight: 15 }}>
              {lang.cancellationPolicyNote1}<br />
              {lang.cancellationPolicyNote2}
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

SalonCancellationPolicyUpdateForm.propTypes = {
  onCallback: PropTypes.func,
  salon: PropTypes.object,
};

export default SalonCancellationPolicyUpdateForm;
