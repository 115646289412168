// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, DatePicker, Checkbox, Typography, message as Alert } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import BigSpin from '../Commons/BigSpin';

// Providers
import { bookingListOnChangeDate, bookingUpdateServices } from 'providers/BookingProvider/actions';
import { getStaffScheduleDaily } from 'providers/StaffProvider/actions';
import { salonDetail } from 'providers/SalonProvider/actions';
// Utils
import { getLanguages } from 'utils/lang';
import { BOOKING_CANCELED_STATUSES } from 'utils/constants';
import { getPastHour } from 'utils/schedule';

const { Text } = Typography;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

const roundBookingDate = (value) => {
  const bookingDate = value;
  // if (value < moment()) {
  //   bookingDate = moment();
  // }
  const remainderMinute = bookingDate.minutes() % 30;
  const addMinute = remainderMinute === 0 ? 0 : 30 - remainderMinute;
  // Round up to 30 minutes
  bookingDate.add(addMinute, 'minutes');
  return bookingDate;
};

const renderChangeDateReasonLabel = () => {
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <div>
      <Text>{lang.changeDateReasonLabel}</Text><Text type="danger">（{lang.required}）</Text>
    </div>
  );
};

const getChangeDateReason = (bookingDate, newBookingDate) => {
  const currentStartTime = bookingDate.format('YYYY-MM-DD HH:mm');
  const newStartTime = newBookingDate.format('YYYY-MM-DD HH:mm');
  return `${currentStartTime} - のご予約を\n${newStartTime} - へ変更させていただきました。`;
};

const checkDateOutRange = (dateToCheck, sourceBooking, maxAcceptBookingDays) => {
  return dateToCheck < moment().startOf('day') || dateToCheck.diff(moment(sourceBooking.extraInfo.requestedDate || sourceBooking.createdAt).startOf('D'), 'days') >= 60 || dateToCheck.diff(moment().startOf('D'), 'days') >= maxAcceptBookingDays;
};

const ChangeBookingDateForm = ({
  booking: sourceBooking,
  updateSubmitData,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { salon } = useSelector(state => state?.salon);
  const maxAcceptBookingDays = salon?.acceptBookingTime?.maximum;

  const bookingDate = moment(_.get(sourceBooking, 'startTime')).set('seconds', 0);
  const isBookingDateOutRange = checkDateOutRange(bookingDate, sourceBooking, maxAcceptBookingDays);
  const defaultNewBookingDate = isBookingDateOutRange ? moment().startOf('D') : moment(bookingDate); // .set('hours', 0).set('minutes', 0).set('seconds', 0);

  // State
  const [newBookingDate, setNewBookingDate] = useState(moment(defaultNewBookingDate));
  const [pickedDate, setPickedDate] = useState(moment(defaultNewBookingDate));

  const [changeDateReason, setChangeDateReason] = useState(getChangeDateReason(bookingDate, newBookingDate));
  const [checkedNotes, setCheckedNotes] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStaffScheduleOnDate(defaultNewBookingDate, false);
  }, []);

  const [dis, setDis] = useState(() => (t) => {
    return ({
      disabledHours: () => [...Array(24).keys()],
      disabledMinutes: () => [...Array(60).keys()].filter(minute => minute % 30 !== 0),
      disabledSeconds: () => [_.range(1, 60)],
    });
  });

  useEffect(() => {
    if (checkedNotes && changeDateReason.trim()) {
      updateSubmitData({
        newBookingDate,
        changeDateReason,
      });
    } else {
      updateSubmitData(false);
    }
  }, [changeDateReason, checkedNotes]);

  const getStaffScheduleOnDate = async (selectedDate, fromCalendar = true) => {
    setLoading(true);
    if (fromCalendar) {
      setPickedDate(moment(selectedDate).startOf('day'));
      setNewBookingDate(moment(selectedDate).startOf('day'));
    }
    const selectedDateOfWeek = moment(selectedDate).days(); // check with app
    const staffId = _.get(sourceBooking, 'orders[0].staff.id');
    const from = selectedDate.startOf('day').toISOString(); // app
    const to = selectedDate.endOf('day').toISOString();

    let bookingsOnDay = await dispatch(bookingListOnChangeDate({ page: 1, limit: 10000, from, to, staffIds: staffId }));
    bookingsOnDay = bookingsOnDay?.bookings?.data?.filter(bk => bk.orders[0]?.staff?.id === staffId && sourceBooking.id !== bk.id);
    bookingsOnDay = bookingsOnDay.filter(booking => !BOOKING_CANCELED_STATUSES.includes(booking?.notes.filter(note => note.type === 'targetSystemStatus')[0]?.value.toUpperCase()));
    bookingsOnDay = bookingsOnDay.map(bk => ({
      id: bk.id,
      startH: bk.startTime.get('hour') + bk.startTime.get('minute') / 60,
      endH: bk.endTime.get('hour') + bk.endTime.get('minute') / 60,
      durationH: bk.duration / 60,
    }));

    const unavailableHours = {};
    _.range(0, 24, 0.5).forEach(estimationHour => {
      bookingsOnDay.forEach(bk => {
        const estimationHourEnd = estimationHour + sourceBooking.duration / 60;
        if (
          (bk.startH <= estimationHour && estimationHour < bk.endH) || (bk.startH < estimationHourEnd && estimationHourEnd < bk.endH) ||
          (estimationHour <= bk.startH && bk.startH < estimationHourEnd) || (estimationHour < bk.endH && bk.endH < estimationHourEnd)
        ) {
          unavailableHours[Math.floor(estimationHour)] = (unavailableHours[Math.floor(estimationHour)] || []).concat(Math.floor(estimationHour % Math.floor(estimationHour < 1 ? 1 : estimationHour) * 60));
          return false;
        }
      });
    });
    if (moment().startOf('day').diff(moment(selectedDate).startOf('day'), 'days') === 0) { // selected today
      const salonData = await dispatch(salonDetail({ salonId: salon.id }));
      const minAcceptBookingHour = salonData?.data?.acceptBookingTime?.minimum;
      const pastHour = getPastHour(minAcceptBookingHour);
      _.range(0, pastHour, 0.5).forEach(estimationHour => {
        unavailableHours[Math.floor(estimationHour)] = (unavailableHours[Math.floor(estimationHour)] || []).concat(Math.floor(estimationHour % Math.floor(estimationHour < 1 ? 1 : estimationHour) * 60));
      });
    }
    if (moment(sourceBooking.startTime).startOf('day').diff(moment(selectedDate).startOf('day'), 'days') === 0) { // selected current booking date
      unavailableHours[sourceBooking.startTime.get('hour')] = (unavailableHours[sourceBooking.startTime.get('hour')] || []).concat([sourceBooking.startTime.get('minute')]);
    }

    let scheduleOnDay = await dispatch(getStaffScheduleDaily({ staffId, from, to }));
    scheduleOnDay = _.get(scheduleOnDay, 'data.data[0]');
    scheduleOnDay = !_.isEmpty(scheduleOnDay.scheduleDailies) ? scheduleOnDay.scheduleDailies : scheduleOnDay.schedules.filter(item => item.dayOfWeek === selectedDateOfWeek);
    scheduleOnDay = scheduleOnDay.map(part => ({
      startH: moment(part.startAt).get('hour') + moment(part.startAt).get('minute') / 60,
      endH: moment(part.endAt).get('hour') + moment(part.endAt).get('minute') / 60,
    }));

    const availableHours = {};
    _.range(0, 24, 0.5).forEach(estimationHour => {
      const estimationHourEnd = estimationHour + sourceBooking.duration / 60;
      scheduleOnDay.forEach(part => {
        if (part.startH <= estimationHour && estimationHour < part.endH && part.startH < estimationHourEnd && estimationHourEnd <= part.endH) {
          availableHours[Math.floor(estimationHour)] = (availableHours[Math.floor(estimationHour)] || []).concat(Math.floor(estimationHour % Math.floor(estimationHour < 1 ? 1 : estimationHour) * 60));
        }
      });
    });

    setDis(() => (t) => {
      return ({
        disabledHours: () => [...Array(24).keys()].filter(hour => !availableHours[hour] || (_.isEmpty(_.pull([...(availableHours[hour] || [])], ...(unavailableHours[hour] || []))))),
        disabledMinutes: (hour) => [...Array(60).keys()].filter(minute => {
          const isHalfHour = minute % 30 === 0;
          const isInAvailableHours = availableHours[hour] && availableHours[hour].includes(minute);
          const isInUnAvailableHours = unavailableHours[hour] && unavailableHours[hour].includes(minute);
          return !isHalfHour || !(isInAvailableHours) || isInUnAvailableHours;
        }),
        disabledSeconds: () => [_.range(1, 60)],
      });
    });

    setLoading(false);
  };

  return (
    <Form
      {...layout}
      form={form}
      name="ChangeBookingDateForm"
    >
      {loading && <BigSpin/>}
      <Form.Item label={lang.newBookingDate}>
        <DatePicker
          allowClear={false}
          value={newBookingDate}
          minuteStep={30}
          onSelect={(value) => {
            if (moment(value).startOf('day').diff(moment(pickedDate).startOf('day'), 'days') !== 0) {
              getStaffScheduleOnDate(value);
            }
          }}
          style={{ width: '100%' }}
          showNow={false}
          showTime={{
            hideDisabledOptions: true,
          }}
          format="YYYY-MM-DD HH:mm"
          onChange={(value) => {
            if (!value) {
              setNewBookingDate(null);
              setChangeDateReason('');
            } else {
              const toBeNewBookingDate = roundBookingDate(value.clone());
              const changeDateReasonValue = getChangeDateReason(bookingDate, toBeNewBookingDate);
              setChangeDateReason(changeDateReasonValue);
              setNewBookingDate(toBeNewBookingDate);
            }
          }}
          disabledDate={(current) => current && checkDateOutRange(current, sourceBooking, maxAcceptBookingDays)}
          disabledTime={dis}
        />
      </Form.Item>

      <Form.Item label={renderChangeDateReasonLabel()} >
        <TextArea
          rows={3}
          value={changeDateReason}
          onChange={(e) => setChangeDateReason(e.target.value)}
        />
      </Form.Item>

      <div style={{ border: '1px solid red', borderRadius: 4 }}>
        <div style={{ textAlign: 'center', backgroundColor: 'red', borderRadius: 4, padding: 4, color: 'white' }}>
          {lang.notes}
        </div>
        <div style={{ padding: 8 }}>
          {lang.changeBookingDateModalNotes}
        </div>
      </div>

      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <Checkbox
          checked={checkedNotes}
          onChange={() => setCheckedNotes(!checkedNotes)}
        >
          {lang.iCheckedNotes}
        </Checkbox>
      </div>
    </Form>
  );
};

const ChangeBookingDateModal = ({
  isShowChangeBookingDateModal,
  closeChangeBookingDateModal,
  onCallbackChangeBookingDateModal,
  booking,
  handleCloseBookingDetailModal,
  onCallbackBookingDetailModal,
}) => {
  const dispatch = useDispatch();

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // States
  const [loading, setLoading] = useState(false);
  const [contentData, setContentData] = useState(false);

  return (
    <Modal
      visible={isShowChangeBookingDateModal}
      destroyOnClose={true}
      title={lang.changeBookingDateModalTitle}
      okText={lang.sendTheUpdate}
      okButtonProps={{ disabled: !contentData, loading }}
      onOk={() => {
        Modal.confirm({
          title: lang.confirm,
          icon: <ExclamationCircleOutlined />,
          centered: true,
          content: lang.confirmationPopup,
          okText: lang.sure,
          cancelText: lang.no,
          onOk: () => {
            setLoading(true);
            const { newBookingDate, changeDateReason } = contentData;

            const startTime = newBookingDate.toISOString();
            const services = booking?.orders.map((order) => {
              return {
                // Update 2/6/2021 : Change only for booking confirmed imported after join salon
                // Not required 'serviceId' .'staffId' in 'services' of 'extraInfo' and ' orders' is the same.
                serviceId: !order.serviceId ? null : order.serviceId,
                staffId: order.staff.id,
                startTime,
              };
            });
            dispatch(bookingUpdateServices({
              bookingId: booking.id,
              body: {
                changeDateReason: changeDateReason.trim(),
                services,
              },
            }))
              .then(() => {
                Alert.success(lang.ChangeBookingDateSuccess);
                closeChangeBookingDateModal();
                onCallbackChangeBookingDateModal();
                handleCloseBookingDetailModal();
                onCallbackBookingDetailModal();
                setLoading(false);
              })
              .catch(() => setLoading(false));
          },
        });
      }}
      onCancel={closeChangeBookingDateModal}
      width={640}
      centered
      maskClosable={false}
    >
      <ChangeBookingDateForm booking={booking} updateSubmitData={setContentData}/>
    </Modal>
  );
};

ChangeBookingDateModal.propTypes = {
  isShowChangeBookingDateModal: PropTypes.bool,
  closeChangeBookingDateModal: PropTypes.func,
  onCallbackChangeBookingDateModal: PropTypes.func,
  booking: PropTypes.object,
  handleCloseBookingDetailModal: PropTypes.func,
  onCallbackBookingDetailModal: PropTypes.func,
};
export default ChangeBookingDateModal;
