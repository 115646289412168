import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Col, Row, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

const SalonCloseDateAddForm = ({ onCancel }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      name="serviceUpdateForm"
      onFinish={values =>
        console.log(values)
        // dispatch(serviceUpdate(
        //   {
        //     id: service.id,
        //     values,
        //   })).then(()=> onCancel())
      }
      initialValues={{}}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: lang.emptyServiceNameError,
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Button type={'primary'} block ghost>{lang.oneDay}</Button>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Button type={'primary'} block>{lang.severalDays}</Button>
        </Col>
      </Row>
      <Row gutter={24} type="flex" style={{ marginTop: 24 }}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="fromDate"
            label={lang.fromDate}
            rules={[
              {
                required: true,
                message: lang.emptyBookingDateError,
              },
            ]}>
            <DatePicker style={{ width: 200 }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="toDate"
            label={lang.toDate}
            rules={[
              {
                required: true,
                message: lang.emptyBookingDateError,
              },
            ]}>
            <DatePicker style={{ width: 200 }} />
          </Form.Item>
        </Col>
      </Row>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel}>
              {lang.cancel}
            </Button>
            <Button type="primary" htmlType="submit">
              {lang.save}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SalonCloseDateAddForm.propTypes = {
  onCancel: PropTypes.func,
};

export default SalonCloseDateAddForm;
