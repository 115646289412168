import _ from 'lodash';
import { SALES_REPORT_PERIOD } from './constants';

const formatFullMonthSalesReport = (data) => {
  const fullMonthData = [];
  const result = data.reduce((prev, current) => {
    if (!_.isEmpty(current?.extra)) {
      return {
        bookingDone: prev?.bookingDone + current?.bookingDone,
        totalTreatmentSales: prev?.totalTreatmentSales + current?.totalTreatmentSales,
        transferAmount: prev?.transferAmount + current?.transferAmount,
        extra: {
          bookingValue: prev?.extra?.bookingValue + current?.extra?.bookingValue,
          settlementFee: prev?.extra?.settlementFee + current?.extra?.settlementFee,
          totalBookingValue: prev?.extra?.totalBookingValue + current?.extra?.totalBookingValue,
          bookingValueDetail: {
            CANCELEDValue: prev?.extra?.bookingValueDetail?.CANCELEDValue + current?.extra?.bookingValueDetail?.CANCELEDValue,
            DONEValue: prev?.extra?.bookingValueDetail?.DONEValue + current?.extra?.bookingValueDetail?.DONEValue,
            firstBK_DONEValue: prev?.extra?.bookingValueDetail?.firstBK_DONEValue + current?.extra?.bookingValueDetail?.firstBK_DONEValue,
            firstBK_CANCELEDValue: prev?.extra?.bookingValueDetail?.firstBK_CANCELEDValue + current?.extra?.bookingValueDetail?.firstBK_CANCELEDValue,
          },
          countDetail: {
            countDONE: prev?.extra?.countDetail?.countDONE + current?.extra?.countDetail?.countDONE,
            countDONE_NEW: prev?.extra?.countDetail?.countDONE_NEW + current?.extra?.countDetail?.countDONE_NEW,
            countDONE_REPEATER: prev?.extra?.countDetail?.countDONE_REPEATER + current?.extra?.countDetail?.countDONE_REPEATER,
          },
        },

      };
    }
    return prev;
  }, {
    bookingDone: 0,
    totalTreatmentSales: 0,
    transferAmount: 0,
    extra: {
      bookingValue: 0,
      totalBookingValue: 0,
      settlementFee: 0,
      bookingValueDetail: {
        CANCELEDValue: 0,
        DONEValue: 0,
        firstBK_DONEValue: 0,
        firstBK_CANCELEDValue: 0,
      },
      countDetail: {
        countDONE_NEW: 0,
        countDONE_REPEATER: 0,
        countDONE: 0,
      },
    },
  });
  fullMonthData.push(result);
  return fullMonthData;
};

const formatListStaffSaleReportFullMonth = (data, period, startOfMonth, endOfMonth) => {
  if (SALES_REPORT_PERIOD.fullMonth === period) {
    const uniqueStaff = _.groupBy(data, 'staffId');
    const result = Object.keys(uniqueStaff).map(key => {
      return {
        staffId: key,
        staff: uniqueStaff[key][0]?.staff,
        period: {
          startDate: startOfMonth,
          endDate: endOfMonth,
        },
        bookingDone: _.sumBy(uniqueStaff[key], 'bookingDone'),
        totalTreatmentSales: _.sumBy(uniqueStaff[key], 'totalTreatmentSales'),
        extra: {
          bookingValueDetail: {
            DONEValue: _.sumBy(uniqueStaff[key], 'extra.bookingValueDetail.DONEValue'),
            firstBK_DONEValue: _.sumBy(uniqueStaff[key], 'extra.bookingValueDetail.firstBK_DONEValue'),
          },
          bookingFeePercent: {
            bookingFeeAmount: _.sumBy(uniqueStaff[key], 'extra.bookingFeePercent.bookingFeeAmount'),
            firstBookingFeeAmount: _.sumBy(uniqueStaff[key], 'extra.bookingFeePercent.firstBookingFeeAmount'),
          },
          totalBookingValue: _.sumBy(uniqueStaff[key], 'extra.totalBookingValue'),
          settlementFee: _.sumBy(uniqueStaff[key], 'extra.settlementFee'),
          countDetail: {
            countDONE: _.sumBy(uniqueStaff[key], 'extra.countDetail.countDONE'),
            countCANCELED: _.sumBy(uniqueStaff[key], 'extra.countDetail.countCANCELED'),
            countDONE_NEW: _.sumBy(uniqueStaff[key], 'extra.countDetail.countDONE_NEW'),
            countDONE_REPEATER: _.sumBy(uniqueStaff[key], 'extra.countDetail.countDONE_REPEATER'),
          },
        },
      };
    });
    return result;
  } else {
    return data;
  }
};

const SalesReportHelper = {
  FormatFullMonthSalesReport: formatFullMonthSalesReport,
  FormatListStaffSaleReportFullMonth: formatListStaffSaleReportFullMonth,
};
export default SalesReportHelper;
