import { useSearchParams as useOriginalSearchParams } from 'react-router-dom';
import qs from 'querystring';
import _isEmpty from 'lodash/isEmpty';

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useOriginalSearchParams();
  const queryString = searchParams.toString();
  const searchParamsValues = qs.parse(queryString);

  const handleSetSearchParams = (search, replace) => {
    const navigateOptions = {};
    if (replace) {
      navigateOptions.replace = true;
    }

    setSearchParams(qs.stringify(search), navigateOptions);
  };

  return [searchParamsValues, handleSetSearchParams];
};

export const shouldFetchApi = (searchParamsValues) => {
  return !_isEmpty(searchParamsValues) && !searchParamsValues.code && !searchParamsValues.state;
};

export const shouldSetSearchParams = (searchParamsValues) => {
  return _isEmpty(searchParamsValues) || (searchParamsValues.code && searchParamsValues.state);
};
