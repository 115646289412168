import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Button, Form, Col, Row, Spin, Divider } from 'antd';
import { useDispatch } from 'hooks/useCustomDispatch';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { padStartTime } from 'utils/schedule';
import { timezone } from 'utils/constants';
import { staffScheduleUpdate, getCurrentScheduleByStaff, getScheduleByStaffOnADate } from 'providers/StaffProvider/actions';
import WeekFormItem from './WeekFormItem';
import './styles.less';

const WeekForm = ({ onCallback, staffId, staffName, setWeekViewModal }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [startSchedule, setStartSchedule] = useState(moment());
  const [pastSchedules, setPastSchedules] = useState([]);

  // Generate slots
  const slots = [];
  for (let index = 0; index < 48; index++) {
    const division = Math.floor(index / 2);
    const remainder = index % 2;
    slots.push({
      value: division * 100 + remainder * 30,
      label: `${padStartTime(division)}:${padStartTime(remainder * 30)}`,
    });
  }

  useEffect(() => {
    setLoadingSchedules(true);
    dispatch(getCurrentScheduleByStaff({ staffId }))
      .then((schedules) => {
        if (schedules.length !== 0) {
          const _startSchedule = moment(_.get(schedules, '[0].startSchedule'));
          setStartSchedule(_startSchedule);
          if (_startSchedule.diff(moment()) > 0) {
            dispatch(getScheduleByStaffOnADate({ staffId }))
              .then(pastSchedules => {
                setPastSchedules(pastSchedules);
              });
          }

          for (let index = 0; index < 7; index++) {
            const schedulesByDay = schedules.filter(item => item.dayOfWeek === index);
            if (schedulesByDay.length === 0) {
              form.setFieldsValue({ [index]: { isOpen: false } });
            } else {
              const startAt = parseInt(moment(schedulesByDay[0].startAt).format('HHmm'));
              const endAt = parseInt(moment(schedulesByDay[0].endAt).format('HHmm'));
              if (endAt > startAt) {
                form.setFieldsValue({
                  [index]: {
                    startAt,
                    endAt,
                    isOpen: true,
                  },
                });
              } else {
                form.setFieldsValue({ [index]: { isOpen: false } });
              }
            }
          }
        }
        setLoadingSchedules(false);
      });
  }, [staffId]);

  // Init values
  const initialValues = {};
  for (let index = 0; index < 7; index++) {
    initialValues[index] = {
      isOpen: false,
    };
  }

  const formatToISOString = (hours, minutes) => {
    const tomorrow = moment().add(1, 'days');
    return moment(`${tomorrow.format('YYYY/MM/DD')} ${hours}:${minutes}`, 'YYYY/MM/DD HH:mm').toISOString();
  };

  const onSubmit = (values) => {
    const cloneValues = JSON.parse(JSON.stringify(values));
    const schedules = [...Array(7).keys()].map((key) => {
      const value = cloneValues[key];
      value.dayOfWeek = key;

      if (!value.isOpen) {
        value.startAt = formatToISOString(0, 0);
        value.endAt = formatToISOString(0, 0);
        return value;
      }

      value.startAt = formatToISOString(Math.floor(value.startAt / 100), value.startAt % 100);
      value.endAt = formatToISOString(Math.floor(value.endAt / 100), value.endAt % 100);
      return value;
    });

    setLoading(true);
    dispatch(staffScheduleUpdate({
      timezone,
      schedules,
      staffId,
    }))
      .then((result) => {
        onCallback();
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error);
      });
  };

  const renderWeekViewModalButton = (label) => (
    <span
      style={{ color: '#06adef', textDecoration: 'underline', cursor: 'pointer' } }
      onClick={() => {
        setWeekViewModal({
          staffId,
          staffName,
          schedules: pastSchedules,
        });
      }}
    >
      {label}
    </span>
  );

  return (
    <Spin spinning={loadingSchedules}>
      {pastSchedules.length !== 0 && (
        <div style={{ marginBottom: 24, marginLeft: 48 }}>
          <span>{lang.latestScheduleHead}{startSchedule.format('YYYY-MM-DD')}{lang.latestScheduleTail}</span>
          {renderWeekViewModalButton(lang.clickHereEnglish)}
          <span>{lang.pastScheduleHead}{moment(_.get(pastSchedules, '[0].startSchedule')).format('YYYY-MM-DD')}{lang.pastScheduleTail}</span>
          {renderWeekViewModalButton(lang.clickHereJapanese)}
        </div>
      )}

      <Form
        className="week-form"
        style={{ marginLeft: 15, marginRight: 15 }}
        form={form}
        name="WeekForm"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        {[1, 2, 3, 4, 5, 6, 0].map(key => (
          <Form.Item key={key} name={key} style={{ marginBottom: 4 }}>
            <WeekFormItem name={key} slots={slots} />
          </Form.Item>
        ))}
        <Divider />
        <Row gutter={24} type="flex" style={{ textAlign: 'right', marginTop: 24 }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <div style={{ marginRight: 16 }}>{lang.staffWorkingTimeFooter}</div>
              <Button type="primary" htmlType="submit" disabled={loading} style={{ marginRight: -12 }}>
                {lang.save}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

WeekForm.propTypes = {
  staffId: PropTypes.string,
  staffName: PropTypes.string,
  onCallback: PropTypes.func,
  setWeekViewModal: PropTypes.func,
};

export default WeekForm;
