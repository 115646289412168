// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Typography, Tag, Row, Col, Button } from 'antd';
import _get from 'lodash/get';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';

// Components
import BookingCancelForm from 'components/Desktop/Booking/BookingCancelForm';
import PaymentDetailForm from 'components/Desktop/Payment/PaymentDetailForm';
import BookingDetail from './booking-detail.component';

// Providers
import { bookingDetail, getPaymentByBookingId } from 'providers/BookingProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import { BOOKING_STATUS, BOOKING_CANCELED_STATUSES } from 'utils/constants';
import ChangeBookingDateModal from './change-booking-date.modal';

const { Text } = Typography;

const BookingDetailModal = ({ bookingId, closeBookingDetailModal, onCallbackBookingDetailModal }) => {
  const dispatch = useDispatch();

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // State
  const [booking, setBooking] = useState({});
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bookingCancelModal, setBookingCancelModal] = useState(false);
  const [paymentDetailModal, setPaymentDetailModal] = useState(false);
  const [isShowChangeBookingDateModal, setChangeBookingDateModal] = useState(false);

  // Constants
  const targetSystemStatus = (booking.notes || []).find(note => note.type === 'targetSystemStatus');
  const bookingStatus = _get(targetSystemStatus, 'value');
  const canPayment = (booking.notes || []).find(note => note.type === 'canPayment');
  const enabledPaymentActionsBtn = bookingStatus === 'WAITING_DONE' || (bookingStatus === 'CONFIRMED' && _get(canPayment, 'value'));
  const changeCancelButtonPositionIntoBookingDetailCenter = enabledPaymentActionsBtn;

  const getBookingDetail = () => {
    setLoading(true);
    dispatch(bookingDetail({ bookingId }))
      .then(result => {
        setBooking(result.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (bookingId) {
      setLoading(true);
      dispatch(getPaymentByBookingId({ bookingId }))
        .then(result => {
          setPayment(result.data[0]);
          setLoading(false);
        });
      getBookingDetail();
    }
  }, [bookingId]);

  const handleCloseBookingDetailModal = () => {
    closeBookingDetailModal();
    setBooking({});
    setPayment(null);
  };

  // NL-4006 NL-4016 https://stoooc-techhub.slack.com/archives/C013QMNJ1E1/p1630981061050100?thread_ts=1630980460.045600&cid=C013QMNJ1E1
  // now < startTime => show cancel button in booking detail footer, hide section NoVisit
  // (startTime <= now < endTime) ===>  show this button in center of booking detail & Text = 'Click here if you were unable to complete your treatment', disable NoVisit
  // endTime <= now => show the position in center of booking detail & Text = 'Click here if you were unable to complete your treatment', enable section  NoVisit

  const renderCancelBookingButton = () => {
    return (
      changeCancelButtonPositionIntoBookingDetailCenter
        ? <div style={{ marginTop: 12, textAlign: 'center' }}>
          <span style={{ color: '#009193', cursor: 'pointer' }}
            onClick={() => { setBookingCancelModal(true); }}
          >
            {lang.unableCompleteTreatment}
          </span>
        </div>
        : <Button
          onClick={() => {
            setBookingCancelModal(true);
          }}
          danger
          type="clear"
          disabled={!bookingStatus || [...BOOKING_CANCELED_STATUSES, 'DONE'].includes(bookingStatus)}
        >
          {lang.cancelBooking}
        </Button>
    );
  };

  return (
    <>
      <Modal
        visible={bookingId}
        title={(
          <>
            {lang.bookingDetail}
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 16 }}>
              <Text strong style={{ fontSize: '14px' }}>{lang.bookingID}: {booking.sourceId || '---'}</Text>
              <Tag style={{ marginLeft: 15 }} color={BOOKING_STATUS[bookingStatus]?.color}>
                {lang[BOOKING_STATUS[bookingStatus]?.langKey]}
              </Tag>
            </div>
          </>
        )}
        onCancel={handleCloseBookingDetailModal}
        destroyOnClose={true}
        width={640}
        centered
        footer={!changeCancelButtonPositionIntoBookingDetailCenter && (
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={8} style={{ textAlign: 'left' }}>
              {renderCancelBookingButton()}
            </Col>

          </Row>
        )}
        maskClosable={false}
      >
        <Spin spinning={loading}>
          <BookingDetail
            booking={booking}
            payment={payment}
            enabledPaymentActionsBtn={enabledPaymentActionsBtn}
            renderCancelBookingButton={renderCancelBookingButton}
            openPaymentDetailModal={() => setPaymentDetailModal(true)}
            openChangeBookingDateModal={() => setChangeBookingDateModal(true)}
            handleCloseBookingDetailModal={handleCloseBookingDetailModal}
            onCallbackBookingDetailModal={onCallbackBookingDetailModal}
          />
        </Spin>
      </Modal>

      <Modal
        visible={bookingCancelModal}
        title={lang.cancelBooking}
        onCancel={() => {
          setBookingCancelModal(false);
        }}
        destroyOnClose={true}
        width={900}
        centered
        footer={null}
        maskClosable={false}
      >
        <BookingCancelForm
          booking={booking}
          onClose={() => setBookingCancelModal(false)}
          onCallback={() => {
            handleCloseBookingDetailModal();
            setBookingCancelModal(false);
            onCallbackBookingDetailModal();
          }}
        />
      </Modal>

      <Modal
        visible={paymentDetailModal}
        title={lang.paymentDetail}
        onCancel={() => {
          setPaymentDetailModal(false);
        }}
        destroyOnClose={true}
        width={710}
        centered
        footer={null}
        maskClosable={false}
      >
        <PaymentDetailForm
          paymentDetail={payment}
          onCallback={() => {
            handleCloseBookingDetailModal();
            setPaymentDetailModal(false);
            onCallbackBookingDetailModal();
          }}
          booking={booking}
        />
      </Modal>

      <ChangeBookingDateModal
        isShowChangeBookingDateModal={isShowChangeBookingDateModal}
        closeChangeBookingDateModal={() => setChangeBookingDateModal(false)}
        onCallbackChangeBookingDateModal={getBookingDetail}
        booking={booking}
        handleCloseBookingDetailModal={handleCloseBookingDetailModal}
        onCallbackBookingDetailModal={onCallbackBookingDetailModal}
      />
    </>
  );
};

BookingDetailModal.propTypes = {
  bookingId: PropTypes.string,
  closeBookingDetailModal: PropTypes.func,
  onCallbackBookingDetailModal: PropTypes.func,
};

export default BookingDetailModal;
