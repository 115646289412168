export const PAYMENT_LIST = 'PAYMENT_LIST';
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS';
export const PAYMENT_LIST_FAIL = 'PAYMENT_LIST_FAIL';

export const PAYMENT_CREATE = 'PAYMENT_CREATE';
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS';
export const PAYMENT_CREATE_FAIL = 'PPAYMENT_CREATE_FAIL';

export const PAYMENT_SUMMARY = 'PAYMENT_SUMMARY';
export const PAYMENT_SUMMARY_SUCCESS = 'PAYMENT_SUMMARY_SUCCESS';
export const PAYMENT_SUMMARY_FAIL = 'PAYMENT_SUMMARY_FAIL';

export const PAYMENT_COMPLETION = 'PAYMENT_COMPLETION';
export const PAYMENT_COMPLETION_SUCCESS = 'PAYMENT_COMPLETION_SUCCESS';
export const PAYMENT_COMPLETION_FAIL = 'PAYMENT_COMPLETION_FAIL';

export const PAYMENT_DETAIL = 'PAYMENT_DETAIL';
export const PAYMENT_DETAIL_SUCCESS = 'PAYMENT_DETAIL_SUCCESS';
export const PAYMENT_DETAIL_FAIL = 'PAYMENT_DETAIL_FAIL';

export const PAYMENT_REFUND = 'PAYMENT_REFUND';
export const PAYMENT_REFUND_SUCCESS = 'PAYMENT_REFUND_SUCCESS';
export const PAYMENT_REFUND_FAIL = 'PAYMENT_REFUND_FAIL';

export const PAYMENT_TIPS = 'PAYMENT_TIPS';
export const PAYMENT_TIPS_SUCCESS = 'PAYMENT_TIPS_SUCCESS';
export const PAYMENT_TIPS_FAIL = 'PAYMENT_TIPS_FAIL';
