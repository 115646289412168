import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Form, Button, Row, Col, Collapse, Radio } from 'antd';

import { useDispatch } from 'hooks/useCustomDispatch';
import { salonGetAllAreas } from 'providers/SalonProvider/actions';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './styles.less';
import BigSpin from '../Commons/BigSpin';
const { Panel } = Collapse;
const SalonAreaSelectForm = ({ salonArea, onCallback, prefecture }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();

  const [areas, setAreas] = useState([]);

  const [regionId, setRegionId] = useState(salonArea?.regionId);
  const [regionName, setRegionName] = useState(salonArea?.regionName);

  const [prefectureId, setPrefectureId] = useState(salonArea?.prefectureId);
  const [prefectureName, setPrefectureName] = useState(salonArea?.prefectureName);

  const [areaId, setAreaId] = useState(salonArea?.areaId);
  const [areaName, setAreaName] = useState(salonArea?.areaName);
  const [loading, setLoading] = useState(false);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await dispatch(salonGetAllAreas());
      await setAreas(result.data);
      const filteredAreas = (result.data || []).find(a => {
        return (a.prefectures || []).find(p => {
          if (p.name.toString() === prefecture.toString()) {
            return true;
          }
        });
      });
      const filteredPrefecture = _.keyBy(filteredAreas?.prefectures, 'name');
      setRegionId(filteredAreas?.id);
      setRegionName(filteredAreas?.name);
      setPrefectureId(filteredPrefecture[prefecture]?.id);
      setPrefectureName(filteredPrefecture[prefecture]?.name);
      if (!prefecture) {
        setAreaId('');
        setAreaName('');
      }
      setLoading(false);
    })();
  }, []);
  const onChangeRegion = e => {
    setRegionId(e.target.value);
    setRegionName(areas.find(x => x.id === e.target.value)?.name);

    setPrefectureId();
    setPrefectureName();

    setAreaId();
    setAreaName();
  };

  const onChangePrefecture = e => {
    setPrefectureId(e.target.value);
    setPrefectureName(areas.find(x => x.id === regionId)?.prefectures.find(y => y.id === e.target.value)?.name);

    setAreaId();
    setAreaName();
  };

  const onChangeArea = e => {
    setAreaId(e.target.value);
    setAreaName(areas.find(x => x.id === regionId)?.prefectures.find(y => y.id === prefectureId)?.cities.find(z => z.id === e.target.value)?.name);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Form {...layout} >
      <Collapse defaultActiveKey={['1', '2', '3']} style={{ marginBottom: 20 }}>
        <Panel header={`${lang.region} ${regionName || ''}`} key="1" style={{ display: 'none' }}>
          <Radio.Group onChange={onChangeRegion} value={regionId}>
            {areas.map(item => (
              <Radio key={item.id} style={radioStyle} value={item.id}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Panel>

        <Panel header={`${lang.prefecture} ${prefectureName || ''}`} key="2" style={{ display: 'none' }}>
          <Radio.Group onChange={onChangePrefecture} value={prefectureId}>
            {areas.find(x => x.id === regionId)?.prefectures.map(item => (
              <Radio key={item.id} style={radioStyle} value={item.id}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Panel>
        <Panel header={`${lang.area} ${areaName || ''}`} key="3">
          {loading && <BigSpin />}
          <Radio.Group onChange={onChangeArea} value={areaId}>
            {areas.find(x => x.id === regionId)?.prefectures.find(y => y.id === prefectureId)?.cities.map(item => (
              <Radio key={item.id} style={radioStyle} value={item.id}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Panel>
      </Collapse>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right', marginLeft: -8 }}>
            <Button
              type="primary"
              disabled={!regionId || !prefectureId || !areaId }
              onClick={() => {
                onCallback({
                  regionId,
                  regionName,
                  prefectureId,
                  prefectureName,
                  areaId,
                  areaName,
                });
              }}>
              {lang.selectedOption}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SalonAreaSelectForm.propTypes = {
  onCallback: PropTypes.func,
  salonArea: PropTypes.object,
};

export default SalonAreaSelectForm;
