// Libraries
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Table, DatePicker, Typography, Tag, Row, Col, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSearchParams, shouldSetSearchParams } from 'hooks/useCustomSearchParams';

// Components
import PageLayout from 'components/Desktop/Layout/PageLayout';

// Providers
import { getListSalonSaleReport } from 'providers/SalonProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import { formatYen } from 'utils/stringFormat';
import { TRANSFER_STATUS, TAX_RATE_STR, TAX_NAILIE_COMPANY_NAME, TAX_COMPANY_ID } from 'utils/constants';
import { HANDLE_EXPORT_CSV_FILE } from 'utils/exportCSVHelper';

const { Text } = Typography;

const CSVStructJA = {
  売上日: 'payoutCyCle',
  売上金額: 'salesAmount',
  [`予約手数料（税込・税率${TAX_RATE_STR}％）`]: 'bookingFee',
  [`決済手数料（税込・税率${TAX_RATE_STR}％）`]: 'settlementFeeAmount',
  小計: 'subtotalSales',
  [`振込手数料（税込・税率${TAX_RATE_STR}％）`]: 'transferFee',
  振込金額: 'transferAmountSales',
  振込日: 'transferDatePayment',
  ステータス: 'statusPayment',
  サロン名: 'salonName',
  'インボイス対象金額合計（消費税込・適用税率10％）': 'sumBookingSettleTransfer',
  消費税合計: 'totalConsumptionTax',
  適格請求書発行事業者: 'nailieCompanyName',
  適格請求書発行事業者登録番号: 'nailieCompanyTaxID',
};
const CSVStructEN = {
  'Payout cycle': 'payoutCyCle',
  'Sales amount': 'salesAmount',
  'Booking fee (tax included)': 'bookingFee',
  'Settlement fee (tax included)': 'settlementFeeAmount',
  Subtotal: 'subtotalSales',
  'Transfer fee (tax included)': 'transferFee',
  'Transfer amount': 'transferAmountSales',
  'Transfer date': 'transferDatePayment',
  Status: 'statusPayment',
  サロン名: 'salonName',
  'インボイス対象金額合計（消費税込・適用税率10％）': 'sumBookingSettleTransfer',
  消費税合計: 'totalConsumptionTax',
  適格請求書発行事業者: 'nailieCompanyName',
  適格請求書発行事業者登録番号: 'nailieCompanyTaxID',
};

const SalonSaleReport = () => {
  const dispatch = useDispatch();
  const { salon } = useSelector(state => state.salon);
  const salonId = salon.id;
  const utcOffset = moment.tz.zone(salon.timezone).utcOffset(moment());
  const utcOffsetHours = utcOffset / 60;
  const locale = useSelector((state) => state.i18n.locale);

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { currentDate = moment().format('YYYY') } = searchParamsValues;
  // </URLSearchParams>

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // Constant
  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/transfer-management',
      breadcrumbName: lang.transferManagement,
    },
  ];

  // Columns
  const salonColumns = [
    {
      key: 'payoutCycle',
      title: lang.payoutCycle,
      dataIndex: 'period',
      align: 'center',
      width: '14%',
      render: function renderItem (record) {
        // moment ISOString subtract utcOffset so we add utcOffset before
        const startDate = moment(record.startDate).add(utcOffsetHours, 'hours');
        const endDate = moment(record.endDate).add(utcOffsetHours, 'hours');
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {locale === 'en'
              ? `${startDate.format('MMM-DD')} to ${endDate.format('DD')}`
              : `${startDate.format('MM月D日')}~${endDate.format('D日')}`
            }
          </div>
        );
      },
    },
    {
      key: 'salesAmount',
      title: lang.salesAmount,
      dataIndex: ['extra', 'totalBookingValue'],
      align: 'center',
      render: function renderItem (record) {
        return (<Text>{formatYen(record || 0)}</Text>);
      },
    },
    {
      key: 'bookingFee',
      title: lang.bookingFee,
      dataIndex: ['extra', 'nailieComission'],
      align: 'center',
      render: function renderItem (record) {
        return (<Text>{formatYen((record || 0))}</Text>);
      },
    },
    {
      key: 'settlementFee',
      title: lang.settlementFee,
      dataIndex: ['extra', 'settlementFee'],
      align: 'center',
      render: function renderItem (record) {
        return (<Text>{formatYen((record))}</Text>);
      },
    },
    {
      key: 'subtotalSales',
      title: lang.subtotalSales,
      dataIndex: 'extra',
      align: 'center',
      render: function renderItem (record) {
        const { totalBookingValue = 0, nailieComission = 0, settlementFee = 0 } = record || {};
        return formatYen(totalBookingValue - nailieComission - settlementFee);
      },
    },
    {
      key: 'transferFee',
      title: lang.transferFee,
      dataIndex: ['extra', 'bankFee'],
      align: 'center',
      render: function renderItem (record) {
        return (<Text>{formatYen((record || 0))}</Text>);
      },
    },
    {
      key: 'transferAmountSales',
      title: lang.transferAmountSales,
      dataIndex: ['extra', 'transferAmount'],
      align: 'center',
      render: function renderItem (record) {
        return (<Text type="success">{formatYen(record || 0)}</Text>);
      },
    },
    {
      key: 'transferDate',
      title: lang.transferDate,
      dataIndex: ['extra', 'transferDate'],
      align: 'center',
      width: '14%',
      render: function renderItem (record) {
        const format = locale === 'en' ? 'YYYY-MM-DD' : 'YYYY年MM月DD日';
        return record ? <div style={{ whiteSpace: 'nowrap' }}>{moment(record).format(format)}</div> : 'ー';
      },
    },
    {
      key: 'status',
      title: lang.status,
      dataIndex: ['extra', 'status'],
      align: 'center',
      render: function renderItem (record) {
        return (
          <Tag color={TRANSFER_STATUS[record].color}>
            {lang[TRANSFER_STATUS[record].langKey]}
          </Tag>
        );
      },
    },
    {
      key: 'detail',
      fixed: 'right',
      align: 'center',
      render: function renderItem (record) {
        const { period } = record;
        const startDate = moment(period.startDate).add(utcOffsetHours, 'hours').format('YYYY-MM-DD');
        const endDate = moment(period.endDate).add(utcOffsetHours, 'hours').format('YYYY-MM-DD');

        return (
          <Link
            to={{
              pathname: '/mysalon/salon-sales-detail',
              search: `?page=1&startDate=${startDate}&endDate=${endDate}`,
            }}
          >
            {lang.details}
          </Link>
        );
      },
    },
  ];

  // State
  const [listSalonSaleReport, setListSalonSaleReport] = useState([]);
  const [salonLoading, setSalonLoading] = useState(false);
  const csvFileName = locale === 'en' ? `payment-list-${searchParamsValues?.currentDate}_${salon.businessName}.csv` : `${salon.businessName}_${searchParamsValues?.currentDate}-振込予定/履歴.csv`;

  const dateFrom = moment(currentDate, 'YYYY').startOf('year');
  const dateTo = moment(currentDate, 'YYYY').endOf('year');
  /**
   * Server Nailie: Not use timezone
   *
   * Example: {
   *  from: "2021-01-01T00:00:00.000Z",
   *  to:   "2021-01-15T23:59:59.999Z",
   * }
   */
  // Server NLSB: Not handle params are passed by web
  // toISOString add utcOffset so we subtract utcOffset before
  dateFrom.subtract(utcOffsetHours, 'hours');
  dateTo.subtract(utcOffsetHours, 'hours');

  const getListSalon = () => {
    setSalonLoading(true);
    dispatch(getListSalonSaleReport({
      salonId,
      from: dateFrom.toISOString(),
      to: dateTo.toISOString(),
    }))
      .then((result) => {
        setListSalonSaleReport(result.filter(item => item.extra.status).map(item => ({
          ...item,
          salonName: salon.businessName,
          nailieCompanyName: TAX_NAILIE_COMPANY_NAME,
          nailieCompanyTaxID: TAX_COMPANY_ID,
        })));
        setSalonLoading(false);
      });
  };

  const handleExportCSVFile = () => {
    HANDLE_EXPORT_CSV_FILE.handleExportListSalonSaleReportCSV(listSalonSaleReport, CSVStructEN, CSVStructJA, csvFileName, utcOffsetHours, locale, lang);
  };

  useEffect(() => {
    if (shouldSetSearchParams(searchParamsValues)) {
      handleSetSearchParams({ currentDate }, true);
    }
  }, [searchParamsValues]);

  useEffect(() => {
    getListSalon();
  }, [currentDate]);

  return (
    <PageLayout ghost={false} title={lang.transferManagement} routes={routes}>
      <Card>
        <Card style={{ marginBottom: 16, backgroundColor: '#F8F8F8' }}>
          <Row>
            <Col span={18} >
              <DatePicker
                value={moment(currentDate, 'YYYY')}
                allowClear={false}
                picker="year"
                onChange={(value) => {
                  const search = {
                    ...searchParamsValues,
                    currentDate: value.format('YYYY'),
                  };
                  handleSetSearchParams(search);
                }}
              />
            </Col>
            <Col span={6} >
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={() => handleExportCSVFile()}
                  icon={<ExportOutlined />}
                  disabled={listSalonSaleReport.length === 0}
                >
                  {lang.exportCSV}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <Table
          columns={salonColumns}
          dataSource={listSalonSaleReport}
          loading={salonLoading}
          bordered
          pagination={false}
          scroll={{ x: 920 }}
        />
      </Card>
    </PageLayout>
  );
};

export default SalonSaleReport;
