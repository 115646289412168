import {
  salonGetAllSurvey,
  salonSurveyResult,
} from 'providers/SalonProvider/actions';

const GetSalonSurveyResults = async (salon, dispatch) => {
  const result = await dispatch(salonGetAllSurvey());
  const surveyId = result?.data[0].id;
  const surveyResult = await dispatch(salonSurveyResult({ salonId: salon?.id, surveyId }));
  return surveyResult;
};
export default GetSalonSurveyResults;
