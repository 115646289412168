// Libraries
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Button, Table, Card, DatePicker, Form, Tag, Space } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, RedoOutlined, ScheduleOutlined } from '@ant-design/icons';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';
import { useSearchParams, shouldFetchApi, shouldSetSearchParams } from 'hooks/useCustomSearchParams';
import { useBookingNotificationNumBadge } from 'hooks/useBookingNotificationNumBadge.js';

// Components
import OutsideBookingsModal from 'components/Desktop/Booking/outside-booking.modal';
import BookingDetailModal from 'components/Desktop/Booking/booking-detail.modal';
import PageLayout from 'components/Desktop/Layout/PageLayout';
import WeekFormModal from 'components/Desktop/WorkingTime/week-form.modal';
import WeekViewModal from 'components/Desktop/WorkingTime/WeekViewModal';
import BookingNotificationModal from 'components/Desktop/Booking/booking-notification-list.modal';
import SalesCard from './SalesCard.component.js';
import WaitingDoneBookingListModal from 'components/Desktop/Booking/waiting-done-booking-list.modal';

// Images
import IconO from 'assets/images/icon-o.svg';
import IconX from 'assets/images/icon-x.svg';
import IconDisableSlot from 'assets/images/ic-disable-slot.svg';
import Avatar from 'components/Desktop/Avatar';

// Providers
import { staffScheduleList, staffScheduleDailyUpdate } from 'providers/StaffProvider/actions';
import { bookingListOnSchedule, bookingListWaitingDone, fetchBookingNotificationList, readAllBookingNotification } from 'providers/BookingProvider/actions';
import { categoryList } from 'providers/CategoryProvider/actions.js';

// Utils
import { getLanguages } from 'utils/lang';
import { getDataIndexFromIndex, getIndexFromMoment, padStartTime, getDateTimeFromSlotIndex, getDefaultTimeTableScrollPosition } from 'utils/schedule';
import { timezone, BOOKING_CANCELED_STATUSES, BOOKING_STATUS } from 'utils/constants';
import { ceilValueToMultipleOfNumber } from 'utils/number';
import { getSalonSalesAmount, calcTotalAmountBookings } from 'utils/sales';

import './styles.less';

const slotColumnWidth = 54; // px
const LIMIT = 1000;
const TIME_RELOAD = 60000; // ms
const LIMIT_PAGE = 100;

let getStaffSchedule = () => { };
let getBookingList = () => { };

const StaffScheduleDaily = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const { state } = useLocation();
  useBookingNotificationNumBadge();

  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { showBooking } = searchParamsValues;
  const isShowBooking = showBooking === 'true';
  const currentPage = parseInt(searchParamsValues.page);
  // </URLSearchParams>

  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [weekFormModal, setWeekFormModal] = useState(null);
  const [weekViewModal, setWeekViewModal] = useState(null);
  const [outsideBookingsModal, setOutsideBookingsModal] = useState(null);
  const [bookingDetailModal, setBookingDetailModal] = useState(null);
  const [waitingDoneListModal, setWaitingDoneListModal] = useState(false);
  const [bookingNotificationModal, setBookingNotificationModal] = useState(false);
  const referenceDate = moment(searchParamsValues.date || moment(), 'YYYY-MM-DD');
  const from = referenceDate.clone().startOf('day').toISOString();
  const to = referenceDate.clone().endOf('day').toISOString();

  // <Time Table Scroll Position>
  const { salon } = useSelector(state => state.salon);
  const salonScheduleByDay = salon.schedules.find(salonSchedule => salonSchedule.dayOfWeek === referenceDate.days());
  const salonScheduleByDayStartAt = moment(salonScheduleByDay?.startAt);
  const salonScheduleByDayEndAt = moment(salonScheduleByDay?.endAt);
  const notAcceptBookingTime = moment.duration(moment().endOf('days').diff(moment())).hours();
  const minAcceptBookingHour = salon?.acceptBookingTime?.minimum === 0 ? notAcceptBookingTime : salon?.acceptBookingTime?.minimum;
  const salonOpenHours = salonScheduleByDayStartAt.diff(salonScheduleByDayEndAt) === 0
    ? 10
    : salonScheduleByDayStartAt.hours() + salonScheduleByDayStartAt.minutes() / 60;
  const defaultTimeTableScrollPosition = getDefaultTimeTableScrollPosition(referenceDate, salonOpenHours, minAcceptBookingHour, slotColumnWidth); // hours * 2 slots * slot width
  const isLockSchedule = salon?.lockSchedule; // when allow to sync the Beauty Merit schedule,  can not edit schedule.
  // </Time Table Scroll Position>
  const tableScrollHeightArea = window.innerHeight - 390; // px
  // <Show booking>
  const { categoriesWithTrashed = [] } = useSelector((state) => state.category);
  const { bookings, waitingDoneBookings, doneBookings, canceledBookings, notification } = useSelector(state => state.booking);
  const numberOfNotification = _.get(notification, 'numUnread');
  const bookingsInSchedule = _.get(bookings, 'data', [])
    .filter(booking => {
      const bookingStatus = booking?.notes.filter(note => note.type === 'targetSystemStatus')[0]?.value.toUpperCase();
      return !BOOKING_CANCELED_STATUSES.includes(bookingStatus);
    })
    .map(booking => {
      const bookingStatus = booking?.notes.filter(note => note.type === 'targetSystemStatus')[0]?.value.toUpperCase();
      const startTime = moment(booking.startTime);
      const endTime = startTime.clone()
        .add(Math.floor(booking.totalDuration / 60), 'hours')
        .add(ceilValueToMultipleOfNumber(booking.totalDuration % 60, 30), 'minutes');
      return {
        staffId: _.get(booking, 'orders[0].staff.id', ''),
        bookingId: booking.id,
        sourceId: booking.sourceId,
        customerName: booking.customer.name,
        customerPhonetic: booking.customer.phonetic,
        price: getSalonSalesAmount(booking),
        startCellIndex: Math.floor(getIndexFromMoment(startTime)),
        endCellIndex: Math.floor(getIndexFromMoment(endTime)),
        status: bookingStatus,
        category: booking?.orders.map((item) => {
          if (item.service || item?.category?.name) { //  // Booking normal sync from NL or booking of staff import
            return { name: item?.service?.category?.name || item?.category?.name };
          } else { // Booking has be deleted menu
            // find the category  if the menu of booking has been deleted
            const categoryBookingDetail = (categoriesWithTrashed || []).find(category => {
              return (category.services || []).find(service => {
                if (service.id === item?.serviceId) {
                  return true;
                }
              });
            });
            return { name: categoryBookingDetail?.name };
          }
        }),
      };
    });
  // </Show booking>
  // < Show sales amount>
  const doneAmounts = calcTotalAmountBookings(doneBookings, 0);
  const canceledAmounts = calcTotalAmountBookings(canceledBookings, 0);
  const totalSales = doneAmounts + canceledAmounts;

  const onChangeDate = (date, dateString) => {
    const search = {
      ...searchParamsValues,
      date: date.format('YYYY-MM-DD'),
    };
    handleSetSearchParams(search);
  };

  // <Declare columns>
  // Declare staff column
  const columns = [
    {
      title: lang.staff,
      dataIndex: 'staff',
      width: 170,
      fixed: 'left',
      render: function renderItem (record) {
        const { staffId, staffName, photo } = record;
        return (
          <div style={{ paddingTop: 4, paddingLeft: 8, paddingBottom: 4, backgroundColor: '#FFFFFF', cursor: 'default' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={photo} size='large' style={{ marginRight: 8, minWidth: 40 }} />
              <div style={{ overflow: 'hidden', paddingRight: 8 }}>{staffName}</div>
            </div >
            { /* Can not edit staff schedule when salon allow sync Schedule to Beauty Merit */}
            <div
              style={{ display: 'none' }}
              onClick={() => {
                setWeekFormModal({
                  staffId,
                  staffName,
                });
              }}
            >
              <ScheduleOutlined style={{ marginRight: 8 }} />
              {lang.editSchedule}
            </div>
          </div>
        );
      },
    },
  ];

  const renderIcon = (icon) => <img src={icon === 'x' ? IconX : IconO} alt={icon} />;

  // Declare time columns
  for (let index = 0; index < 47; index++) {
    const dataIndex = getDataIndexFromIndex(index);
    const isPastDate = referenceDate.clone().add(index / 2, 'hours').isBefore(moment().clone().add(minAcceptBookingHour, 'hours'));
    columns.push({
      title: <div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{padStartTime(Math.floor(dataIndex / 100))}:{padStartTime(dataIndex % 100)}</div>,
      dataIndex,
      colSpan: dataIndex % 100 === 0 ? 2 : 0,
      align: 'center',
      width: slotColumnWidth,
      render: (value, row, index) => {
        // This slot is still in duration of booking.
        // Not use colSpan = 0 because this prop won't number for this cell index.
        if (value === null) {
          return {
            props: {
              style: { display: 'none' },
            },
          };
        }

        // <Show booking>
        if (value && value.bookingId) {
          const { customerPhonetic } = value;
          const phonetic = customerPhonetic ? `（${customerPhonetic}）` : '';
          const categoriesName = _.uniqBy(value.category, 'name');
          const renderBookingCell = (categoriesName) => {
            return (
              <div className="booking-cell">
                <div className="booking-tag-child booking-tag-customer">{value.customerName + phonetic}</div>
                <div className="booking-tag-child booking-tag-menu">
                  {categoriesName.map(item => <span className="booking-cell-span" key={item.name}> {item.name} </span>)}
                </div>
              </div>
            );
          };
          return {
            props: {
              colSpan: value.endCellIndex - value.startCellIndex,
            },
            children: (
              <Tag
                style={{ maxWidth: '100%', width: '100%', height: 70 }}
                className="booking-tag"
                color={BOOKING_STATUS[value?.status]?.color}
                onClick={() => setBookingDetailModal(value.bookingId)}
              >
                {renderBookingCell(categoriesName)}
              </Tag>
            ),
          };
        }
        // </Show booking>
        return {
          props: {
            style: isPastDate || isLockSchedule ? { opacity: 0.5, background: '#e2e2e2', pointerEvents: 'none' } : {},
          },
          children: isPastDate ? < img src={IconDisableSlot} alt={IconDisableSlot } /> : renderIcon(value ? 'o' : 'x'),
        };
      },
    });
  }
  // </Declare columns>

  // Handle click Yes button on Modal
  const clickAndDrag = (rowIndex) => {
    setLoading(true);
    const dayOfWeek = referenceDate.days();
    // rowIndex:
    // 1st: .ant-table-thead tr
    // 2nd: tr.ant-table-measure-row
    const staff = staffs[rowIndex - 1];

    // Get staff schedule
    const staffScheduleDailies = staff.scheduleDailies.filter(item =>
      item.dayOfWeek === dayOfWeek);
    const staffSchedules = staff.schedules.filter(item =>
      item.dayOfWeek === dayOfWeek &&
      moment(referenceDate).diff(moment(item.startSchedule)) >= 0 &&
      (item.endSchedule === null || moment(referenceDate).diff(moment(item.endSchedule)) <= 0));
    const staffScheduleList = staffScheduleDailies.length === 0 ? staffSchedules : staffScheduleDailies;

    // Init slot value
    const slots = [];
    for (let index = 0; index < 47; index++) {
      slots[index] = false;
    }

    // Open slot
    staffScheduleList.forEach((schedule) => {
      const startAt = moment(schedule.startAt);
      const endAt = moment(schedule.endAt);
      // Minutes starting from 1->29 must be rounded to 30 and 31-59 -> 0 of the next hour
      const oddTime = startAt.minutes();
      if (oddTime > 0 && oddTime < 30) { // 10,20 -> startAt = 30
        startAt.set({ minute: 30, second: 0, millisecond: 0 });
      } else if (oddTime > 30 && oddTime < 60) { // 40,50 => startAt hh:00 (next hour)
        startAt.set({ minute: 60, second: 0, millisecond: 0 });
      }

      const startAtIndex = getIndexFromMoment(startAt);
      const endAtIndex = getIndexFromMoment(endAt);
      for (let index = startAtIndex; index < endAtIndex; index++) {
        slots[index] = true;
      }
    });

    // Open/Close slot(s) after clicking and dragging cell(s)
    const highlighted = $('.highlighted');
    // 1st cellIndex: staff info
    const startCellIndex = highlighted[0].cellIndex - 1;
    const endCellIndex = highlighted[highlighted.length - 1].cellIndex - 1;
    for (let index = startCellIndex; index <= endCellIndex; index++) {
      slots[index] = !slots[index];
    }

    // Convert slots (boolean array) to schedules (object array)
    let startIndex = null;
    const schedules = [];
    for (let index = 0; index < 47; index++) {
      if (slots[index] === true && startIndex === null) {
        startIndex = index;
      }

      const isLastSlotOpened = index === 46 && slots[index] === true;
      if ((slots[index] === false || isLastSlotOpened) && startIndex !== null) {
        schedules.push({
          dayOfWeek,
          startAt: getDateTimeFromSlotIndex(startIndex, referenceDate).toISOString(),
          endAt: isLastSlotOpened
            ? getDateTimeFromSlotIndex(47, referenceDate).toISOString()
            : getDateTimeFromSlotIndex(index, referenceDate).toISOString(),
        });
        startIndex = null;
      }
    }

    // Case: Off whole day
    // `schedules` array must have at least 1 element (api rule)
    // This element has start time === end time
    if (schedules.length === 0) {
      const startOfDayString = referenceDate.clone().startOf('day').toISOString();

      schedules.push({
        dayOfWeek,
        startAt: startOfDayString,
        endAt: startOfDayString,
      });
    }

    dispatch(staffScheduleDailyUpdate({
      timezone,
      schedules,
      staffId: staff.id,
    })).then(() => {
      getStaffSchedule();
    });
  };

  const handleMouseUp = (value, rowIndex) => {
    clickAndDrag(rowIndex);
  };

  useEffect(() => {
    // View only for data table in the past dates or when allow to sync the Beauty Merit schedule,
    if (referenceDate.clone().startOf('day').isBefore(moment().startOf('day')) || isLockSchedule) {
      $('.ant-table-body').find('.highlighted').removeClass('highlighted');
      return;
    }

    if (staffs.length !== 0) {
      $('.ant-table-body').ready(function () {
        if ($('.ant-table-body').length) {
          let isMouseDown = false;
          let isMouseUp = false;
          let cellIndex;
          let rowIndex;
          let value;
          /**
           * Keep track of the first cell being highlighted.
           */
          let startingCellIndex;
          /**
           * Tracking current mouse moving direction.
           * Value: -1, 0, 1
           * @type {-1 | 0 | 1}
           */
          let currentDirection = 0;
          /**
           * Tracking highlighting direction, it can be different from `currentDirection`.
           * @type {-1 | 0 | 1}
           */
          let highlightingDirection = 0;

          $('.ant-table-body').on('mousedown', 'td', function (event) { // mousedown
            const startCell = $(this)[0];

            // <Show booking>
            if (startCell.childNodes[0].alt === undefined) {
              return;
            }
            // </Show booking>

            // Not allow click staff info cell
            if (startCell.cellIndex !== 0) {
              // Remove all old highlighted class
              $('.ant-table-body').find('.highlighted').removeClass('highlighted');
              isMouseDown = true;
              startingCellIndex = cellIndex = startCell.cellIndex;
              currentDirection = highlightingDirection = 0;
              rowIndex = startCell.parentNode.rowIndex;
              value = startCell.childNodes[0].alt;
              $(this).addClass('highlighted');
            }
            return false;
          }).on('mouseenter', 'td', function (event) { // mouseenter
            const nextCell = $(this)[0];
            const nextCellSameRow = $(`tr:eq(${rowIndex + 1}) td:eq(${nextCell.cellIndex})`);

            if (nextCell.cellIndex > cellIndex) {
              // moving right
              currentDirection = 1;
            } else if (nextCell.cellIndex < cellIndex) {
              // moving left
              currentDirection = -1;
            }

            // If have not started highlighting any direction yet.
            // So set it to the same direction as the mouse movement.
            if (highlightingDirection === 0) highlightingDirection = currentDirection;

            if (
              isMouseDown &&
              Math.abs(nextCell.cellIndex - cellIndex) === 1 &&
              value === nextCellSameRow[0].childNodes[0].alt
            ) {
              // If the 2 direction trackers are similar, we add 1 more highlight cell.
              // Otherwise, we remove highlight from a previously highlighted cell.
              if (highlightingDirection * currentDirection > 0) {
                nextCellSameRow.addClass('highlighted');
              } else {
                const prevCellSameRow = $(`tr:eq(${rowIndex}) td:eq(${cellIndex})`);
                prevCellSameRow.removeClass('highlighted');
                if (nextCell.cellIndex === startingCellIndex) {
                  // Arrived back to the starting cell, reset all direction trackers.
                  currentDirection = 0;
                  highlightingDirection = 0;
                }
              }

              cellIndex = nextCell.cellIndex;
            }
          }).on('mouseup', 'td', function (event) { // mouseup
            const endCell = $(this)[0];
            if (
              isMouseDown &&
              endCell.parentNode.rowIndex === rowIndex &&
              Math.abs(endCell.cellIndex - cellIndex) === 1 &&
              value === endCell.childNodes[0].alt
            ) {
              isMouseUp = true;
              $(this).addClass('highlighted');
              handleMouseUp(value, rowIndex);
            }
          });

          $(document).mouseup(function () {
            if (isMouseDown && !isMouseUp) {
              handleMouseUp(value, rowIndex);
            }
            isMouseDown = false;
            isMouseUp = false;
          });
        }
      });
    }

    // Note: Must cleanup to prevent additional event handlers to the table component.
    return function cleanup () {
      $('.ant-table-body').off('mousedown').off('mouseenter').off('mouseup');
    };
  }, [staffs]);

  useEffect(() => {
    if (shouldFetchApi(searchParamsValues)) {
      getStaffSchedule();
    }
  }, [currentPage, from, to]);

  getBookingList = () => {
    dispatch(bookingListWaitingDone({ page: 1, limit: 1000, targetSystemStatus: 'WAITING_DONE' })); // Get all booking waiting done
    dispatch(bookingListOnSchedule({ page: 1, limit: 10000, from, to }))
      .then(() => {
        setTimeout(() => setLoading(false), 300);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error);
      });
  };

  // <Show booking>
  // Get all category, if booking has menu deleted, use serviceId to compare with service.Id of category item
  useEffect(() => {
    dispatch(categoryList({ withTrashed: 1 }));
  }, []);
  // Get all bookings of all staffs of salon from start of day to end of day.
  useEffect(() => {
    getBookingList();

    const elements = document.getElementsByClassName('ant-table-body');
    if (elements.length !== 0) {
      setTimeout(() => { elements[0].scrollLeft = defaultTimeTableScrollPosition; }, 1250);
    }
  }, [from, to]);
  // </Show booking>

  getStaffSchedule = () => {
    setLoading(true);
    dispatch(staffScheduleList({ currentPage, pageSize: LIMIT, sortedInfo: 'order asc', from, to }))
      .then(result => {
        const { data } = result.data;
        setStaffs(data);
        setTimeout(() => setLoading(false), 300);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error);
      });
  };

  const getBookingNotificationList = () => {
    dispatch(fetchBookingNotificationList({ page: 1, limit: LIMIT_PAGE }));
  };

  useEffect(() => {
    const elements = document.getElementsByClassName('ant-table-body');
    if (elements.length !== 0) {
      elements[0].scrollLeft = defaultTimeTableScrollPosition;
    }
  }, []);

  useEffect(() => {
    const now = moment();
    let date = moment();
    if (state && (state.year !== now.weekYear() || state.week !== now.week())) {
      date = moment().weekYear(state.year).week(state.week).startOf('week');
    }

    if (shouldSetSearchParams(searchParamsValues)) {
      handleSetSearchParams({ page: 1, date: date.format('YYYY-MM-DD'), showBooking: true }, true);
    }
  }, [searchParamsValues]);

  // <Reload page every 1 minute>
  useEffect(() => {
    const interval = setInterval(() => {
      getStaffSchedule();
      getBookingList();
    }, TIME_RELOAD);
    return () => clearInterval(interval);
  }, []);

  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/staff-schedule',
      breadcrumbName: lang.manageBookings,
    },
  ];

  return (
    <PageLayout ghost={false} title={lang.manageBookings} routes={routes}>
      <div id="staff-schedule-daily">
        <Card style={{ marginBottom: 16 }}>
          <Form layout="inline" onSubmit={() => {}} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Item>
              <div style={{ display: 'flex', flexWrap: 1, flexDirection: 'row' }}>
                <Button
                  onClick={() => {
                    $('.ant-table-body').find('.highlighted').removeClass('highlighted');
                    const search = {
                      ...searchParamsValues,
                      date: referenceDate.clone().subtract(1, 'days').format('YYYY-MM-DD'),
                    };
                    handleSetSearchParams(search);
                  }}
                >
                  <CaretLeftOutlined />
                </Button>
                <DatePicker
                  style={{ margin: '0 4px' }}
                  allowClear={false}
                  picker="date"
                  value={referenceDate}
                  onChange={onChangeDate}
                />
                <Button
                  onClick={() => {
                    $('.ant-table-body').find('.highlighted').removeClass('highlighted');
                    const search = {
                      ...searchParamsValues,
                      date: referenceDate.clone().add(1, 'days').format('YYYY-MM-DD'),
                    };
                    handleSetSearchParams(search);
                  }}
                >
                  <CaretRightOutlined />
                </Button>

                {/* Reload */}
                <Button
                  onClickCapture={() => {
                    getBookingNotificationList();
                    getStaffSchedule();
                    getBookingList();
                  }}
                  icon={<RedoOutlined />}
                  style={{ marginLeft: 8 }}
                />

                {/* Reservation Alert */}
                <div id={`${numberOfNotification === 0 ? 'button-notification' : ''}`}>
                  <Button
                    onClickCapture={() => setBookingNotificationModal(true)}
                    style={{ marginLeft: 8 }}
                    danger={numberOfNotification > 0}
                  >
                    {lang.reservationAlertBtn} ({numberOfNotification > 99 ? '99+' : numberOfNotification} {lang.case})
                  </Button>
                </div>

                {/* Waiting Done */}
                <div id={`${waitingDoneBookings?.length > 0 ? 'button-waiting-done' : ''}`}>
                  <Button
                    danger
                    onClickCapture={() => setWaitingDoneListModal(true)}
                    style={{ marginLeft: 8 }}
                    disabled={waitingDoneBookings?.length === 0}
                  >
                    {lang.titleStatusWaitingDone} ({waitingDoneBookings?.length} {lang.case})
                  </Button>
                </div>
              </div>
            </Form.Item>
            <div>
              {/* Show sales amount */}
              <Space className="sales-card">
                <SalesCard langKey={'totalSales'} amounts={totalSales} />
                <SalesCard langKey={'doneAmounts'} amounts={doneAmounts} />
                <SalesCard langKey={'canceledAmounts'} amounts={canceledAmounts} />
              </Space>
            </div>
          </Form>
        </Card>
        <Card >
          <Table
            id="schedule-table-all-staff"
            columns={columns}
            dataSource={staffs.map((item) => {
              let staff = _.get(item, 'connections[0].data');
              if (!staff) {
                staff = {
                  avatarLink: 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png',
                  name: item.name,
                };
              }
              // Staff info data
              const data = {
                key: item.id,
                staff: {
                  photo: staff.avatarLink,
                  staffId: item.id,
                  staffName: staff.name,
                },
              };

              // Get staff schedule
              const staffScheduleDailies = item.scheduleDailies.filter(
                (item) => item.dayOfWeek === referenceDate.days(),
              );
              const staffSchedules = item.schedules.filter(
                (item) =>
                  item.dayOfWeek === referenceDate.days() &&
                  moment(referenceDate).diff(moment(item.startSchedule)) >= 0 &&
                  (item.endSchedule === null || (moment().isSame(moment(item.endSchedule), 'days'))),
              );

              // today: if endSchedule < current time => endAt = endSchedule.
              let staffSchedulesDefault = staffSchedules; // off schedule for today Because BE return only 1 obj for this today
              if (staffSchedules?.length > 0) {
                const endScheduleBeforeCurrentTime = (moment(staffSchedules[0]?.endSchedule).diff(moment()) < 0) && (moment(staffSchedules[0].startAt) !== moment(staffSchedules[0].endAt));
                if (staffSchedules[0].endSchedule === null) { // initial value
                  staffSchedulesDefault = staffSchedules;
                } else if (endScheduleBeforeCurrentTime) { // connected 3rd => off from today
                  staffSchedulesDefault = [{ ...staffSchedules[0], endAt: staffSchedules[0]?.endSchedule }];
                }
              }
              const schedules =
              staffScheduleDailies.length === 0 ? staffSchedulesDefault : staffScheduleDailies;

              // Time data
              schedules.forEach((schedule) => {
                const startAt = moment(schedule.startAt);
                const endAt = moment(schedule.endAt);
                const oddTime = startAt.minutes();

                if (oddTime > 0 && oddTime < 30) { // 10,20 -> startAt = 30
                  startAt.set({ minute: 30, second: 0, millisecond: 0 });
                } else if (oddTime > 30 && oddTime < 60) { // 40,50 => startAt hh:00 (next hour)
                  startAt.set({ minute: 60, second: 0, millisecond: 0 });
                }

                for (
                  let index = getIndexFromMoment(startAt);
                  index < getIndexFromMoment(endAt);
                  index++
                ) {
                  const dataIndex = getDataIndexFromIndex(index);
                  data[dataIndex] = true;
                }
              });

              // <Show booking detail>
              if (isShowBooking) {
                const bookingsInScheduleByStaff = bookingsInSchedule.filter(booking => booking.staffId === item.id) || [];
                bookingsInScheduleByStaff.forEach(booking => {
                  const { startCellIndex, endCellIndex } = booking;
                  data[getDataIndexFromIndex(startCellIndex)] = booking;
                  for (let i = startCellIndex + 1; i < endCellIndex; i++) {
                    data[getDataIndexFromIndex(i)] = null;
                  }
                });
              }
              // </Show booking detail>

              return data;
            })}
            loading={loading}
            bordered
            pagination={false}
            scroll={{ x: 1300, y: tableScrollHeightArea }}
          />
        </Card>
      </div>

      <WeekFormModal
        weekFormModal={weekFormModal}
        closeWeekFormModal={() => setWeekFormModal(null)}
        onCallbackWeekFormModal={() => {
          getStaffSchedule();
        }}
        setWeekViewModal={setWeekViewModal}
        setOutsideBookingsModal={setOutsideBookingsModal}
      />

      <WeekViewModal
        weekViewModal={weekViewModal}
        setWeekViewModal={setWeekViewModal}
      />

      <OutsideBookingsModal
        outsideBookingsModal={outsideBookingsModal}
        closeOutsideBookingsModal={() => setOutsideBookingsModal(null)}
        setBookingDetailModal={setBookingDetailModal}
      />

      <BookingDetailModal
        bookingId={bookingDetailModal}
        closeBookingDetailModal={() => setBookingDetailModal(null)}
        onCallbackBookingDetailModal={getBookingList}
      />

      <WaitingDoneBookingListModal
        showModal={waitingDoneListModal}
        closeModal={() => setWaitingDoneListModal(false)}
        data={waitingDoneBookings}
        handleDetailBooking={(object) => {
          setBookingDetailModal(object.id);
          setWaitingDoneListModal(false);
        }}
      />
      <BookingNotificationModal
        showNotificationModal={bookingNotificationModal}
        closeNotificationModal={() => {
          dispatch(readAllBookingNotification());
          setBookingNotificationModal(false);
        }}
        data={bookings}
        handleDetailBooking={(object) => {
          setBookingDetailModal(object.id);
        }}
      />
    </PageLayout>
  );
};

export default StaffScheduleDaily;
