import React, { useState, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Typography, Select, Space, Avatar, message, Alert, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { LinkOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
// hooks
import { useDispatch } from 'hooks/useCustomDispatch';
import useFetchStaffs from 'hooks/staffs/useFetchStaffs';
import useFetchStaffsComingSoon from 'hooks/staffs/useFetchStaffComingSoon';

// providers
import { toggleThirdPartyStaffConnection } from 'providers/StaffProvider/actions';
import { ComingSoonContext } from './SalonConnectThirdPartiesSchedule';

// Components
import StaffsMappingInitSettingModal from './SalonStaffMappingSettingPopup';
import BigSpin from '../Commons/BigSpin';

// utils
import './styles.less';
import { getLanguages } from 'utils/lang';
import { _sleep } from 'utils/common';
import { useSelector } from 'react-redux';

let modalWarningControl = null;
export const ShowPopupContext = createContext();
const { Text } = Typography;
const { Option } = Select;

const StaffsMappingContainer = ({ connectingParty, renderButtonDisconnect3rd }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { staffsComingSoon, getStaffComingSoonList, loading } = useFetchStaffsComingSoon();
  const [isRetryGetListCS, setIsRetryGetListCS] = useState(false); // show popup after CS sync data

  if (_.isEmpty(staffsComingSoon)) {
    return (
      <>
        {loading && <BigSpin />}
        <Space style={{ marginTop: 16 }}>
          <Space direction='vertical' >
            <Text>{lang.csConnectStaffWarningHead}</Text>
            <Text>{lang.CsConnectStaffWarningTail}</Text>
          </Space>
          <Button type='primary'
            onClick={() => {
              getStaffComingSoonList();
              setIsRetryGetListCS(true);
            }}
          >{lang.retry}</Button>
        </Space>
        <div style={{ marginTop: 24 }} >
          {renderButtonDisconnect3rd(connectingParty)}
        </div>
      </>
    );
  }

  return (
    <ShowPopupContext.Provider value={{ isRetryGetListCS, setIsRetryGetListCS }}>
      <WrapperMappingStaffSection
        staffsComingSoon={staffsComingSoon}
        connectingParty={connectingParty}
        renderButtonDisconnect3rd={renderButtonDisconnect3rd}
      />
    </ShowPopupContext.Provider>);
};

const WrapperMappingStaffSection = ({ connectingParty, renderButtonDisconnect3rd, staffsComingSoon }) => {
  const { staffs, getStaffList } = useFetchStaffs();
  const CSContext = useContext(ComingSoonContext);
  const popupConText = useContext(ShowPopupContext);
  if (_.isEmpty(staffs)) {
    return <BigSpin/>;
  }
  // Show popup after retry to get list CS
  if (popupConText.isRetryGetListCS && !_.isEmpty(staffsComingSoon)) {
    CSContext.setIsOpenInitSettingMappingPopup(true);
  }

  if (CSContext.isOpenInitSettingMappingPopup) {
    return <StaffsMappingInitSettingModal
      staffsComingSoon={staffsComingSoon}
      staffs={staffs}
      getStaffList={getStaffList}
      connectingParty={connectingParty}
      renderButtonDisconnect3rd={renderButtonDisconnect3rd}
    />;
  } else if (!CSContext.isOpenInitSettingMappingPopup) {
    return (
      <div style={{ marginTop: 24 }}>
        <SalonStaffConnectComingSoonStaff
          staffs={staffs}
          staffsComingSoon={staffsComingSoon}
          getStaffList={getStaffList}
          connectingParty={connectingParty}
          renderButtonDisconnect3rd={renderButtonDisconnect3rd}
        />
      </div>
    );
  }
};

export const SalonStaffConnectComingSoonStaff = ({ staffs, staffsComingSoon, getStaffList, renderButtonDisconnect3rd, connectingParty }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isShowAlertConfirmDisconnectStaff, setIsShowAlertConfirmDisconnectStaff] = useState(false);
  const [, forceRender] = useState([]);
  const selectedCSStaff = Object.values(form.getFieldsValue());
  const { getStaffComingSoonList } = useFetchStaffsComingSoon();
  // hide this alert when at least 1 staff is mapped with CS staff
  const isShowAlertCompleteMapping = staffs.find(s => s?.thirdParties?.length > 0);
  const CSContext = useContext(ComingSoonContext);
  const PopupContext = useContext(ShowPopupContext);

  const { salon } = useSelector(state => state?.salon);
  const acceptanceDate = salon?.acceptBookingTime?.maximum;

  // close modal
  useEffect(() => {
    return () => {
      if (modalWarningControl) {
        modalWarningControl.destroy();
      }
    };
  }, []);

  // Mapping manual each salon staff to CS staff
  const submit = async (values) => {
    setLoading(true);
    const fetchedStaffs = await getStaffList();
    const fetchedStaffCS = await getStaffComingSoonList();
    for (const staffId in values) {
      const CSId = values[staffId];
      if (CSId && !fetchedStaffCS.some((s) => s.sourceId === CSId)) {
        message.error('The coming-soon staff list is no longer available. The page will refresh');
        setLoading(false);
        await _sleep(1500);
        window.location.reload();
        return;
      }
    }
    const staffsCSConnection = staffIdCSSourceId(fetchedStaffs);// current staff cs
    // new logic submit this form: Update 18/2/2022
    // step1: disconnect all staff of disconnectStaff list
    // step2 : disconnect staff of switchStaffList and connect again with new value
    // step3: connect new staff of newConnectStaffsList

    const disconnectStaffsList = [];
    const newConnectStaffsList = [];
    const switchStaffsList = [];
    // staff CS sourceID = 0, => disconnect => show alert
    for (const staffId in staffsCSConnection) {
      if (staffsCSConnection[staffId] !== undefined && values[staffId] === 0) {
        disconnectStaffsList.push({ staffId, sourceId: staffsCSConnection[staffId] });
      } else if (staffsCSConnection[staffId] !== undefined && values[staffId] !== 0 && staffsCSConnection[staffId] !== values[staffId]) {
        switchStaffsList.push({ staffId, sourceId: values[staffId] });
      } else if (staffsCSConnection[staffId] === undefined && values[staffId] !== 0 && values[staffId] !== undefined) {
        newConnectStaffsList.push({ staffId, sourceId: values[staffId] });
      }
    }
    // 1.disconnect all staff of disconnectStaff list
    if (!_.isEmpty(disconnectStaffsList)) {
      for (const s of disconnectStaffsList) {
        await dispatch(toggleThirdPartyStaffConnection({
          staffId: s.staffId,
          sourceId: undefined,
        }));
      }
    }

    // 2.disconnect staff of switchStaffList and connect again with new value
    if (!_.isEmpty(switchStaffsList)) {
      try {
        for (const s of switchStaffsList) {
          await dispatch(toggleThirdPartyStaffConnection({
            staffId: s.staffId,
            sourceId: undefined, // disconnect
          }));
        }
        for (const s of switchStaffsList) {
          await dispatch(toggleThirdPartyStaffConnection({
            staffId: s.staffId,
            sourceId: s.sourceId,
          }));
        }
      } catch (errInfo) {
        console.log(errInfo);
      }
    }
    // 3.connect new staff of newConnectStaffsList
    if (!_.isEmpty(newConnectStaffsList)) {
      for (const s of newConnectStaffsList) {
        await dispatch(toggleThirdPartyStaffConnection({
          staffId: s.staffId,
          sourceId: s.sourceId,
        }));
      }
    }

    await getStaffList();
    message.success('設定を変更しました');
    PopupContext.setIsRetryGetListCS(false); // turn off flag show popup after retry get CS data
    setLoading(false);
    setIsShowAlertConfirmDisconnectStaff(false);
    CSContext.setIsOpenInitSettingMappingPopup(false);
  };

  // Show confirm popup when remove a staff connected CS
  const confirmDisconnectStaff = (values) => {
    modalWarningControl = Modal.confirm({
      width: 890,
      className: 'connect-control-modal',
      centered: true,
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: `${lang.contentDisconnect3rdParty}
      ${lang.contentDisconnect3rdPartyHead}${acceptanceDate}${lang.contentDisconnect3rdPartyTail}`,
      onOk: () => submit(values),
    });
  };
  const handleUnlinkStaff = () => {
    const currentMappingStaffs = form.getFieldsValue();
    const staffsConnected = staffIdCSSourceId(staffs);
    setIsShowAlertConfirmDisconnectStaff(false); // reset when form data change
    // staff CS sourceID = 0, => disconnect => show alert
    for (const staff in staffsConnected) {
      if (staffsConnected[staff] !== undefined && currentMappingStaffs[staff] === 0) {
        setIsShowAlertConfirmDisconnectStaff(true);
      }
    }
  };

  return (
    <Form
      className="salon-integrate-staff-form"
      form={form}
      name="salonConnectStaffForm"
      onFinish={values => {
        if (isShowAlertConfirmDisconnectStaff) {
          confirmDisconnectStaff(values);
        } else {
          submit(values);
        }
      }}
      initialValues={staffIdCSSourceId(staffs)}
    >
      {loading && <BigSpin />}
      <div style={{ marginTop: 16, marginBottom: 24 }}>
        {!CSContext.isOpenInitSettingMappingPopup && renderButtonDisconnect3rd(connectingParty)}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          isShowAlertCompleteMapping === undefined &&
          <div style={{ marginBottom: 16 }}>
            <Alert message={lang.warningCompleteMapping} type="warning" showIcon />
          </div>
        }
        <div><Text strong>{lang.csConnectStaffTitle}</Text></div>
        <div className='column-title-form'>
          <div className ='column-title-staff-salon'>
            <Text strong>{lang.salonStaff}</Text>
          </div>
          <div className='column-title-cs-staff'>
            <Text strong>{lang.comingSoonStaff}</Text>
          </div>
        </div>
        {
          staffs.map(s => (
            <Space key={s.id} className='staff-row-info' >
              <div style={{ display: 'flex', alignItems: 'center', width: 250 }}>
                <Avatar src={s?.connections[0]?.data?.avatarLink} size='large' style={{ marginRight: 8 }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {<span>{s?.connections[0]?.data?.username}</span>}
                  {<span>{s?.connections[0]?.data?.name}</span>}
                </div>
              </div>
              <Space style={{ width: 120 }}>
                <LinkOutlined />
              </Space>
              <div id="select-cs-staff">
                <Form.Item
                  name={s.id}
                >
                  <Select style={{ width: 220 }}
                    showSearch
                    onChange={() => {
                      forceRender([]);
                      handleUnlinkStaff();
                    }
                    }
                    placeholder={lang.selectStaffPlaceholder}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    {
                      [<Option key={0} value={0}> {lang.disconnectThirdParty}</Option>].concat(staffsComingSoon.map(item => (<Option disabled={selectedCSStaff.includes(item.sourceId)} key={item.id} value={item.sourceId}>{item.name}</Option>)))
                    }
                  </Select>
                </Form.Item>
              </div>
            </Space>
          ))
        }

      </div>

      <div className={`submit-section-on-screen ${CSContext.isOpenInitSettingMappingPopup ? 'submit-section-popup' : ''} `}>
        <span>{CSContext.isOpenInitSettingMappingPopup && renderButtonDisconnect3rd(connectingParty)}</span>
        <Button block type='primary' style={{ width: '30%' }} htmlType='submit'>{lang.save}</Button>
      </div>
    </Form>
  );
};

SalonStaffConnectComingSoonStaff.propTypes = {
  staffs: PropTypes.array,
  getStaffList: PropTypes.func,
  connectingParty: PropTypes.string,
  renderButtonDisconnect3rd: PropTypes.func,
  staffsComingSoon: PropTypes.array,
};

StaffsMappingContainer.propTypes = {
  connectingParty: PropTypes.string,
  renderButtonDisconnect3rd: PropTypes.func,
};

const staffIdCSSourceId = (staffs = []) => {
  const initValue = {};
  const result = staffs.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: item.thirdParties[0]?.sourceId,
    };
  }, initValue);
  return result;
};

export default StaffsMappingContainer;
