import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { Modal, Row, Col } from 'antd';
import _ from 'lodash';

import './styles.less';

const WeekViewModal = ({ weekViewModal, setWeekViewModal }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const dayName = {
    0: lang.sunday,
    1: lang.monday,
    2: lang.tuesday,
    3: lang.wednesday,
    4: lang.thursday,
    5: lang.friday,
    6: lang.saturday,
  };

  const startSchedule = moment(_.get(weekViewModal, 'schedules[0].startSchedule'));

  return (
    <Modal
      visible={!!weekViewModal}
      title={`${_.get(weekViewModal, 'staffName', '')} ${lang.workingTime}`}
      onCancel={() => setWeekViewModal(null)}
      destroyOnClose={true}
      width={500}
      centered
      footer={null}
      maskClosable={false}
    >
      <div style={{ marginLeft: 48 }}>
        <div style={{ marginRight: 4, marginBottom: 8 }}>
          {lang.staffWorkingTimeViewModalContentHead}{startSchedule.format('YYYY-MM-DD')}{lang.staffWorkingTimeViewModalContentTail}
        </div>
        {[1, 2, 3, 4, 5, 6, 0].map(key => {
          const schedulesByDay = _.get(weekViewModal, 'schedules', []).filter(item => item.dayOfWeek === key);
          if (schedulesByDay.length === 0) {
            return (
              <Row key={key} style={{ display: 'flex' }}>
                <Col span={6} className={`day-of-week ${(key === 0 && 'sun') || (key === 6 && 'sat')}`}>{dayName[key]}:</Col>
                <Col span={6}>{lang.off}</Col>
              </Row>
            );
          }

          const startAt = moment(schedulesByDay[0].startAt).format('HH:mm');
          const endAt = moment(schedulesByDay[0].endAt).format('HH:mm');
          return (
            <Row key={key} style={{ display: 'flex' }}>
              <Col span={6} className={`day-of-week ${(key === 0 && 'sun') || (key === 6 && 'sat')}`}>{dayName[key]}:</Col>
              {startAt === endAt
                ? <Col span={6} style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{lang.off}</Col>
                : <Col span={6}>{startAt} - {endAt}</Col>
              }
            </Row>
          );
        })}
      </div>
    </Modal>
  );
};

WeekViewModal.propTypes = {
  weekViewModal: PropTypes.object,
  setWeekViewModal: PropTypes.func,
};

export default WeekViewModal;
