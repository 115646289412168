import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import _ from 'lodash';
import { Layout, Menu, Dropdown, Modal, Row, Col, Button, Affix, Tooltip, Spin, Badge } from 'antd';
import {
  ExclamationCircleOutlined,
  SettingOutlined,
  IdcardOutlined,
  PayCircleOutlined,
  LogoutOutlined,
  EditOutlined,
  MailOutlined,
  FormOutlined,
  LinkOutlined,
  ScheduleOutlined,
  MenuUnfoldOutlined,
  UsergroupAddOutlined,
  OrderedListOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import logo from 'assets/images/nlsb-logo.png';
import logoFss from 'assets/images/FSS/becolorful-logo-3x.png';
import './styles.less';
import { useDispatch } from 'hooks/useCustomDispatch';
import { changePassword } from 'providers/AuthProvider/actions';
import { salonDetail } from 'providers/SalonProvider/actions';

import { getLanguages } from 'utils/lang';
import { ToggleLanguageButton } from 'components/Desktop/UI';
import { useSelector } from 'react-redux';
import GetSalonSurveyResults from 'utils/salonSurveyResult';
import environment from 'environment';
import SharedSalonEnv, { checkAndRedirectSalonType, SALON_HIDDEN_TYPES } from 'utils/SharedSalonEnv';
import { keepLocalStorageBeforeLogout } from 'utils/common';
import ChangeEmailModal from 'components/Desktop/Modal/ChangeEmailModal';

const { Sider } = Layout;

const MySalonLayout = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // useBookingNotificationNumBadge();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const [user, setUser] = useState();
  const { salon, needUpdateSalonSurvey } = useSelector(state => state.salon);
  const salonType = salon?.salonType;
  const { notification } = useSelector(state => state.booking);
  const numberOfNotification = _.get(notification, 'numUnread');
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const needUpdatedProfileInfo = salon && (!salon?.logoUrl || !salon?.location?.building || !salon?.description || salon?.properties?.length === 0 || salon?.photos?.length === 0);
  const needUpdatedSalonBasicInfo = needUpdatedProfileInfo || !salon?.bankInfo || needUpdateSalonSurvey;
  const pathname = useLocation().pathname;
  const { getAccessTokenSilently } = useAuth0();
  const mainLogo = SharedSalonEnv.isFSS() ? logoFss : logo;
  // Just show this popup once time and If Salon Skip the popup, do not show again.
  const showPopupEditStation = (salon = {}) => {
    let editStationPopupShownSalonIdsList = localStorage.getItem('editStationPopupShownSalonIdsList');
    try {
      editStationPopupShownSalonIdsList = JSON.parse(editStationPopupShownSalonIdsList || '') || [];
    } catch {
      editStationPopupShownSalonIdsList = [];
    }
    if (!salon.location?.station?.stationId && !editStationPopupShownSalonIdsList.includes(salon.id)) {
      Modal.confirm({
        title: lang.updateStationPopupTitle,
        width: '700px',
        icon: <ExclamationCircleOutlined />,
        content: lang.updateStationPopupContent,
        okText: lang.updateStationPopupOkText,
        centered: true,
        cancelText: lang.updateStationPopupCancelText,
        maskClosable: false,
        closable: false,
        keyboard: false,
        onOk: () => {
          navigate('/mysalon/settings');
          editStationPopupShownSalonIdsList.push(salon.id);
          localStorage.setItem('editStationPopupShownSalonIdsList', JSON.stringify(editStationPopupShownSalonIdsList));
          setTimeout(() => document.getElementById('salon_station').scrollIntoView({ behavior: 'smooth' }), 1200);
        },
        onCancel: () => {
          editStationPopupShownSalonIdsList.push(salon.id);
          localStorage.setItem('editStationPopupShownSalonIdsList', JSON.stringify(editStationPopupShownSalonIdsList));
        },
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const showPopupNewProperties = (salon = {}) => {
    // Do not show this popup for Shared and Five Star Studio salon
    if (SALON_HIDDEN_TYPES.includes(salon?.salonType)) {
      return;
    }

    let newSalonPropertiesPopupShownSalonIdsList = localStorage.getItem('newSalonPropertiesPopupShownSalonIdsList');
    try {
      newSalonPropertiesPopupShownSalonIdsList = JSON.parse(newSalonPropertiesPopupShownSalonIdsList || '') || [];
    } catch {
      newSalonPropertiesPopupShownSalonIdsList = [];
    }
    if (!salon?.properties.some(p => ['MEN_NAILS', 'PARAGEL'].includes(p.type)) && !newSalonPropertiesPopupShownSalonIdsList.includes(salon.id)) {
      Modal.confirm({
        title: lang.updatePropertiesPopupTitle,
        width: '700px',
        icon: <ExclamationCircleOutlined />,
        content: lang.updatePropertiesPopupContent,
        okText: lang.updateStationPopupOkText,
        centered: true,
        cancelText: lang.updateStationPopupCancelText,
        maskClosable: false,
        closable: false,
        keyboard: false,
        onOk: () => {
          navigate('/mysalon/settings');
          newSalonPropertiesPopupShownSalonIdsList.push(salon.id);
          localStorage.setItem('newSalonPropertiesPopupShownSalonIdsList', JSON.stringify(newSalonPropertiesPopupShownSalonIdsList));
          setTimeout(() => document.getElementById('salon_properties').scrollIntoView({ behavior: 'smooth' }), 1200);
        },
        onCancel: () => {
          newSalonPropertiesPopupShownSalonIdsList.push(salon.id);
          localStorage.setItem('newSalonPropertiesPopupShownSalonIdsList', JSON.stringify(newSalonPropertiesPopupShownSalonIdsList));
        },
      });
    }
  };

  useEffect(() => {
    window.logout = logout;

    const getUserMetadata = async () => {
      try {
        const token = await getAccessTokenSilently({ audience: environment.api.auth0Audience });
        const user = jwtDecode(token);
        setUser(user);

        const result = await dispatch(salonDetail({
          salonId: _.get(user, '[https://user_metadata].salon_id'),
        }));
        if (_.get(result, 'data.location') === null) {
          navigate('/complete-profile');
        } else if (_.get(result, 'data.schedules', []).length === 0) {
          navigate('/complete-schedule');
        } else {
          showPopupNewProperties(result?.data);
          showPopupEditStation(result.data);
          setLoading(false);
        }
        await GetSalonSurveyResults(result.data, dispatch);
        // check salon-type, show alert and redirect to right location to login
        checkAndRedirectSalonType(result?.data?.salonType);
      } catch (e) {
        console.log(e);
      }
    };

    getUserMetadata();
  }, []);

  const handleLogout = () => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.confirmLogout,
      okText: lang.sure,
      cancelText: lang.no,
      onOk: () => {
        keepLocalStorageBeforeLogout();
        logout({ returnTo: window.location.origin });
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" disabled style={{ cursor: 'default' }}>
        <div>
          {_.get(salon, 'businessName')}
          <br />
          {_.get(user, '[https://email]')}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => setChangeEmailModal(true)}>
        <MailOutlined /> {lang.changeEmailAddress}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setChangePasswordModal(true)}>
        <EditOutlined /> {lang.changePassword}
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        <LogoutOutlined /> {lang.logout}
      </Menu.Item>
    </Menu>
  );

  let defaultOpenKeys = [];
  if (
    ['/mysalon', '/mysalon/services', '/mysalon/customers', '/mysalon/settings'].includes(pathname)
  ) {
    defaultOpenKeys = ['submenu1'];
  }
  if (
    ['/mysalon/staff-member', '/mysalon/staff-schedule', '/mysalon/staff-overview'].includes(
      pathname,
    )
  ) {
    defaultOpenKeys = ['submenu2'];
  }

  const onChangePassword = async () => {
    try {
      dispatch(
        changePassword({
          salonId: _.get(user, '[https://user_metadata].salon_id'),
        }),
      ).then(() => {
        setChangePasswordModal(false);
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return (
      <div className="center-full-screen-container">
        <Spin size="large" />
      </div>
    );
  }

  const selectedKeys = [pathname];
  if (pathname.includes('/mysalon/staff-member')) {
    selectedKeys.push('/mysalon/staff-member');
  }
  if (pathname === '/mysalon/salon-sales-detail') {
    selectedKeys.push('/mysalon/transfer-management');
  }

  return (
    <Layout className="mysalon-layout">
      <Affix offsetTop={0}>
        <Sider className="sider">
          <div className="sider-left">
            <div>
              {/* Logo */}
              <div className="logo-wrapper">
                <img src={mainLogo} className="logo" alt="Salon Board" />
              </div>
              {/* Salon Name */}
              <div className="salon-name">
                <div>{_.get(salon, 'businessName')}</div>
              </div>
              {/* Menu */}
              <Menu
                className="menu-list"
                mode="inline"
                selectedKeys={selectedKeys}
                defaultOpenKeys={defaultOpenKeys}
              >
                <Menu.Item
                  key="/mysalon/staff-schedule"
                  onClick={() => navigate('/mysalon/staff-schedule')}
                  icon ={<ScheduleOutlined />}
                >
                  <Badge Badge offset={[10, 5]} dot={numberOfNotification > 0 }>{lang.manageBookings}</Badge>
                </Menu.Item>
                <Menu.Item
                  onClick={() => navigate('/mysalon/bookings')}
                  key="/mysalon/bookings"
                  icon = { <OrderedListOutlined />}
                >
                  {lang.searchBookings}
                </Menu.Item>
                {
                  !SALON_HIDDEN_TYPES.includes(salonType) &&
                  <Menu.Item key="/mysalon/menu-settings"
                    onClick={() => navigate('/mysalon/menu-settings')}
                    icon ={ <MenuUnfoldOutlined />}
                  >
                    {lang.menuSettings}
                  </Menu.Item>
                }
                <Menu.Item
                  key="/mysalon/staff-member"
                  onClick={() => navigate('/mysalon/staff-member')}
                  icon ={ <UsergroupAddOutlined />}
                >
                  {lang.staffMember}
                </Menu.Item>
                <Menu.Item
                  onClick={() => navigate('/mysalon/sales-management')}
                  key="/mysalon/sales-management"
                  icon={<PayCircleOutlined />}
                >
                  {lang.salesManagement}
                </Menu.Item>
                <Menu.Item
                  key="/mysalon/transfer-management"
                  onClick={() => navigate('/mysalon/transfer-management')}
                  icon={<BarChartOutlined />}
                >
                  {lang.transferManagement}
                </Menu.Item>
                <Menu.Item
                  key="/mysalon/settings"
                  onClick={() => navigate('/mysalon/settings')}
                  icon={<IdcardOutlined />}
                >
                  <Badge Badge offset={[10, 5]} dot={needUpdatedSalonBasicInfo}>{lang.accountSettings}</Badge>
                </Menu.Item>

              </Menu>
            </div>
            {/* Profile */}

            <div className="sider-bottom">
              <div className="sider-bottom-account">
                <Dropdown
                  trigger={['click']}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  overlay={menu}
                  placement="topRight"
                >
                  <Tooltip placement="right" mouseEnterDelay={0.4} title={lang.settings}>
                    <Button
                      size="large"
                      icon={<SettingOutlined className="sider-bottom-setting" />}
                      onClick={(e) => e.preventDefault()}
                    />
                  </Tooltip>
                </Dropdown>
                {!process.env.REACT_APP_DISABLE_CHANGE_LANGUAGE && <ToggleLanguageButton className="sider-bottom-lang" />}
                <div className="sider-bottom-version">{`v${process.env.REACT_APP_VERSION}`}</div>
              </div>
            </div>
          </div>

          <Modal
            visible={changePasswordModal}
            title={lang.changePasswordProcess}
            onCancel={() => setChangePasswordModal(false)}
            destroyOnClose={true}
            centered
            width={650}
            footer={null}
            maskClosable={false}
          >
            <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={7}>
                <div>
                  <MailOutlined /> 1.{lang.receiveEmail}
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <LinkOutlined /> 2.{lang.clickEmailLink}
                </div>
              </Col>
              <Col span={8}>
                <div>
                  <FormOutlined /> 3.{lang.enterNewPassword}
                </div>
              </Col>
            </Row>
            <div
              className="ant-modal-footer"
              style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 16 }}
            >
              <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
                <Col
                  className="gutter-row"
                  span={24}
                  style={{ textAlign: 'right', paddingRight: 20 }}
                >
                  <Button
                    type="clear"
                    onClick={() => setChangePasswordModal(false)}
                    style={{ fontWeight: 'bold' }}
                  >
                    {lang.cancel}
                  </Button>
                  <Button onClick={onChangePassword} type="primary" style={{ fontWeight: 'bold' }}>
                    {lang.confirm}
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>
          <ChangeEmailModal
            salon={salon}
            isChangeEmailModal={changeEmailModal}
            setIsChangeEmailModal={setChangeEmailModal}
          />
        </Sider>
      </Affix>
      <Outlet />
    </Layout>
  );
};

export default MySalonLayout;
