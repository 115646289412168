import {
  SERVICE_DETAIL,
  SERVICE_CREATE,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_FAIL,
  SERVICE_UPDATE,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
  SERVICE_ORDER,
  SERVICE_ORDER_SUCCESS,
  SERVICE_ORDER_FAIL,
  SERVICE_DELETE,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,
} from './types';

const INITIAL_STATE = {
  services: [],
  service: {},
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SERVICE_DETAIL:
      return {
        ...state,
        service: action.payload,
      };

    case SERVICE_CREATE:
      return {
        ...state,
      };

    case SERVICE_CREATE_SUCCESS:
      return {
        ...state,
      };

    case SERVICE_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SERVICE_UPDATE:
      return {
        ...state,
      };

    case SERVICE_UPDATE_SUCCESS:
      return {
        ...state,
      };

    case SERVICE_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SERVICE_ORDER:
      return {
        ...state,
      };

    case SERVICE_ORDER_SUCCESS:
      return {
        ...state,
      };

    case SERVICE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SERVICE_DELETE:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SERVICE_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
