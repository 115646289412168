// Libraries
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Table, Typography, Row, Col, Form, DatePicker, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import _get from 'lodash/get';

// Customs
import { useDispatch } from 'hooks/useCustomDispatch';

// Components
import PageLayout from 'components/Desktop/Layout/PageLayout';

// Providers
import { getListSalonSaleReport, getListStaffSaleReportBySalon } from 'providers/SalonProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import { formatYen } from 'utils/stringFormat';
import { SALES_CARD_BORDER_COLOR, SALES_REPORT_PERIOD } from 'utils/constants';
import { useSearchParams, shouldSetSearchParams } from 'hooks/useCustomSearchParams';
import { getPeriodDate } from 'utils/common';
import SalesReportHelper from 'utils/salesReportHelper';

import './style.less';

const { Title } = Typography;
const { Option } = Select;

const SalesManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // Selectors
  const locale = useSelector((state) => state.i18n.locale);
  const { salon } = useSelector(state => state.salon);
  const salonId = salon.id;

  const now = moment();
  const dateFrom = now.clone().startOf('month');
  let dateTo = now.clone().endOf('month');
  let defaultPeriod = SALES_REPORT_PERIOD.firstPeriod;
  if (now.date() <= 15) {
    dateTo = dateFrom.clone().add(14, 'days').endOf('day');
  } else {
    dateFrom.add(15, 'days').startOf('day');
    defaultPeriod = SALES_REPORT_PERIOD.secondPeriod;
  }
  // <URLSearchParams>
  const [searchParamsValues, handleSetSearchParams] = useSearchParams();
  const { currentDate, period } = searchParamsValues;
  /**
   * Server Nailie: Not use timezone
   *
   * Example: {
   *  from: "2021-01-01T00:00:00.000Z",
   *  to:   "2021-01-15T23:59:59.999Z",
   * }
   */
  // Server NLSB: Not handle params are passed by web
  // toISOString add utcOffset so we subtract utcOffset before
  const utcOffset = moment.tz.zone(salon.timezone).utcOffset(moment());
  const utcOffsetHours = utcOffset / 60;
  dateFrom.subtract(utcOffsetHours, 'hours');
  dateTo.subtract(utcOffsetHours, 'hours');

  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);

  // State
  const [listSalonSaleReport, setListSalonSaleReport] = useState([]);
  const salonSaleReport = listSalonSaleReport[0] || {};
  const salonSaleReportExtra = salonSaleReport.extra || {};
  const doneAmounts = _get(salonSaleReportExtra, 'bookingValueDetail.DONEValue', 0) + _get(salonSaleReportExtra, 'bookingValueDetail.firstBK_DONEValue', 0);
  const canceledAmounts = _get(salonSaleReportExtra, 'bookingValueDetail.CANCELEDValue', 0) + _get(salonSaleReportExtra, 'bookingValueDetail.firstBK_CANCELEDValue', 0);
  const totalSales = _get(salonSaleReportExtra, 'totalBookingValue', 0);
  const countDone = _get(salonSaleReportExtra, 'countDetail.countDONE', 1);
  const average = doneAmounts === 0 ? doneAmounts : doneAmounts / countDone;
  const countDoneNew = _get(salonSaleReportExtra, 'countDetail.countDONE_NEW');
  const countDoneRepeater = _get(salonSaleReportExtra, 'countDetail.countDONE_REPEATER');
  const [listStaffSaleReportBySalon, setListStaffSaleReportBySalon] = useState([]);
  const [staffLoading, setStaffLoading] = useState(false);
  const [salePeriod, setSalePeriod] = useState([]);
  const startSalePeriod = moment(new Date(salePeriod[0])).add(utcOffsetHours, 'hours');
  const endSalePeriod = moment(new Date(salePeriod[1])).add(utcOffsetHours, 'hours');

  // Constants
  const routes = [
    {
      path: '/mysalon',
      breadcrumbName: lang.mySalon,
    },
    {
      path: '/sales-management',
      breadcrumbName: lang.salesManagement,
    },
  ];

  // Columns
  const columns = [
    {
      key: 'staff',
      title: lang.staff,
      dataIndex: 'staff',
      fixed: 'left',
      render: function renderItem (record) {
        const staffConnectedInfo = _get(record, 'connections[0].data', {});
        const staffName = staffConnectedInfo.name || record.name;
        return staffName;
      },
    },
    {
      key: 'countDone',
      width: '20%',
      title: lang.bookingDone,
      dataIndex: ['extra', 'countDetail', 'countDONE'],
      render: function renderItem (record) {
        return record || 0;
      },
    },
    {
      key: 'totalTreatmentSales',
      title: lang.salesAmount,
      dataIndex: ['extra', 'totalBookingValue'],
      render: function renderItem (record) {
        return formatYen(record || 0);
      },
    },
    {
      key: 'extra',
      title: lang.average,
      dataIndex: 'extra',
      render: function renderItem (record) {
        const staffDoneAmounts = _get(record, 'bookingValueDetail.DONEValue', 0) + _get(record, 'bookingValueDetail.firstBK_DONEValue', 0);
        const staffCountDone = _get(record, 'countDetail.countDONE', 1);
        const staffAverage = staffDoneAmounts === 0 ? staffDoneAmounts : staffDoneAmounts / staffCountDone;
        return formatYen(staffAverage);
      },
    },
    {
      title: '',
      key: 'Action',
      width: '25%',
      render: function renderItem (record) {
        return (
          <Button
            type='link'
            onClick={() => {
              navigate('/mysalon/sales-management/staff-sale-report', {
                state: {
                  staffId: record.staffId,
                  currentDate: currentDate,
                  period: period,
                },
              });
            }}>
            {lang.detailBtn}
          </Button>
        );
      },
    },
  ];

  const getListSalon = (startPeriod, endPeriod) => {
    dispatch(getListSalonSaleReport({
      salonId,
      from: startPeriod,
      to: endPeriod,
    }))
      .then((result) => {
        if (result?.length === 1) { // BE response only 1 period
          setListSalonSaleReport(result);
        } else if (result?.length > 1) { // BE response 2 period => summed up 2 period
          const fullMonthSaleReport = SalesReportHelper.FormatFullMonthSalesReport(result);
          setListSalonSaleReport(fullMonthSaleReport);
        } else if (result?.length === 0) { // No data
          setListSalonSaleReport([]);
        }
      });
  };

  const getListStaff = (startPeriod, endPeriod) => {
    setStaffLoading(true);
    dispatch(getListStaffSaleReportBySalon({
      salonId,
      page: 1,
      limit: 10000,
      from: startPeriod,
      to: endPeriod,
    }))
      .then((result) => { // if BE response 2 period => summed up 2 period
        const listStaff = SalesReportHelper.FormatListStaffSaleReportFullMonth(result?.data, period);
        setListStaffSaleReportBySalon(listStaff);
        setStaffLoading(false);
      });
  };

  useEffect(() => {
    const now = moment();
    const defaultSearch = {
      page: 1,
      currentDate: now.format('YYYY-MM'),
      period: defaultPeriod,
    };

    if (shouldSetSearchParams(searchParamsValues)) {
      handleSetSearchParams(defaultSearch, true);
    }
  }, [searchParamsValues]);

  useEffect(() => {
    const [startDateTime, endDateTime] = getPeriodDate(searchParamsValues.currentDate, searchParamsValues.period, utcOffsetHours);
    setSalePeriod([startDateTime, endDateTime]);
    if (startDateTime && endDateTime) {
      getListSalon(startDateTime, endDateTime);
      getListStaff(startDateTime, endDateTime);
    }
    form.setFieldsValue({
      currentDate: moment(currentDate, 'YYYY-MM'),
      period: period,
    });
  }, [currentDate, period]);

  const renderSalesCard = (langKey, amounts, isFormatNumber) => {
    return (
      <Card
        style={{
          borderRadius: 8,
          borderTop: `8px solid ${SALES_CARD_BORDER_COLOR[langKey]}`,
        }}
      >
        <Title level={5}>
          {lang[langKey]}
        </Title>
        <Title level={2} style={{ margin: 0 }}>
          {isFormatNumber ? amounts : formatYen(amounts)}
        </Title>
      </Card>
    );
  };

  return (
    <PageLayout ghost={false} title={lang.salesManagement} routes={routes}>
      <Card>
        <Form
          layout="inline"
          form={form}
          name="SaleReportForm"
          onFinish={(values) => {
            const search = {
              ...searchParamsValues,
              page: 1,
              currentDate: values.currentDate.format('YYYY-MM'),
              period: values.period,
            };
            handleSetSearchParams(search);
          }}
        >

          <Form.Item name="currentDate">
            <DatePicker
              picker="month"
            />
          </Form.Item>
          <Form.Item name="period">
            <Select
              style={{ width: 160 }}
            >
              <Option value={SALES_REPORT_PERIOD.firstPeriod}>{lang.firstPeriod}</Option>
              <Option value={SALES_REPORT_PERIOD.secondPeriod}>{lang.secondPeriod}</Option>
              <Option value={SALES_REPORT_PERIOD.fullMonth}>{lang.fullMonth}</Option>
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{lang.search}</Button>
        </Form>
        <div style={{ marginBottom: 16, marginTop: 32 }}>
          <span style={{ fontSize: 16 }}>
            {locale === 'en'
              ? `${startSalePeriod.format('YYYY-MM-DD')} to ${endSalePeriod.format('YYYY-MM-DD')}`
              : `${startSalePeriod.format('YYYY年M月D日')}~${endSalePeriod.format('M月D日の売上')}`
            }
          </span>

          <Button
            type="primary"
            ghost
            style={{ marginLeft: 24 }}
            onClickCapture={() => {
              const startDate = startSalePeriod.format('YYYY-MM-DD');
              const endDate = endSalePeriod.format('YYYY-MM-DD');
              navigate(`/mysalon/salon-sales-detail?page=1&startDate=${startDate}&endDate=${endDate}`);
            }}
          >
            {lang.details}
          </Button>
        </div>

        <Row style={{ marginBottom: 16 }}>
          <Col span={5} style={{ marginRight: 16 }}>
            {renderSalesCard('totalSales', totalSales)}
          </Col>

          <Col span={5} style={{ marginRight: 16 }}>
            {renderSalesCard('doneAmounts', doneAmounts)}
          </Col>

          <Col span={5} style={{ marginRight: 16 }}>
            {renderSalesCard('canceledAmounts', canceledAmounts)}
          </Col>

        </Row>
        <Row style={{ marginBottom: 48 }}>
          <Col span={5} style={{ marginRight: 16 }}>
            {renderSalesCard('average', average)}
          </Col>
          {
            countDoneNew >= 0 &&
          <Col span={5} style={{ marginRight: 16 }}>
            {renderSalesCard('countDoneNew', countDoneNew, true)}
          </Col>
          }
          {
            countDoneRepeater >= 0 &&
            <Col span={5} style={{ marginRight: 16 }}>
              {renderSalesCard('countDoneRepeater', countDoneRepeater, true)}
            </Col>
          }
        </Row>

        <Table
          columns={columns}
          dataSource={(listStaffSaleReportBySalon || []).filter(s => s.totalTreatmentSales > 0)}
          loading={staffLoading}
          bordered
          pagination={false}
        />
      </Card>
    </PageLayout>
  );
};

export default SalesManagement;
