import React from 'react';
import { Layout, PageHeader } from 'antd';
import { Link } from 'react-router-dom';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PageLayout = ({
  children,
  routes,
  extraHeader,
  title,
  subtitle,
  className,
  ghost = false,
  onBack,
}) => {
  return (
    <Layout className={className}>
      <PageHeader
        ghost={ghost}
        title={title}
        extra={extraHeader}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        onBack={onBack}
      />
      <Layout.Content style={{ margin: '24px' }}>{children}</Layout.Content>
    </Layout>
  );
};

export default PageLayout;
