export const SALON_DETAIL = 'SALON_DETAIL';
export const SALON_DETAIL_SUCCESS = 'SALON_DETAIL_SUCCESS';
export const SALON_DETAIL_FAIL = 'SALON_DETAIL_FAIL';

export const SALON_ACCOUNT_UPDATE = 'SALON_ACCOUNT_UPDATE';
export const SALON_ACCOUNT_UPDATE_SUCCESS = 'SALON_ACCOUNT_UPDATE_SUCCESS';
export const SALON_ACCOUNT_UPDATE_FAIL = 'SALON_ACCOUNT_UPDATE_FAIL';

export const SALON_GET_PROPERTIES = 'SALON_GET_PROPERTIES';
export const SALON_GET_PROPERTIES_SUCCESS = 'SALON_GET_PROPERTIES_SUCCESS';
export const SALON_GET_PROPERTIES_FAIL = 'SALON_GET_PROPERTIES_FAIL';

export const SALON_BANK_UPDATE = 'SALON_BANK_UPDATE';
export const SALON_BANK_UPDATE_SUCCESS = 'SALON_BANK_UPDATE_SUCCESS';
export const SALON_BANK_UPDATE_FAIL = 'SALON_BANK_UPDATE_FAIL';

export const SALON_GET_BANK = 'SALON_GET_BANK';
export const SALON_GET_BANK_SUCCESS = 'SALON_GET_BANK_SUCCESS';
export const SALON_GET_BANK_FAIL = 'SALON_GET_BANK_FAIL';

export const SALON_GET_BANK_BRANCH = 'SALON_GET_BANK_BRANCH';
export const SALON_GET_BANK_BRANCH_SUCCESS = 'SALON_GET_BANK_BRANCH_SUCCESS';
export const SALON_GET_BANK_BRANCH_FAIL = 'SALON_GET_BANK_BRANCH_FAIL';

export const SALON_SCHEDULE_UPDATE = 'SALON_SCHEDULE_UPDATE';
export const SALON_SCHEDULE_UPDATE_SUCCESS = 'SALON_SCHEDULE_UPDATE_SUCCESS';
export const SALON_SCHEDULE_UPDATE_FAIL = 'SALON_SCHEDULE_UPDATE_FAIL';

export const SALON_SETTINGS_GET = 'SALON_SETTINGS_GET';
export const SALON_SETTINGS_GET_SUCCESS = 'SALON_SETTINGS_GET_SUCCESS';
export const SALON_SETTINGS_GET_FAIL = 'SALON_SETTINGS_GET_FAIL';

export const SALON_CANCELLATION_POLICY_UPDATE = 'SALON_CANCELLATION_POLICY_UPDATE';
export const SALON_CANCELLATION_POLICY_UPDATE_SUCCESS = 'SALON_CANCELLATION_POLICY_UPDATE_SUCCESS';
export const SALON_CANCELLATION_POLICY_UPDATE_FAIL = 'SALON_CANCELLATION_POLICY_UPDATE_FAIL';

export const SALON_GET_ALL_SURVEY = 'SALON_GET_ALL_SURVEY';
export const SALON_GET_ALL_SURVEY_SUCCESS = 'SALON_GET_ALL_SURVEY_SUCCESS';
export const SALON_GET_ALL_SURVEY_FAIL = 'SALON_GET_ALL_SURVEY_FAIL';

export const SALON_SURVEY_DETAIL = 'SALON_SURVEY_DETAIL';
export const SALON_SURVEY_DETAIL_SUCCESS = 'SALON__SURVEY_DETAIL_SUCCESS';
export const SALON_SURVEY_DETAIL_FAIL = 'SALON_SURVEY_DETAIL_FAIL';

export const SALON_UPDATE_SURVEY = 'SALON_UPDATE_SURVEY';
export const SALON_UPDATE_SURVEY_SUCCESS = 'SALON_UPDATE_SURVEY_SUCCESS';
export const SALON_UPDATE_SURVEY_FAIL = 'SALON_UPDATE_SURVEY_FAIL';

export const SALON_SURVEY_RESULT = 'SALON_SURVEY_RESULT';
export const SALON_SURVEY_RESULT_SUCCESS = 'SALON_SURVEY_RESULT_SUCCESS';
export const SALON_SURVEY_RESULT_FAIL = 'SALON_SURVEY_RESULT_FAIL';

// GET LIST SALON SALE REPORT
export const GET_LIST_SALON_SALE_REPORT = 'GET_LIST_SALON_SALE_REPORT';
export const GET_LIST_SALON_SALE_REPORT_SUCCESS = 'GET_LIST_SALON_SALE_REPORT_SUCCESS';
export const GET_LIST_SALON_SALE_REPORT_FAIL = 'GET_LIST_SALON_SALE_REPORT_FAIL';

// GET LIST STAFF SALE REPORT BY SALON
export const GET_LIST_STAFF_SALE_REPORT_BY_SALON = 'GET_LIST_STAFF_SALE_REPORT_BY_SALON';
export const GET_LIST_STAFF_SALE_REPORT_BY_SALON_SUCCESS = 'GET_LIST_STAFF_SALE_REPORT_BY_SALON_SUCCESS';
export const GET_LIST_STAFF_SALE_REPORT_BY_SALON_FAIL = 'GET_LIST_STAFF_SALE_REPORT_BY_SALON_FAIL';

// GET LIST BOOKING SALON SALE REPORT
export const GET_LIST_BOOKING_SALON_SALE_REPORT = 'GET_LIST_BOOKING_SALON_SALE_REPORT';
export const GET_LIST_BOOKING_SALON_SALE_REPORT_SUCCESS = 'GET_LIST_BOOKING_SALON_SALE_REPORT_SUCCESS';
export const GET_LIST_BOOKING_SALON_SALE_REPORT_FAIL = 'GET_LIST_BOOKING_SALON_SALE_REPORT_FAIL';

export const SALON_GET_ALL_AREAS = 'SALON_GET_ALL_AREAS';
export const SALON_GET_ALL_AREAS_SUCCESS = 'SALON_GET_ALL_AREAS_SUCCESS';
export const SALON_GET_ALL_AREAS_FAIL = 'SALON_GET_ALL_AREAS_FAIL';

// TOGGLE THIRD PARTY CONNECTIONS
export const TOGGLE_THIRD_PARTY = 'TOGGLE_THIRD_PARTY';
export const TOGGLE_THIRD_PARTY_SUCCESS = 'TOGGLE_THIRD_PARTY_SUCCESS';
export const TOGGLE_THIRD_PARTY_FAIL = 'TOGGLE_THIRD_PARTY_FAIL';

// CHANGE SALON EMAIL
export const CHANGE_SALON_EMAIL = 'CHANGE_SALON_EMAIL';
export const CHANGE_SALON_EMAIL_SUCCESS = 'CHANGE_SALON_EMAIL_SUCCESS';
export const CHANGE_SALON_EMAIL_FAIL = 'CHANGE_SALON_EMAIL_FAIL';
