import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Row, Col } from 'antd';
import './styles.less';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { ToggleLanguageButton } from 'components/Desktop/UI';
import environment from 'environment';

const Login = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const navigate = useNavigate();

  return (
    <div className="login-page">
      <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="login-page-row" >
        <Col xs={{ span: 24 }}>
          <div className="login-logo">
            <img src={environment.logo} alt="logo" style={{ height: 60 }} />
          </div>
          <div className="login-button">
            <Button type="primary" onClick={() => {
              navigate('/mysalon');
            }} className="login-form-button">
              {lang.login}
            </Button>
          </div>
        </Col>
      </Row>
      {!process.env.REACT_APP_DISABLE_CHANGE_LANGUAGE &&
      (<Row justify="center" style={{ marginTop: '24px' }}>
        <ToggleLanguageButton />
      </Row>)}
    </div>
  );
};

export default Login;
