// FETCH STAFF LIST
export const FETCH_STAFF_LIST = 'FETCH_STAFF_LIST';
export const FETCH_STAFF_LIST_SUCCESS = 'FETCH_STAFF_LIST_SUCCESS';
export const FETCH_STAFF_LIST_FAIL = 'FETCH_STAFF_LIST_FAIL';

// FETCH STAFF DETAIL
export const FETCH_STAFF_DETAIL = 'FETCH_STAFF_DETAIL';
export const FETCH_STAFF_DETAIL_SUCCESS = 'FETCH_STAFF_DETAIL_SUCCESS';
export const FETCH_STAFF_DETAIL_FAIL = 'FETCH_STAFF_DETAIL_FAIL';

// ADD STAFF
export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAIL = 'ADD_STAFF_FAIL';

// IMPORT STAFF
export const IMPORT_STAFF = 'IMPORT_STAFF';
export const IMPORT_STAFF_SUCCESS = 'IMPORT_STAFF_SUCCESS';
export const IMPORT_STAFF_FAIL = 'IMPORT_STAFF_FAIL';

// GET_INVITATION_SUGGESTION
export const GET_INVITATION_SUGGESTION_LIST = 'GET_INVITATION_SUGGESTION_LIST';
export const GET_INVITATION_SUGGESTION_LIST_SUCCESS = 'GET_INVITATION_SUGGESTION_LIST_SUCCESS';
export const GET_INVITATION_SUGGESTION_LIST_FAIL = 'GET_INVITATION_SUGGESTION_LIST_FAIL';

// CHECK STAFF INVITATION
export const CHECK_STAFF_INVITATION = 'CHECK_STAFF_INVITATION';
export const CHECK_STAFF_INVITATION_SUCCESS = 'CHECK_STAFF_INVITATION_SUCCESS';
export const CHECK_STAFF_INVITATION_FAIL = 'CHECK_STAFF_INVITATION_FAIL';

// UPDATE STAFF PROFILE
export const UPDATE_STAFF_PROFILE = 'UPDATE_STAFF_PROFILE';
export const UPDATE_STAFF_PROFILE_SUCCESS = 'UPDATE_STAFF_PROFILE_SUCCESS';
export const UPDATE_STAFF_PROFILE_FAIL = 'UPDATE_STAFF_PROFILE_FAIL';

// FETCH BANK NAME
export const FETCH_LIST_BANK_NAME = 'FETCH_LIST_BANK_NAME';
export const FETCH_LIST_BANK_NAME_SUCCESS = 'FETCH_LIST_BANK_NAME_SUCCESS';
export const FETCH_LIST_BANK_NAME_FAIL = 'FETCH_LIST_BANK_NAME_FAIL';

// FETCH BANK BRANCH
export const FETCH_LIST_BANK_BRANCH = 'FETCH_LIST_BANK_BRANCH';
export const FETCH_LIST_BANK_BRANCH_SUCCESS = 'FETCH_LIST_BANK_BRANCH_SUCCESS';
export const FETCH_LIST_BANK_BRANCH_FAIL = 'FETCH_LIST_BANK_BRANCH_FAIL';

// STAFF_DETAIL
export const STAFF_DETAIL_CATEGORIES_SERVICES = 'STAFF_DETAIL_CATEGORIES_SERVICES';
export const STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS = 'STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS';
export const STAFF_DETAIL_CATEGORIES_SERVICES_FAIL = 'STAFF_DETAIL_CATEGORIES_SERVICES_FAIL';

export const STAFF_DETAIL_ADD_SERVICES = 'STAFF_DETAIL_ADD_SERVICES';
export const STAFF_DETAIL_ADD_SERVICES_SUCCESS = 'STAFF_DETAIL_ADD_SERVICES_SUCCESS';
export const STAFF_DETAIL_ADD_SERVICES_FAIL = 'STAFF_DETAIL_ADD_SERVICES_FAIL';

// STAFF INVITATION MANAGEMENT
export const STAFF_SEND_INVITE = 'STAFF_SEND_INVITE';
export const STAFF_SEND_INVITE_SUCCESS = 'STAFF_SEND_INVITE_SUCCESS';
export const STAFF_SEND_INVITE_FAIL = 'STAFF_SEND_INVITE_FAIL';

export const STAFF_CANCEL_INVITE = 'STAFF_CANCEL_INVITE';
export const STAFF_CANCEL_INVITE_SUCCESS = 'STAFF_CANCEL_INVITE_SUCCESS';
export const STAFF_CANCEL_INVITE_FAIL = 'STAFF_CANCEL_INVITE_FAIL';

export const STAFF_DETAIL = 'STAFF_DETAIL';
export const STAFF_DETAIL_SUCCESS = 'STAFF_DETAIL_SUCCESS';
export const STAFF_DETAIL_FAIL = 'STAFF_DETAIL_FAIL';

export const STAFF_SCHEDULE_LIST = 'STAFF_SCHEDULE_LIST';
export const STAFF_SCHEDULE_LIST_SUCCESS = 'STAFF_SCHEDULE_LIST_SUCCESS';
export const STAFF_SCHEDULE_LIST_FAIL = 'STAFF_SCHEDULE_LIST_FAIL';

export const STAFF_SCHEDULE_UPDATE = 'STAFF_SCHEDULE_UPDATE';
export const STAFF_SCHEDULE_UPDATE_SUCCESS = 'STAFF_SCHEDULE_UPDATE_SUCCESS';
export const STAFF_SCHEDULE_UPDATE_FAIL = 'STAFF_SCHEDULE_UPDATE_FAIL';

export const STAFF_SCHEDULE_DAILY_UPDATE = 'STAFF_SCHEDULE_DAILY_UPDATE';
export const STAFF_SCHEDULE_DAILY_UPDATE_SUCCESS = 'STAFF_SCHEDULE_DAILY_UPDATE_SUCCESS';
export const STAFF_SCHEDULE_DAILY_UPDATE_FAIL = 'STAFF_SCHEDULE_DAILY_UPDATE_FAIL';

// GET STAFF SCHEDULE DAILY
export const GET_STAFF_SCHEDULE_DAILY = 'GET_STAFF_SCHEDULE_DAILY';
export const GET_STAFF_SCHEDULE_DAILY_SUCCESS = 'GET_STAFF_SCHEDULE_DAILY_SUCCESS';
export const GET_STAFF_SCHEDULE_DAILY_FAIL = 'GET_STAFF_SCHEDULE_DAILY_FAIL';

// GET CURRENT SCHEDULE BY STAFF
export const GET_CURRENT_SCHEDULE_BY_STAFF = 'GET_CURRENT_SCHEDULE_BY_STAFF';
export const GET_CURRENT_SCHEDULE_BY_STAFF_SUCCESS = 'GET_CURRENT_SCHEDULE_BY_STAFF_SUCCESS';
export const GET_CURRENT_SCHEDULE_BY_STAFF_FAIL = 'GET_CURRENT_SCHEDULE_BY_STAFF_FAIL';

// GET SCHEDULE BY STAFF ON A DATE
export const GET_SCHEDULE_BY_STAFF_ON_A_DATE = 'GET_SCHEDULE_BY_STAFF_ON_A_DATE';
export const GET_SCHEDULE_BY_STAFF_ON_A_DATE_SUCCESS = 'GET_SCHEDULE_BY_STAFF_ON_A_DATE_SUCCESS';
export const GET_SCHEDULE_BY_STAFF_ON_A_DATE_FAIL = 'GET_SCHEDULE_BY_STAFF_ON_A_DATE_FAIL';

// GET LIST STAFF SALE REPORT
export const GET_LIST_STAFF_SALE_REPORT = 'GET_LIST_STAFF_SALE_REPORT';
export const GET_LIST_STAFF_SALE_REPORT_SUCCESS = 'GET_LIST_STAFF_SALE_REPORT_SUCCESS';
export const GET_LIST_STAFF_SALE_REPORT_FAIL = 'GET_LIST_STAFF_SALE_REPORT_FAIL';

// GET LIST BOOKING STAFF SALE REPORT
export const GET_LIST_BOOKING_STAFF_SALE_REPORT = 'GET_LIST_BOOKING_STAFF_SALE_REPORT';
export const GET_LIST_BOOKING_STAFF_SALE_REPORT_SUCCESS = 'GET_LIST_BOOKING_STAFF_SALE_REPORT_SUCCESS';
export const GET_LIST_BOOKING_STAFF_SALE_REPORT_FAIL = 'GET_LIST_BOOKING_STAFF_SALE_REPORT_FAIL';

// REMOVE STAFF
export const REMOVE_STAFF = 'REMOVE_STAFF';
export const REMOVE_STAFF_SUCCESS = 'REMOVE_STAFF_SUCCESS';
export const REMOVE_STAFF_FAIL = 'REMOVE_STAFF_FAIL';

// UPDATE STAFF ORDER
export const UPDATE_STAFF_ORDER = 'UPDATE_STAFF_ORDER';
export const UPDATE_STAFF_ORDER_SUCCESS = 'UPDATE_STAFF_ORDER_SUCCESS';
export const UPDATE_STAFF_ORDER_FAIL = 'UPDATE_STAFF_ORDER_FAIL';

// CONNECT THIRD PARTY STAFF
export const GET_THIRD_PARTY_SOURCE_STAFF_LIST = 'GET_THIRD_PARTY_SOURCE_STAFF_LIST';
export const GET_THIRD_PARTY_SOURCE_STAFF_LIST_SUCCESS = 'GET_THIRD_PARTY_SOURCE_STAFF_LIST_SUCCESS';
export const GET_THIRD_PARTY_SOURCE_STAFF_LIST_FAIL = 'GET_THIRD_PARTY_SOURCE_STAFF_LIST_FAIL';

export const GET_THIRD_PARTY_SOURCE_ID = 'GET_THIRD_PARTY_SOURCE_ID';
export const GET_THIRD_PARTY_SOURCE_ID_SUCCESS = 'GET_THIRD_PARTY_SOURCE_ID_SUCCESS';
export const GET_THIRD_PARTY_SOURCE_ID_FAIL = 'GET_THIRD_PARTY_SOURCE_ID_FAIL';

export const UPDATE_THIRD_PARTY_SOURCE_STAFF = 'UPDATE_THIRD_PARTY_SOURCE_STAFF';
export const UPDATE_THIRD_PARTY_SOURCE_STAFF_SUCCESS = 'UPDATE_THIRD_PARTY_SOURCE_STAFF_SUCCESS';
export const UPDATE_THIRD_PARTY_SOURCE_STAFF_FAIL = 'UPDATE_THIRD_PARTY_SOURCE_STAFF_FAIL';

export const TOGGLE_THIRD_PARTY_STAFF_CONNECTION = 'TOGGLE_THIRD_PARTY_STAFF_CONNECTION';
export const TOGGLE_THIRD_PARTY_STAFF_CONNECTION_SUCCESS = 'TOGGLE_THIRD_PARTY_STAFF_CONNECTION_SUCCESS';
export const TOGGLE_THIRD_PARTY_STAFF_CONNECTION_FAIL = 'TOGGLE_THIRD_PARTY_STAFF_CONNECTION_FAIL';
