// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, Tooltip, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Avatar from 'components/Desktop/Avatar';

// Utils
import { getLanguages } from 'utils/lang';
import { CUSTOMER_RANKING, MENU_REPEAT_TYPE, PAYMENT_METHOD } from 'utils/constants';
const { Text } = Typography;

const CustomerNote = ({ extraInfo = {} }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const nailieBooked = extraInfo?.infoUserBooking?.reservation || 0;
  const delayedNum = extraInfo?.infoUserBooking?.late || 0;
  const canceledNum = extraInfo?.infoUserBooking?.cancel || 0;
  const paymentMethodCode = extraInfo?.paymentMethod;
  return (
    <div >
      <Text strong>{lang.additionalInfor}:</Text>
      <Row className="gutter-row" style={{ color: 'black', marginLeft: 20, marginBottom: 16 }}>
        <Col span={11}>
          <div> {lang.nailieBookedNote}</div>
          <div> {lang.delayedNote}</div>
          <div> {lang.canceledNote}</div>
          <br/>
          <div> {lang.paymentMethod}</div>
        </Col>
        <Col span={13}>
          <div>{nailieBooked} {lang.times}</div>
          <div>{delayedNum} {lang.times}</div>
          <div>{canceledNum} {lang.times}</div>
          <br/>
          <div>{paymentMethodCode ? lang[PAYMENT_METHOD[paymentMethodCode]?.langKey] : '--'}</div>
        </Col>
      </Row>
    </div>
  );
};
const CustomerInfo = ({ booking, isConfirmed, isCanceled }) => {
  // Language
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { customer = {}, extraInfo = {} } = booking;
  const { phonetic, name, avatar } = customer;
  const customerPhonetic = phonetic ? `(${phonetic})` : '';
  const customerBadge = extraInfo?.customerBookingBadge;
  const customerRank = extraInfo?.customerRank;

  return (
    <Col sm={{ span: 12 }} xs={{ span: 24 }}>
      <h3 style={{ fontWeight: 'bold' }}>{lang.customerInfo}</h3>
      <Row>
        {/* Customer avatar */}
        <Col span={6}>
          {avatar
            ? <Avatar size={70} src={avatar} />
            : <Avatar size={70} icon={<UserOutlined />} />
          }
        </Col>
        {/* Customer info */}
        <Col span={16} offset={2}>
          <Tooltip
            mouseEnterDelay={0.2}
            overlayInnerStyle={{ width: '320px', marginLeft: -70 }}
            color="#EBEBEB"
            title={<CustomerNote extraInfo={ booking?.extraInfo }/>} placement="right"
          >
            <Text ellipsis style={{ width: '100%' }}>{name}</Text>
            <Text ellipsis style={{ width: '100%' }}>{customerPhonetic || ''}</Text>
            {isConfirmed && <Text ellipsis style={{ width: '100%' }}>{customer.phoneNumber || 'Customer Phone number'}</Text>}
          </Tooltip>
          {
            customerRank &&
            <div style={{ marginTop: 4, marginBottom: 8 }}>
              <Tag color={CUSTOMER_RANKING[customerRank]?.color}>{lang[CUSTOMER_RANKING[customerRank]?.langKey]}</Tag>
            </div>
          }
          {
            // Not show customer booking badge with status canceled
            (customerBadge && !isCanceled) &&
            <Tag color={'cyan'}>{lang[MENU_REPEAT_TYPE[customerBadge]?.langText]}</Tag>
          }
        </Col>
      </Row>
    </Col>
  );
};

CustomerInfo.propTypes = {
  booking: PropTypes.object,
  isConfirmed: PropTypes.bool,
  isCanceled: PropTypes.bool,
};

export default CustomerInfo;
